import {
  CheckoutTransactionItem,
  CompanyVatStatus,
  PortionPercentScaledNumber,
  MeroCurrency,
  CheckoutItemTotalScaledNumber,
  PositiveInt,
} from '@mero/api-sdk';
import { AppointmentId } from '@mero/api-sdk/dist/calendar';
import { CheckoutTransactionDetails } from '@mero/api-sdk/dist/checkout/checkoutTransactionDetails';
import { isItemPaidWithMembership } from '@mero/api-sdk/dist/checkout/checkoutTransactionDetails/utils';
import { CheckoutTransactionId } from '@mero/api-sdk/dist/checkout/checkoutTransactionId';
import { NO_VAT } from '@mero/api-sdk/dist/checkout/companies/companyVatStatus/noVat';
import {
  colors,
  H2s,
  Label,
  SmallBody,
  styles as meroStyles,
  Title,
  useShowError,
  Spacer,
  Line,
  Row,
} from '@mero/components';
import { capitalize, formatDurationInMinutes, formatPhoneNumber } from '@mero/shared-components';
import { MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import { flow, pipe } from 'fp-ts/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, useWindowDimensions } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import Icon from '@mero/components/lib/components/Icon';
import Column from '@mero/components/lib/components/Layout/Column';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import Body from '@mero/components/lib/components/Text/Body';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CashRegistryContext } from '../../../contexts/CashRegistryContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutStackParamList } from '../../../types';
import { getMeasure } from '../../../utils/products';
import { divideScaled, scaledToString } from '../../../utils/scaled';
import { styles } from './AddProceedMenu.styles';
import DeleteProceedDialog from './DeleteProceedDialog';
import ProceedDetailsOptionsMenu from './ProceedDetailsOptionsMenu';
import { computeFinishedTransactionPaymentTotal } from './utils';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<AuthorizedStackParamList & CheckoutStackParamList, 'ProceedDetailsScreen'>;

const ProceedDetailsScreen: React.FC<Props> = ({ navigation, route, page }): React.ReactElement | null => {
  const { t } = useTranslation('checkout');
  const { isPhone, isDesktop } = useMediaQueries();
  const { width } = useWindowDimensions();

  const { checkoutTransactionId, appointmentId, occurrenceIndex, backMode } = route.params;

  const [isLoading, setIsLoading] = React.useState(false);
  const [proceedDetails, setProceedDetails] = React.useState<CheckoutTransactionDetails.Any | undefined>();
  const [showOptionsMenu, setShowOptionsMenu] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [, { loadTransactions }] = CashRegistryContext.useContext();

  const goBack = useGoBack();
  const showError = useShowError();

  const onGoBack = React.useCallback(() => {
    if (backMode === 'all') {
      navigation.popToTop();
    }
    goBack();
  }, [backMode, navigation, goBack]);

  const groupByType = (items: CheckoutTransactionDetails.Any['items']) => {
    const bookingItems = items.filter((item) => item.type === 'Booking');
    const serviceItems = items.filter((item) => item.type === 'Service');
    const productItems = items.filter((item) => item.type === 'Product');
    const membershipItems = items.filter((item) => item.type === 'Membership');
    const membershipInstallmentItems = items.filter((item) => item.type === 'MembershipInstallment');
    const amountItems = items.filter((item) => item.type === 'Amount');

    return [
      ...bookingItems,
      ...serviceItems,
      ...productItems,
      ...membershipItems,
      ...membershipInstallmentItems,
      ...amountItems,
    ];
  };

  const loadProceedDetails = async (
    checkoutTransactionId: CheckoutTransactionId,
    appointmentId?: AppointmentId,
    occurrenceIndex?: number,
  ) => {
    setIsLoading(true);
    try {
      const proceedDetails = await (appointmentId
        ? meroApi.checkout.getAppointmentTransactionById({
            pageId: page.details._id,
            transactionId: checkoutTransactionId,
            appointmentId,
            occurrenceIndex,
          })
        : meroApi.checkout.getTransaction({
            pageId: page.details._id,
            transactionId: checkoutTransactionId,
          }));

      setProceedDetails(proceedDetails);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteProceed = async () => {
    setShowDeleteDialog(true);
  };

  const hideDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const onDeleteSuccess = () => {
    onGoBack();
    loadTransactions({ pageId: page.details._id });
  };

  React.useEffect(() => {
    loadProceedDetails(checkoutTransactionId, appointmentId, occurrenceIndex);
  }, [checkoutTransactionId]);

  const getClient = (client: CheckoutTransactionDetails.Any['client']) => {
    if (!client) {
      return t('noClient');
    }

    if (!client.user.profile.firstname && !client.user.profile.lastname) {
      return `${t('noName')} - ${formatPhoneNumber(client.user.phone)}`;
    }

    return `${client.user.profile.firstname ? `${client.user.profile.firstname} ` : ''}${
      client.user.profile.lastname ? `${client.user.profile.lastname} ` : ''
    } - ${formatPhoneNumber(client.user.phone)}`;
  };

  const getItemDetails = (item: CheckoutTransactionDetails.Any['items'][number]) => {
    if (!proceedDetails) {
      return null;
    }
    switch (item.type) {
      case 'Booking': {
        return (
          <React.Fragment key={item.appointmentId}>
            {item.items.map((subItem, index) => {
              const hasDiscount =
                subItem.total.discount &&
                (subItem.total.discount.type === 'Value'
                  ? ScaledNumber.toNumber(subItem.total.discount.value.amount)
                  : subItem.total.discount.percent.value.toFixed()) !== 0;

              const totalWithDiscount = CheckoutItemTotalScaledNumber.getTotal(
                subItem.total,
                proceedDetails.company.type === 'Company' ? proceedDetails.company.company.vatStatus : NO_VAT,
                2,
              ).amount;

              const quantity = PositiveInt.JSON.is(subItem.quantity)
                ? subItem.quantity
                : ScaledNumber.toNumber(subItem.quantity);

              const paidWithMembership = isItemPaidWithMembership(subItem, proceedDetails.payments);
              const itemUnitPriceWithMembership = divideScaled(
                paidWithMembership.isPaidWithMembership
                  ? paidWithMembership.effectivePrice.amount.amount
                  : subItem.total.amount.amount,
                quantity,
              );

              const itemUnitPriceWithDiscount = divideScaled(totalWithDiscount, quantity);

              const itemUnitPrice = divideScaled(subItem.total.amount.amount, quantity);

              const unitDiscount = ScaledNumber.sub(itemUnitPrice, itemUnitPriceWithDiscount);
              const unitMembershipDiscount = ScaledNumber.sub(itemUnitPrice, itemUnitPriceWithMembership);

              const measure = subItem.type === 'Product' ? getMeasure(subItem.product.measure) : null;

              return subItem.type === 'Service' ? (
                <Column key={index}>
                  <Row>
                    <Column style={{ width: '50%', paddingRight: 16 }}>
                      <Title style={{ fontSize: 14 }} numberOfLines={1} ellipsizeMode="tail">
                        {subItem.service.name} {formatDurationInMinutes(subItem.service.durationInMinutes)}
                      </Title>
                      <Spacer size={4} />
                      {subItem.saleOwner ? (
                        <SmallBody style={{ color: colors.BLACK }}>
                          {subItem.saleOwner.profile.firstname} {subItem.saleOwner.profile.lastname}
                        </SmallBody>
                      ) : null}
                      <Spacer size={4} />
                      <SmallBody style={{ color: colors.COMET }}>
                        {paidWithMembership.isPaidWithMembership
                          ? ScaledNumber.toNumber(paidWithMembership.effectivePrice.amount.amount) === 0
                            ? t('paidWithMembershipUnlimited')
                            : t('paidWithMembership')
                          : capitalize(
                              DateTime.fromJSDate(item.start)
                                .toFormat('ccc dd MMM yyyy, HH:mm', { locale: 'ro' })
                                .toLowerCase(),
                            )}
                      </SmallBody>
                    </Column>
                    <SmallBody style={{ width: '20%', textAlign: 'right', paddingRight: 8, color: colors.COMET }}>
                      x
                      {PositiveInt.JSON.is(subItem.quantity)
                        ? subItem.quantity
                        : ScaledNumber.toNumber(subItem.quantity)}
                    </SmallBody>
                    <Column style={{ width: '30%' }}>
                      <Title style={{ textAlign: 'right', fontSize: 14 }}>
                        {scaledToString(itemUnitPrice)} {t(subItem.total.amount.unit)}
                      </Title>
                      {paidWithMembership.isPaidWithMembership &&
                      ScaledNumber.toNumber(paidWithMembership.effectivePrice.amount.amount) !== 0 ? (
                        <SmallBody
                          style={{
                            textAlign: 'right',
                            fontSize: 12,
                            color: colors.RADICAL_RED,
                          }}
                        >
                          -{scaledToString(unitMembershipDiscount)} {t(subItem.total.amount.unit)}
                        </SmallBody>
                      ) : hasDiscount ? (
                        <SmallBody
                          style={{
                            textAlign: 'right',
                            fontSize: 12,
                            color: colors.RADICAL_RED,
                          }}
                        >
                          -{scaledToString(unitDiscount)} {t(subItem.total.amount.unit)}
                        </SmallBody>
                      ) : null}
                    </Column>
                  </Row>

                  <Spacer size={8} />
                </Column>
              ) : (
                <React.Fragment key={subItem.product._id}>
                  <Column>
                    <Row>
                      <Column style={{ width: '50%', paddingRight: 16 }}>
                        <Title style={{ fontSize: 14 }}>{subItem.product.name}</Title>
                        <Spacer size={4} />
                        {subItem.saleOwner ? (
                          <SmallBody style={{ color: colors.BLACK }}>
                            {subItem.saleOwner.profile.firstname} {subItem.saleOwner.profile.lastname}
                          </SmallBody>
                        ) : null}
                        {/*{paidWithMembership.isPaidWithMembership ? (*/}
                        {/*  <SmallBody style={{ color: colors.COMET }}>*/}
                        {/*    {ScaledNumber.toNumber(paidWithMembership.effectivePrice.amount.amount) === 0*/}
                        {/*      ? t('paidWithMembershipUnlimited')*/}
                        {/*      : t('paidWithMembership')}*/}
                        {/*  </SmallBody>*/}
                        {/*) : null}}*/}
                        {measure || subItem.product.barcode ? (
                          <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                            {measure}
                            {measure && subItem.product.barcode ? ` - ` : ''}
                            {subItem.product.barcode?.value}
                          </SmallBody>
                        ) : null}
                      </Column>
                      <SmallBody style={{ width: '20%', textAlign: 'right', paddingRight: 8, color: colors.COMET }}>
                        x{scaledToString(subItem.quantity)}
                      </SmallBody>
                      <Column style={{ width: '30%' }}>
                        <Title style={{ textAlign: 'right', fontSize: 14 }}>
                          {scaledToString(itemUnitPriceWithMembership)} {t(subItem.total.amount.unit)}
                        </Title>
                        {/*{paidWithMembership.isPaidWithMembership &&*/}
                        {/*ScaledNumber.toNumber(paidWithMembership.effectivePrice.amount.amount) !== 0 &&*/}
                        {/*!ScaledNumber.equals(item.total.amount.amount, paidWithMembership.effectivePrice.amount.amount) ? (*/}
                        {/*  <SmallBody*/}
                        {/*    style={{*/}
                        {/*      textAlign: 'right',*/}
                        {/*      fontSize: 12,*/}
                        {/*      color: colors.COMET,*/}
                        {/*      textDecorationLine: 'line-through',*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    {scaledToString(itemUnitPriceWithDiscount)} {t(item.total.amount.unit)}*/}
                        {/*  </SmallBody>*/}
                        {/*) : */}
                        {hasDiscount ? (
                          <SmallBody
                            style={{
                              textAlign: 'right',
                              fontSize: 12,
                              color: colors.RADICAL_RED,
                            }}
                          >
                            -{scaledToString(unitDiscount)} {t(subItem.total.amount.unit)}
                          </SmallBody>
                        ) : null}
                      </Column>
                    </Row>
                  </Column>
                  <Spacer size={8} />
                </React.Fragment>
              );
            })}
            <Spacer size={8} />
            <Line />
            <Spacer size={16} />
          </React.Fragment>
        );
      }
      case 'Service': {
        const hasDiscount =
          item.total.discount &&
          (item.total.discount.type === 'Value'
            ? ScaledNumber.toNumber(item.total.discount.value.amount)
            : item.total.discount.percent.value.toFixed()) !== 0;

        const totalWithDiscount = CheckoutItemTotalScaledNumber.getTotal(
          item.total,
          proceedDetails.company.type === 'Company' ? proceedDetails.company.company.vatStatus : NO_VAT,
          2,
        ).amount;

        const paidWithMembership = isItemPaidWithMembership(item, proceedDetails.payments);
        const itemUnitPriceWithMembership = divideScaled(
          paidWithMembership.isPaidWithMembership
            ? paidWithMembership.effectivePrice.amount.amount
            : item.total.amount.amount,
          item.quantity,
        );

        const itemUnitPriceWithDiscount = divideScaled(totalWithDiscount, item.quantity);

        const itemUnitPrice = divideScaled(item.total.amount.amount, item.quantity);

        const unitDiscount = ScaledNumber.sub(itemUnitPrice, itemUnitPriceWithDiscount);
        const unitMembershipDiscount = ScaledNumber.sub(itemUnitPrice, itemUnitPriceWithMembership);

        return (
          <React.Fragment key={item.service._id}>
            <Column>
              <Row>
                <Column style={{ width: '50%', paddingRight: 16 }}>
                  <Title style={{ fontSize: 14 }} numberOfLines={1} ellipsizeMode="tail">
                    {item.service.name} ({formatDurationInMinutes(item.service.durationInMinutes)})
                  </Title>
                  <Spacer size={4} />
                  {item.saleOwner ? (
                    <SmallBody style={{ color: colors.BLACK }}>
                      {item.saleOwner.profile.firstname} {item.saleOwner.profile.lastname}
                    </SmallBody>
                  ) : null}
                  {paidWithMembership.isPaidWithMembership ? (
                    <SmallBody style={{ color: colors.COMET }}>
                      {ScaledNumber.toNumber(paidWithMembership.effectivePrice.amount.amount) === 0
                        ? t('paidWithMembershipUnlimited')
                        : t('paidWithMembership')}
                    </SmallBody>
                  ) : null}
                </Column>
                <SmallBody style={{ width: '20%', textAlign: 'right', paddingRight: 8, color: colors.COMET }}>
                  x{item.quantity}
                </SmallBody>
                <Column style={{ width: '30%' }}>
                  <Title style={{ textAlign: 'right', fontSize: 14 }}>
                    {scaledToString(itemUnitPrice)} {t(item.total.amount.unit)}
                  </Title>
                  {paidWithMembership.isPaidWithMembership &&
                  ScaledNumber.toNumber(paidWithMembership.effectivePrice.amount.amount) !== 0 &&
                  !ScaledNumber.equals(item.total.amount.amount, paidWithMembership.effectivePrice.amount.amount) ? (
                    <SmallBody
                      style={{
                        textAlign: 'right',
                        fontSize: 12,
                        color: colors.RADICAL_RED,
                      }}
                    >
                      -{scaledToString(unitMembershipDiscount)} {t(item.total.amount.unit)}
                    </SmallBody>
                  ) : hasDiscount ? (
                    <SmallBody
                      style={{
                        textAlign: 'right',
                        fontSize: 12,
                        color: colors.RADICAL_RED,
                      }}
                    >
                      -{scaledToString(unitDiscount)} {t(item.total.amount.unit)}
                    </SmallBody>
                  ) : null}
                </Column>
              </Row>
            </Column>
            <Spacer size={16} />
            <Line />
            <Spacer size={16} />
          </React.Fragment>
        );
      }
      case 'Product': {
        const measure = getMeasure(item.product.measure);
        const hasDiscount =
          item.total.discount &&
          (item.total.discount.type === 'Value'
            ? ScaledNumber.toNumber(item.total.discount.value.amount)
            : item.total.discount.percent.value.toFixed()) !== 0;

        const totalWithDiscount = CheckoutItemTotalScaledNumber.getTotal(
          item.total,
          proceedDetails.company.type === 'Company' ? proceedDetails.company.company.vatStatus : NO_VAT,
          2,
        ).amount;

        // const paidWithMembership = isItemPaidWithMembership(item, proceedDetails.payments);
        const itemUnitPriceWithMembership = divideScaled(
          item.total.amount.amount,
          ScaledNumber.toNumber(item.quantity),
        );

        const itemUnitPriceWithDiscount = divideScaled(totalWithDiscount, ScaledNumber.toNumber(item.quantity));

        const itemUnitPrice = divideScaled(item.total.amount.amount, ScaledNumber.toNumber(item.quantity));

        const unitDiscount = ScaledNumber.sub(itemUnitPrice, itemUnitPriceWithDiscount);

        return (
          <React.Fragment key={item.product._id}>
            <Column>
              <Row>
                <Column style={{ width: '50%', paddingRight: 16 }}>
                  <Title style={{ fontSize: 14 }}>{item.product.name}</Title>
                  <Spacer size={4} />
                  {item.saleOwner ? (
                    <SmallBody style={{ color: colors.BLACK }}>
                      {item.saleOwner.profile.firstname} {item.saleOwner.profile.lastname}
                    </SmallBody>
                  ) : null}
                  {/*{paidWithMembership.isPaidWithMembership ? (*/}
                  {/*  <SmallBody style={{ color: colors.COMET }}>*/}
                  {/*    {ScaledNumber.toNumber(paidWithMembership.effectivePrice.amount.amount) === 0*/}
                  {/*      ? t('paidWithMembershipUnlimited')*/}
                  {/*      : t('paidWithMembership')}*/}
                  {/*  </SmallBody>*/}
                  {/*) : null}}*/}
                  {measure || item.product.barcode ? (
                    <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                      {measure}
                      {measure && item.product.barcode ? ` - ` : ''}
                      {item.product.barcode?.value}
                    </SmallBody>
                  ) : null}
                </Column>
                <SmallBody style={{ width: '20%', textAlign: 'right', paddingRight: 8, color: colors.COMET }}>
                  x{scaledToString(item.quantity)}
                </SmallBody>
                <Column style={{ width: '30%' }}>
                  <Title style={{ textAlign: 'right', fontSize: 14 }}>
                    {scaledToString(itemUnitPriceWithMembership)} {t(item.total.amount.unit)}
                  </Title>
                  {/*{paidWithMembership.isPaidWithMembership &&*/}
                  {/*ScaledNumber.toNumber(paidWithMembership.effectivePrice.amount.amount) !== 0 &&*/}
                  {/*!ScaledNumber.equals(item.total.amount.amount, paidWithMembership.effectivePrice.amount.amount) ? (*/}
                  {/*  <SmallBody*/}
                  {/*    style={{*/}
                  {/*      textAlign: 'right',*/}
                  {/*      fontSize: 12,*/}
                  {/*      color: colors.COMET,*/}
                  {/*      textDecorationLine: 'line-through',*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    {scaledToString(itemUnitPriceWithDiscount)} {t(item.total.amount.unit)}*/}
                  {/*  </SmallBody>*/}
                  {/*) : */}
                  {hasDiscount ? (
                    <SmallBody
                      style={{
                        textAlign: 'right',
                        fontSize: 12,
                        color: colors.RADICAL_RED,
                      }}
                    >
                      -{scaledToString(unitDiscount)} {t(item.total.amount.unit)}
                    </SmallBody>
                  ) : null}
                </Column>
              </Row>
            </Column>
            <Spacer size={16} />
            <Line />
            <Spacer size={16} />
          </React.Fragment>
        );
      }
      case 'Amount': {
        return (
          <React.Fragment key={scaledToString(item.total.amount.amount)}>
            <Column>
              <Row>
                <Title style={{ width: '50%', paddingRight: 16, fontSize: 14 }} numberOfLines={1} ellipsizeMode="tail">
                  {t('otherAmount')}
                </Title>
                <SmallBody style={{ width: '20%', textAlign: 'right', paddingRight: 8, color: colors.COMET }}>
                  x1
                </SmallBody>
                <Title style={{ width: '30%', textAlign: 'right', fontSize: 14 }}>
                  {scaledToString(item.total.amount.amount)} {t(item.total.amount.unit)}
                </Title>
              </Row>
              <Spacer size={4} />
              {item.saleOwner ? (
                <SmallBody style={{ color: colors.BLACK }}>
                  {item.saleOwner.profile.firstname} {item.saleOwner.profile.lastname}
                </SmallBody>
              ) : null}
            </Column>
            <Spacer size={16} />
            <Line />
            <Spacer size={16} />
          </React.Fragment>
        );
      }
      case 'Membership': {
        const unit = item.total.amount.unit;
        const decimals = MeroCurrency[unit].exponent;
        const companyVat = CompanyVatStatus.payer(PortionPercentScaledNumber.unsafeFromNumber(10, decimals), 'asdf');
        const price = CheckoutTransactionItem.Membership.getPrice(item, item.total.amount.unit, companyVat, decimals);
        const totals = CheckoutTransactionItem.Membership.getTotals(item, item.total.amount.unit, companyVat, decimals);
        const debt = ScaledNumber.toNumber(ScaledNumber.sub(price.total.amount, totals.total.amount));

        return (
          <React.Fragment key={item.membershipPurchaseId}>
            <Column>
              <Row>
                <Column style={{ width: '50%', paddingRight: 16 }}>
                  <Title style={{ fontSize: 14 }} numberOfLines={1} ellipsizeMode="tail">
                    {item.membershipTemplate.name}
                  </Title>
                  <Spacer size={4} />
                  <SmallBody style={{ color: colors.BLACK }}>
                    {debt > 0
                      ? t('membershipPaidPartial', {
                          paid: `${scaledToString(item.total.amount.amount)} ${t(item.total.amount.unit)}`,
                          total: `${scaledToString(price.total.amount)} ${t(item.total.amount.unit)}`,
                        })
                      : t('membershipPaidFully')}
                  </SmallBody>
                </Column>
                <SmallBody style={{ width: '20%', textAlign: 'right', paddingRight: 8, color: colors.COMET }}>
                  x{item.quantity}
                </SmallBody>
                <Title style={{ width: '30%', textAlign: 'right', fontSize: 14 }}>
                  {scaledToString(item.total.amount.amount)} {t(item.total.amount.unit)}
                </Title>
              </Row>
            </Column>
            <Spacer size={16} />
            <Line />
            <Spacer size={16} />
          </React.Fragment>
        );
      }
      case 'MembershipInstallment': {
        return (
          <React.Fragment key={scaledToString(item.total.amount.amount)}>
            <Column>
              <Row>
                <Title style={{ width: '50%', paddingRight: 16, fontSize: 14 }} numberOfLines={1} ellipsizeMode="tail">
                  {item.membership.name}
                </Title>
                <SmallBody style={{ width: '20%', textAlign: 'right', paddingRight: 8, color: colors.COMET }}>
                  x1
                </SmallBody>
                <Title style={{ width: '30%', textAlign: 'right', fontSize: 14 }}>
                  {scaledToString(item.total.amount.amount)} {t(item.total.amount.unit)}
                </Title>
              </Row>
              <Spacer size={4} />
              {item.saleOwner ? (
                <SmallBody style={{ color: colors.BLACK }}>
                  {item.saleOwner.profile.firstname} {item.saleOwner.profile.lastname}
                </SmallBody>
              ) : null}
            </Column>
            <Spacer size={16} />
            <Line />
            <Spacer size={16} />
          </React.Fragment>
        );
      }
      default:
        return null;
    }
  };
  const getPaymentDetails = (payment: CheckoutTransactionDetails.Any['payments'][number]) => {
    if (!proceedDetails) {
      return null;
    }
    switch (payment.type) {
      case 'Cash':
        return (
          <React.Fragment key={payment.type}>
            <Row alignItems="center">
              <SmallBody style={{ color: colors.COMET, width: '70%', textAlign: 'right', paddingRight: 8 }}>
                {t('payedCash')}
              </SmallBody>
              <Title style={{ textAlign: 'right', width: '30%', fontSize: 14 }}>
                {scaledToString(payment.total.amount)} {t(payment.total.unit)}
              </Title>
            </Row>
            <Spacer size={12} />
          </React.Fragment>
        );
      case 'Card':
        return (
          <React.Fragment key={payment.type}>
            <Row alignItems="center">
              <SmallBody style={{ color: colors.COMET, width: '70%', textAlign: 'right', paddingRight: 8 }}>
                {t('payedCard')}
              </SmallBody>
              <Title style={{ textAlign: 'right', width: '30%', fontSize: 14 }}>
                {scaledToString(payment.total.amount)} {t(payment.total.unit)}
              </Title>
            </Row>
            <Spacer size={12} />
          </React.Fragment>
        );
      case 'BankTransfer':
        return (
          <React.Fragment key={payment.type}>
            <Row alignItems="center">
              <SmallBody style={{ color: colors.COMET, width: '70%', textAlign: 'right', paddingRight: 8 }}>
                {t('payedBankTransfer')}
              </SmallBody>
              <Title style={{ textAlign: 'right', width: '30%', fontSize: 14 }}>
                {scaledToString(payment.total.amount)} {t(payment.total.unit)}
              </Title>
            </Row>
            <Spacer size={12} />
          </React.Fragment>
        );
      case 'Giftcard':
        return (
          <React.Fragment key={payment.type}>
            <Row alignItems="center">
              <SmallBody style={{ color: colors.COMET, width: '70%', textAlign: 'right', paddingRight: 8 }}>
                {t('payedGiftcard')}
              </SmallBody>
              <Title style={{ textAlign: 'right', width: '30%', fontSize: 14 }}>
                {scaledToString(payment.total.amount)} {t(payment.total.unit)}
              </Title>
            </Row>
            <Spacer size={12} />
          </React.Fragment>
        );
      case 'Membership': {
        const value = computeFinishedTransactionPaymentTotal(payment, 'RON');
        return (
          <React.Fragment key={payment.type}>
            <Row alignItems="center">
              <SmallBody style={{ color: colors.COMET, width: '70%', textAlign: 'right', paddingRight: 8 }}>
                {t('payedMembership')}
              </SmallBody>
              <Title
                style={{
                  textAlign: 'right',
                  width: '30%',
                  fontSize: 14,
                }}
              >
                {scaledToString(value.amount)} {t(value.unit)}
              </Title>
            </Row>
            <Spacer size={12} />
          </React.Fragment>
        );
      }
      default:
        return null;
    }
  };

  const totalPayed = React.useMemo((): Money<ScaledNumber, MeroUnits.Any> | undefined => {
    if (proceedDetails) {
      return proceedDetails.total.total;
    }
  }, [proceedDetails]);

  const getDateFormat = (date: Date) => {
    const dateObj = DateTime.fromJSDate(date);

    //if date is invalid
    if (!dateObj.isValid) {
      return '';
    }

    return dateObj.setLocale('ro').toFormat('d MMM yyyy, HH:mm').toLowerCase();
  };

  const hasMembership = React.useMemo(
    () => !!proceedDetails?.items.some((item) => item.type === 'Membership'),
    [proceedDetails?.items],
  );

  const globalDiscountValue = React.useMemo(() => {
    if (proceedDetails?.discount?.type === 'Value') {
      return proceedDetails.discount.value.amount;
    }

    if (proceedDetails?.discount?.type === 'Percent') {
      return ScaledNumber.mul(proceedDetails.total.subtotal.amount, proceedDetails.discount.percent);
    }

    return undefined;
  }, [proceedDetails?.total.discount]);

  return (
    <>
      {isDesktop && <Column style={{ width }} />}
      <ModalScreenContainer
        style={[{ backgroundColor: colors.WHITE }, isDesktop && { width: 500, borderRadius: 0, alignSelf: 'flex-end' }]}
      >
        <Column style={{ zIndex: 1 }}>
          <MeroHeader
            title={t('proceed')}
            RightComponent={
              proceedDetails?.status === 'Finished' ? (
                <Column style={{ position: 'relative', zIndex: 10000 }}>
                  <TouchableOpacity
                    style={{ justifyContent: 'center', height: 50, marginRight: 16 }}
                    onPress={() => setShowOptionsMenu(true)}
                  >
                    <SmallBody style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>
                      {t('options')}
                    </SmallBody>
                  </TouchableOpacity>
                  {showOptionsMenu && isDesktop ? (
                    <Column
                      style={{
                        position: 'absolute',
                        top: 50,
                        right: 0,
                        paddingHorizontal: 16,
                        backgroundColor: '#ffffff',
                        borderRadius: 16,
                        minWidth: 250,
                        shadowColor: '#000000',
                        shadowOffset: { width: 1, height: 1 },
                        shadowOpacity: 0.16,
                        shadowRadius: 16,
                        elevation: 16,
                        zIndex: 10000,
                      }}
                    >
                      <TouchableOpacity
                        style={styles.optionsMenuItem}
                        delayPressIn={0}
                        onPress={flow(deleteProceed, () => setShowOptionsMenu(false))}
                      >
                        <Title style={{ color: colors.RADICAL_RED }}>{t('cancelProceed')}</Title>
                      </TouchableOpacity>
                    </Column>
                  ) : null}
                </Column>
              ) : null
            }
            LeftComponent={
              <TouchableOpacity style={{ justifyContent: 'center', height: 50 }} onPress={onGoBack}>
                <Icon type="close" />
              </TouchableOpacity>
            }
          />
        </Column>

        <ScrollView style={{ paddingTop: 16 }}>
          {proceedDetails ? (
            <>
              <Column style={{ paddingHorizontal: 24 }}>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Label
                    style={{
                      color: proceedDetails.status === 'Finished' ? colors.SHAMROCK : colors.RADICAL_RED,
                      fontFamily: 'open-sans-semibold',
                    }}
                  >
                    {t(
                      proceedDetails.isProtocol &&
                        (proceedDetails.status === 'Finished' || proceedDetails.status === 'Deleted')
                        ? proceedDetails.status === 'Finished'
                          ? 'proceedProtocol'
                          : 'proceedProtocolDeleted'
                        : proceedDetails.status,
                    )}
                  </Label>
                  <SmallBody style={{ fontSize: 12, color: colors.COMET }}>
                    {getDateFormat(proceedDetails.createdAt)}
                  </SmallBody>
                </Row>
                <Spacer size={8} />
                <H2s>{t('checkoutId', { value: `#${proceedDetails.code}` })}</H2s>
                <Spacer size={4} />
                {proceedDetails.client ? (
                  <TouchableOpacity
                    onPress={() =>
                      proceedDetails.client
                        ? navigation.navigate('ClientDetails', {
                            screen: 'DetailsScreen',
                            params: {
                              pageId: proceedDetails.client.pageId,
                              clientId: proceedDetails.client._id,
                            },
                          })
                        : null
                    }
                  >
                    <Body style={{ color: colors.DARK_BLUE }}>{getClient(proceedDetails.client)}</Body>
                  </TouchableOpacity>
                ) : (
                  <Body>{getClient(proceedDetails.client)}</Body>
                )}
                <Spacer size={16} />
              </Column>
              <Line />
              <Column style={{ paddingHorizontal: 24 }}>
                <Spacer size={16} />
                <Label style={{ color: colors.COMET }}>{t('items')}</Label>
                <Spacer size={8} />
                {groupByType(proceedDetails.items).map((item) => getItemDetails(item))}
                {globalDiscountValue ? (
                  <>
                    <Column>
                      <Row>
                        <Title
                          style={{ width: '50%', paddingRight: 16, fontSize: 14 }}
                          numberOfLines={1}
                          ellipsizeMode="tail"
                        >
                          {t('globalDiscount')}
                        </Title>
                        <SmallBody
                          style={{ width: '20%', textAlign: 'right', paddingRight: 8, color: colors.COMET }}
                        ></SmallBody>
                        <Title style={{ width: '30%', textAlign: 'right', fontSize: 14, color: colors.RADICAL_RED }}>
                          -{scaledToString(globalDiscountValue)} {t(proceedDetails.total.subtotal.unit)}
                        </Title>
                      </Row>
                    </Column>
                    <Spacer size={16} />
                    <Line />
                    <Spacer size={16} />
                  </>
                ) : null}
                <Row alignItems="center">
                  <SmallBody style={{ color: colors.COMET, width: '70%', textAlign: 'right', paddingRight: 8 }}>
                    {t('subtotal')}
                  </SmallBody>
                  <Title style={{ textAlign: 'right', width: '30%', fontSize: 14 }}>
                    {scaledToString(proceedDetails.total.subtotal.amount)} {t(proceedDetails.total.subtotal.unit)}
                  </Title>
                </Row>
                <Spacer size={12} />
                {proceedDetails.total.discount ? (
                  <>
                    <Row alignItems="center">
                      <SmallBody style={{ color: colors.COMET, width: '70%', textAlign: 'right', paddingRight: 8 }}>
                        {t('totalDiscount')}
                      </SmallBody>
                      <Title style={{ textAlign: 'right', width: '30%', fontSize: 14 }}>
                        {scaledToString(proceedDetails.total.discount.amount)} {t(proceedDetails.total.discount.unit)}
                      </Title>
                    </Row>
                    <Spacer size={12} />
                  </>
                ) : null}
                {proceedDetails.total.vat ? (
                  <>
                    <Row alignItems="center">
                      <SmallBody style={{ color: colors.COMET, width: '70%', textAlign: 'right', paddingRight: 8 }}>
                        {t('vat')}
                      </SmallBody>
                      <Title style={{ textAlign: 'right', width: '30%', fontSize: 14 }}>
                        {scaledToString(proceedDetails.total.vat.amount)} {t(proceedDetails.total.vat.unit)}
                      </Title>
                    </Row>
                    <Spacer size={12} />
                  </>
                ) : null}
                {totalPayed ? (
                  <Row alignItems="center">
                    <Body style={{ color: colors.COMET, width: '70%', textAlign: 'right', paddingRight: 8 }}>
                      {t(proceedDetails.isProtocol ? 'paymentProtocol' : 'totalPayed')}
                    </Body>
                    <Title style={{ textAlign: 'right', width: '30%' }}>
                      {scaledToString(totalPayed.amount)} {t(proceedDetails.total.total.unit)}
                    </Title>
                  </Row>
                ) : null}
                <Spacer size={32} />
                <Line />
                <Spacer size={16} />
                {proceedDetails.payments.map((payment) => getPaymentDetails(payment))}
                <Spacer size={4} />
              </Column>
              <Line />
              <Spacer size={32} />
              <Column style={{ paddingHorizontal: 24 }}>
                {proceedDetails.company.type === 'Company' ? (
                  <>
                    <Title style={{ fontSize: 14 }}>{proceedDetails.company.company.name.toLocaleUpperCase()}</Title>
                    <SmallBody style={{ color: colors.COMET }}>
                      {t('regNoV')}: {proceedDetails.company.company.regNo}
                    </SmallBody>
                    <SmallBody style={{ color: colors.COMET }}>
                      {t('cui')}: {proceedDetails.company.company.fiscalCode}
                    </SmallBody>
                    <SmallBody style={{ color: colors.COMET }}>
                      {t('address')}: {proceedDetails.company.company.city}, {proceedDetails.company.company.address}
                      {', '}
                      {proceedDetails.company.company.county}
                    </SmallBody>
                  </>
                ) : null}
              </Column>
              <Spacer size={32} />
            </>
          ) : null}
        </ScrollView>
      </ModalScreenContainer>
      {showOptionsMenu && isPhone && (
        <ProceedDetailsOptionsMenu
          onDismiss={() => setShowOptionsMenu(false)}
          onSelect={flow(deleteProceed, () => setShowOptionsMenu(false))}
        />
      )}
      {showDeleteDialog && (
        <DeleteProceedDialog
          hasMembership={hasMembership}
          checkoutTransactionId={checkoutTransactionId}
          onCancel={hideDeleteDialog}
          onSuccess={flow(onDeleteSuccess, hideDeleteDialog)}
        />
      )}
    </>
  );
};

export default pipe(ProceedDetailsScreen, CurrentBusiness, Authorized);
