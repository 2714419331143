import { AppointmentId, UserAppointment } from '@mero/api-sdk/dist/calendar';
import { colors, H3s, Label, Spacer, styles as meroStyles } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { SectionList, View } from 'react-native';

import ClientAppointmentListItemView from './ClientAppointmentListItemView';
import ClientEmptyListView from './ClientEmptyListView';

type Props = {
  readonly HeaderElement?: React.ReactElement;
  readonly totals: {
    readonly futureBookingsCount: number;
    readonly pastBookingsCount: number;
  };
  readonly appointments: UserAppointment[];
  readonly boostAppointmentId?: AppointmentId;
  readonly now: DateTime;
  readonly onItemPress: (appointment: UserAppointment) => void;
  readonly size?: 'small' | 'normal';
};

const ClientAppointmentsListView: React.FC<Props> = ({
  HeaderElement,
  totals,
  appointments,
  now,
  onItemPress,
  boostAppointmentId,
  size = 'normal',
}) => {
  type GroupedAppointments = {
    readonly future: UserAppointment[];
    readonly past: UserAppointment[];
  };

  const groupedAppointments: GroupedAppointments = React.useMemo(
    () =>
      appointments.reduce(
        (acc: GroupedAppointments, item) => {
          if (item.start.getTime() < now.toMillis()) {
            acc.past.push(item);
          } else {
            acc.future.push(item);
          }
          return acc;
        },
        { future: [], past: [] },
      ),
    [appointments, now],
  );

  type SectionData = {
    readonly title: string;
    readonly data: UserAppointment[];
  };

  const sectionsData: SectionData[] = React.useMemo(() => {
    const data: SectionData[] = [];

    if (groupedAppointments.future.length > 0) {
      data.push({
        title: `Următoare (${totals.futureBookingsCount})`,
        data: groupedAppointments.future,
      });
    }

    if (groupedAppointments.past.length > 0) {
      data.push({
        title: `În trecut (${totals.pastBookingsCount})`,
        data: groupedAppointments.past,
      });
    }

    return data;
  }, [groupedAppointments]);

  return (
    <SectionList
      scrollEnabled={false}
      style={{ backgroundColor: colors.WHITE }}
      ListHeaderComponent={HeaderElement}
      sections={sectionsData}
      keyExtractor={clientAppointmentItemKeyExtractor}
      renderSectionHeader={({ section: { title } }) => (
        <View style={meroStyles.layout.hrPadding16}>
          <Spacer size="16" />
          {size === 'small' ? (
            <Label style={[meroStyles.text.semibold, { color: colors.COMET }]}>{title}</Label>
          ) : (
            <H3s style={[meroStyles.text.semibold, { color: colors.COMET }]}>{title}</H3s>
          )}
          <Spacer size="16" />
        </View>
      )}
      renderItem={({ item }) => {
        return (
          <ClientAppointmentListItemView
            size={size}
            appointment={item}
            now={now}
            onPress={onItemPress}
            isBoost={boostAppointmentId === item._id}
          />
        );
      }}
      keyboardShouldPersistTaps="handled"
      stickySectionHeadersEnabled={false}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există programări" subtitle="Programările clientului vor fi afișate aici" />
      }
    />
  );
};

const clientAppointmentItemKeyExtractor = (appointment: UserAppointment): string =>
  `${appointment._id}${appointment.occurrenceIndex}`;

export default ClientAppointmentsListView;
