import { MembershipPurchaseId } from '@mero/api-sdk';
import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import { ServiceId } from '@mero/api-sdk/dist/services';
import {
  colors,
  Column,
  H3s,
  HSpacer,
  Icon,
  Label,
  Row,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Title,
} from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import { flow } from 'fp-ts/function';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import AdvancedSelect, { Ref as AdvanceSelectRef } from '../../../components/AdvancedSelect';
import { MembershipIcon } from '../ClientDetailsScreen/components/ClientMembershipsListItemView';

import { nullish } from '../../../utils/function';
import { scaledToString } from '../../../utils/scaled';
import HoverButtons from './HoverButtons';
import { withHoverState } from './HoverState';
import { ArrowDown } from './Icons';
import { MembershipServices } from './common';

type HeaderProps = {
  isHovered: boolean;
  isOpen: boolean;
  t: TFunction;
  selected?: MembershipServices;
  availableQuantity: number;
  onDelete: () => void;
};

const Header: React.FC<HeaderProps> = ({ isHovered, isOpen, t, selected, availableQuantity, onDelete }) => (
  <Column
    style={[
      selected && isHovered && !isOpen ? { backgroundColor: colors.ALABASTER } : null,
      { margin: 8, borderRadius: 6, paddingVertical: 6, paddingLeft: 6 },
    ]}
  >
    <Row>
      <Column style={{ paddingRight: 12, flex: 1 }}>
        <Title>{t('membership')}</Title>
        {selected ? (
          <>
            <SmallBody>{selected.name}</SmallBody>
            <SmallBody style={{ color: colors.COMET }}>
              {availableQuantity === Infinity
                ? t('membershipNameWithAvailabilityUnlimited')
                : t('membershipNameWithAvailability', { count: availableQuantity })}
            </SmallBody>
          </>
        ) : (
          <SmallBody>{t('selectMembership')}</SmallBody>
        )}
      </Column>
      {isOpen ? (
        <ArrowDown />
      ) : isHovered && selected ? (
        <>
          <HoverButtons onDeletePress={onDelete} showEditIcon={false} />
          <HSpacer left={4} />
        </>
      ) : (
        <Icon type="arrow-right" color={colors.DARK_BLUE} />
      )}
    </Row>
  </Column>
);

const WithHover = withHoverState(Header);

export type Props = {
  list: MembershipServices[];
  serviceId: ServiceId;
  selected?: MembershipServices;
  onSelect: (membershipId?: MembershipPurchaseId) => void;
};

const SelectMembership: React.FC<Props> = ({ list, selected, serviceId, onSelect }) => {
  const advancedSelectRef = React.useRef<AdvanceSelectRef>(null);
  const { t } = useTranslation('services');

  const [memberships, setMemberships] = React.useState<MembershipServices[]>(list);

  const onSearch = React.useCallback(
    (query: string) => {
      setMemberships(list.filter((membership) => membership.name.toLowerCase().includes(query.toLowerCase())));
    },
    [list],
  );

  const availableQuantity = React.useMemo(() => {
    if (!selected) {
      return 0;
    }
    return selected.items.find((item) => item._id === serviceId)?.quantity ?? 0;
  }, [selected, serviceId]);

  return (
    <AdvancedSelect
      ref={advancedSelectRef}
      Header={({ isOpen }) => (
        <WithHover
          isHovered={false}
          isOpen={isOpen}
          t={t}
          selected={selected}
          availableQuantity={availableQuantity}
          onDelete={() => onSelect(undefined)}
        />
      )}
      onSearch={onSearch}
      data={memberships}
      onClose={() => onSearch('')}
      Item={(props) => {
        const { status, color } = React.useMemo(() => {
          if (!props.item) {
            return { status: '', color: colors.COMET };
          }
          const status = PurchasedMembership.getMembershipStatus({
            status: props.item.status,
            validFor: props.item.validFor,
          });

          return {
            status: status,
            color:
              status === 'Cancelled' || status === 'Expired'
                ? colors.RADICAL_RED
                : status === 'Active'
                ? colors.SHAMROCK
                : colors.COMET,
          };
        }, [props.item]);

        const hasDebt = React.useMemo(() => ScaledNumber.toNumber(props.item.debt.amount) > 0, [props.item.debt]);

        return (
          <TouchableOpacity
            style={{ flexDirection: 'row', padding: 16 }}
            onPress={flow(() => onSelect(props.item._id), advancedSelectRef.current?.onHide ?? nullish)}
          >
            <Column>
              <MembershipIcon />
            </Column>
            <Column style={{ flex: 1, paddingLeft: 16 }}>
              <Row>
                <Column style={{ flex: 1 }}>
                  <Label style={[{ color }, meroStyles.text.semibold]}>{t(status)}</Label>
                  <H3s style={{ fontSize: 16 }}>{props.item.name}</H3s>
                  <Spacer size={6} />
                  <SmallBody>
                    {DateTime.fromJSDate(props.item.validFor.from).setLocale('ro').toFormat('dd MMM yyyy')} -{' '}
                    {props.item.validFor.type === 'Unlimited'
                      ? t('Unlimited')
                      : DateTime.fromJSDate(props.item.validFor.to).setLocale('ro').toFormat('dd MMM yyyy')}
                  </SmallBody>
                </Column>
                <Column>
                  <Icon type="next" color={colors.DARK_BLUE} />
                </Column>
              </Row>
              {hasDebt && (
                <Row
                  style={{
                    backgroundColor: colors.OUTRAGEOUS_ORANGE,
                    paddingHorizontal: 12,
                    paddingVertical: 4,
                    borderRadius: 4,
                    marginTop: 16,
                  }}
                >
                  <Label style={[{ flex: 1, color: colors.WHITE }, meroStyles.text.semibold]}>{t('debtAmount')}</Label>
                  <Label style={[{ color: colors.WHITE }, meroStyles.text.semibold]}>
                    {scaledToString(props.item.debt.amount)} {t(props.item.debt.unit)}
                  </Label>
                </Row>
              )}
            </Column>
          </TouchableOpacity>
        );
      }}
    />
  );
};

export default SelectMembership;
