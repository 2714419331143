import { ScaledNumber } from '@mero/api-sdk';
import { Body, colors, Column, HSpacer, Icon, Row, SmallBody, Spacer, Title } from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { SvgProps, Path, G } from 'react-native-svg';

import { FlashyLabel } from '../../../components/FlashyLabel';

import { ConsumedProduct } from '../../../contexts/BookingFormContext';
import { getMeasure } from '../../../utils/products';
import { scaledToString } from '../../../utils/scaled';
import HoverButtons from './HoverButtons';
import { withHoverState } from './HoverState';

const StockIcon = (props: SvgProps) => (
  <Svg width={24} height={24} data-name="Group 8532" {...props}>
    <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 59" />
    <G fill="none">
      <Path d="M0 0h24v24H0Z" data-name="Path 8308" />
      <G stroke="#52577f" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.2}>
        <Path d="M7.522 12.003v-8.4h8.4v8.4m-5.041-6.161h1.679" />
        <Path d="M12 12h8.4v8.4H12m3.359-6.158h1.679" />
        <Path d="M3.603 12h8.4v8.4h-8.4Zm3.359 2.239h1.679" data-name="layer1" />
      </G>
    </G>
  </Svg>
);

type Props = {
  disabled?: boolean;
  isHovered: boolean;
  computedPrice?: {
    discounted: ScaledNumber;
    total: ScaledNumber;
  };
  product: ConsumedProduct;
  onSelect: (product: ConsumedProduct) => void;
  onDelete: (product: ConsumedProduct) => void;
  isSelected: boolean;
};

const ProductListItem: React.FC<Props> = ({
  product,
  onSelect,
  onDelete,
  isHovered,
  isSelected,
  disabled = false,
  computedPrice,
}) => {
  const { t } = useTranslation('products');

  const measure = getMeasure(product.measure);
  return (
    <TouchableOpacity
      onPress={() => onSelect(product)}
      style={{ padding: 8, borderWidth: 1, borderColor: colors.GEYSER, borderRadius: 6 }}
    >
      <Column
        style={[
          { paddingLeft: 8, paddingRight: 4, paddingVertical: 8, borderRadius: 6 },
          isHovered && { backgroundColor: colors.ALABASTER },
          isSelected && { backgroundColor: colors.SKY_BLUE },
        ]}
      >
        <Row>
          <Title style={{ flex: 1 }}>{product.name}</Title>
          {isHovered && !disabled ? (
            <>
              <HoverButtons onEditPress={() => onSelect(product)} onDeletePress={() => onDelete(product)} />
              <HSpacer left={4} />
            </>
          ) : (
            <>
              <Column>
                <Body style={{ paddingRight: 8 }}>
                  {scaledToString(
                    computedPrice?.discounted ??
                      ScaledNumber.mul(product.price.discountedPrice.amount, product.quantity),
                  )}{' '}
                  {t(product.price.discountedPrice.unit)}
                </Body>
              </Column>
              <Icon type="next" color={colors.DARK_BLUE} />
            </>
          )}
        </Row>
        <Row>
          <SmallBody style={{ color: colors.COMET, flex: 1 }}>
            {scaledToString(product.quantity)} x {measure}
          </SmallBody>
          {computedPrice &&
          !ScaledNumber.equals(computedPrice?.discounted, computedPrice?.total) &&
          !(isHovered && !disabled) ? (
            <SmallBody
              style={{ color: colors.COMET, textDecorationLine: 'line-through', textAlign: 'right', paddingRight: 32 }}
            >
              {scaledToString(computedPrice.total)} {t(product.price.discountedPrice.unit)}
            </SmallBody>
          ) : null}
        </Row>
        {/*<Spacer size={8} />*/}
        {/*<FlashyLabel*/}
        {/*  type="info"*/}
        {/*  text={*/}
        {/*    product.isStockManagementEnabled*/}
        {/*      ? t('defaultStock', { count: product.stock.stock.value })*/}
        {/*      : t('defaultStockUnlimited')*/}
        {/*  }*/}
        {/*  LeftComponent={<StockIcon />}*/}
        {/*/>*/}
      </Column>
    </TouchableOpacity>
  );
};

export default pipe(ProductListItem, withHoverState);
