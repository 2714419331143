import { CalendarEntryDetails } from '@mero/api-sdk';
import { AppointmentId, AppointmentStatus } from '@mero/api-sdk/dist/calendar';
import { AppointmentHistoryRecord } from '@mero/api-sdk/dist/calendar/appointment-history-record';
import { Type } from '@mero/api-sdk/dist/calendar/calendarEntry/type';
import { CheckoutTransactionPreview } from '@mero/api-sdk/dist/checkout/checkoutTransactionPreview';
import { MembershipItemConsumptionLockDetails } from '@mero/api-sdk/dist/memberships/membershipConsumptionLockDetails';
import { Price } from '@mero/api-sdk/dist/services/price';
import {
  Body,
  colors,
  Column,
  H3s,
  Label,
  Line,
  Row,
  SmallBody,
  Spacer,
  Title,
  styles as meroStyles,
  FlashyLabel,
} from '@mero/components';
import { capitalize, formatPhoneNumber } from '@mero/shared-components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import MuiTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Instance } from '@popperjs/core';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dimensions } from 'react-native';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import log from '../../../../../utils/log';
import { getPriceAndType } from '../../../../../utils/number';
import { national } from '../../../../../utils/phone';
import { getMeasure } from '../../../../../utils/products';
import { scaledToString } from '../../../../../utils/scaled';
import {
  computeServicePrice,
  computeServicesTotal,
  getPriceText,
  getUnit,
  isDiscountedPrice,
} from '../../../../../utils/servicePrice';
import RecurrenceText from '../../../../RecurrenceText';
import { NormalizedAppointmentEvent } from '../../../NormalizedEvent';

const TOOLTIP_WIDTH = 300;
const SPACER_SIZE = 8;

const MembershipIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <Path fill="none" d="M0 0h24v24H0Z" data-name="Path 8275" />
    <G
      fill="none"
      stroke="#52577f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      transform="translate(3 5.25)"
    >
      <Path d="M14.741 0v.295a1.474 1.474 0 0 1-2.948 0V0H5.9v.295a1.474 1.474 0 0 1-2.948 0V0H0v12.972h17.689V0ZM10.613 4.717h4.717m-4.717 2.358h4.717m-4.717 2.359h2.359" />
      <Circle cx={1.598} cy={1.598} r={1.598} data-name="layer1" transform="translate(3.732 3.834)" />
      <Path
        d="M5.307 7.075a2.978 2.978 0 0 0-2.947 3.037v.5h5.9v-.5a2.978 2.978 0 0 0-2.953-3.037Z"
        data-name="layer1"
      />
    </G>
  </Svg>
);

const computeTotalPrice = (appointment: CalendarEntryDetails.Appointment) => {
  const membershipUsage = (appointment.membershipConsumptionLocks ?? []).reduce((acc, lock, index) => {
    if (lock.item.type === 'Service') {
      return {
        ...acc,
        [lock.item.bookingServiceIndex]: {
          quantity: lock.quantity,
          membership: lock.membership,
        },
      };
    }

    return acc;
  }, {} as Record<number, Pick<MembershipItemConsumptionLockDetails<MeroUnits.Any>, 'quantity' | 'membership'>>);
  const servicePrices = appointment.payload.bookedServices.map((service, index) => {
    const quantity = service.quantity ?? 0;
    const membershipQuantity = membershipUsage[index]?.quantity ?? 0;
    const defaultPrice = getPriceAndType(service.price);

    return computeServicePrice(quantity, membershipQuantity, service.customPrice ?? defaultPrice);
  });

  return {
    memberships: membershipUsage,
    services: servicePrices,
  };
};

const ClientAppointmentStatusTextView: React.FC<{ status: AppointmentStatus; isPast: boolean }> = ({
  status,
  isPast,
}) => {
  const { t } = useTranslation('calendar');

  switch (status) {
    case 'accepted': {
      if (isPast) {
        return <Label style={[meroStyles.text.bold, { color: colors.COMET }]}>{t('done')}</Label>;
      } else {
        return <Label style={[meroStyles.text.bold, { color: colors.SHAMROCK }]}>{t('confirmed')}</Label>;
      }
    }
    case 'cancelled': {
      return <Label style={[meroStyles.text.bold, { color: colors.RADICAL_RED }]}>{t('canceled')}</Label>;
    }
    case 'noShow': {
      return <Label style={[meroStyles.text.bold, { color: colors.OUTRAGEOUS_ORANGE }]}>{t('noShow')}</Label>;
    }
    case 'pending': {
      return <Label style={[meroStyles.text.bold, { color: colors.COMET }]}>{t('pending')}</Label>;
    }
    case 'rejected': {
      return <Label style={[meroStyles.text.bold, { color: colors.RADICAL_RED }]}>{t('declined')}</Label>;
    }
  }
};

const SmallBodyTitle: React.FC<React.PropsWithChildren> = ({ children }) => (
  <SmallBody style={{ color: colors.COMET, fontSize: 12, fontFamily: 'open-sans-semibold' }}>{children}</SmallBody>
);

const TooltipContent: React.FC<Props> = ({ event, now }) => {
  const { t } = useTranslation('booking');

  const [pageState] = CurrentBusinessContext.useContext();
  const [isLoading, setIsLoading] = React.useState(true);

  const [appointmentDetails, setAppointmentDetails] = React.useState<CalendarEntryDetails.Appointment | undefined>();

  const now2EndDiff = React.useMemo(() => now.diff(event.end, 'minute').minutes, [now, event.end]);
  const isPastEvent = now2EndDiff > 0;

  const [history, setHistory] = React.useState<AppointmentHistoryRecord[]>([]);
  const [checkout, setCheckout] = React.useState<CheckoutTransactionPreview.Any>();

  const total = React.useMemo(
    () =>
      event.extra.raw?.bookedServices.reduce((acc, service) => {
        if (service.price.type === 'hidden') {
          return acc + (service.price.fixed ?? 0);
        }

        if (service.price.type === 'fixed' && service.price.promo) {
          return acc + service.price.promo;
        }

        if (service.price.type === 'fixed') {
          return acc + (service.price.fixed ?? 0);
        }

        if (service.price.type === 'range') {
          return acc + ((service.price.range.from ?? 0) + (service.price.range.to ?? 0)) / 2;
        }

        return acc;
      }, 0) || 0,
    [],
  );

  const interval = React.useMemo(() => {
    const start = event.start.setLocale('ro').toFormat('ccc dd MMM HH:mm').toLowerCase();
    const end = event.end.toFormat('HH:mm');
    const diff = event.end.diff(event.start, ['hours', 'minutes']).toObject();
    const formattedDiff = [diff.hours ? `${diff.hours}h` : undefined, diff.minutes ? `${diff.minutes}m` : undefined]
      .filter(Boolean)
      .join(' ');
    return `${capitalize(start)} - ${end} (${formattedDiff})`;
  }, []);

  const formatDuration = React.useCallback((duration: number) => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return [hours ? `${hours}h` : undefined, minutes ? `${minutes}m` : undefined].filter(Boolean).join(' ');
  }, []);

  const formatPrice = React.useCallback((price: Price) => {
    if (price.type === 'hidden') {
      return price.fixed ? `${price.fixed} lei` : 'n/a';
    }

    if (price.type === 'fixed' && price.promo) {
      return `${price.promo} lei`;
    }

    if (price.type === 'fixed') {
      return `${price.fixed} lei`;
    }

    if (price.type === 'range') {
      return `${price.range.from} - ${price.range.to} lei`;
    }

    return 'n/a';
  }, []);

  const generateHistoryText = React.useCallback((history: AppointmentHistoryRecord) => {
    switch (history.type) {
      case 'appointmentCreatedByClient':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.client.firstname} ${history.payload.client.lastname}`,
              date: DateTime.fromJSDate(history.payload.start).setLocale('ro').toFormat('dd MMMM yyyy'),
              hour: DateTime.fromJSDate(history.payload.start).setLocale('ro').toFormat('HH:mm'),
              worker: `${history.payload.worker.firstname} ${history.payload.worker.lastname}`,
            }}
          >
            0<SmallBodyTitle>1</SmallBodyTitle>2<SmallBodyTitle>3</SmallBodyTitle>4<SmallBodyTitle>5</SmallBodyTitle>
          </Trans>
        );
      case 'appointmentCreatedByPro':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
              date: DateTime.fromJSDate(history.payload.start).setLocale('ro').toFormat('dd MMMM yyyy'),
              hour: DateTime.fromJSDate(history.payload.start).setLocale('ro').toFormat('HH:mm'),
              worker: `${history.payload.worker.firstname} ${history.payload.worker.lastname}`,
            }}
          >
            <SmallBodyTitle>0</SmallBodyTitle>1<SmallBodyTitle>2</SmallBodyTitle>3<SmallBodyTitle>4</SmallBodyTitle>5
            <SmallBodyTitle>6</SmallBodyTitle>
          </Trans>
        );
      case 'appointmentCancelledByClient':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.client.firstname} ${history.payload.client.lastname}`,
              reason: history.payload.reason ? t('withReason', { reason: history.payload.reason }) : t('noReason'),
            }}
          >
            0<SmallBodyTitle>1</SmallBodyTitle>
          </Trans>
        );
      case 'appointmentCancelledByPro':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
              reason: history.payload.reason ? t('withReason', { reason: history.payload.reason }) : t('noReason'),
            }}
          >
            <SmallBodyTitle>0</SmallBodyTitle>1<SmallBodyTitle>2</SmallBodyTitle>
          </Trans>
        );
      case 'appointmentModifiedByPro':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
              startDate: DateTime.fromJSDate(history.payload.start.from).setLocale('ro').toFormat('ccc dd MMMM yyyy'),
              endDate: DateTime.fromJSDate(history.payload.start.to).setLocale('ro').toFormat('ccc dd MMMM yyyy'),
              startHour: DateTime.fromJSDate(history.payload.start.from).setLocale('ro').toFormat('HH:mm'),
              endHour: DateTime.fromJSDate(history.payload.start.to).setLocale('ro').toFormat('HH:mm'),
            }}
          >
            <SmallBodyTitle>0</SmallBodyTitle>1<SmallBodyTitle>2</SmallBodyTitle>3<SmallBodyTitle>4</SmallBodyTitle>5
            <SmallBodyTitle>6</SmallBodyTitle>7<SmallBodyTitle>8</SmallBodyTitle>
          </Trans>
        );
      case 'appointmentConfirmed':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
            }}
          >
            <SmallBodyTitle>0</SmallBodyTitle>1
          </Trans>
        );
      case 'appointmentRefused':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
            }}
          >
            <SmallBodyTitle>0</SmallBodyTitle>1
          </Trans>
        );
      case 'appointmentMarkedAsNoShow':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
            }}
          >
            <SmallBodyTitle>0</SmallBodyTitle>1<SmallBodyTitle>2</SmallBodyTitle>
          </Trans>
        );
    }
  }, []);

  React.useEffect(() => {
    if (AppointmentId.is(event.extra.id)) {
      if (event.extra.raw) {
        meroApi.calendar
          .getCalendarEntryById({
            pageId: event.extra.raw.page._id,
            entryId: event.extra.id,
            occurrenceIndex: event.extra.occurrenceIndex,
          })
          .then((entry) => entry.type === Type.Appointment.VALUE && setAppointmentDetails(entry))
          .catch((error) => log.error('Failed to fetch appointment details', error))
          .finally(() => setIsLoading(false));
      }

      meroApi.calendar
        .fetchAppointmentHistory({ appointmentId: event.extra.id })
        .then((history) => {
          setHistory(history);
        })
        .catch((error) => {
          log.error('Failed to fetch history', error);
        });

      if (event.extra.raw?.hasFinishedCheckoutTransactions) {
        meroApi.checkout
          .listAppointmentTransactions({
            pageId: event.extra.raw.page._id,
            appointmentId: event.extra.id,
            occurrenceIndex: event.extra.occurrenceIndex,
          })
          .then((transactions) => {
            const finished = transactions.data.find((transaction) => transaction.status === 'Finished');
            setCheckout(finished);
          })
          .catch((error) => {
            log.error('Failed to fetch checkout transaction', error);
          });
      }
    }
  }, [event]);

  const canShowValues = React.useMemo(() => {
    if (appointmentDetails) {
      return appointmentDetails.payload.bookedServices.every((s) => s.quantity);
    }

    return false;
  }, [appointmentDetails]);

  const { services, memberships } = React.useMemo(() => {
    if (appointmentDetails) {
      return computeTotalPrice(appointmentDetails);
    }
    return { services: [], memberships: {} };
  }, [appointmentDetails]);

  const totalPrice = React.useMemo(() => {
    return computeServicesTotal(services);
  }, [services]);

  const status = React.useMemo(() => {
    if (event.extra.raw) {
      switch (event.extra.raw.status) {
        case 'pending':
          return {
            text: t('pending'),
            type: 'custom',
            backgroundColor: colors.WHITE,
            color: colors.OUTRAGEOUS_ORANGE,
            style: { borderWidth: 1, borderColor: colors.OUTRAGEOUS_ORANGE },
          } as const;
        case 'accepted':
          return {
            text: isPastEvent ? t('finalised') : t('accepted'),
            type: isPastEvent ? 'info' : 'success',
          } as const;
        case 'noShow':
          return {
            text: t('noShow'),
            type: 'warning',
          } as const;
        case 'cancelled':
          return {
            text: t('cancelled'),
            type: 'error',
          } as const;
        case 'rejected':
          return {
            text: t('rejected'),
            type: 'error',
          } as const;
      }
    }
    return undefined;
  }, [event.extra.raw]);

  return isLoading || !appointmentDetails ? (
    <Column style={{ backgroundColor: colors.WHITE, width: TOOLTIP_WIDTH }}>
      {event.extra.raw?.hasFinishedCheckoutTransactions && (
        <Row style={{ paddingHorizontal: 16, paddingVertical: 12, backgroundColor: colors.SHAMROCK }}>
          <Label style={{ color: colors.WHITE, fontFamily: 'open-sans-semibold', flex: 1 }}>
            {checkout?.paymentTypes && checkout.paymentTypes.length === 0
              ? t('appointmentProtocol')
              : t('appointmentBilled')}
          </Label>
          {checkout ? (
            <Label style={{ color: colors.WHITE, fontFamily: 'open-sans-semibold' }}>
              {scaledToString(checkout.total.total.amount)} {t(checkout.total.total.unit)}
            </Label>
          ) : null}
        </Row>
      )}
      <Column style={{ paddingHorizontal: 16 }}>
        <Spacer size={12} />
        {event.extra.raw && !event.extra.raw.hasFinishedCheckoutTransactions ? (
          <>
            {status && <FlashyLabel {...status} />}
            <Spacer size={4} />
          </>
        ) : null}
        <Row style={{ alignItems: 'center' }}>
          <H3s style={{ flex: 1 }}>{event.extra.client}</H3s>
        </Row>
        <Spacer size={2} />
        {event.extra.raw?.clientBoostStatus?.isBoostClient && event.extra?.raw?.hideBoostClientDetails ? (
          <Column style={{ paddingBottom: 6 }}>
            <Label style={{ color: colors.PORTAGE, fontFamily: 'open-sans-semibold' }}>{t('boostClient')}</Label>
          </Column>
        ) : event.extra.raw?.user?.phone &&
          pageState.type === 'Loaded' &&
          pageState.page.permissions.clients.canViewClientDetails() ? (
          <>
            <Body>{formatPhoneNumber(event.extra.raw.user.phone)}</Body>
            <Spacer size={6} />
          </>
        ) : null}
        <SmallBody style={{ color: colors.COMET }}>{interval}</SmallBody>
        {event.extra.recurrent && (
          <>
            <Spacer size={4} />
            <RecurrenceText style={{ color: colors.COMET }} recurrenceRule={event.extra.recurrenceRule} dot />
          </>
        )}
        <Spacer size={12} />
      </Column>
      <Line />
      <Column style={{ paddingHorizontal: 16 }}>
        <Spacer size={12} />
        {event.extra.note ? (
          <>
            <Line />
            <Spacer size={12} />
            <SmallBody style={{ fontSize: 12 }}>* {event.extra.note}</SmallBody>
            <Spacer size={12} />
          </>
        ) : null}
      </Column>
      {history.length === 0 ? null : (
        <>
          <Line />
          <Column style={{ backgroundColor: '#FAFAFA', paddingHorizontal: 16 }}>
            <Spacer size={16} />
            <SmallBody style={{ color: colors.COMET, fontSize: 12 }}>{generateHistoryText(history[0])}</SmallBody>
            <Spacer size={16} />
          </Column>
        </>
      )}
    </Column>
  ) : (
    <Column style={{ backgroundColor: colors.WHITE, width: TOOLTIP_WIDTH }}>
      {event.extra.raw?.hasFinishedCheckoutTransactions && (
        <Row style={{ paddingHorizontal: 16, paddingVertical: 12, backgroundColor: colors.SHAMROCK }}>
          <Label style={{ color: colors.WHITE, fontFamily: 'open-sans-semibold', flex: 1 }}>
            {checkout?.paymentTypes && checkout.paymentTypes.length === 0
              ? t('appointmentProtocol')
              : t('appointmentBilled')}
          </Label>
          {checkout ? (
            <Label style={{ color: colors.WHITE, fontFamily: 'open-sans-semibold' }}>
              {scaledToString(checkout.total.total.amount)} {t(checkout.total.total.unit)}
            </Label>
          ) : null}
        </Row>
      )}
      <Column style={{ paddingHorizontal: 16 }}>
        <Spacer size={12} />
        {event.extra.raw && !event.extra.raw.hasFinishedCheckoutTransactions ? (
          <>
            <Spacer size={4} />
            {status && <FlashyLabel {...status} />}
            <Spacer size={4} />
          </>
        ) : null}
        <Row style={{ alignItems: 'center' }}>
          <H3s style={{ flex: 1 }}>{event.extra.client}</H3s>
          <Body>
            {canShowValues ? getPriceText({ price: totalPrice, t }) : total}{' '}
            {t(getUnit(appointmentDetails.payload.bookedServices[0]?.customPrice))}
          </Body>
        </Row>
        <Spacer size={2} />
        {event.extra.raw?.clientBoostStatus?.isBoostClient && event.extra?.raw?.hideBoostClientDetails ? (
          <Column style={{ paddingBottom: 4 }}>
            <Label style={{ color: colors.PORTAGE, fontFamily: 'open-sans-semibold' }}>{t('boostClient')}</Label>
          </Column>
        ) : event.extra.raw?.user?.phone &&
          pageState.type === 'Loaded' &&
          pageState.page.permissions.clients.canViewClientDetails() ? (
          <>
            <Body>{formatPhoneNumber(event.extra.raw.user.phone)}</Body>
            <Spacer size={4} />
          </>
        ) : null}
        <SmallBody style={{ color: colors.COMET }}>{interval}</SmallBody>
        {event.extra.recurrent && (
          <>
            <Spacer size={4} />
            <RecurrenceText style={{ color: colors.COMET }} recurrenceRule={event.extra.recurrenceRule} dot />
          </>
        )}
        <Spacer size={12} />
      </Column>
      <Line />
      <Column style={{ paddingHorizontal: 16 }}>
        <Spacer size={12} />
        {event.extra.raw?.bookedServices.map((service, index) => (
          <Column key={service._id}>
            {index !== 0 && <Spacer size={8} />}
            <Row>
              <Column style={{ flex: 1 }}>
                <Title style={{ flex: 1 }}>{service.name}</Title>
                <Spacer size={2} />
                <SmallBody style={{ color: colors.COMET }}>
                  {service.quantity ?? 1} x {formatDuration(service.durationInMinutes)}
                </SmallBody>
                <Spacer size={4} />
                {memberships[index] ? (
                  <FlashyLabel
                    type="info"
                    LeftComponent={
                      <Column style={{ marginRight: 2 }}>
                        <MembershipIcon />
                      </Column>
                    }
                    text={memberships[index].membership.name}
                  />
                ) : null}
              </Column>
              {service.quantity && services[index] ? (
                <Column>
                  <Body
                    style={[
                      { paddingRight: 8, textAlign: 'right' },
                      services[index].type === 'Hidden' && { fontSize: 12, textTransform: 'uppercase' },
                    ]}
                  >
                    {getPriceText({
                      type: 'discounted',
                      price: services[index],
                      t,
                    })}{' '}
                    {services[index].type !== 'Hidden' && t(getUnit(service.customPrice))}
                  </Body>
                  {isDiscountedPrice(services[index]) && (
                    <SmallBody
                      style={{
                        color: colors.COMET,
                        textDecorationLine: 'line-through',
                        textAlign: 'right',
                        paddingRight: 8,
                      }}
                    >
                      {getPriceText({
                        type: 'total',
                        price: services[index],
                        t,
                      })}{' '}
                      {services[index].type !== 'Hidden' && t(getUnit(service.customPrice))}
                    </SmallBody>
                  )}
                </Column>
              ) : (
                <Body>{formatPrice(service.price)}</Body>
              )}
            </Row>

            {index < (event.extra.raw?.bookedServices.length ?? 1) - 1 && (
              <>
                <Spacer size={8} />
                <Line />
              </>
            )}
          </Column>
        ))}
        <Spacer size={12} />
        {(event.extra.raw?.consumedProducts ?? []).map((product, index) => (
          <Column key={product._id}>
            {index !== 0 && <Spacer size={8} />}
            <Row>
              <Column style={{ flex: 1 }}>
                <Title style={{ flex: 1 }}>{product.name}</Title>
                <Spacer size={2} />
                <SmallBody style={{ color: colors.COMET }}>
                  {scaledToString(product.quantity)} x {getMeasure(product.measure)}
                </SmallBody>
                <Spacer size={4} />
              </Column>

              <Column>
                <Body style={[{ paddingRight: 8, textAlign: 'right' }]}>
                  {scaledToString(product.price.discountedPrice.amount)} {t(product.price.discountedPrice.unit)}
                </Body>
                {!ScaledNumber.equals(product.price.discountedPrice.amount, product.price.retailPrice.amount) && (
                  <SmallBody
                    style={{
                      color: colors.COMET,
                      textDecorationLine: 'line-through',
                      textAlign: 'right',
                      paddingRight: 8,
                    }}
                  >
                    {scaledToString(product.price.retailPrice.amount)} {t(product.price.retailPrice.unit)}
                  </SmallBody>
                )}
              </Column>
            </Row>

            {index < (event.extra.raw?.bookedServices.length ?? 1) - 1 && (
              <>
                <Spacer size={8} />
                <Line />
              </>
            )}
          </Column>
        ))}

        <Spacer size={12} />
        {event.extra.note ? (
          <>
            <Line />
            <Spacer size={12} />
            <SmallBody style={{ fontSize: 12 }}>* {event.extra.note}</SmallBody>
            <Spacer size={12} />
          </>
        ) : null}
      </Column>
      {history.length === 0 ? null : (
        <>
          <Line />
          <Column style={{ backgroundColor: '#FAFAFA', paddingHorizontal: 16 }}>
            <Spacer size={16} />
            <SmallBody style={{ color: colors.COMET, fontSize: 12 }}>{generateHistoryText(history[0])}</SmallBody>
            <Spacer size={16} />
          </Column>
        </>
      )}
    </Column>
  );
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
    padding: 0,
    fontSize: 11,
    overflow: 'hidden',
    marginLeft: -200,
  },
}));

export type Props = {
  event: NormalizedAppointmentEvent;
  now: DateTime;
};

const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({ event, now, children }) => {
  const windowWidth = Dimensions.get('window').width;

  const { isPhone } = useMediaQueries();
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<Instance>(null);
  const areaRef = React.useRef<HTMLDivElement>(null);

  const updatePosition = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    popperRef.current?.update();
  };

  return isPhone ? (
    <>{children}</>
  ) : (
    <LightTooltip
      title={
        <>
          <TooltipContent event={event} now={now} />
        </>
      }
      placement="right"
      followCursor
      style={{ backgroundColor: colors.WHITE, height: '100%' }}
      enterDelay={0}
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            const clientHeight = areaRef.current?.clientHeight ?? 0;

            if (positionRef.current.x + TOOLTIP_WIDTH + SPACER_SIZE > windowWidth) {
              return new DOMRect(
                positionRef.current.x - TOOLTIP_WIDTH - 2 * SPACER_SIZE,
                (areaRef.current?.getBoundingClientRect().y ?? 0) + clientHeight / 2,
                0,
                0,
              );
            }

            return new DOMRect(
              positionRef.current.x + SPACER_SIZE,
              (areaRef.current?.getBoundingClientRect().y ?? 0) + clientHeight / 2,
              0,
              0,
            );
          },
        },
      }}
    >
      <div ref={areaRef} onMouseEnter={updatePosition} style={{ height: '100%' }}>
        {children}
      </div>
    </LightTooltip>
  );
};

export default Tooltip;
