import { dateStringUtils } from '@mero/api-sdk';
import { WaitingListDetails } from '@mero/api-sdk/dist/calendar/waitingListDetails';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { H2s, Select, Spacer, useShowError } from '@mero/components';
import { pipe } from 'fp-ts/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import Column from '@mero/components/lib/components/Layout/Column';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import Body from '@mero/components/lib/components/Text/Body';
import H1 from '@mero/components/lib/components/Text/H1';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, MenuStackParamList } from '../../../types';
import { nameGenerator } from '../../../utils/string';
import PendingCard from './PendingCard';

type ComponentProps = AuthorizedProps & CurrentBusinessProps;

const PendingList: React.FC<ComponentProps> = ({ page }) => {
  const { t } = useTranslation('booking');
  const showError = useShowError();
  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList>>();
  const isFocused = useIsFocused();

  const [isLoading, setIsLoading] = React.useState(true);
  const [pendingList, setPendingList] = React.useState<WaitingListDetails[]>([]);
  const [nextPage, setNextPage] = React.useState<string>();
  const [selectedWorker, setSelectedWorker] = React.useState<WorkerId | undefined>(undefined);
  const lastRequest = React.useRef<symbol>();
  const lastRequestWorker = React.useRef<WorkerId>();

  const canViewPhone = page.permissions.clients.canViewClientDetails();

  const goBack = useGoBack();

  const workers = React.useMemo(
    () => [
      {
        label: t('allPros'),
        value: undefined,
      },
      ...page.workers.map((worker) => ({
        label: `${worker.user.firstname} ${worker.user.lastname}`,
        value: worker._id,
      })),
    ],
    [page.workers],
  );

  const getPendingList = async (nextPage?: string) => {
    try {
      const currentRequest = Symbol('currentRequest');
      lastRequest.current = currentRequest;
      const newWorker = selectedWorker !== lastRequestWorker.current;
      lastRequestWorker.current = selectedWorker;
      const pendingList = await meroApi.calendar.fetchPagedWaitingList({
        pageId: page.details._id,
        workerId: selectedWorker,
        limit: 10,
        page: newWorker ? undefined : nextPage,
      });
      if (lastRequest.current !== currentRequest) {
        return;
      }
      setPendingList((prev) => (newWorker ? pendingList.data : [...prev, ...pendingList.data]));
      setNextPage(pendingList.next);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMore = React.useCallback(async () => {
    if (nextPage) {
      await getPendingList(nextPage);
    }
  }, [nextPage]);

  React.useEffect(() => {
    if (isFocused) {
      getPendingList();
    }
  }, [isFocused, selectedWorker]);

  return (
    <ModalScreenContainer>
      <MeroHeader onClose={goBack} canClose title={t('pendingList')} />
      <Column style={{ paddingHorizontal: 16, flex: 1 }}>
        <Spacer size={16} />
        <H1>{t('pendingList')}</H1>
        <Spacer size={32} />
        {workers.length > 1 && (
          <>
            <Select value={selectedWorker} onChange={setSelectedWorker} items={workers} />
          </>
        )}
        {pendingList.length === 0 && !isLoading ? (
          <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <H2s style={{ textAlign: 'center' }}>{t('noPendingClientsTitle')}</H2s>
            <Spacer size={8} />
            <Body style={{ textAlign: 'center' }}>{t('noPendingClientsDescription')}</Body>
          </Column>
        ) : (
          <>
            <Spacer size={16} />
            <FlatList
              data={pendingList}
              showsVerticalScrollIndicator={false}
              renderItem={({ item }) => {
                const onCancel = async () => {
                  await meroApi.calendar.deleteAppointmentWaitingListByWorker({
                    pageId: page.details._id,
                    waitingListId: item._id,
                  });
                  await getPendingList();
                };

                return (
                  <React.Fragment key={item._id}>
                    <PendingCard
                      pageId={page.details._id}
                      details={item}
                      onBooking={() => {
                        const now = DateTime.now().startOf('hour');
                        const date = DateTime.fromJSDate(dateStringUtils.toDate(item.date));
                        navigation.navigate('Booking', {
                          screen: 'AppointmentScreen',
                          params: {
                            date: date.set({ hour: now.hour, minute: now.minute }).toJSDate(),
                            workerId: item.forWorker._id,
                            clientId: item.user.clientId,
                            serviceIds: (item.services ?? []).map((service) => service._id),
                            clientFullName: nameGenerator(item.user ?? {}, t('unknownUser')),
                            clientPhone: item.user.phone,
                            waitingListId: item._id,
                          },
                        });
                      }}
                      reload={getPendingList}
                      onCancel={onCancel}
                      showPhone={canViewPhone}
                    />
                    <Spacer size={16} />
                  </React.Fragment>
                );
              }}
              onEndReached={loadMore}
              onEndReachedThreshold={0.8}
            />
          </>
        )}
      </Column>
    </ModalScreenContainer>
  );
};

type PendingListScreenProps = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<AuthorizedStackParamList, 'PendingList'>;

const PendingListScreen: React.FC<PendingListScreenProps> = (props) => {
  return <PendingList {...props} />;
};

type PagePendingClientsScreenProps = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<MenuStackParamList, 'PagePendingClientsScreen'>;

const PagePendingClients: React.FC<PagePendingClientsScreenProps> = (props) => {
  return <PendingList {...props} />;
};

export const PagePendingClientsScreen = pipe(PagePendingClients, CurrentBusiness, Authorized);

export default pipe(PendingListScreen, CurrentBusiness, Authorized);
