import * as React from 'react';
import { TouchableOpacityProps } from 'react-native';

type Props = {
  children: React.FC<{ isHovered: boolean }>;
  disabled?: boolean;
};

const HoverState: React.FC<Props> = ({ children: Children, disabled = false }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const isTouchScreen = 'ontouchstart' in window;

  const handleHover = () => {
    if (disabled || isTouchScreen) {
      return;
    }
    setIsHovered(true);
  };

  const handleLeave = () => {
    if (disabled || isTouchScreen) {
      return;
    }
    setIsHovered(false);
  };

  return (
    <div onMouseEnter={handleHover} onMouseLeave={handleLeave} style={{ position: 'relative' }}>
      <Children isHovered={isHovered} />
    </div>
  );
};

export const withHoverState = <P extends { isHovered: boolean }>(
  Component: React.ComponentType<P>,
): React.FC<P & TouchableOpacityProps> => {
  return function WithHoverState(props: P & TouchableOpacityProps) {
    return <HoverState>{({ isHovered }) => <Component {...props} isHovered={isHovered} />}</HoverState>;
  };
};

export default HoverState;
