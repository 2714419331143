import { ClientId, WorkerId } from '@mero/api-sdk';
import { mergedService, MergedService } from '@mero/api-sdk/dist/services';
import {
  AvoidKeyboard,
  DismissKeyboard,
  MeroHeader,
  SearchTextInput,
  Spacer,
  Body,
  H1,
  H2s,
  normalize,
} from '@mero/components';
import { pipe } from 'fp-ts/function';
import { IANAZone } from 'luxon';
import * as React from 'react';
import { View, Keyboard, FlatList } from 'react-native';

import { useKeyboardIsOpen } from '@mero/components/lib/hooks';
import { styles as text } from '@mero/components/lib/styles/text';

import { CurrentBusiness, CurrentBusinessProps } from '../../contexts/CurrentBusiness';
import { SearchServicesContext, withSearchServicesContextProvider } from '../../contexts/ServicesSearchContext';
import { ServicesGroup, ServiceGroupPreview, flattenServices, groupServices, Item } from '../GroupedServicesList';
import ModalScreenContainer from '../ModalScreenContainer';
import { styles } from './styles';

type ServiceSearchIndex = {
  /**
   * Find services matching query string
   */
  readonly search: (q: string) => ServicesGroup[];
};

export const tokenize = (s: string): string[] => {
  const ss = normalize(s);
  if (ss !== '') {
    return ss.split(/\s+/);
  } else {
    return [];
  }
};

export const match = (tokens: string[], text: string): boolean => !tokens.some((token) => !text.includes(token));

type SavedServiceSearchText = MergedService & { searchText: string };

type IndexedGroup = {
  readonly group: ServiceGroupPreview;
  readonly services: SavedServiceSearchText[];
};

export const buildServiceSearchIndex = (groups: ServicesGroup[]): ServiceSearchIndex => {
  const index: IndexedGroup[] = groups.map(({ group, services }) => ({
    group,
    services: services.map(
      (service): SavedServiceSearchText => ({
        ...service,
        searchText: normalize(`${service.name} ${service.description}`),
      }),
    ),
  }));

  return {
    search: (q) => {
      const tokens = tokenize(q);

      if (tokens.length === 0) {
        return groups;
      }

      return index.reduce((acc: IndexedGroup[], { group, services: allServices }: IndexedGroup) => {
        const services = allServices.filter((service) => match(tokens, service.searchText));
        if (services.length > 0) {
          return acc.concat([{ group, services }]);
        } else {
          return acc;
        }
      }, []);
    },
  };
};

type NotFoundProps = {
  readonly title: string;
  readonly titleL2?: string;
  readonly message?: string;
};

const NotFound: React.FC<NotFoundProps> = ({ title, titleL2, message }: NotFoundProps) => (
  <DismissKeyboard
    style={{
      flex: 1,
      justifyContent: 'flex-start',
      alignContent: 'center',
      paddingTop: 70,
      paddingLeft: 48,
      paddingRight: 48,
    }}
  >
    <H2s style={text.alignCenter}>{title}</H2s>
    {titleL2 !== undefined ? <H2s style={text.alignCenter}>{titleL2}</H2s> : null}
    <Spacer size="16" />
    {message !== undefined ? <Body style={text.alignCenter}>{message}</Body> : null}
  </DismissKeyboard>
);

export type SelectServiceScreenProps = CurrentBusinessProps & {
  readonly services: ServicesGroup[];
  readonly onBackPressed?: () => void;
  readonly onServiceSelected?: (service: MergedService) => void;
  readonly workerId?: WorkerId;
  readonly clientId?: ClientId;
};

export const SelectService: React.FC<SelectServiceScreenProps> = ({
  page,
  workerId,
  clientId,
  services,
  onBackPressed,
  onServiceSelected,
}: SelectServiceScreenProps) => {
  const [searchState, { debounceSearch, loadMore, init }] = SearchServicesContext.useContext();

  const onSearch = React.useCallback(
    (query: string) => {
      debounceSearch({ query: { search: query || undefined, workerId }, clientId });
    },
    [workerId, clientId],
  );

  const isKeyboardOpen = useKeyboardIsOpen();

  const dismissKeyboardCallback = React.useCallback(() => {
    if (isKeyboardOpen) {
      Keyboard.dismiss();
    }
  }, [isKeyboardOpen]);

  const queryIsEmpty = (searchState.query?.search ?? '').trim() === '';
  const showTitle = !isKeyboardOpen && queryIsEmpty;

  const timeZone = React.useMemo(() => IANAZone.create('Europe/Bucharest'), []);

  const groupsWithServices: ServicesGroup[] = React.useMemo(() => {
    const { serviceGroups } = page.details;

    const services = searchState.result.data.map((service) => ({
      ...mergedService.fromSavedService(service),
      availableMemberships: service.availableMemberships,
    }));

    return groupServices(services, serviceGroups, 'Alte Servicii');
  }, [searchState.result.data]);

  React.useEffect(() => {
    init({ pageId: page.details._id, query: { search: undefined, workerId }, clientId });
  }, []);

  return (
    <>
      <MeroHeader title="Alege serviciu" canGoBack={onBackPressed !== undefined} onBack={onBackPressed} />
      <AvoidKeyboard style={{ flex: 1 }}>
        <DismissKeyboard>
          <View style={styles.hrPadding}>
            {showTitle ? (
              <>
                <Spacer size="16" />
                <H1>Alege serviciu</H1>
              </>
            ) : null}
            <Spacer size="24" />
            <SearchTextInput placeholder="Caută serviciu" value={searchState.query?.search ?? ''} onChange={onSearch} />
            <Spacer size="24" />
          </View>
        </DismissKeyboard>
        {searchState.result.data.length > 0 ? (
          <FlatList
            data={flattenServices(groupsWithServices, timeZone)}
            renderItem={(props) => <Item {...props} onServiceSelected={onServiceSelected} />}
            ItemSeparatorComponent={null}
            onEndReached={loadMore}
            onEndReachedThreshold={0.9}
            windowSize={11}
            refreshing={searchState.type === 'Loading'}
          />
        ) : services.length > 0 && searchState.query?.search ? (
          <NotFound
            title="Niciun rezultat pentru"
            titleL2={`"${searchState.query?.search}"`}
            message="Verifică ca termenul de căutare să fie corect și încearcă din nou"
          />
        ) : (
          <NotFound
            title="Nu există servicii"
            message="Adaugă serviciile pe care le oferi clienților tăi din profilul tău de business"
          />
        )}
      </AvoidKeyboard>
    </>
  );
};

const SelectServiceScreen: React.FC<SelectServiceScreenProps> = (props) => (
  <ModalScreenContainer edges={['left', 'top', 'right']}>
    <SelectService {...props} />
  </ModalScreenContainer>
);

export default React.memo(pipe(SelectServiceScreen, withSearchServicesContextProvider(false), CurrentBusiness));
