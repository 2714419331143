import { Portal } from '@gorhom/portal';
import { AppointmentId } from '@mero/api-sdk';
import { Column, H3s, Row, SelectButton, Spacer, styles as meroStyles } from '@mero/components';
import { capitalize } from '@mero/shared-sdk/dist/common';
import { flow } from 'fp-ts/lib/function';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { styles } from '../../../components/AddBookingScreen/styles';
import DayTimeSelect from '../../../components/DayTimeSelect';
import SelectDateTimeModal from '../../../components/SelectDateTimeModal';
import HSpacer from '@mero/components/lib/components/HSpacer';

import { TimeSelectStep } from './AppointmentScreen';

type Props = {
  appointmentId?: AppointmentId;
  onEditRecurrenceRule: () => void;
  editable: {
    start: boolean;
    end: boolean;
  };
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  date: {
    start: DateTime;
    end: DateTime;
    timeZone: IANAZone;
  };
};

const Calendar: React.FC<Props> = ({ appointmentId, setStartDate, setEndDate, editable, date }) => {
  const { t } = useTranslation('booking');

  const [showSelectStartDateScreen, setShowSelectStartDateScreen] = React.useState(false);

  const endDateEnd = React.useMemo(() => date.start.plus({ hours: 12 }), [date.start]);

  return (
    <>
      <Column style={{ paddingHorizontal: 16 }}>
        <Spacer size={16} />
        <Row style={{ justifyContent: 'space-between' }}>
          <H3s>{appointmentId ? t('dateAndTime') : t('selectDateAndTime')}</H3s>
          {/*<TouchableOpacity*/}
          {/*  onPress={onEditRecurrenceRule}*/}
          {/*  style={{ flexDirection: 'row', alignItems: 'center' }}*/}
          {/*  disabled={!editable}*/}
          {/*>*/}
          {/*  <SmallBody style={[meroStyles.text.semibold, meroStyles.text.link, { paddingLeft: 8 }]}>*/}
          {/*    {t('recurrence')}*/}
          {/*  </SmallBody>*/}
          {/*</TouchableOpacity>*/}
        </Row>
        <Spacer size={16} />
        <Row>
          <Column flex={1} style={{ minWidth: 149 }}>
            <SelectButton
              text={capitalize(date.start.toFormat('d LLL HH:mm', { locale: 'ro' }))}
              onPress={() => {
                setShowSelectStartDateScreen(true);
              }}
              disabled={!editable.start}
            />
          </Column>
          <HSpacer left={8} />
          <Column flex={1}>
            <DayTimeSelect
              start={date.start}
              end={endDateEnd}
              value={date.end}
              timeZone={date.timeZone}
              steps={[1, TimeSelectStep]}
              onChange={(date) => setEndDate(date.toJSDate())}
              showDuration
              disabled={!editable.end}
            />
          </Column>
        </Row>
        <Spacer size={16} />
        {/*<Row style={{ justifyContent: 'space-between' }}>*/}
        {/*  <H3s>{t('Calendar')}</H3s>*/}
        {/*  <TouchableOpacity onPress={console.debug} style={{ flexDirection: 'row', alignItems: 'center' }}>*/}
        {/*    <SmallBody style={[meroStyles.text.semibold, meroStyles.text.link, { paddingLeft: 8 }]}>*/}
        {/*      {t('availableTimes')}*/}
        {/*    </SmallBody>*/}
        {/*  </TouchableOpacity>*/}
        {/*</Row>*/}
      </Column>
      {showSelectStartDateScreen ? (
        <Portal>
          <View style={styles.modalScreen}>
            <SelectDateTimeModal
              selected={date.start.toJSDate()}
              minDate={DateTime.now().startOf('day').toJSDate()}
              onDateSelected={flow(setStartDate, () => setShowSelectStartDateScreen(false))}
              onClosePress={() => {
                setShowSelectStartDateScreen(false);
              }}
              timeZone={date.timeZone}
              timeStepMinutes={TimeSelectStep}
            />
          </View>
        </Portal>
      ) : null}
    </>
  );
};

export default Calendar;
