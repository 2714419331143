import { RecurrenceRule } from '@mero/api-sdk';
import { colors, Column } from '@mero/components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import RecurrenceText from '@mero/components/lib/components/Mero/RecurrenceText';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';

import { withHoverState } from './HoverState';

type Props = {
  isHovered: boolean;
  onEdit: () => void;
  disabled: boolean;
  recurrenceRule: RecurrenceRule.Any;
};

const RecurrenceListItem: React.FC<Props> = ({ onEdit, recurrenceRule, disabled, isHovered }) => (
  <Column
    style={[
      {
        borderWidth: 1,
        borderColor: colors.GEYSER,
        borderRadius: 6,
      },
    ]}
  >
    <Column
      style={[
        isHovered ? { backgroundColor: colors.ALABASTER } : null,
        { margin: 8, borderRadius: 6, paddingVertical: 6, paddingLeft: 6, paddingRight: 4 },
      ]}
    >
      <TouchableOpacity onPress={onEdit} disabled={disabled}>
        <SimpleListItem icon="arrow-right" iconPosition="right" paddingTop={0} paddingBottom={0}>
          <RecurrenceText recurrenceRule={recurrenceRule} />
        </SimpleListItem>
      </TouchableOpacity>
    </Column>
  </Column>
);

export default pipe(RecurrenceListItem, withHoverState);
