import { Price } from '@mero/api-sdk/dist/services';
import { MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';

import { ValueIO } from '@mero/components/lib/components/TypeSafeTextInput';

import { localeNumberValidator, localeStringToNumber, replaceDecimalSeparator } from './scaled';

export const roundToDecimals = (no: string | number, decimals = 2) => {
  if (typeof no === 'string') {
    const parsed = parseFloat(no);
    if (isNaN(parsed)) {
      return 0;
    }

    return Math.round(parsed * 10 ** decimals) / 10 ** decimals;
  }

  if (isNaN(no)) {
    return 0;
  }

  return Math.round(no * 10 ** decimals) / 10 ** decimals;
};

export const getPriceValue = (price: Price) =>
  price.type === 'hidden' || price.type === 'range' ? 0 : price.type === 'fixed' ? price.promo ?? price.fixed : 0;

export const getPriceAndType = (price: Price) => {
  if (price.type === 'hidden') {
    return undefined;
  }

  if (price.type === 'range') {
    return {
      type: 'Range' as const,
      range: {
        from: Money.of(ScaledNumber.fromNumber(price.rangePromo?.from ?? price.range.from ?? 0, 2), MeroUnits.RON.code),
        to: Money.of(ScaledNumber.fromNumber(price.rangePromo?.to ?? price.range.to ?? 0, 2), MeroUnits.RON.code),
      },
      discount: undefined,
    };
  }

  return {
    type: 'Fixed' as const,
    fixed: Money.of(ScaledNumber.fromNumber(price.promo ?? price.fixed ?? 0, 2), MeroUnits.RON.code),
    discount: undefined,
  };
};
//Create a io-ts brand that convert an Number from locale string
export const NumberFromString = new t.Type<number, string, unknown>(
  'NumberFromString',
  (u): u is number => typeof u === 'number',
  (u, c) =>
    pipe(
      u,
      t.string.decode,
      E.chain((s) => (isNaN(localeStringToNumber(s)) ? t.failure(s, c) : t.success(localeStringToNumber(s)))),
    ),
  String,
);

export const numberValidator =
  (prev: string) =>
  <A extends t.Mixed>(next: ValueIO<t.TypeOf<A>>) => {
    const parsed = replaceDecimalSeparator(next.input);
    return localeNumberValidator(parsed)
      ? { input: parsed, decoded: next.decoded }
      : { input: prev, decoded: next.decoded };
  };

export const PositiveNumberFromString = t.refinement(
  NumberFromString,
  (input): input is t.Branded<number, { readonly PositiveNumber: unique symbol }> => input > 0,
  'PositiveNumberFromString',
);
