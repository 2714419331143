import { CreateCheckoutTransactionPayment, MembershipPurchaseId, SavedCheckoutCompany, apiError } from '@mero/api-sdk';
import { CreateCheckoutTransactionItem } from '@mero/api-sdk/dist/checkout/checkoutApi';
import { NONE } from '@mero/api-sdk/dist/checkout/checkoutTransactionCompany/checkoutTransactionCompany';
import { computeTransactionTotals } from '@mero/api-sdk/dist/checkout/checkoutTransactionDetails/utils';
import { CheckoutCompanyId } from '@mero/api-sdk/dist/checkout/companies/checkoutCompanyId';
import { ALLOWED } from '@mero/api-sdk/dist/checkout/companies/companyEmitReceiptStatus/companyEmitReceiptStatus';
import {
  Body,
  Checkbox,
  colors,
  Column,
  DismissKeyboard,
  H1,
  Line,
  Row,
  Select,
  SmallBody,
  Spacer,
  TextInput,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import { MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import { flow, pipe } from 'fp-ts/function';
import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray';
import * as types from 'io-ts';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, ScrollView, TouchableOpacity, useWindowDimensions } from 'react-native';

import { styles } from '../../../components/AddClientScreen/styles';
import KeyboardAvoidingView from '../../../components/KeyboardAvoidingView';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import Button from '@mero/components/lib/components/Button';
import FormCard from '@mero/components/lib/components/FormCard';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CashRegistryContext } from '../../../contexts/CashRegistryContext';
import {
  applyDiscountToTotal,
  CheckoutFormContext,
  convertSavedCheckoutCompanyToCheckoutTransactionCompany,
  Item,
  MembershipPayment,
  Payment,
  WithUiKey,
} from '../../../contexts/CheckoutFormContext';
import { CheckoutsContext } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutStackParamList, CombineCheckoutStackParamList } from '../../../types';
import log from '../../../utils/log';
import { uiKeyToTransactionItemId } from '../../../utils/payment';
import {
  localeNumberValidator,
  localeStringToNumber,
  replaceDecimalSeparator,
  scaledToString,
  stripLocalThousandsSeparators,
} from '../../../utils/scaled';
import { paymentConversion } from './AddProceedComponent';
import { computeDraftTransactionPaymentTotal } from './utils';

export const checkoutItemConversion = (item: WithUiKey<Item>): CreateCheckoutTransactionItem.Any<MeroUnits.Any> => {
  switch (item.type) {
    case 'MembershipInstallment':
      return {
        type: 'MembershipInstallment',
        membership: item.membership,
        total: item.total,
        saleOwner: item.saleOwner,
      };
    default:
      return item;
  }
};

export const convertMembershipPayment = (
  payment: MembershipPayment,
): CreateCheckoutTransactionPayment.Membership<ScaledNumber, MeroUnits.Any> => ({
  ...payment,
  items: payment.items.map((item) => ({
    ...item,
    effectivePrice:
      item.type === 'Service'
        ? {
            amount: item.effectivePrice,
            vatStatus: {
              type: 'Included',
              rate: undefined,
            },
          }
        : {
            amount: {
              amount: ScaledNumber.fromNumber(0, 2),
              unit: MeroUnits.RON.code,
            },
            vatStatus: {
              type: 'Included',
              rate: undefined,
            },
          },
  })),
});

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutStackParamList & AuthorizedStackParamList & CombineCheckoutStackParamList, 'PaymentScreen'>;

const getMembershipItems = (items: WithUiKey<Item>[], uiKeys: string[]): WithUiKey<Item>[] => {
  return items
    .map((item) =>
      item.type === 'Booking'
        ? {
            ...item,
            items: item.items.filter((i) => uiKeys.includes(i.uiKey)),
          }
        : item,
    )
    .filter((item) => (item.type === 'Booking' ? item.items.length > 0 : uiKeys.includes(item.uiKey)));
};

const calculatePerMembership = (payment: MembershipPayment, items: WithUiKey<Item>[]) => {
  const uiKeys = payment.items.map((item) => item.uiKey);
  return computeDraftTransactionPaymentTotal(
    convertMembershipPayment(payment),
    getMembershipItems(applyDiscountToTotal(items), uiKeys),
    MeroUnits.RON.code,
  ).amount;
};

const PAYMENTS = ['Card', 'Cash', 'BankTransfer'] as const;

const calculateTotalMembership = (payment: MembershipPayment[], items: WithUiKey<Item>[]) => {
  return payment.reduce((acc, p) => {
    return ScaledNumber.add(acc, calculatePerMembership(p, items));
  }, ScaledNumber.fromNumber(0, 2));
};

const PaymentScreen: React.FC<Props> = ({ navigation, page }): React.ReactElement | null => {
  const [
    formState,
    {
      updatePayment,
      removePaymentByType,
      setPayment,
      setCompany,
      removeCompany,
      toggleEmitReceipt,
      selectReceipt,
      cancelAutoSave,
      setCustomerFiscalCode,
      resetPayment,
    },
  ] = CheckoutFormContext.useContext();
  const [checkoutState, { reloadCompanies }] = CheckoutsContext.useContext();
  const [, { loadTransactions }] = CashRegistryContext.useContext();

  const { t } = useTranslation('checkout');
  const goBack = useGoBack();
  const { isPhone, isDesktop } = useMediaQueries();
  const toast = useToast();
  const showError = useShowError();
  const { width } = useWindowDimensions();
  const isFocused = useIsFocused();

  const [isLoading, setIsLoading] = React.useState(false);
  const [showFiscalCodeError, setShowFiscalCodeError] = React.useState(false);
  const [isCompanyLoading, setIsCompanyLoading] = React.useState(false);

  const [showMissingCompany, setShowMissingCompany] = React.useState(false);
  const [showCustomerFiscalCode, setShowCustomerFiscalCode] = React.useState(false);

  const toggleCustomerFiscalCode = () => {
    setShowCustomerFiscalCode(!showCustomerFiscalCode);
    if (showCustomerFiscalCode) {
      setCustomerFiscalCode('');
    } else {
      setCustomerFiscalCode(formState.draft.company?.fiscalCode ?? '');
    }
    setShowFiscalCodeError(false);
  };

  const defaultPaymentStatus = React.useMemo(() => {
    return PAYMENTS.reduce((acc, p) => {
      return {
        ...acc,
        [p]: formState.draft.payments.some((payment) => payment.type === p),
      };
    }, {} as Record<(typeof PAYMENTS)[number], boolean>);
  }, []);

  const [paymentMethodStatus, setPaymentMethodStatus] = React.useState(defaultPaymentStatus);

  const togglePaymentMethod = React.useCallback(
    (method: (typeof PAYMENTS)[number]) => () => {
      setPaymentMethodStatus((status) => {
        const newStatus = !status[method];
        let updatedStatus = {
          ...status,
          [method]: !status[method],
        };
        if (!newStatus) {
          removePaymentByType({
            type: method,
          });
        } else {
          if ((method === 'Card' || method === 'Cash') && status.BankTransfer) {
            resetPayment('Membership');
            updatedStatus = {
              ...updatedStatus,
              BankTransfer: false,
            };
          }
          if (method === 'BankTransfer' && (status.Card || status.Cash)) {
            resetPayment('Membership');
            updatedStatus = {
              ...updatedStatus,
              Card: false,
              Cash: false,
            };
          }
          setPayment({
            type: method,
            total: Money.of(ScaledNumber.zero(), MeroUnits.RON.code),
          });
        }
        return updatedStatus;
      });
    },
    [],
  );

  const values = React.useMemo(
    () =>
      formState.draft.payments.reduce(
        (acc, p) => ({
          ...acc,
          [p.type]:
            p.type === 'Membership'
              ? scaledToString(
                  calculateTotalMembership(
                    formState.draft.payments.filter((p): p is MembershipPayment => p.type === 'Membership'),
                    formState.draft.items,
                  ),
                )
              : p.type === 'Online'
              ? ''
              : scaledToString(p.total.amount),
        }),
        {} as { [key in Payment['type']]: string },
      ),
    [formState.draft.payments, formState.draft.items],
  );

  const [tempValues, setTempValues] = React.useState(values);

  const totalValues = React.useMemo(() => {
    return computeTransactionTotals(
      {
        unit: MeroUnits.RON.code,
        payments: formState.draft.payments.map(paymentConversion),
        items: formState.draft.items.map((i): WithUiKey<Item> => {
          if (i.type === 'Booking') {
            return {
              ...i,
              items: i.items.map(uiKeyToTransactionItemId),
            };
          }

          return uiKeyToTransactionItemId(i);
        }),
        company: formState.draft.company
          ? {
              type: 'Company',
              company: formState.draft.company,
              emitReceipt: formState.draft.emitReceipt,
              receipt: formState.draft.receipt,
            }
          : NONE,
        discount: formState.draft.discount,
      },
      2,
    );
  }, [formState]);

  const membershipValue = React.useMemo(() => {
    const membership = formState.draft.payments.filter((p): p is MembershipPayment => p.type === 'Membership');
    if (membership.length === 0) {
      return {};
    }

    return membership.reduce((acc, p) => {
      return {
        ...acc,
        [p.membership._id]: calculatePerMembership(p, formState.draft.items),
      };
    }, {} as Record<MembershipPurchaseId, ScaledNumber>);
  }, [formState]);

  const totalPayedScaled = React.useMemo(() => {
    return formState.draft.payments
      .filter((p) => p.type !== 'Membership')
      .reduce((acc, p) => {
        return ScaledNumber.add(
          acc,
          computeDraftTransactionPaymentTotal(p, formState.draft.items.map(checkoutItemConversion), MeroUnits.RON.code)
            .amount,
        );
      }, ScaledNumber.fromNumber(0, 2));
  }, [formState.draft.payments, formState.draft.items]);

  const totalPayed = ScaledNumber.toNumber(totalPayedScaled);

  const isValidTempPayed = React.useMemo(() => {
    const isValid = formState.draft.payments.every((payment) => {
      const tempValue = tempValues[payment.type];
      if (!tempValue) {
        return false;
      }

      const parsed = localeStringToNumber(tempValue);

      return !isNaN(parsed);
    });

    if (!isValid) {
      return false;
    }

    const total = formState.draft.payments
      .filter((p) => p.type !== 'Membership')
      .reduce((acc, payment) => acc + localeStringToNumber(tempValues[payment.type]), 0);

    return ScaledNumber.equals(
      ScaledNumber.fromNumber(total, totalValues.total.amount.scale ?? 2),
      totalValues.total.amount,
    );
  }, [formState.draft.payments, tempValues, totalValues]);

  const validatePaymentValue = React.useCallback((value: string, defaultValue = 0) => {
    if (value === '') {
      return 0;
    }

    const parsed = localeStringToNumber(value);

    if (isNaN(parsed)) {
      return defaultValue;
    }

    return parsed;
  }, []);

  const companies = React.useMemo(() => {
    if (checkoutState.type === 'Loaded') {
      return checkoutState.companies;
    }

    return [];
  }, [checkoutState]);

  const setSelectedCompany = async (company: SavedCheckoutCompany) => {
    setIsCompanyLoading(true);
    try {
      if (company.emitReceiptStatus.type === ALLOWED.type) {
        const cashRegister = await meroApi.checkout.listCashRegisters({
          pageId: page.details._id,
          companyId: company._id,
        });

        if (cashRegister.length === 0) {
        } else {
          selectReceipt({
            emit: true,
            cashRegister: cashRegister[0],
            customerFiscalCode: undefined,
          });
        }
      }
      setCustomerFiscalCode(company.billingDetails?.fiscalCode ?? '');
      setCompany(convertSavedCheckoutCompanyToCheckoutTransactionCompany(company));
    } catch (error) {
      log.error('Failed to fetch cash registers', error);
      showError(t('selectCompanyError'));
    } finally {
      setIsCompanyLoading(false);
    }
  };

  const handleDownload = async (url: string) => {
    if (page.permissions.checkout.isReceiptPrinter()) {
      try {
        const supported = await Linking.canOpenURL(url);

        if (supported) {
          await Linking.openURL(url);
        } else {
          log.error(`Don't know how to open this URL: ${url}`);
        }
      } catch (error) {
        log.error('Failed to open URL', error);
      }
    }
  };

  const onCompanyChange = React.useCallback(
    async (companyId: CheckoutCompanyId | string) => {
      const company = companies.find((c) => c._id === companyId);

      if (company) {
        setSelectedCompany(company);
      } else {
        removeCompany();
      }
    },
    [companies],
  );

  const selectCompanyItems = React.useMemo(
    () =>
      companies.map((company) => ({
        label: convertSavedCheckoutCompanyToCheckoutTransactionCompany(company).name,
        value: company._id,
      })),
    [companies],
  );

  const save = async () => {
    if (!formState.draft.company) {
      setShowMissingCompany(true);
    }
    if (formState.type === 'Invalid' || formState.draft.items.length === 0 || showFiscalCodeError) {
      return;
    }

    const items = formState.draft.items.map(checkoutItemConversion) as NonEmptyArray<
      CreateCheckoutTransactionItem.Any<MeroUnits.Any>
    >;

    setIsLoading(true);
    try {
      const emitReceipt =
        total > 0 &&
        formState.draft.emitReceipt &&
        formState.draft.payments.some((p) => p.type === 'Cash' || p.type === 'Card') &&
        formState.draft.company?.emitReceiptStatus.type === 'Allowed';

      if (formState.draftId) {
        const checkout = await meroApi.checkout.checkoutDraft({
          _id: formState.draftId,
          unit: MeroUnits.RON.code,
          isProtocol,
          page: {
            _id: page.details._id,
          },
          client: formState.draft.client.type === 'existing' ? { _id: formState.draft.client.client._id } : undefined,
          items,
          discount: formState.draft.discount,
          payments: formState.draft.payments.map(paymentConversion),
          company: {
            type: 'Company',
            company: { _id: formState.draft.company._id },
            receipt: emitReceipt ? formState.draft.receipt : { emit: false },
          },
        });
        if (emitReceipt && formState.draft.receipt.emit) {
          const receipt = await meroApi.checkout.printReceipt({
            pageId: page.details._id,
            transactionId: checkout._id,
            force: false,
          });

          handleDownload(receipt.downloadUrl);
        }
        cancelAutoSave(formState.autoSaveInterval);
        toast.show({
          type: 'success',
          text: t('checkoutSaved'),
        });
        navigation.popToTop();
        navigation.goBack();
      } else {
        const checkout = await meroApi.checkout.checkout({
          unit: MeroUnits.RON.code as MeroUnits.RON,
          isProtocol,
          page: {
            _id: page.details._id,
          },
          client: formState.draft.client.type === 'existing' ? { _id: formState.draft.client.client._id } : undefined,
          items,
          discount: formState.draft.discount,
          payments: formState.draft.payments.map(paymentConversion),
          company: {
            type: 'Company',
            company: { _id: formState.draft.company._id },
            receipt: emitReceipt ? formState.draft.receipt : { emit: false },
          },
        });
        if (emitReceipt && formState.draft.receipt.emit) {
          const receipt = await meroApi.checkout.printReceipt({
            pageId: page.details._id,
            transactionId: checkout._id,
            force: false,
          });
          handleDownload(receipt.downloadUrl);
        }
        cancelAutoSave(formState.autoSaveInterval);
        toast.show({
          type: 'success',
          text: t('checkoutSaved'),
        });
        navigation.popToTop();
        navigation.goBack();
      }
      loadTransactions({ pageId: page.details._id });
    } catch (error) {
      if (
        apiError(types.unknown).is(error) &&
        error.code === 135 &&
        formState.draft.emitReceipt &&
        showCustomerFiscalCode
      ) {
        setShowFiscalCodeError(true);
      } else {
        showError(error);
      }
      log.error('Failed to save checkout', error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (checkoutState.type === 'Loaded' && checkoutState.companies.length === 1) {
      setSelectedCompany(checkoutState.companies[0]);
    }
  }, [checkoutState.type, companies]);

  React.useEffect(() => {
    reloadCompanies(page.details._id).catch((error) => {
      log.error('Failed to reload companies', error);
    });
  }, [isFocused]);

  const total = ScaledNumber.toNumber(totalValues.total.amount);

  const updateTempValues = React.useCallback(
    (type: Payment['type']) => (value: string) => {
      setTempValues((values) => ({
        ...values,
        [type]: value,
      }));

      return value;
    },
    [],
  );

  const canEmitReceipt =
    total > 0 &&
    formState.draft.company?.emitReceiptStatus.type === 'Allowed' &&
    formState.draft.payments.some((p) => p.type === 'Cash' || p.type === 'Card');

  const isProtocol = formState.draft.isProtocol;

  React.useEffect(() => {
    const values = formState.draft.payments.reduce(
      (acc, p) => ({
        ...acc,
        [p.type]:
          p.type === 'Membership'
            ? scaledToString(
                calculateTotalMembership(
                  formState.draft.payments.filter((p): p is MembershipPayment => p.type === 'Membership'),
                  formState.draft.items,
                ),
              )
            : p.type === 'Online'
            ? ''
            : scaledToString(p.total.amount),
      }),
      {} as { [key in Payment['type']]: string },
    );

    setTempValues(values);
  }, [formState.draft.payments, membershipValue]);

  const numberValidator = (prev: string) => (next: string) => {
    const parsed = replaceDecimalSeparator(next);
    return localeNumberValidator(parsed) ? parsed : prev;
  };

  const totalMembershipValue = Object.values(membershipValue).reduce(
    (acc, value) => ScaledNumber.add(acc, value),
    ScaledNumber.fromNumber(0, 2),
  );
  const isPaidCompletelyFromMembership = ScaledNumber.equals(totalMembershipValue, totalValues.total.amount);

  const savedPaymentValues = React.useMemo(
    () =>
      formState.draft.payments.reduce(
        (acc, p) => ({
          ...acc,
          [p.type]: p.type !== 'Membership' ? p.total.amount : ScaledNumber.zero(),
        }),
        {} as Record<Payment['type'], ScaledNumber>,
      ),
    [formState.draft.payments],
  );

  return (
    <>
      {isDesktop && <Column style={{ width }} />}
      <ModalScreenContainer
        style={isDesktop && { width: 500, borderRadius: 0, alignSelf: 'flex-end', paddingBottom: 96 }}
      >
        <MeroHeader
          canGoBack
          onBack={goBack}
          title={t('finalizePayment')}
          containerStyle={{ backgroundColor: colors.ALABASTER }}
        />
        <DismissKeyboard style={{ flex: 1 }}>
          <KeyboardAvoidingView style={{ flex: 1 }} keyboardVerticalOffset={110}>
            <ScrollView style={{ flex: 1 }}>
              <Spacer size={24} />
              <Column style={{ paddingHorizontal: 24 }}>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Title>{t('company')}</Title>
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate('Menu', {
                        screen: 'PageCheckoutSettingsScreen',
                        params: { screen: 'BillingDetailsList' },
                      })
                    }
                  >
                    <Title style={{ color: colors.DARK_BLUE, fontSize: 14 }}>{t('addCompany')}</Title>
                  </TouchableOpacity>
                </Row>
                <Spacer size={8} />
                <Select
                  items={selectCompanyItems}
                  value={formState.draft.company?._id}
                  onChange={onCompanyChange}
                  placeholder={t('noCompany')}
                  editable={!isCompanyLoading && selectCompanyItems.length > 0}
                />
                {showMissingCompany && !formState.draft.company && (
                  <>
                    <Spacer size={8} />
                    <SmallBody style={{ color: colors.RADICAL_RED }}>{t('missingCompany')}</SmallBody>
                  </>
                )}
                <Spacer size={8} />
              </Column>
              <Spacer size={16} />
              <Spacer size={16} color={colors.ALABASTER} />
              <Column style={{ backgroundColor: colors.WHITE, flex: 1, paddingHorizontal: 24 }}>
                <Spacer size={24} />
                {isProtocol && (
                  <>
                    <H1>{t('finalizeProtocol')}</H1>
                    <Spacer size={8} />
                    <Body>{t('paymentProtocolDescription')}</Body>
                  </>
                )}
                {!isProtocol && (
                  <>
                    <H1>{t('selectPaymentMethod')}</H1>
                    <Spacer size={16} />
                    {PAYMENTS.map((paymentMethod) => {
                      return (
                        <React.Fragment key={paymentMethod}>
                          <Row alignItems="center" style={{ minHeight: 43 }}>
                            <TouchableOpacity
                              style={{ flexDirection: 'row', flex: 2, paddingRight: 16, alignItems: 'center' }}
                              onPress={togglePaymentMethod(paymentMethod)}
                            >
                              <Checkbox
                                value={paymentMethodStatus[paymentMethod]}
                                onValueChange={togglePaymentMethod(paymentMethod)}
                              />
                              <Title style={{ paddingLeft: 12 }}>{t(paymentMethod)}</Title>
                            </TouchableOpacity>
                            {paymentMethodStatus[paymentMethod] && (
                              <Column style={{ flex: 2 }}>
                                <TextInput
                                  autoFocus
                                  returnKeyType="done"
                                  value={
                                    tempValues[paymentMethod] ??
                                    scaledToString(savedPaymentValues[paymentMethod] ?? ScaledNumber.zero())
                                  }
                                  onChange={flow(
                                    numberValidator(
                                      tempValues[paymentMethod] ??
                                        scaledToString(savedPaymentValues[paymentMethod] ?? ScaledNumber.zero()),
                                    ),
                                    updateTempValues(paymentMethod),
                                  )}
                                  onFocus={() => {
                                    updateTempValues(paymentMethod)(
                                      stripLocalThousandsSeparators(
                                        tempValues[paymentMethod] ??
                                          scaledToString(savedPaymentValues[paymentMethod] ?? ScaledNumber.zero()),
                                      ),
                                    );
                                  }}
                                  onBlur={() => {
                                    pipe(
                                      tempValues[paymentMethod] ??
                                        scaledToString(savedPaymentValues[paymentMethod] ?? ScaledNumber.zero()),
                                      validatePaymentValue,
                                      (value) => {
                                        updatePayment({
                                          type: paymentMethod,
                                          total: Money.of(ScaledNumber.fromNumber(value, 2), MeroUnits.RON.code),
                                        });
                                      },
                                    );
                                  }}
                                  keyboardType="numeric"
                                  style={{ textAlign: 'right' }}
                                />
                              </Column>
                            )}
                          </Row>
                          <Spacer size={16} />
                        </React.Fragment>
                      );
                    })}

                    <Spacer size={8} />
                    {canEmitReceipt && (
                      <>
                        <Line />
                        <Spacer size={24} />
                        <TouchableOpacity
                          onPress={toggleEmitReceipt}
                          style={{ flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Checkbox value={formState.draft.emitReceipt} onValueChange={toggleEmitReceipt} />
                          <Title style={{ paddingLeft: 12 }}>{t('emitReceipt')}</Title>
                        </TouchableOpacity>
                        {formState.draft.emitReceipt && (
                          <>
                            <Spacer size={24} />
                            <Row>
                              <Checkbox value={showCustomerFiscalCode} onValueChange={toggleCustomerFiscalCode} />
                              <Column>
                                <TouchableOpacity onPress={toggleCustomerFiscalCode} style={{ paddingLeft: 12 }}>
                                  <Title>{t('customerFiscalCode')}</Title>
                                </TouchableOpacity>
                                {showCustomerFiscalCode && (
                                  <Column style={{ paddingLeft: 12 }}>
                                    <Spacer size={8} />
                                    <TextInput
                                      isError={showFiscalCodeError}
                                      value={
                                        formState.draft.receipt.emit
                                          ? formState.draft.receipt.customerFiscalCode ?? ''
                                          : ''
                                      }
                                      onChange={(value) => {
                                        setCustomerFiscalCode(value);
                                        setShowFiscalCodeError(false);
                                      }}
                                    />
                                    {showFiscalCodeError && (
                                      <SmallBody style={{ color: colors.RADICAL_RED, paddingTop: 8 }}>
                                        {t('receiptFiscalCodeError')}
                                      </SmallBody>
                                    )}
                                    <Spacer size={8} />
                                    <SmallBody style={{ color: colors.COMET, textTransform: 'uppercase' }}>
                                      {formState.draft.company?.name}
                                    </SmallBody>
                                  </Column>
                                )}
                              </Column>
                            </Row>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                <Spacer size={isPhone ? 144 : 48} />
              </Column>
            </ScrollView>
          </KeyboardAvoidingView>
        </DismissKeyboard>
      </ModalScreenContainer>
      <FormCard
        dropShaddow
        paddings="button"
        style={[
          { position: 'absolute', bottom: 0, right: 0 },
          !isPhone && styles.modalBorderBottom,
          isPhone ? { left: 0 } : { width: 500 },
        ]}
      >
        <SafeAreaView edges={['bottom']}>
          <Button
            text={
              isPaidCompletelyFromMembership
                ? t('completePaymentWithMembership')
                : isProtocol
                ? t('completeProtocol')
                : formState.draft.payments.length === 0 ||
                  (totalPayed === 0 && ScaledNumber.toNumber(totalMembershipValue) === 0)
                ? t('addPayment')
                : canEmitReceipt
                ? `${t('completePaymentWithReceipt', { value: total })}`
                : `${t('completePayment', { value: total })} ${t(totalValues.total.unit)}`
            }
            onPress={save}
            disabled={
              !isProtocol &&
              total > 0 &&
              (formState.draft.payments.length === 0 ||
                totalPayed !== total ||
                totalPayed === 0 ||
                isLoading ||
                !isValidTempPayed ||
                showFiscalCodeError)
            }
          />
        </SafeAreaView>
      </FormCard>
    </>
  );
};

export default pipe(PaymentScreen, CurrentBusiness, Authorized);
