import { Portal } from '@gorhom/portal';
import { PageId, Paged, DefinedString } from '@mero/api-sdk';
import { UserAppointment } from '@mero/api-sdk/dist/calendar';
import {
  ClientHistoryRecord,
  ClientHistoryRecordId,
  ClientId,
  ClientNoteHistoryRecord,
  ClientNoteHistoryType,
  ClientProfile,
  SavedClient,
} from '@mero/api-sdk/dist/clients';
import { PageClientStats } from '@mero/api-sdk/dist/clients/page-client-stats';
import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import {
  Avatar,
  colors,
  Column,
  FormCard,
  H1,
  HSpacer,
  Label,
  Row,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Title,
  useToast,
  useShowError,
} from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import { pick } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, ScrollViewProps, TouchableOpacity, View } from 'react-native';
import Svg, { SvgProps, Path } from 'react-native-svg';

import ClientBlockedLabel from '../../../components/ClientBlockedLabel';
import ClientBoostedLabel from '../../../components/ClientBoostedLabel';
import ClientWarnedLabel from '../../../components/ClientWarnedLabel';
import ScrollableSwitch from '../../../components/ScrollableSwitch';
import ClientAppointmentsListView from '../ClientDetailsScreen/components/ClientAppointmentsListView';
import ClientConfirmNoteDeleteModal from '../ClientDetailsScreen/components/ClientConfirmNoteDeleteModal';
import ClientHistoryListView from '../ClientDetailsScreen/components/ClientHistoryListView';
import ClientMembershipsListView from '../ClientDetailsScreen/components/ClientMembershipsListView';
import ClientNoteAddModal from '../ClientDetailsScreen/components/ClientNoteAddModal';
import ClientNoteEditModal from '../ClientDetailsScreen/components/ClientNoteEditModal';
import ClientNoteOptionsModal from '../ClientDetailsScreen/components/ClientNoteOptionsModal';
import ClientNotesListView from '../ClientDetailsScreen/components/ClientNotesListView';
import H3s from '@mero/components/lib/components/Text/H3s';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { ClientDetailsContext, PageAppointmentsWithTotals } from '../../../contexts/ClientDetailsContext';
import { ClientHistoryNoteCreateContext } from '../../../contexts/ClientHistoryNoteAddContext';
import { ClientHistoryNoteDeleteContext } from '../../../contexts/ClientHistoryNoteDeleteContext';
import { ClientHistoryNoteUpdateContext } from '../../../contexts/ClientHistoryNoteUpdateContext';
import { ClientUpdateIsBlockedContext } from '../../../contexts/ClientUpdateIsBlockedContext';
import { ClientUpdateIsWarnedContext } from '../../../contexts/ClientUpdateIsWarnedContext';
import { AuthorizedStackParamList } from '../../../types';
import { nullish } from '../../../utils/function';
import { scaledToString } from '../../../utils/scaled';
import { nameGenerator } from '../../../utils/string';
import { Tabs, TabsOptions } from '../ClientDetailsScreen/ClientDetailsLoadedScreenView';

const CloseIcon = (props: SvgProps) => (
  <Svg width={18} height={18} {...props}>
    <Path fill="none" d="M0 0h18v18H0Z" data-name="Path 8343" />
    <Path
      fill="#52577f"
      d="M15 5.108 13.892 4 9.5 8.392 5.108 4 4 5.108 8.392 9.5 4 13.892 5.108 15 9.5 10.608 13.892 15 15 13.892 10.608 9.5Z"
      data-name="Path 8344"
    />
  </Svg>
);

type Props = {
  clientId: ClientId;
  pageId: PageId;
  client: ClientProfile;
  history: ClientHistoryRecord[];
  appointments: PageAppointmentsWithTotals;
  memberships: Paged<PurchasedMembership<MeroUnits.Any>[]>;
  reports: PageClientStats<MeroUnits.Any>;
  onClientRemove: () => void;
  size?: 'small' | 'normal';
};

const ClientInfo: React.FC<Props> = ({
  clientId,
  pageId,
  client,
  history: fullHistory,
  appointments,
  memberships,
  reports,
  onClientRemove,
  size = 'normal',
}) => {
  const { t } = useTranslation('clients');
  const toast = useToast();
  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList>>();
  const showError = useShowError();
  const isFocused = useIsFocused();

  const [, { pushEvent, subscribe: subscribeAppEvents }] = AppEventsContext.useContext();
  const [state, { reload: reloadClientDetails, reloadMemberships, loadMoreAppointments, loadMoreMemberships }] =
    ClientDetailsContext.useContext();
  const [clientNoteCreateState, { createClientNote, tryReset: tryResetClientNoteCreate }] =
    ClientHistoryNoteCreateContext.useContext();
  const [clientNoteUpdateState, { updateClientNote, tryReset: tryResetClientNoteUpdate }] =
    ClientHistoryNoteUpdateContext.useContext();
  const [clientNoteDeleteState, { deleteClientNote, tryReset: tryResetClientNoteDelete }] =
    ClientHistoryNoteDeleteContext.useContext();
  const [clientUpdateIsBlockedState, { updateClientIsBlocked, tryReset: tryResetUpdateClientIsBlocked }] =
    ClientUpdateIsBlockedContext.useContext();
  const [clientUpdateIsWarnedState, { updateClientIsWarned, tryReset: tryResetUpdateClientIsWarned }] =
    ClientUpdateIsWarnedContext.useContext();

  const [selectedTab, setSelectedTab] = React.useState<Tabs>('bookings');
  const [showClientNoteAddModal, setShowClientNoteAddModal] = React.useState(false);
  const [showClientNoteOptionsModalMenu, setShowClientNoteOptionsModalMenu] = React.useState<
    ClientNoteHistoryRecord | undefined
  >(undefined);
  const [showClientNoteEditModal, setShowClientNoteEditModal] = React.useState<ClientNoteHistoryRecord | undefined>(
    undefined,
  );

  const [showClientNoteDeleteConfirmModal, setShowClientNoteDeleteConfirmModal] = React.useState<
    ClientHistoryRecordId | undefined
  >(undefined);

  const handleScroll: ScrollViewProps['onScroll'] = ({ nativeEvent }) => {
    const isCloseToBottom =
      nativeEvent.layoutMeasurement.height + nativeEvent.contentOffset.y >= nativeEvent.contentSize.height - 20;
    if (isCloseToBottom) {
      switch (selectedTab) {
        case 'bookings':
          return loadMoreAppointments(clientId);
        case 'memberships':
          return loadMoreMemberships(clientId, pageId);
      }
      // You can do something here when the end is reached
    }
  };

  const { firstname, lastname } = client.user;
  const isBoost = client.boostStatus.isBoost;
  const hideBoostDetails = client.boostStatus.isBoost && client.boostStatus.hideBoostDetails;
  const isClaimPending = isBoost && client.boostStatus.isClaimPending;
  const canClaim = isBoost && client.boostStatus.canClaim;
  const canAcceptCommission = isBoost && client.boostStatus.canAcceptCommission;

  const onNewMembership = React.useCallback(() => {
    navigation.navigate('CombineCheckout', {
      screen: 'CheckoutStack',
      params: {
        screen: 'AddProceedScreen',
        params: {
          type: 'Membership',
          clientId: clientId,
        },
      },
    });
  }, []);

  const onNoteOptionsPressCallback = React.useCallback(
    (note: ClientNoteHistoryRecord) => {
      setShowClientNoteOptionsModalMenu(note);
    },
    [setShowClientNoteOptionsModalMenu],
  );

  const { notes, history } = React.useMemo(
    () =>
      fullHistory.reduce(
        (acc: { notes: ClientNoteHistoryRecord[]; history: ClientHistoryRecord[] }, item: ClientHistoryRecord) => {
          if (item.type === ClientNoteHistoryType.value) {
            acc.notes.push(item);
          } else {
            acc.history.push(item);
          }
          return acc;
        },
        {
          notes: [],
          history: [],
        },
      ),
    [fullHistory],
  );

  const nowJsDate = new Date();
  const now = React.useMemo(() => DateTime.fromJSDate(nowJsDate), [Math.floor(nowJsDate.getTime() / 60000)]);

  const onAddNote = React.useCallback(
    (client: SavedClient, text: DefinedString) => {
      if (clientNoteCreateState.type === 'Ready') {
        createClientNote({
          clientId: client._id,
          text: text,
        });
      }
    },
    [clientNoteCreateState, createClientNote],
  );

  const onUpdateNote = React.useCallback(
    (note: ClientNoteHistoryRecord, newText: DefinedString) => {
      if (clientNoteUpdateState.type === 'Ready') {
        updateClientNote({
          clientId: clientId,
          noteId: note._id,
          text: newText,
        });
      }
    },
    [clientNoteUpdateState, clientId],
  );

  const onDeleteNote = React.useCallback(
    (noteId: ClientHistoryRecordId) => {
      if (clientNoteDeleteState.type === 'Ready') {
        deleteClientNote({
          clientId,
          noteId,
        });
      }
    },
    [clientNoteDeleteState, clientId, deleteClientNote],
  );

  const reload = React.useCallback(() => {
    if (state.type === 'Loaded') {
      reloadClientDetails(state.clientId, pageId);
    }
  }, [state, reloadClientDetails]);

  // ClientUpdateIsBlockedContext state changes effect
  React.useEffect(() => {
    if (clientUpdateIsBlockedState.type === 'Updated') {
      tryResetUpdateClientIsBlocked();

      pushEvent({
        type: 'PageClientUpdated',
        pageId: pageId,
        clientId: clientUpdateIsBlockedState.clientId,
      });

      if (clientUpdateIsBlockedState.isBlocked) {
        toast.show({
          type: 'success',
          text: 'Clientul a fost blocat',
        });
      } else {
        toast.show({
          type: 'success',
          text: 'Clientul a fost deblocat',
        });
      }
      reload();
    } else if (clientUpdateIsBlockedState.type === 'Failed') {
      showError(clientUpdateIsBlockedState.error);
      tryResetUpdateClientIsBlocked();
    }
  }, [clientUpdateIsBlockedState, pageId, reload, pushEvent, tryResetUpdateClientIsBlocked, showError, toast]);

  // ClientUpdateIsWarnedContext state changes effect
  React.useEffect(() => {
    if (clientUpdateIsWarnedState.type === 'Updated') {
      tryResetUpdateClientIsWarned();
      pushEvent({
        type: 'PageClientUpdated',
        pageId: pageId,
        clientId: clientUpdateIsWarnedState.clientId,
      });

      if (clientUpdateIsWarnedState.isWarned) {
        toast.show({
          type: 'success',
          text: 'Clientul a fost marcat ca Avertizat',
        });
      } else {
        toast.show({
          type: 'success',
          text: 'Avertizarea clientului a fost ștearsă',
        });
      }
      reload();
    } else if (clientUpdateIsWarnedState.type === 'Failed') {
      showError(clientUpdateIsWarnedState.error);
      tryResetUpdateClientIsWarned();
    }
  }, [clientUpdateIsWarnedState, pageId, pushEvent, tryResetUpdateClientIsWarned, showError]);

  // ClientNoteCreateContext state changes effect
  React.useEffect(() => {
    if (clientNoteCreateState.type === 'Created') {
      tryResetClientNoteCreate();
      toast.show({
        type: 'success',
        text: 'Notița a fost adăugată',
      });
      reload();
    } else if (clientNoteCreateState.type === 'Failed') {
      tryResetClientNoteCreate();
      showError(clientNoteCreateState.error);
    }
  }, [clientNoteCreateState, toast, reload, tryResetClientNoteCreate, showError]);

  // ClientNoteDeleteContext state changes effect
  React.useEffect(() => {
    if (clientNoteDeleteState.type === 'Deleted') {
      tryResetClientNoteDelete();
      toast.show({
        type: 'success',
        text: 'Notița a fost ștearsă',
      });
      reload();
    } else if (clientNoteDeleteState.type === 'Failed') {
      tryResetClientNoteDelete();
      showError(clientNoteDeleteState.error);
    }
  }, [clientNoteDeleteState, toast, reload, tryResetClientNoteDelete, showError]);

  // ClientNoteDeleteContext state changes effect
  React.useEffect(() => {
    if (clientNoteUpdateState.type === 'Updated') {
      tryResetClientNoteUpdate();
      toast.show({
        type: 'success',
        text: 'Notița a fost modificată',
      });
      reload();
    } else if (clientNoteUpdateState.type === 'Failed') {
      tryResetClientNoteUpdate();
      showError(clientNoteUpdateState.error);
    }
  }, [clientNoteUpdateState, toast, reload, tryResetClientNoteUpdate, showError]);

  React.useEffect(() => {
    if (isFocused && state.type === 'Loaded') {
      reloadMemberships(state.clientId, pageId);
    }
  }, [isFocused]);

  // Navigate to appointment details screen
  const openAppointmentDetails = React.useCallback(
    (appointment: UserAppointment) => {
      navigation.navigate('Booking', {
        screen: 'AppointmentScreen',
        params: {
          appointmentId: appointment._id,
          occurrenceIndex: appointment.occurrenceIndex ?? 0,
        },
      });
    },
    [navigation],
  );

  const getHeader = (tab: Tabs) => {
    switch (tab) {
      case 'bookings': {
        if (appointments.data.length > 0) {
          return (
            <>
              <Column style={{ marginTop: -32 }} />
            </>
          );
        } else {
          return null;
        }
      }
      case 'notes': {
        if (notes.length > 0) {
          return (
            <>
              <View style={{ flexDirection: 'row' }}>
                <H1 style={{ flex: 1 }}>{Tabs[selectedTab].label}</H1>
                <TouchableOpacity
                  onPress={() => {
                    setShowClientNoteAddModal(true);
                  }}
                >
                  <Title style={{ color: colors.DARK_BLUE }}>Adaugă notiță</Title>
                </TouchableOpacity>
              </View>
              <Spacer size="24" />
            </>
          );
        } else {
          return null;
        }
      }
      case 'history': {
        if (history.length) {
          return (
            <>
              <H1>{Tabs[selectedTab].label}</H1>
              <Spacer size="24" />
            </>
          );
        } else {
          return null;
        }
      }
      case 'memberships': {
        if (memberships.data.length > 0) {
          return (
            <>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <H1 style={{ flex: 1 }}>{Tabs[selectedTab].label}</H1>
                <TouchableOpacity onPress={onNewMembership}>
                  <Title style={{ color: colors.DARK_BLUE }}>Adaugă abonament</Title>
                </TouchableOpacity>
              </View>
              <Spacer size="24" />
              <Spacer size="24" />
            </>
          );
        } else {
          return null;
        }
      }
    }
  };

  const getContent = (tab: Tabs) => {
    switch (tab) {
      case 'bookings': {
        return (
          <ClientAppointmentsListView
            size={size}
            totals={pick(appointments, 'pastBookingsCount', 'futureBookingsCount')}
            appointments={appointments.data}
            boostAppointmentId={isBoost ? client.boostStatus.boostAppointmentId : undefined}
            now={now}
            onItemPress={openAppointmentDetails}
          />
        );
      }
      case 'notes': {
        return (
          <ClientNotesListView
            notes={notes}
            onNoteOptionsPress={onNoteOptionsPressCallback}
            onAddNote={() => {
              setShowClientNoteAddModal(true);
            }}
          />
        );
      }
      case 'history': {
        return <ClientHistoryListView history={history} now={now.toJSDate()} />;
      }
      case 'memberships': {
        return (
          <ClientMembershipsListView
            memberships={memberships.data}
            clientId={clientId}
            onNewMembership={onNewMembership}
          />
        );
      }
    }
  };

  return (
    <>
      <ScrollView onScroll={handleScroll} scrollEventThrottle={400}>
        <Spacer size="16" />
        <Column style={{ paddingHorizontal: 16 }}>
          <Row justifyContent="space-between">
            <Label style={[{ color: colors.COMET }, meroStyles.text.semibold]}>{t('client')}</Label>
            <TouchableOpacity onPress={onClientRemove}>
              <CloseIcon />
            </TouchableOpacity>
          </Row>
          <Spacer size="8" />
          <Row alignItems="center">
            <Avatar
              source={client.user.photo?.medium}
              firstname={firstname ?? ''}
              lastname={lastname ?? ''}
              size={60}
            />
            <Column style={{ paddingLeft: 8 }}>
              <H1 style={{ fontSize: 20 }}>{nameGenerator(client.user, t('noName'))}</H1>
              {!hideBoostDetails && <SmallBody>{client.user.phone}</SmallBody>}
            </Column>
          </Row>
          <Column alignItems="center">
            {client.isBlocked || client.isWarned || isBoost ? (
              <>
                <Spacer size="8" />
                <View style={meroStyles.layout.row}>
                  {client.isBlocked ? (
                    <>
                      <ClientBlockedLabel />
                      <HSpacer left={8} />
                    </>
                  ) : null}
                  {client.isWarned ? (
                    <>
                      <ClientWarnedLabel />
                      <HSpacer left={8} />
                    </>
                  ) : null}
                  {isBoost ? (
                    <>
                      <ClientBoostedLabel />
                      <HSpacer left={8} />
                    </>
                  ) : null}
                </View>
                <Spacer size="8" />
              </>
            ) : null}
          </Column>
          <Spacer size={16} />
          {reports ? (
            <>
              <Spacer size={16} />
              <FormCard rounded dropShaddow paddings="none" style={{ paddingHorizontal: 16 }}>
                <Spacer size={16} />
                <Row>
                  <Column style={{ flex: 3 }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                      {t('appointmentReports').toLocaleUpperCase()}
                    </SmallBody>
                    <Spacer size={4} />
                    <H3s>{reports.completedBookings}</H3s>
                  </Column>
                  <Column style={{ flex: 3 }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                      {t('canceledReports').toLocaleUpperCase()}
                    </SmallBody>
                    <Spacer size={4} />
                    <H3s>{reports.cancelledBookings}</H3s>
                  </Column>
                  <Column style={{ flex: 3 }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                      {t('noShowReports').toLocaleUpperCase()}
                    </SmallBody>
                    <Spacer size={4} />
                    <H3s>{reports.noShowBookings}</H3s>
                  </Column>
                </Row>
                <Spacer size={16} />
                <Row justifyContent="space-between">
                  <Column style={{ flex: 3 }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                      {t('lastAppointmentReports').toLocaleUpperCase()}
                    </SmallBody>
                    <Spacer size={4} />
                    <H3s>
                      {reports.lastCompletedBookingTs
                        ? DateTime.fromJSDate(reports.lastCompletedBookingTs).toFormat('dd.MM.yy')
                        : '-'}
                    </H3s>
                  </Column>
                  <Column style={{ flex: 3 }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                      {t('revenueReports').toLocaleUpperCase()}
                    </SmallBody>
                    <Spacer size={4} />
                    <H3s>
                      {scaledToString(reports.totalCheckoutCharge.amount)} {t(reports.totalCheckoutCharge.unit)}
                    </H3s>
                  </Column>
                  <Column style={{ flex: 3 }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                      {t('unpaidReports').toLocaleUpperCase()}
                    </SmallBody>
                    <Spacer size={4} />
                    <H3s>
                      {scaledToString(reports.debt.amount)} {t(reports.debt.unit)}
                    </H3s>
                  </Column>
                </Row>
                <Spacer size={16} />
              </FormCard>
            </>
          ) : null}
        </Column>
        <Spacer size="32" color={colors.ALABASTER} />
        <View style={[{ backgroundColor: colors.WHITE, paddingTop: 16, paddingHorizontal: 16 }]}>
          <ScrollableSwitch selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabsOptions={TabsOptions} />
          <Spacer size={32} />
          {getHeader(selectedTab)}
        </View>
        <Column style={{ backgroundColor: colors.WHITE }}>{getContent(selectedTab)}</Column>
      </ScrollView>

      {showClientNoteDeleteConfirmModal !== undefined ? (
        <Portal>
          <ClientConfirmNoteDeleteModal
            noteId={showClientNoteDeleteConfirmModal}
            onDismiss={() => {
              setShowClientNoteDeleteConfirmModal(undefined);
            }}
            onConfirm={() => {
              setShowClientNoteDeleteConfirmModal(undefined);
              onDeleteNote(showClientNoteDeleteConfirmModal);
            }}
          />
        </Portal>
      ) : null}

      {showClientNoteOptionsModalMenu !== undefined ? (
        <ClientNoteOptionsModal
          note={showClientNoteOptionsModalMenu}
          onDismiss={() => {
            setShowClientNoteOptionsModalMenu(undefined);
          }}
          onEdit={(note) => {
            setShowClientNoteOptionsModalMenu(undefined);
            setShowClientNoteEditModal(note);
          }}
          onDelete={(note) => {
            setShowClientNoteOptionsModalMenu(undefined);
            setShowClientNoteDeleteConfirmModal(note._id);
          }}
        />
      ) : null}

      {showClientNoteEditModal !== undefined ? (
        <Portal>
          <ClientNoteEditModal
            note={showClientNoteEditModal}
            onDismiss={() => {
              setShowClientNoteEditModal(undefined);
            }}
            onSave={(note, newText) => {
              setShowClientNoteEditModal(undefined);
              onUpdateNote(note, newText);
            }}
          />
        </Portal>
      ) : null}

      {showClientNoteAddModal ? (
        <Portal>
          <ClientNoteAddModal
            onDismiss={() => {
              setShowClientNoteAddModal(false);
            }}
            onAdd={(text) => {
              setShowClientNoteAddModal(false);
              onAddNote(client, text);
            }}
          />
        </Portal>
      ) : null}
    </>
  );
};

export default ClientInfo;
