import { ClientId } from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import { mergedService } from '@mero/api-sdk/dist/services';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { Column, SmallBody, styles as meroStyles } from '@mero/components';
import { flow, pipe } from 'fp-ts/function';
import { IANAZone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

import AdvancedSelect, { Ref as AdvanceSelectRef } from '../../../components/AdvancedSelect';
import {
  Item,
  ServicesGroup,
  flattenServices,
  groupServices,
  MergedServiceWithAvailableMemberships,
} from '../../../components/GroupedServicesList';

import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { SelectBookingPerformerContext } from '../../../contexts/SelectBookingPerformerContext';
import { SelectBookingServiceContext } from '../../../contexts/SelectBookingServiceContext';
import { SearchServicesContext, withSearchServicesContextProvider } from '../../../contexts/ServicesSearchContext';
import { nullish } from '../../../utils/function';

const MoreIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 8401" transform="translate(-13859 1492)">
      <Circle cx={12} cy={12} r={12} fill="#f2f2fe" data-name="Ellipse 683" transform="translate(13859 -1492)" />
      <Path
        fill="#080de0"
        d="M13875.594-1480.656h-3.938v-3.938a.656.656 0 1 0-1.312 0v3.938h-3.938a.656.656 0 1 0 0 1.312h3.938v3.937a.656.656 0 1 0 1.312 0v-3.937h3.937a.656.656 0 1 0 0-1.312Z"
      />
    </G>
  </Svg>
);

const ServicesIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 8471" transform="translate(-234 -465)">
      <Circle cx={12} cy={12} r={12} fill="#e9ecef" transform="translate(234 465)" />
      <G fill="#9da0b6" data-name="Orion_bulleted-list (1)" transform="translate(240.67 470)">
        <Path d="M3.993 2.335h6.667v2H3.993Zm0 7.333h6.667v2H3.993Zm0-3.669h6.667v2H3.993Z" />
        <Circle cx={1.333} cy={1.333} r={1.333} transform="translate(-.004 2)" />
        <Circle cx={1.333} cy={1.333} r={1.333} data-name="layer1" transform="translate(-.004 9.333)" />
        <Circle cx={1.333} cy={1.333} r={1.333} data-name="layer1" transform="translate(-.004 5.667)" />
      </G>
    </G>
  </Svg>
);
export type Props = CurrentBusinessProps &
  AuthorizedProps & {
    clientId?: ClientId;
    workerId?: WorkerId;
    bookingPageId?: PageId;
    isMoreServices?: boolean;
    disabled?: boolean;
  };

const SelectService: React.FC<Props> = ({
  page,
  workerId,
  clientId,
  isMoreServices,
  bookingPageId,
  disabled = false,
}) => {
  const { t } = useTranslation('booking');

  const advancedSelectRef = React.useRef<AdvanceSelectRef>(null);

  const [, { setSelectedService }] = SelectBookingServiceContext.useContext();
  const [selectedWorkerState] = SelectBookingPerformerContext.useContext();
  const [searchState, { debounceSearch, loadMore, init }] = SearchServicesContext.useContext();

  const [showMoreServices, setShowMoreServices] = React.useState(false);

  const selectServiceCallback = React.useCallback(
    (service: MergedServiceWithAvailableMemberships) => {
      setSelectedService(service);
    },
    [setSelectedService],
  );

  const groupsWithServices: ServicesGroup[] = React.useMemo(() => {
    const { serviceGroups } = page.details;

    const services = searchState.result.data.map((service) => ({
      ...mergedService.fromSavedService(service),
      availableMemberships: service.availableMemberships,
    }));

    return groupServices(services, serviceGroups, 'Alte Servicii');
  }, [searchState.result.data]);

  const onSearch = React.useCallback(
    (query: string) => {
      debounceSearch({ query: { search: query || undefined, workerId }, clientId });
    },
    [workerId, clientId],
  );

  const timeZone = React.useMemo(() => IANAZone.create('Europe/Bucharest'), []);

  const hideMoreServices = React.useCallback(() => {
    setShowMoreServices(false);
  }, []);

  React.useEffect(() => {
    onSearch('');
  }, [workerId, clientId]);

  React.useEffect(() => {
    init({ pageId: page.details._id, query: { search: undefined, workerId }, clientId });
  }, []);

  return (
    <>
      {!showMoreServices && isMoreServices && !disabled ? (
        <TouchableOpacity
          onPress={() => {
            setShowMoreServices(true);
          }}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <MoreIcon />
          <SmallBody style={[meroStyles.text.semibold, meroStyles.text.link, { paddingLeft: 8 }]}>
            {t('addMoreServices')}
          </SmallBody>
        </TouchableOpacity>
      ) : null}
      {showMoreServices || !isMoreServices ? (
        <AdvancedSelect
          ref={advancedSelectRef}
          Icon={
            <Column style={{ paddingRight: 12 }}>
              <ServicesIcon />
            </Column>
          }
          defaultState={isMoreServices}
          text={t('selectService')}
          placeholder={t('searchServicePlaceholder')}
          onSearch={onSearch}
          extraData={selectedWorkerState}
          data={flattenServices(groupsWithServices, timeZone)}
          onClose={flow(hideMoreServices, () => onSearch(''))}
          Item={(props) => (
            <Item
              {...props}
              onServiceSelected={flow(
                selectServiceCallback,
                hideMoreServices,
                advancedSelectRef.current?.onHide ?? nullish,
              )}
            />
          )}
          ItemSeparatorComponent={null}
          onEndReached={loadMore}
          onEndReachedThreshold={0.9}
          windowSize={11}
          refreshing={searchState.type === 'Loading'}
        />
      ) : null}
    </>
  );
};

export default pipe(SelectService, withSearchServicesContextProvider(false), CurrentBusiness, Authorized);
