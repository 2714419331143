import { ProductSearchType, ProductSearchResult, ProductCountersResult, Inventory } from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import { Eq } from 'fp-ts/lib/Eq';
import { omit } from 'lodash';
import * as React from 'react';
import { debounce } from 'throttle-debounce';

import log from '../../utils/log';
import { AuthContext, meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';
import { SelectedProductsTabContext } from '../SelectedProductsTabContext';

export const LIMIT = 10;

type SearchProductsState =
  | {
      readonly type: 'New';
      readonly query: ProductSearchType;
      readonly result: ProductSearchResult;
      readonly counters: ProductCountersResult;
      readonly inventories: Inventory[];
    }
  | {
      readonly type: 'Loading';
      readonly pageId: PageId;
      readonly query: ProductSearchType;
      readonly result: ProductSearchResult;
      readonly counters: ProductCountersResult;
      readonly inventories: Inventory[];
    }
  | {
      readonly type: 'Loaded';
      readonly pageId: PageId;
      readonly query: ProductSearchType;
      readonly result: ProductSearchResult;
      readonly counters: ProductCountersResult;
      readonly inventories: Inventory[];
    }
  | {
      readonly type: 'Failed';
      readonly pageId: PageId;
      readonly query: ProductSearchType;
      readonly result: ProductSearchResult;
      readonly counters: ProductCountersResult;
      readonly inventories: Inventory[];
      readonly error: unknown;
    };

const defaultState = (): SearchProductsState => ({
  type: 'New',
  query: {
    search: '',
    limit: LIMIT,
  },
  result: {
    products: [],
    next: undefined,
  },
  counters: {
    all: 0,
    active: 0,
    inactive: 0,
    noCategory: 0,
    noBrand: 0,
    brands: {},
    categories: {},
  },
  inventories: [],
});

const productsSearchQuery: Eq<ProductSearchType> = {
  equals: (a, b) => a.search === b.search && a.sortBy === a.sortBy && a.sortDirection === a.sortDirection,
};

export const SearchProductsContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      payload: {
        pageId: PageId;
        query: ProductSearchType;
        result: ProductSearchResult;
        counters?: ProductCountersResult;
        inventories?: Inventory[];
      },
    ) => {
      if (
        state.type === 'Loading' &&
        state.pageId === payload.pageId &&
        productsSearchQuery.equals(payload.query, state.query)
      ) {
        return {
          type: 'Loaded',
          pageId: payload.pageId,
          query: payload.query,
          result: payload.result,
          counters: payload.counters ?? state.counters,
          inventories: payload.inventories ?? state.inventories,
        };
      } else {
        return state;
      }
    },
    trySetFailed: (
      state,
      payload: {
        pageId: PageId;
        query: ProductSearchType;
        error: unknown;
      },
    ) => {
      if (
        state.type === 'Loading' &&
        state.pageId === payload.pageId &&
        productsSearchQuery.equals(payload.query, state.query)
      ) {
        return {
          ...state,
          type: 'Failed',
          pageId: payload.pageId,
          query: payload.query,
          result: state.result,
          error: payload.error,
          counters: state.counters,
        };
      } else {
        return state;
      }
    },
    tryResetFailed: (state) => {
      if (state.type === 'Failed') {
        return {
          ...state,
          type: 'Loaded',
          pageId: state.pageId,
          query: state.query,
          result: state.result,
          counters: state.counters,
        };
      } else {
        return state;
      }
    },
    updateSearch: (state, search: string) => {
      if (state.type === 'Loaded') {
        return {
          ...state,
          type: 'Loaded',
          pageId: state.pageId,
          query: {
            ...state.query,
            search,
          },
          result: state.result,
          counters: state.counters,
        };
      } else {
        return state;
      }
    },
    reset: () => defaultState(),
    mutate: (s, fn: (s: SearchProductsState) => SearchProductsState) => {
      return fn(s);
    },
  },
  (dispatch) => {
    log.debug('Init SearchProductsContext methods');
    type SearchFn = (pageId: PageId, query: ProductSearchType) => Promise<void>;

    const search: SearchFn = async (pageId, query): Promise<void> => {
      try {
        log.debug('search products for page', pageId, 'query', query);

        const [result, counters, inventories] = await Promise.all([
          meroApi.pro.products.search(pageId, query),
          meroApi.pro.products.getSearchCounters(pageId, query),
          meroApi.pro.inventories.getAllInventories(pageId),
        ]);

        dispatch.trySetResult({
          pageId,
          query,
          result,
          counters,
          inventories,
        });
      } catch (e) {
        log.exception(e);
        dispatch.trySetFailed({
          pageId,
          query,
          error: e,
        });
      }
    };

    const debounceSearch = debounce(250, search);

    return {
      init: (payload: { pageId: PageId; query?: Partial<ProductSearchType> }): void => {
        dispatch.mutate((state) => {
          const query: ProductSearchType = {
            ...payload.query,
            limit: LIMIT,
          };

          search(payload.pageId, query);

          return {
            type: 'Loading',
            pageId: payload.pageId,
            query: query,
            result: state.result,
            counters: state.counters,
            inventories: state.inventories,
          };
        });
      },
      search: (payload: { query?: Partial<ProductSearchType> }): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loading' || state.type === 'Loaded') {
            const query: ProductSearchType = payload.query ? { ...payload.query, limit: LIMIT } : state.query;

            search(state.pageId, omit(query, 'page'));

            return {
              type: 'Loading',
              pageId: state.pageId,
              query,
              result: state.result,
              counters: state.counters,
              inventories: state.inventories,
            };
          } else {
            return state;
          }
        });
      },
      debounceSearch: (payload: { query?: Partial<ProductSearchType> }): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loading' || state.type === 'Loaded') {
            const query: ProductSearchType = payload.query
              ? { ...state.query, ...payload.query, limit: LIMIT }
              : state.query;

            debounceSearch(state.pageId, query);

            return {
              type: 'Loading',
              pageId: state.pageId,
              query,
              result: state.result,
              counters: state.counters,
              inventories: state.inventories,
            };
          } else {
            return state;
          }
        });
      },

      updateSearch: dispatch.updateSearch,

      reset: dispatch.reset,

      loadMore: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded' && state.result.next) {
            const { pageId, query, result } = state;

            const loadMore = async (): Promise<void> => {
              try {
                log.debug('loading more products for page', pageId, 'query', query, 'next', result.next);

                const moreProducts = await meroApi.pro.products.search(pageId, {
                  ...query,
                  page: result.next,
                });

                log.debug('loaded', LIMIT, 'more products for page', pageId, 'query', query);

                const newResult = {
                  ...moreProducts,
                  products: result.products.concat(moreProducts.products),
                };

                dispatch.trySetResult({
                  pageId,
                  query,
                  result: newResult,
                });
              } catch (e) {
                log.exception(e);
                dispatch.trySetFailed({
                  pageId,
                  query,
                  error: e,
                });
              }
            };

            loadMore();

            return {
              type: 'Loading',
              pageId: state.pageId,
              query,
              result: state.result,
              counters: state.counters,
              inventories: state.inventories,
            };
          } else {
            return state;
          }
        });
      },

      reload: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loading' || state.type === 'Loaded') {
            log.debug('reload products for page', state.pageId, 'query', state.query);
            search(state.pageId, state.query);

            return {
              type: 'Loading',
              pageId: state.pageId,
              query: state.query,
              result: state.result,
              counters: state.counters,
              inventories: state.inventories,
            };
          } else {
            return state;
          }
        });
      },
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    query?: Partial<ProductSearchType>;
  }>
> = ({ children, query }) => {
  const [authState] = AuthContext.useContext();
  const [currentBusinessState] = CurrentBusinessContext.useContext();
  const [selectedTab] = SelectedProductsTabContext.useContext();
  const [, { init }] = SearchProductsContext.useContext();

  const page = currentBusinessState.type === 'Loaded' ? currentBusinessState.page : undefined;
  const user = authState.type === 'Authorized' ? authState.user : undefined;

  React.useEffect(() => {
    const initSearch = async () => {
      if (page && user) {
        init({
          pageId: page.details._id,
          query,
        });
      }
    };
    initSearch();
  }, [init, page, user, selectedTab]);

  return <>{children}</>;
};

export const withSearchProductsContextProvider =
  (query?: Partial<ProductSearchType>) =>
  <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
    return function WithSearchProductsContextProvider(props: P) {
      return (
        <SearchProductsContext.Provider>
          <ContextInit query={query}>
            <Content {...props} />
          </ContextInit>
        </SearchProductsContext.Provider>
      );
    };
  };
