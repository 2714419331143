import { CalendarEntryDetails, InventoryId, ProductId } from '@mero/api-sdk';
import { CreateCheckoutTransactionItem } from '@mero/api-sdk/dist/checkout';
import { NONE } from '@mero/api-sdk/dist/checkout/checkoutTransactionCompany/checkoutTransactionCompany';
import { computeTransactionTotals } from '@mero/api-sdk/dist/checkout/checkoutTransactionDetails/utils';
import { CheckoutUserPreview } from '@mero/api-sdk/dist/checkout/checkoutUserPreview';
import { PurchasedMembershipDetails } from '@mero/api-sdk/dist/memberships/purchasedMembershipDetails';
import { Column, useShowError } from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { NonEmptyArray } from 'fp-ts/NonEmptyArray';
import { flow, pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from 'react-native';

import { styles } from '../../../components/AddClientScreen/styles';
import Button from '@mero/components/lib/components/Button';
import FormCard from '@mero/components/lib/components/FormCard';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { BookingClientSelectContext } from '../../../contexts/BookingClientSelectContext';
import {
  CheckoutFormContext,
  getEmptyAmount,
  Item,
  ItemMembershipInstallment,
  MembershipPayment,
  WithUiKey,
  withUiKey,
} from '../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { CheckoutStackParamList, CombineCheckoutStackParamList } from '../../../types';
import log from '../../../utils/log';
import { uiKeyToTransactionItemId } from '../../../utils/payment';
import AddProceedComponent, { paymentConversion } from './AddProceedComponent';
import DeleteDraftDialog from './DeleteDraftDialog';
import EditAmount from './EditAmount';
import ItemsMenu, { Props as ItemsMenuProps } from './ItemsMenu';
import { appointmentToCheckoutItem } from './SelectBookingScreen';

const isNotUndefined = <T,>(value: T): value is NonNullable<T> => typeof value !== 'undefined';

export type ProductAvailability = {
  _id: ProductId;
  quantity: number;
  stock: number;
};

export const membershipInstallmentToCheckoutItem = (
  membership: PurchasedMembershipDetails<MeroUnits.Any>,
  user: CheckoutUserPreview,
): Omit<ItemMembershipInstallment, 'type'> => {
  return {
    membership: {
      _id: membership._id,
      name: membership.template.name,
    },
    total: {
      amount: {
        amount: ScaledNumber.sub(membership.template.sellingPrice.amount, membership.paid.amount),
        unit: 'RON',
      },
      vatStatus: {
        type: 'Included',
        rate: undefined,
      },
    },
    saleOwner: user,
  };
};

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutStackParamList & CombineCheckoutStackParamList, 'AddProceedScreen'>;

const AddProceedScreen: React.FC<Props> = ({ navigation, route, page, authorization }): React.ReactElement | null => {
  const [
    formState,
    {
      setClient,
      findOrCreateClient,
      loadTransaction,
      setItem,
      updateItem,
      removeItem,
      addDiscount,
      updateDiscount,
      removeDiscount,
      addProtocol,
      removeProtocol,
      reset,
      setPayment,
      resetPayment,
    },
  ] = CheckoutFormContext.useContext();
  const [{ value: newClient }, { set: setNewClient }] = BookingClientSelectContext.useContext();

  const { t } = useTranslation('checkout');
  const { isPhone, isDesktop } = useMediaQueries();
  const showError = useShowError();
  const { width } = useWindowDimensions();
  const goBack = useGoBack();

  const { type, transactionId, workerId } = route.params;

  const [isLoading, setIsLoading] = React.useState(false);
  const [showItemsMenu, setShowItemsMenu] = React.useState(false);
  const [showButton, setShowButton] = React.useState(true);
  const [showAmountModal, setShowAmountModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<Parameters<ItemsMenuProps['onSelect']>[0] | undefined>(
    undefined,
  );
  const [hasInvalidItems, setHasInvalidItems] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [productAvailability, setProductAvailability] = React.useState<Record<InventoryId, ProductAvailability[]>>({});

  const addNewItemCallback = React.useCallback(() => {
    setShowItemsMenu(true);
  }, []);

  const selectClientCallback = React.useCallback(() => {
    navigation.push('CheckoutSubStack', { screen: 'SelectClientScreen' });
  }, [navigation]);

  const removeClientCallback = () => setClient({ type: 'none' });

  const hideAmountModal = React.useCallback(() => {
    setShowAmountModal(false);
  }, []);

  const addBookingItem = React.useCallback(
    async (appointment: CalendarEntryDetails.Appointment) => {
      const booking = {
        type: 'Booking' as const,
        ...appointmentToCheckoutItem(appointment, authorization.user, page.workers),
      };
      setItem(booking);
      if (
        appointment.membershipConsumptionLocks &&
        appointment.membershipConsumptionLocks.length > 0 &&
        appointment.payload.client
      ) {
        const lockMemberships = appointment.membershipConsumptionLocks;
        const memberships = await meroApi.memberships.getPurchasedMembershipsAvailableForTransaction({
          page: page.details,
          client: appointment.payload.client,
          items: appointment.payload.bookedServices.map((s) => ({
            type: 'Service',
            service: {
              _id: s._id,
            },
            quantity: s.quantity,
          })) as NonEmptyArray<CreateCheckoutTransactionItem.Service<MeroUnits.Any>>,
          appointments: [
            {
              _id: appointment._id,
              occurrenceIndex: appointment.occurrenceIndex,
            },
          ],
        });
        const locks = memberships.reduce((acc, membership) => {
          const lockMembership = lockMemberships.find((lock) => lock.membership._id === membership.membership._id);

          if (!lockMembership || lockMembership.item.type !== 'Service') {
            return acc;
          }

          const service = booking.items[lockMembership.item.bookingServiceIndex];

          if (!service || service.type !== 'Service') {
            return acc;
          }

          const item = membership.items.find(
            (item) => item.type === 'Service' && item.service._id === service.service._id,
          );

          if (!item) {
            return acc;
          }

          return [
            ...acc,
            withUiKey({
              type: 'Membership',
              membership: {
                ...membership.membership,
              },
              items: [
                withUiKey({
                  ...item,
                  quantity: lockMembership.quantity,
                  usedFor: service.uiKey,
                }),
              ],
            }) satisfies MembershipPayment,
          ];
        }, [] as MembershipPayment[]);
        setPayment(locks);
      }
    },
    [authorization],
  );

  const addMembershipInstallment = React.useCallback(
    (membershipInstallment: PurchasedMembershipDetails<MeroUnits.Any>) => {
      setItem({
        type: 'MembershipInstallment',
        ...membershipInstallmentToCheckoutItem(membershipInstallment, authorization.user),
      });
    },
    [authorization],
  );

  const showAddAmount = React.useCallback(() => {
    setShowAmountModal(true);
  }, []);

  const toggleButton = React.useCallback(() => {
    setShowButton((prev) => !prev);
  }, []);

  // Select client
  React.useEffect(() => {
    if (newClient !== undefined && newClient.phone) {
      setNewClient(undefined); // reset the state so next select will trigger the change
      setClient({
        type: 'existing',
        client: {
          _id: newClient._id,
          pageId: page.details._id,
          user: {
            _id: newClient.userId,
            phone: newClient.phone,
            profile: {
              firstname: newClient.firstname,
              lastname: newClient.lastname,
              photo: newClient.photo,
            },
          },
        },
      });
    }
  }, [newClient, setClient]);

  const deleteProceed = () => {
    setShowDeleteModal(true);
  };

  const onAddProtocol = async () => {
    setIsLoading(true);
    const shouldContinue = (await saveClient()) && checkAllPrices();
    if (shouldContinue) {
      addProtocol();
      navigation.navigate('CheckoutStack', { screen: 'PaymentScreen' });
    } else {
      // showError(t('checkScreenInfo'));
    }
    setIsLoading(false);
  };

  const getContent = React.useCallback(() => {
    const appointment =
      isNotUndefined(route.params.appointmentId) && isNotUndefined(route.params.occurrenceIndex)
        ? {
            appointmentId: route.params.appointmentId,
            occurrenceIndex: route.params.occurrenceIndex,
          }
        : undefined;
    const membership = isNotUndefined(route.params.clientId)
      ? {
          membershipPurchaseId: route.params.membershipPurchaseId,
          clientId: route.params.clientId,
          membershipTemplateId: route.params.membershipTemplateId,
        }
      : undefined;
    switch (type) {
      case 'Booking':
        return (
          <AddProceedComponent
            type={type}
            onClientChanged={setClient}
            onChangeClientPress={selectClientCallback}
            onSelectClientPress={selectClientCallback}
            onRemoveClientPress={removeClientCallback}
            addNewItemPress={flow(toggleButton, addNewItemCallback)}
            addBooking={addBookingItem}
            addAmount={showAddAmount}
            onUpdateItem={updateItem}
            onRemoveItem={removeItem}
            addDiscount={addDiscount}
            addProtocol={onAddProtocol}
            removeProtocol={removeProtocol}
            updateDiscount={updateDiscount}
            removeDiscount={removeDiscount}
            page={page}
            appointment={appointment}
            isSavedTransaction={!!transactionId}
            hasInvalidItems={hasInvalidItems}
            deleteProceed={deleteProceed}
            toggleButton={toggleButton}
            productAvailability={productAvailability}
            setProductAvailability={setProductAvailability}
            {...formState.draft}
          />
        );
      case 'Service':
        return (
          <AddProceedComponent
            type={type}
            defaultWorker={workerId}
            onClientChanged={setClient}
            onChangeClientPress={selectClientCallback}
            onSelectClientPress={selectClientCallback}
            onRemoveClientPress={removeClientCallback}
            addNewItemPress={flow(toggleButton, addNewItemCallback)}
            addBooking={addBookingItem}
            addAmount={showAddAmount}
            onUpdateItem={updateItem}
            onRemoveItem={removeItem}
            addDiscount={addDiscount}
            updateDiscount={updateDiscount}
            removeDiscount={removeDiscount}
            addProtocol={onAddProtocol}
            removeProtocol={removeProtocol}
            page={page}
            isSavedTransaction={!!transactionId}
            hasInvalidItems={hasInvalidItems}
            deleteProceed={deleteProceed}
            toggleButton={toggleButton}
            productAvailability={productAvailability}
            setProductAvailability={setProductAvailability}
            {...formState.draft}
          />
        );
      case 'Product':
        return (
          <AddProceedComponent
            type={type}
            defaultWorker={workerId}
            onClientChanged={setClient}
            onChangeClientPress={selectClientCallback}
            onSelectClientPress={selectClientCallback}
            onRemoveClientPress={removeClientCallback}
            addNewItemPress={flow(toggleButton, addNewItemCallback)}
            addBooking={addBookingItem}
            addAmount={showAddAmount}
            onUpdateItem={updateItem}
            onRemoveItem={removeItem}
            addDiscount={addDiscount}
            updateDiscount={updateDiscount}
            removeDiscount={removeDiscount}
            addProtocol={onAddProtocol}
            removeProtocol={removeProtocol}
            page={page}
            isSavedTransaction={!!transactionId}
            hasInvalidItems={hasInvalidItems}
            deleteProceed={deleteProceed}
            toggleButton={toggleButton}
            productAvailability={productAvailability}
            setProductAvailability={setProductAvailability}
            {...formState.draft}
          />
        );
      case 'Membership':
        return (
          <AddProceedComponent
            type={type}
            onClientChanged={setClient}
            onChangeClientPress={selectClientCallback}
            onSelectClientPress={selectClientCallback}
            onRemoveClientPress={removeClientCallback}
            addNewItemPress={flow(toggleButton, addNewItemCallback)}
            addBooking={addBookingItem}
            addAmount={showAddAmount}
            onUpdateItem={updateItem}
            onRemoveItem={removeItem}
            addDiscount={addDiscount}
            updateDiscount={updateDiscount}
            removeDiscount={removeDiscount}
            addProtocol={onAddProtocol}
            removeProtocol={removeProtocol}
            page={page}
            isSavedTransaction={!!transactionId}
            membership={membership}
            hasInvalidItems={hasInvalidItems}
            deleteProceed={deleteProceed}
            toggleButton={toggleButton}
            productAvailability={productAvailability}
            setProductAvailability={setProductAvailability}
            {...formState.draft}
          />
        );
      case 'MembershipInstallment':
        return (
          <AddProceedComponent
            type={type}
            onClientChanged={setClient}
            onChangeClientPress={selectClientCallback}
            onSelectClientPress={selectClientCallback}
            onRemoveClientPress={removeClientCallback}
            addNewItemPress={flow(toggleButton, addNewItemCallback)}
            addBooking={addBookingItem}
            addAmount={showAddAmount}
            onUpdateItem={updateItem}
            onRemoveItem={removeItem}
            addDiscount={addDiscount}
            updateDiscount={updateDiscount}
            removeDiscount={removeDiscount}
            addProtocol={onAddProtocol}
            removeProtocol={removeProtocol}
            page={page}
            isSavedTransaction={!!transactionId}
            membership={membership}
            addMembershipInstallment={addMembershipInstallment}
            hasInvalidItems={hasInvalidItems}
            deleteProceed={deleteProceed}
            toggleButton={toggleButton}
            productAvailability={productAvailability}
            setProductAvailability={setProductAvailability}
            {...formState.draft}
          />
        );
      case 'Amount':
        return (
          <AddProceedComponent
            type={type}
            defaultWorker={workerId}
            onClientChanged={setClient}
            onChangeClientPress={selectClientCallback}
            onSelectClientPress={selectClientCallback}
            onRemoveClientPress={removeClientCallback}
            addNewItemPress={flow(toggleButton, addNewItemCallback)}
            addBooking={addBookingItem}
            addAmount={showAddAmount}
            onUpdateItem={updateItem}
            onRemoveItem={removeItem}
            addDiscount={addDiscount}
            updateDiscount={updateDiscount}
            removeDiscount={removeDiscount}
            addProtocol={onAddProtocol}
            removeProtocol={removeProtocol}
            page={page}
            isSavedTransaction={!!transactionId}
            hasInvalidItems={hasInvalidItems}
            deleteProceed={deleteProceed}
            toggleButton={toggleButton}
            productAvailability={productAvailability}
            setProductAvailability={setProductAvailability}
            {...formState.draft}
          />
        );
    }
  }, [page, route.params.type, formState, selectClientCallback, removeClientCallback, addNewItemCallback, setClient]);

  const getAddItemModal = React.useCallback(() => {
    switch (selectedItem) {
      case 'Booking':
        return null;
      case 'Service':
        return null;
      case 'Product':
        return null;
      // case 'Membership':
      //   return null;
      // case 'MembershipInstallment':
      //   return null;
      case 'Amount':
        return null;
      default:
        return null;
    }
  }, [selectedItem]);

  const saveClient = React.useCallback(async () => {
    try {
      if (formState.draft.client.type === 'new') {
        await findOrCreateClient();
      }
      return true;
    } catch (error) {
      log.error('Failed to create new client', error);
      return false;
    }
  }, [formState.draft.client]);

  const isClientRequired = () => {
    const isRequired =
      formState.draft.items.some((item) => item.type === 'Membership' || item.type === 'MembershipInstallment') &&
      formState.draft.client.type === 'none';

    if (isRequired) {
      showError(undefined, t('clientIsRequired'));
    }

    return isRequired;
  };

  const checkAllPrices = React.useCallback(() => {
    const areInvalid = formState.draft.items.some((item) => {
      if (item.type === 'Service') {
        const hasDiscount =
          item.total.discount &&
          (item.total.discount.type === 'Value'
            ? ScaledNumber.toNumber(item.total.discount.value.amount)
            : item.total.discount.percent.value.toFixed()) !== 0;

        return ScaledNumber.toNumber(item.total.amount.amount) === 0 && !hasDiscount;
      }

      if (item.type === 'Booking') {
        return item.items.some((bookingItem) => {
          const hasDiscount =
            bookingItem.total.discount &&
            (bookingItem.total.discount.type === 'Value'
              ? ScaledNumber.toNumber(bookingItem.total.discount.value.amount)
              : bookingItem.total.discount.percent.value.toFixed()) !== 0;

          return ScaledNumber.toNumber(bookingItem.total.amount.amount) === 0 && !hasDiscount;
        });
      }

      if (item.type === 'Product') {
        const hasDiscount =
          item.total.discount &&
          (item.total.discount.type === 'Value'
            ? ScaledNumber.toNumber(item.total.discount.value.amount)
            : item.total.discount.percent.value.toFixed()) !== 0;

        return ScaledNumber.toNumber(item.total.amount.amount) === 0 && !hasDiscount;
      }

      if (item.type === 'Amount') {
        return ScaledNumber.toNumber(item.total.amount.amount) === 0;
      }

      return false;
    });

    if (areInvalid) {
      setHasInvalidItems(true);
      return false;
    }

    return true;
  }, [formState.draft.items]);

  React.useEffect(() => {
    if (!transactionId && formState.draftId) {
      navigation.setParams({ transactionId: formState.draftId });
    }
  }, [formState.draftId]);

  React.useEffect(() => {
    if (transactionId) {
      loadTransaction(transactionId, page.details._id);
    }
  }, [transactionId]);

  const totalValues = React.useMemo(() => {
    return computeTransactionTotals(
      {
        unit: 'RON',
        payments: formState.draft.payments.map(paymentConversion),
        items: formState.draft.items.map((i): WithUiKey<Item> => {
          if (i.type === 'Booking') {
            return {
              ...i,
              items: i.items.map(uiKeyToTransactionItemId),
            };
          }

          return uiKeyToTransactionItemId(i);
        }),
        company: formState.draft.company
          ? {
              type: 'Company',
              company: formState.draft.company,
              emitReceipt: formState.draft.emitReceipt,
              receipt: formState.draft.receipt,
            }
          : NONE,
        discount: formState.draft.discount,
      },
      2,
    );
  }, [formState.draft]);

  const disableButton = React.useMemo(
    () =>
      formState.draft.items.length === 0 ||
      isLoading ||
      Object.values(productAvailability).some((p) => p.some((a) => a.quantity > a.stock)),
    [formState.draft.items.length, isLoading, productAvailability],
  );

  const defaultOwner = React.useMemo(() => {
    const worker = workerId ? page.workers.find((w) => w._id === workerId) : undefined;
    const member =
      (worker
        ? page.members.find((m) => m.user._id === worker?.user._id)
        : page.members.find((m) => m.user._id === authorization.user._id)) ?? page.members[0];

    return {
      _id: member.user._id,
      phone: member.user.phone ?? '074000000',
      profile: {
        firstname: member.user.profile.firstname,
        lastname: member.user.profile.lastname,
        photo: member.user.profile.photo,
      },
    };
  }, [workerId]);

  return (
    <>
      {isDesktop && <Column style={{ width }} />}

      {getContent()}

      {showItemsMenu && (
        <ItemsMenu
          onSelect={flow(
            (item) => {
              if (item === 'Booking') {
                return navigation.navigate('CheckoutSubStack', {
                  screen: 'SelectBookingScreen',
                  params:
                    formState.draft.client.type === 'existing'
                      ? { clientId: formState.draft.client.client._id }
                      : undefined,
                });
              }

              if (item === 'Service') {
                return navigation.navigate('CheckoutSubStack', {
                  screen: 'SelectServiceScreen',
                  params: workerId ? { workerId } : undefined,
                });
              }

              if (item === 'Product') {
                return navigation.navigate('CheckoutSubStack', {
                  screen: 'SelectProductScreen',
                  params: workerId ? { workerId } : undefined,
                });
              }

              if (item === 'Amount') {
                return showAddAmount();
              }

              if (item === 'Membership') {
                return navigation.navigate('CheckoutSubStack', { screen: 'SelectMembershipScreen' });
              }

              if (item === 'MembershipInstallment') {
                return navigation.navigate('CheckoutSubStack', { screen: 'SelectMembershipInstallmentScreen' });
              }

              return setSelectedItem(item);
            },
            () => setShowItemsMenu(false),
            toggleButton,
          )}
          onDismiss={flow(() => setShowItemsMenu(false), toggleButton)}
        />
      )}
      {showAmountModal && (
        <EditAmount
          isEdit={false}
          amount={getEmptyAmount(defaultOwner)}
          onSave={flow(setItem, hideAmountModal)}
          onDelete={flow(removeItem, hideAmountModal)}
          onCancel={hideAmountModal}
        />
      )}
      {showButton && (
        <FormCard
          dropShaddow
          paddings="button"
          style={[
            { position: 'absolute', bottom: 0, right: 0 },
            isDesktop ? styles.modalBorderBottom : { left: 0 },
            isDesktop && { width: 500 },
          ]}
        >
          <SafeAreaView edges={['bottom']}>
            <Button
              text={
                formState.draft.items.length === 0
                  ? t('toPaymentButtonDisabled')
                  : t('toPaymentButton', {
                      value: ScaledNumber.toNumber(totalValues.total.amount),
                      currency: t(totalValues.total.unit),
                    })
              }
              onPress={async () => {
                setIsLoading(true);
                const shouldContinue = (await saveClient()) && checkAllPrices() && !isClientRequired();
                if (shouldContinue) {
                  removeProtocol();
                  resetPayment('Membership');
                  navigation.navigate('PaymentScreen');
                } else {
                  // showError(t('checkScreenInfo'));
                }
                setIsLoading(false);
              }}
              disabled={disableButton}
              // disabled
            />
          </SafeAreaView>
        </FormCard>
      )}
      {getAddItemModal()}
      {showDeleteModal && transactionId && (
        <DeleteDraftDialog
          onSuccess={() => {
            setShowDeleteModal(false);
            reset();
            goBack();
          }}
          onCancel={() => setShowDeleteModal(false)}
          checkoutTransactionId={transactionId}
        />
      )}
    </>
  );
};

export default pipe(AddProceedScreen, CurrentBusiness, Authorized);
