import { BulkDailyBounds, HasId } from '@mero/api-sdk';
import { BusinessHours as BusinessHoursType } from '@mero/api-sdk/dist/business';
import { CalendarEntry, CalendarId } from '@mero/api-sdk/dist/calendar';
import { SavedWorker, WorkerId } from '@mero/api-sdk/dist/workers';
import { colors, Column, Icon, Spacer } from '@mero/components';
import * as A from 'fp-ts/Array';
import * as Eq from 'fp-ts/Eq';
import * as O from 'fp-ts/Option';
import { flow, pipe } from 'fp-ts/function';
import * as N from 'fp-ts/number';
import * as S from 'fp-ts/string';
import { DateTime, Zone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

import AddProceedMenu from '../../screens/Authorized/CheckoutScreen/AddProceedMenu';

import Body from '@mero/components/lib/components/Text/Body';

import { useClickOutsideWeb } from '../../hooks/useClickOutsideWeb';
import { useMediaQueries } from '../../hooks/useMediaQueries';
import useMemoEq from '../../hooks/useMemoEq';

import { AuthorizedProps } from '../../contexts/AuthContext';
import { isFullDay } from '../../contexts/BlockedTimeFormContext';
import { CalendarContext, LocalDateObject } from '../../contexts/CalendarContext';
import { CurrentBusinessProps } from '../../contexts/CurrentBusiness';
import { CalendarScreenNavigationProp } from '../../types';
import AddBookingPlusButton from './AddBookingPlusButton';
import BigCalendar from './BigCalendar';
import AddBookingOptionsModal from './BigCalendar/AddBookingOptionsModal';
import { CalendarWorkerPreview } from './BigCalendar/CalendarBodySplitView/calendarWorkerPreview';
import CalendarDropdown from './BigCalendar/CalendarDropdown';
import CalendarHeader from './BigCalendar/CalendarHeader';
import CalendarHeaderLarge from './BigCalendar/CalendarHeaderLarge';
import CalendarHeaderMobileWeb from './BigCalendar/CalendarHeaderMobileWeb';
import { DatePickerPopup } from './BigCalendar/DatePickerPopup';
import { CalendarPeriod, Mode } from './BigCalendar/types';
import { DAY_HOURS_BUFFER, formatStartEnd, getDayHours } from './BigCalendar/utils';
import { NormalizedEvent } from './NormalizedEvent';
import { styles } from './styles';

const NoProIcon = (props: SvgProps) => (
  <Svg width={62} height={54} {...props}>
    <G data-name="Orion_schedule-window (1)" transform="translate(-1 -5)">
      <Circle cx={16} cy={16} r={16} fill="#a6aac6" transform="translate(30 26)" />
      <Circle cx={16} cy={16} r={16} fill="#a6aac6" data-name="layer4" transform="translate(30 26)" />
      <Path fill="#a8acc7" d="M2 6h52v12H2Z" />
      <Path fill="#fff" d="M46 26a15.9 15.9 0 0 1 7.9 2.1h.1V18H2v32h30.1A16 16 0 0 1 46 26Z" />
      <Path fill="#000064" d="M32.1 49.9a16 16 0 0 1 4.8-21.1A16 16 0 0 0 27.2 50h4.9Z" opacity={0.15} />
      <Circle cx={1} cy={1} r={1} fill="#a7abc7" transform="translate(7 11)" />
      <Circle cx={1} cy={1} r={1} fill="#a7abc7" data-name="layer1" transform="translate(14 11)" />
      <Circle cx={1} cy={1} r={1} fill="#a7abc7" data-name="layer1" transform="translate(21 11)" />
      <Path fill="#000064" d="M2 18h4v32H2ZM2 6h4v12H2Z" data-name="opacity" opacity={0.15} />
      <Circle
        cx={16}
        cy={16}
        r={16}
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        transform="translate(30 26)"
      />
      <Path
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M46 34v10h8"
        data-name="stroke"
      />
      <Circle
        cx={1}
        cy={1}
        r={1}
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        data-name="stroke"
        transform="translate(7 11)"
      />
      <Circle
        cx={1}
        cy={1}
        r={1}
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        data-name="stroke"
        transform="translate(14 11)"
      />
      <Circle
        cx={1}
        cy={1}
        r={1}
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        data-name="stroke"
        transform="translate(21 11)"
      />
      <Path
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M2 6h52v12H2Zm52 22V18M2 18v32h30"
        data-name="stroke"
      />
    </G>
  </Svg>
);

export type ViewMode = {
  label: string;
  value: Mode;
};

export const MODES: ViewMode[] = [
  {
    label: 'Zilnic',
    value: 'day',
  },
  {
    label: 'Săptămânal',
    value: 'week',
  },
];

/*
original: 201 230 202
new: 181 207 182
 */

const mapColors = (colors: (string | undefined)[]): string[] =>
  colors
    .map((color) => {
      switch (color?.toUpperCase()) {
        case '#A5D6A7':
          return '#C9E6CA';
        case '#FFAB91':
          return '#FDCBBB';
        case '#FFF59D':
          return '#FEF9C4';
        case '#80CBC4':
          return '#B3E0DB';
        case '#90CAF9':
          return '#BCDFFB';
        case '#F48FB1':
          return '#F8BCD0';
        case '#CE93D8':
          return '#E1BDE7';
        default:
          return color;
      }
    })
    .filter((c): c is Exclude<undefined, typeof c> => c !== undefined);

const formatCalendarEntryTime = (start: DateTime, end: DateTime, timezone: Zone | string): string => {
  const isFullDayEntry = isFullDay(start, end, timezone);

  if (isFullDayEntry) {
    const newEnd = end.minus({ days: 1 });
    if (LocalDateObject.equals(start, newEnd)) {
      return start.toFormat('d LLL', { locale: 'ro' });
    } else {
      return `${start.toFormat('d LLL', { locale: 'ro' })} - ${newEnd.toFormat('d LLL', { locale: 'ro' })}`;
    }
  } else if (LocalDateObject.equals(start, end)) {
    return `${start.toFormat('HH:mm', { locale: 'ro' })} - ${end.toFormat('HH:mm', { locale: 'ro' })}`;
  } else {
    return `${start.toFormat('d LLL HH:mm', { locale: 'ro' })} - ${end.toFormat('d LLL HH:mm', { locale: 'ro' })}`;
  }
};

type Events = Record<string, NormalizedEvent[]>;

const normalizeEvents = (entries: CalendarEntry.Any[], period: CalendarPeriod, timezone: string): Events => {
  const events = entries
    .slice()
    .sort((a, b) => a.start.getTime() - b.start.getTime())
    .reduce((acc: Events, entry: CalendarEntry.Any) => {
      const start = DateTime.fromJSDate(entry.start, { zone: timezone });
      const end = DateTime.fromJSDate(entry.end, { zone: timezone });

      if (start > end) {
        // Ignore broken events
        return acc;
      }

      const normalizedEvent: NormalizedEvent = ((): NormalizedEvent => {
        switch (entry.type) {
          case CalendarEntry.Type.Appointment.VALUE: {
            const { firstname, lastname } =
              entry.payload.client?.firstname || entry.payload.client?.lastname
                ? entry.payload.client
                : entry.payload.user?.profile ?? {};

            const fullName = firstname || lastname ? `${firstname ?? ''} ${lastname ?? ''}` : 'Fără client';

            const normalizedEvent: NormalizedEvent = {
              type: CalendarEntry.Type.Appointment.VALUE,
              title: formatStartEnd(start, end),
              start: start,
              end: end,
              extra: {
                raw: entry.type === CalendarEntry.Type.Appointment.VALUE ? entry.payload : undefined,
                calendarId: entry.calendarId,
                client: fullName,
                clientIsBlocked: entry.payload.client?.isBlocked ?? false,
                clientIsFavourite: entry.payload.client?.isFavourite ?? false,
                clientIsWarned: entry.payload.client?.isWarned ?? false,
                clientIsBoost: Boolean(
                  entry.payload.clientBoostStatus?.isBoostClient && entry.payload.clientBoostStatus.isBoostAppointment,
                ),
                isPending: entry.payload.status === 'pending',
                bookedServices: entry.payload.bookedServices.map((s) => s.name),
                colors: mapColors(entry.payload.bookedServices.map((s) => s.color)),
                id: entry._id || undefined,
                occurrenceIndex: entry.occurrenceIndex,
                note: entry.payload.note,
                couponTypes: entry.payload.coupons?.map((coupon) => coupon.type) ?? [],
                isNoShow: entry.payload.status === 'noShow',
                isDeleted: entry.payload.status === 'cancelled',
                hasCheckoutFinished: entry.payload.hasFinishedCheckoutTransactions ?? false,
                //FIXME: use ts-expect-error Recurrence.Any
                recurrent: false,
                //@ts-expect-error Recurrence.Any
                recurrenceRule: entry.recurrenceRule,
              },
            };

            return normalizedEvent;
          }
          case CalendarEntry.Type.BlockedTime.VALUE: {
            const normalizedEvent: NormalizedEvent = {
              type: CalendarEntry.Type.BlockedTime.VALUE,
              title: formatCalendarEntryTime(start, end, timezone),
              start: start,
              end: end,
              extra: {
                id: entry._id || '',
                calendarId: entry.calendarId,
                occurrenceIndex: entry.occurrenceIndex,
                reason: entry.payload.reason,
              },
            };

            return normalizedEvent;
          }
        }
      })();

      // Limit generated event days to selected period as there are entries which takes years!
      const entryDaysStart = start < period.from ? period.from : start;
      const entryDaysEnd = end > period.to ? period.to : end;

      // Generate date strings for all the days are covered by the event
      const entryDays: string[] = A.unfold(entryDaysStart, (start) =>
        pipe(
          start,
          O.some,
          O.filter((start) => start < entryDaysEnd),
          O.map((start) => [start.toFormat('yyyy-MM-dd'), start.plus({ days: 1 }).startOf('day')]),
        ),
      );

      for (const dayStr of entryDays) {
        if (!acc[dayStr]) {
          acc[dayStr] = [];
        }

        acc[dayStr].push(normalizedEvent);
      }

      return acc;
    }, {});

  return events;
};

export type AddEventIntentParams = {
  readonly start: DateTime;
  readonly worker?: CalendarWorkerPreview;
};

type Props = AuthorizedProps &
  CurrentBusinessProps & {
    readonly navigation: CalendarScreenNavigationProp;
  };

const Calendar: React.FC<Props> = ({ authorization, page, navigation }) => {
  const { t } = useTranslation('calendar');
  const [
    {
      selectedCalendars,
      calendarsOrder,
      selectedDate,
      selectedTimezone,
      slices,
      period,
      activeOnly,
      showOnlyWorkingHours,
      floatingMenuOpenId,
    },
    { setSelectedDate, setMultipleCalendars },
  ] = CalendarContext.useContext();

  const { isPhone } = useMediaQueries();
  const selectedDateStart = React.useMemo(
    () => DateTime.fromObject(selectedDate, { zone: selectedTimezone }),
    [selectedDate, selectedTimezone],
  );
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

  const userId = authorization.user._id;
  const nowTs = new Date().getTime();

  // currentDate, updates every minute, if changed
  const currentDate: LocalDateObject = useMemoEq(
    () => {
      const t = DateTime.fromMillis(nowTs, { zone: selectedTimezone }).toObject();
      return LocalDateObject.unsafeFromPartial({
        year: t.year,
        month: t.month,
        day: t.day,
      });
    },
    Math.floor(nowTs / 1000 / 60),
    N.Eq,
  );

  const team = React.useMemo(
    () =>
      pipe(
        page.workers,
        A.sort({
          compare: (a: SavedWorker) => (a.user._id === userId ? -1 : 1),
          equals: (a: SavedWorker, b: SavedWorker) => a._id === b._id,
        }),
      ),
    [page.workers, userId],
  );

  const viewMode = React.useMemo(() => MODES.find((m) => m.value === period.type) ?? MODES[0], [period]);

  const calendarSelectRef = React.useRef<View>(null);

  const selectedCalendarsProcessed = React.useMemo(() => {
    if (activeOnly) {
      return selectedCalendars
        .filter((c) => slices[c]?.activeDailyBounds)
        .sort((a, b) => calendarsOrder.indexOf(a) - calendarsOrder.indexOf(b));
    }

    return selectedCalendars.sort((a, b) => calendarsOrder.indexOf(a) - calendarsOrder.indexOf(b));
  }, [activeOnly, selectedCalendars, selectedDate, slices]);

  useClickOutsideWeb({
    ref: calendarSelectRef,
    isVisible: isCalendarOpen,
    onClickOutside() {
      setIsCalendarOpen(false);
    },
  });

  const setMode = (mode: ViewMode): void => {
    const firstWorker = team[0];

    setMultipleCalendars({
      calendarIds:
        mode.value === 'week' && selectedCalendarsProcessed.length > 1
          ? [firstWorker.calendar._id]
          : selectedCalendarsProcessed,
      calendarSettings: firstWorker.calendar.settings,
      period: mode.value,
    });
  };

  const selectedWorkers: CalendarWorkerPreview[] = useMemoEq(
    (): CalendarWorkerPreview[] => {
      // filter selected team members and keep calendars selection order
      return selectedCalendarsProcessed.reduce((acc: CalendarWorkerPreview[], calendarId) => {
        const member = team.find((m) => m.calendar._id === calendarId);

        if (member) {
          acc.push(member);
        }

        return acc;
      }, []);
    },
    [team, selectedCalendarsProcessed],
    Eq.tuple(CalendarWorkerPreview.array, A.getEq(S.Eq)),
  );

  const activeHours = React.useMemo(
    (): Record<CalendarId, BusinessHoursType> =>
      team.reduce((acc: Record<CalendarId, BusinessHoursType>, worker) => {
        return {
          ...acc,
          [worker.calendar._id]: worker?.businessHours ?? page.details.businessHours,
        };
      }, {}),
    [team, page.details.businessHours],
  );

  const events = React.useMemo(() => {
    return selectedCalendarsProcessed.reduce((acc, selectedCalendar) => {
      return {
        ...acc,
        [selectedCalendar]: normalizeEvents(slices[selectedCalendar]?.entries ?? [], period, selectedTimezone),
      };
    }, {});
  }, [selectedCalendarsProcessed, slices, period, selectedTimezone]);

  const [showAddBookingOptionsModal, setShowAddBookingOptionsModal] = React.useState(false);

  const [showAddProceedMenu, setShowAddProceedMenu] = React.useState(false);

  const addBookingOptionsCallback = React.useCallback(() => {
    setShowAddBookingOptionsModal(true);
  }, [setShowAddBookingOptionsModal]);

  const [addEventIntentParams, setAddEventIntentParams] = React.useState<AddEventIntentParams | undefined>(undefined);

  const addBookingCallback = React.useCallback(() => {
    setShowAddBookingOptionsModal(false);

    const now = DateTime.now().setZone(selectedTimezone);

    // Time should be either HH:30 or HH:00
    const eventDate =
      addEventIntentParams?.start ??
      (selectedDateStart > now
        ? selectedDateStart.set({ hour: 9, minute: 0 })
        : now.minute > 30
        ? now.set({ minute: 0 }).plus({ hours: 1 })
        : now.set({ minute: 30 }));

    const worker =
      addEventIntentParams?.worker ??
      (selectedCalendarsProcessed.length === 1
        ? team.find((w) => selectedCalendarsProcessed.includes(w.calendar._id))
        : undefined);

    setAddEventIntentParams(undefined);

    navigation.navigate('Booking', {
      screen: 'AppointmentScreen',
      params: { date: eventDate.toJSDate(), workerId: worker?._id },
    });
  }, [selectedDateStart, selectedCalendarsProcessed, addEventIntentParams, navigation, setShowAddBookingOptionsModal]);

  const addBlockedTimeCallback = React.useCallback(() => {
    setShowAddBookingOptionsModal(false);

    const now = DateTime.now().setZone(selectedTimezone);

    // Time should be either HH:30 or HH:00
    const eventDate =
      addEventIntentParams?.start ??
      (selectedDateStart > now
        ? selectedDateStart.set({ hour: 9, minute: 0 })
        : now.minute > 30
        ? now.set({ minute: 0 }).plus({ hours: 1 })
        : now.set({ minute: 30 }));

    const worker =
      addEventIntentParams?.worker ??
      (selectedCalendarsProcessed.length === 1
        ? team.find((w) => selectedCalendarsProcessed.includes(w.calendar._id))
        : undefined);

    setAddEventIntentParams(undefined);

    navigation.navigate('Booking', {
      screen: 'BlockedTimeCreateScreen',
      params: { date: eventDate.toISO(), workerId: worker?._id },
    });
  }, [selectedDateStart, selectedCalendarsProcessed, addEventIntentParams, navigation, setShowAddBookingOptionsModal]);

  const addCheckoutCallback = React.useCallback(() => {
    setShowAddBookingOptionsModal(false);

    setShowAddProceedMenu(true);
  }, []);

  const dismissAddBookingOptionsModal = React.useCallback(() => {
    setShowAddBookingOptionsModal(false);
    setAddEventIntentParams(undefined);
    setShowAddProceedMenu(false);
  }, [setShowAddBookingOptionsModal]);

  const userCanWriteAllBookings = page.permissions.bookings.canWriteAllBookings();
  const userCanWriteOwnBookings = page.permissions.bookings.canWriteOwnBookings();

  const addNewEvent = React.useCallback(
    (eventDate: Date, workerRef?: HasId<WorkerId>): void => {
      const worker: CalendarWorkerPreview | undefined = workerRef
        ? selectedWorkers.find((w) => w._id === workerRef._id)
        : undefined;

      if (userCanWriteAllBookings || userCanWriteOwnBookings /*&& worker?.user._id === userId*/) {
        setAddEventIntentParams({
          start: DateTime.fromJSDate(eventDate, { zone: selectedTimezone }),
          worker,
        });
        isPhone && addBookingOptionsCallback();
      }
    },
    [
      selectedWorkers,
      userCanWriteAllBookings,
      userCanWriteOwnBookings,
      userId,
      selectedTimezone,
      setAddEventIntentParams,
      addBookingOptionsCallback,
      isPhone,
    ],
  );

  const hideHeaderCalendar = React.useCallback(() => {
    setIsCalendarOpen(false);
  }, [setIsCalendarOpen]);

  const onDayPress = React.useCallback(
    (date: LocalDateObject) => {
      setSelectedDate(date);
      isCalendarOpen && hideHeaderCalendar();
    },
    [isCalendarOpen, setSelectedDate, hideHeaderCalendar],
  );

  const onAddToDate = React.useCallback(
    (addDays: number) => () => {
      setSelectedDate(
        LocalDateObject.unsafeFromPartial(DateTime.fromObject(selectedDate).plus({ days: addDays }).toObject()),
      );
    },
    [selectedDate, setSelectedDate],
  );

  const canAddBooking = React.useMemo(() => {
    return page.permissions.bookings.canWriteOwnBlockedTime() || page.permissions.bookings.canWriteOwnBookings();
  }, [page]);

  const selfWorker = React.useMemo(() => {
    return page.workers.find((w) => w.user._id === userId);
  }, [userId, page]);

  const dayHours = React.useMemo(() => {
    if (showOnlyWorkingHours) {
      const firstCalendar = slices[selectedCalendarsProcessed[0]];
      const activeDailyBounds = Object.values(slices)
        .filter((s) => (activeOnly ? s?.activeDailyBounds : true))
        .map((s) => s?.activeDailyBounds)
        .reduce(BulkDailyBounds.tryMerge, firstCalendar?.workingHours);

      const calculateDayHours = getDayHours(
        Math.max((activeDailyBounds?.from.hour ?? DAY_HOURS_BUFFER) - DAY_HOURS_BUFFER, 0),
        Math.min((activeDailyBounds?.to.hour ?? 24 - DAY_HOURS_BUFFER) + DAY_HOURS_BUFFER, 24),
      );

      return calculateDayHours.length > 0 ? calculateDayHours : getDayHours(0, 24);
    }

    return getDayHours(0, 24);
  }, [showOnlyWorkingHours, slices, activeOnly, selectedCalendarsProcessed]);

  return (
    <>
      <Column style={styles.headerWrapper}>
        <CalendarDropdown
          navigation={navigation}
          activeHours={activeHours}
          activeMode={viewMode}
          modes={MODES}
          setMode={setMode}
          selectedDate={selectedDate}
          isCalendarOpen={isCalendarOpen}
          onCalendarStateChange={(v) => setIsCalendarOpen(v)}
          onAddToDate={onAddToDate}
        />
        {isPhone ? (
          Platform.OS === 'web' ? (
            <CalendarHeaderMobileWeb selectedDate={selectedDate} currentDate={currentDate} onDayPress={onDayPress} />
          ) : (
            <CalendarHeader selectedDate={selectedDate} currentDate={currentDate} onDayPress={onDayPress} />
          )
        ) : (
          <CalendarHeaderLarge activeMode={viewMode} selectedDate={selectedDate} currentDate={currentDate} />
        )}
      </Column>

      <Column style={{ position: 'relative', flex: 1 }}>
        {selectedCalendarsProcessed.length === 0 && activeOnly && (
          <Column
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 200,
            }}
          >
            <NoProIcon />
            <Spacer size={16} />
            <Body style={{ textAlign: 'center' }}>{t('noProsAvailable')}</Body>
          </Column>
        )}

        <BigCalendar
          height={2334}
          events={events || {}}
          selectedDate={selectedDate}
          currentDate={currentDate}
          dayHours={dayHours}
          selectedWorkers={selectedWorkers}
          selectedTimezone={selectedTimezone}
          activeHours={activeHours}
          onChangeDate={([start]) => {
            if (!LocalDateObject.equals(start, selectedDate)) {
              onDayPress(start);
            }
          }}
          mode={viewMode.value}
          weekStartsOn={1}
          onScroll={hideHeaderCalendar}
          onPressCell={canAddBooking ? addNewEvent : undefined}
          onAddBooking={addBookingCallback}
          onAddCheckout={addCheckoutCallback}
          onAddBlockedTime={addBlockedTimeCallback}
          onPressEvent={(event) => {
            const canManageBooking =
              page.permissions.bookings.canWriteAllBookings() || event.extra.calendarId === selfWorker?.calendar._id;
            if (selectedCalendarsProcessed && canManageBooking) {
              switch (event.type) {
                case CalendarEntry.Type.Appointment.VALUE: {
                  navigation.navigate('Booking', {
                    screen: 'AppointmentScreen',
                    params: {
                      appointmentId: event.extra.id,
                      occurrenceIndex: event.extra.occurrenceIndex,
                    },
                  });

                  return;
                }
                case CalendarEntry.Type.BlockedTime.VALUE: {
                  navigation.navigate('Booking', {
                    screen: 'BlockedTimeEditScreen',
                    params: {
                      calendarId: event.extra.calendarId,
                      calendarEntryId: event.extra.id,
                      occurrenceIndex: `${event.extra.occurrenceIndex}`,
                      start: event.start.toISO(),
                    },
                  });

                  return;
                }
              }
            }
          }}
          addEventIntent={addEventIntentParams}
        />
      </Column>

      {isCalendarOpen && (
        <View style={[styles.calendar, isPhone ? { top: 35 } : { top: 91 }]}>
          <View ref={calendarSelectRef} style={!isPhone && styles.selectCalendarDesktop}>
            <DatePickerPopup
              selected={selectedDate}
              selectedCalendarIds={selectedCalendarsProcessed}
              onDateSelected={onDayPress}
            />
          </View>
        </View>
      )}

      {!showAddBookingOptionsModal && !floatingMenuOpenId && (
        <View
          style={{
            zIndex: 201,
            position: 'absolute',
            bottom: 32,
            right: 24,
            alignItems: 'flex-end',
          }}
          pointerEvents="box-none"
        >
          {!LocalDateObject.equals(selectedDate, DateTime.local()) && (
            <Column>
              <TouchableOpacity
                onPress={() =>
                  onDayPress(LocalDateObject.unsafeFromPartial(DateTime.now().setZone(selectedTimezone).toObject()))
                }
              >
                <Column
                  justifyContent="center"
                  alignItems="center"
                  style={[
                    {
                      height: 52,
                      width: 52,
                      borderRadius: 25,
                      backgroundColor: '#F7FAFC',
                    },
                  ]}
                >
                  <Icon type="calendar-today" color={colors.DARK_BLUE} />
                </Column>
              </TouchableOpacity>
            </Column>
          )}
          {canAddBooking && (
            <>
              <Spacer size={16} />
              <AddBookingPlusButton onPress={addBookingOptionsCallback} />
            </>
          )}
        </View>
      )}

      {showAddBookingOptionsModal ? (
        <AddBookingOptionsModal
          onDismiss={dismissAddBookingOptionsModal}
          onAddBooking={addBookingCallback}
          onAddCheckout={addCheckoutCallback}
          onAddBlockedTime={addBlockedTimeCallback}
        />
      ) : null}
      {showAddProceedMenu && (
        <AddProceedMenu
          onDismiss={() => setShowAddProceedMenu(false)}
          onSelect={flow(
            (type) => {
              const worker =
                addEventIntentParams?.worker ??
                (selectedCalendarsProcessed.length === 1
                  ? team.find((w) => selectedCalendarsProcessed.includes(w.calendar._id))
                  : undefined);
              navigation.navigate('CombineCheckout', {
                screen: 'CheckoutStack',
                params: { screen: 'AddProceedScreen', params: { type, workerId: worker?._id } },
              });
            },
            () => setShowAddProceedMenu(false),
          )}
        />
      )}
    </>
  );
};

export default Calendar;
