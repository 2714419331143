import { PositiveInt, ProductId } from '@mero/api-sdk';
import {
  colors,
  Column,
  H2,
  Label,
  Row,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Title,
  Button,
} from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import * as E from 'fp-ts/Either';
import { flow, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { isNaN } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TextInput, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, Rect, G, Circle, Path } from 'react-native-svg';

import HSpacer from '@mero/components/lib/components/HSpacer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import TypeSafeTextInput, { ValueIO } from '@mero/components/lib/components/TypeSafeTextInput';

import { BookingFormContext, ConsumedProduct } from '../../../contexts/BookingFormContext';
import { NumberFromString, roundToDecimals } from '../../../utils/number';
import {
  localeNumberValidator,
  localeStringToNumber,
  multiplyScaled,
  positiveScaledNumber,
  replaceDecimalSeparator,
  scaledToString,
  stripLocalThousandsSeparators,
  subtractScaled,
} from '../../../utils/scaled';
import DiscountComponent, { MinusIcon, PlusIcon } from '../CheckoutScreen/DiscountComponent';
import { DeleteIcon } from './Icons';

const CloseIcon = (props: SvgProps) => (
  <Svg width={18} height={18} {...props}>
    <Path fill="none" d="M0 0h18v18H0Z" data-name="Path 8343" />
    <Path
      fill="#52577f"
      d="M15 5.108 13.892 4 9.5 8.392 5.108 4 4 5.108 8.392 9.5 4 13.892 5.108 15 9.5 10.608 13.892 15 15 13.892 10.608 9.5Z"
      data-name="Path 8344"
    />
  </Svg>
);

const DiscountIcon = (props: SvgProps) => (
  <Svg width={24} height={24} data-name="Group 6969" {...props}>
    <Rect width={24} height={24} fill="#f2f2fe" rx={12} />
    <G
      fill="none"
      stroke="#080de0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      data-name="Orion_discount (2)"
      transform="translate(8 8.287)"
    >
      <Circle cx={1} cy={1} r={1} />
      <Circle cx={1} cy={1} r={1} data-name="layer2" transform="translate(6 5.427)" />
      <Path d="M7.453.244.245 7.38" data-name="layer2" />
    </G>
  </Svg>
);

type Props = {
  disabled?: boolean;
  onClose: () => void;
  onSave: (product: ConsumedProduct) => void;
  onRemove: () => void;
  productIndex: number;
  productId: ProductId;
};

const Product: React.FC<Props> = ({ productId, productIndex, onClose, onSave, onRemove, disabled }) => {
  const i18n = useTranslation('products');

  const [formState] = BookingFormContext.useContext();

  const product = formState.products[productIndex];

  const initialRender = React.useRef(true);

  const productPrice = React.useMemo(
    () => product.product.price.discountedPrice.amount,
    [product.product.price.discountedPrice],
  );

  const [actionInProgress, setActionInProgress] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);
  const [discount, setDiscount] = React.useState({
    value: 0,
    percentage: 0,
    type: 'Value',
  });
  const quantityTimer = React.useRef(0);

  const [price, setPrice] = React.useState({
    input: scaledToString(productPrice),
    decoded: NumberFromString.decode(scaledToString(productPrice)),
  });
  const priceValid = E.isRight(price.decoded);

  const [quantity, setDiscountQuantity] = React.useState(ScaledNumber.toNumber(product.product.quantity));
  const [tempQuantity, setTempQuantity] = React.useState(roundToDecimals(quantity, 0).toString() ?? '1');
  const [totalValue, setTotalValue] = React.useState(
    multiplyScaled(product.product.price.discountedPrice.amount, quantity),
  );
  const [showDiscountInput, setShowDiscountInput] = React.useState(product.product.customPrice.discount !== undefined);

  const { discountValue, discountPercentage } = React.useMemo(() => {
    if (!product.product.customPrice.discount) {
      return {
        discountValue: 0,
        discountPercentage: 0,
      };
    }
    const total = ScaledNumber.toNumber(product.product.customPrice.amount.amount);
    if (product.product.customPrice.discount.type === 'Value') {
      const value = ScaledNumber.toNumber(product.product.customPrice.discount.value.amount);
      return {
        discountValue: value,
        discountPercentage: roundToDecimals((value * 100) / total),
      };
    }

    if (product.product.customPrice.discount.type === 'Percent') {
      const percentage = product.product.customPrice.discount.percent.value;
      return {
        discountValue: roundToDecimals((percentage * total) / 100),
        discountPercentage: percentage,
      };
    }

    return {
      discountValue: 0,
      discountPercentage: 0,
    };
  }, [product.product.customPrice.discount]);

  const updateQuantity = (value: string = tempQuantity) => {
    window.clearTimeout(quantityTimer.current);

    const parsedValue = localeStringToNumber(value);
    const computedValue = PositiveInt.JSON.is(parsedValue) ? parsedValue : (1 as PositiveInt);

    setDiscountQuantity(computedValue);
    setTempQuantity(computedValue.toString());
    const priceValue = localeStringToNumber(price.input);
    setTotalValue(
      multiplyScaled(
        ScaledNumber.fromNumber(
          isNaN(priceValue) ? 0 : priceValue,
          product.product.customPrice.amount.amount.scale ?? 2,
        ),
        computedValue,
      ),
    );
  };

  const incrementValue = () => {
    updateQuantity((quantity + 1).toString());
  };

  const decrementValue = () => {
    updateQuantity((quantity - 1).toString());
  };

  const updateTempQuantity = (value: string) => {
    setTempQuantity(value);
    window.clearTimeout(quantityTimer.current);
    quantityTimer.current = window.setTimeout(() => {
      updateQuantity(value);
    }, 500);
  };

  const numberValidator =
    (prev: string) =>
    <A extends t.Mixed>(next: ValueIO<t.TypeOf<A>>) => {
      const parsed = replaceDecimalSeparator(next.input);
      return localeNumberValidator(parsed)
        ? { input: parsed, decoded: next.decoded }
        : { input: prev, decoded: next.decoded };
    };

  const quantityValidator = (prev: string) => (next: string) => {
    const parsed = replaceDecimalSeparator(next);
    return localeNumberValidator(parsed) ? parsed : prev;
  };

  const handleSave = () => {
    onSave({
      ...product.product,
      price: {
        ...product.product.price,
        discountedPrice: {
          amount: ScaledNumber.fromNumber(localeStringToNumber(price.input), 2),
          unit: product.product.price.discountedPrice.unit,
        },
      },
      quantity: positiveScaledNumber(quantity, product.product.quantity.scale ?? 2),
      customPrice: {
        ...product.product.customPrice,
        amount: {
          ...product.product.customPrice.amount,
          amount: ScaledNumber.fromNumber(localeStringToNumber(price.input) * quantity, 2),
        },
        discount:
          discount.value > 0
            ? {
                type: discount.type === 'Value' ? 'Value' : 'Percent',
                value: {
                  amount: ScaledNumber.fromNumber(discount.value, 2),
                  unit: product.product.customPrice.amount.unit,
                },
                percent: {
                  value: discount.percentage,
                },
              }
            : undefined,
      },
    });
  };

  /**
   * Trigger save on value change
   */
  React.useEffect(() => {
    handleSave();
  }, [price, quantity, discount]);

  if (!product) {
    return null;
  }

  const isQuantityValid = product.product.isStockManagementEnabled
    ? product.product.stock.quantity.value >= quantity
    : true;

  return (
    <ScrollView style={{ paddingHorizontal: 16 }}>
      <Spacer size="16" />
      <Row justifyContent="space-between">
        <Label style={[{ color: colors.COMET }, meroStyles.text.semibold]}>{i18n.t('changeProduct')}</Label>
        <TouchableOpacity onPress={onClose}>
          <CloseIcon />
        </TouchableOpacity>
      </Row>
      <Spacer size="8" />
      <H2>{product.product.name}</H2>
      <Spacer size="16" />
      <Row style={{ alignItems: 'center' }}>
        <Column style={{ flex: 1 }}>
          <Title>{i18n.t('quantity')}</Title>
          <Spacer size={8} />
          <Row
            style={[
              {
                padding: 8,
                borderWidth: 1,
                borderRadius: 4,
                borderColor: isQuantityValid ? colors.GEYSER : colors.RADICAL_RED,
                alignItems: 'center',
                width: '100%',
              },
              disabled && { backgroundColor: colors.ATHENS_GRAY, height: 43 },
            ]}
          >
            {!disabled && (
              <TouchableOpacity
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                  backgroundColor: colors.SKY_BLUE,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={decrementValue}
                disabled={disabled}
              >
                <MinusIcon />
              </TouchableOpacity>
            )}
            <Column style={{ flex: 1, paddingHorizontal: 4 }}>
              <TextInput
                style={[
                  { flex: 1, textAlign: 'center', fontSize: 16, lineHeight: 22, fontFamily: 'open-sans' },
                  disabled && { textAlign: 'left' },
                ]}
                value={tempQuantity}
                onChangeText={setTempQuantity}
                onBlur={flow(() => tempQuantity, quantityValidator(quantity.toString()), updateQuantity)}
                keyboardType={'numeric'}
                editable={!disabled}
              />
            </Column>
            {!disabled && (
              <TouchableOpacity
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                  backgroundColor: colors.SKY_BLUE,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={incrementValue}
                disabled={disabled}
              >
                <PlusIcon />
              </TouchableOpacity>
            )}
          </Row>
        </Column>
        <HSpacer left={16} />
        <Column flex={1}>
          <InputWithLabel
            label={i18n.t('unitPrice')}
            isError={showErrors && !priceValid}
            errorText={i18n.t('priceError')}
          >
            <TypeSafeTextInput
              codec={NumberFromString}
              value={price.input}
              onChange={flow(numberValidator(price.input), setPrice)}
              keyboardType="numeric"
              placeholder={i18n.t('pricePlaceholder')}
              onFocus={() => {
                setPrice({
                  input: stripLocalThousandsSeparators(price.input),
                  decoded: NumberFromString.decode(stripLocalThousandsSeparators(price.input)),
                });
              }}
              onBlur={() => {
                const valueAsNumber = localeStringToNumber(price.input);
                const parsed = ScaledNumber.fromNumber(
                  isNaN(valueAsNumber) ? 0 : valueAsNumber,
                  product.product.customPrice.amount.amount.scale ?? 2,
                );
                setPrice({
                  input: price.input ? scaledToString(parsed) : '',
                  decoded: NumberFromString.decode(stripLocalThousandsSeparators(price.input)),
                });
                setTotalValue(multiplyScaled(parsed, quantity));
              }}
              editable={!disabled}
            />
          </InputWithLabel>
        </Column>
      </Row>

      <Spacer size="8" />
      <Row justifyContent="space-between">
        <Column>
          {!isQuantityValid && (
            <SmallBody style={{ color: colors.RADICAL_RED, paddingBottom: 8 }}>{i18n.t('quantityError')}</SmallBody>
          )}
          {!showDiscountInput && !disabled && (
            <TouchableOpacity
              style={{ flexDirection: 'row', alignItems: 'center' }}
              onPress={() => setShowDiscountInput(true)}
            >
              <DiscountIcon />
              <SmallBody style={[{ color: colors.DARK_BLUE, paddingLeft: 8 }, meroStyles.text.semibold]}>
                {i18n.t('addDiscount')}
              </SmallBody>
            </TouchableOpacity>
          )}
        </Column>
        {!showDiscountInput && (
          <SmallBody style={[meroStyles.text.semibold, { textAlign: 'right', flex: 1 }]}>
            {i18n.t('total')}:{' '}
            <SmallBody>
              {scaledToString(totalValue)} {i18n.t(product.product.customPrice.amount.unit)}
            </SmallBody>
          </SmallBody>
        )}
      </Row>
      <Spacer size="8" />
      {showDiscountInput && (
        <>
          <DiscountComponent
            disabled={disabled}
            price={
              priceValid
                ? ScaledNumber.toNumber(
                    multiplyScaled(ScaledNumber.fromNumber(localeStringToNumber(price.input), 2), quantity),
                  )
                : 0
            }
            value={discountValue}
            percentage={discountPercentage}
            onUpdate={(value, percentage, type) => setDiscount({ value, percentage, type })}
            type={product.product.customPrice.discount?.type === 'Percent' ? 'percentage' : 'value'}
          />
          <Spacer size="8" />
          <SmallBody style={[meroStyles.text.semibold, { textAlign: 'right', flex: 1 }]}>
            {i18n.t('total')}:{' '}
            <SmallBody>
              {scaledToString(subtractScaled(totalValue, discount.value))}{' '}
              {i18n.t(product.product.customPrice.amount.unit)}
            </SmallBody>
          </SmallBody>
        </>
      )}
      {!disabled && (
        <>
          <Spacer size={32} />
          <Row flex={1} alignItems="center" justifyContent="center">
            {/*<TouchableOpacity onPress={onRemove}>*/}
            {/*  <DeleteIcon />*/}
            {/*</TouchableOpacity>*/}
            {/*<HSpacer left={8} />*/}
            <Button
              size="small"
              text={i18n.t('deleteFromAppointment')}
              color={colors.RADICAL_RED}
              backgroundColor={colors.WHITE}
              onPress={onRemove}
            />
          </Row>
        </>
      )}
      <Spacer size={326} />
    </ScrollView>
  );
};

const withForceReload = (Component: React.FC<Props>) =>
  function WithForceReload(props: Props) {
    const [reload, setReload] = React.useState(false);

    React.useEffect(() => {
      window.setTimeout(() => setReload(false), 100);

      return () => {
        setReload(true);
      };
    }, [props.productId, props.productIndex]);

    return reload ? null : <Component {...props} />;
  };

export default pipe(Product, withForceReload);
