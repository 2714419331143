import * as React from 'react';
import Svg, { SvgProps, G, Circle, Path, Rect, Ellipse } from 'react-native-svg';

export const DeleteIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 8437" transform="translate(-178 -293.031)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" data-name="Ellipse 692" transform="translate(178 293.031)" />
      <G fill="none">
        <Path d="M185 300.03h18v18h-18z" data-name="Rectangle 2852" />
        <G
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          data-name="Orion_bin (1)"
        >
          <Path d="M188.367 304.263h11.267m-7.8 0v-.888a.845.845 0 0 1 .845-.845h2.643a.845.845 0 0 1 .845.845v.888m2.275 0-.628 10.2a1.083 1.083 0 0 1-1.062 1.067h-5.5a1.083 1.083 0 0 1-1.065-1.062l-.628-10.205M194.001 305.997v7.8m-2.167-7.8.433 7.8m3.9-7.8-.433 7.8" />
        </G>
      </G>
    </G>
  </Svg>
);

export const NotesIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 8401" transform="translate(-13859 1492)">
      <Circle cx={12} cy={12} r={12} fill="#f2f2fe" data-name="Ellipse 683" transform="translate(13859 -1492)" />
      <Path
        fill="#080de0"
        d="M13875.594-1480.656h-3.938v-3.938a.656.656 0 1 0-1.312 0v3.938h-3.938a.656.656 0 1 0 0 1.312h3.938v3.937a.656.656 0 1 0 1.312 0v-3.937h3.937a.656.656 0 1 0 0-1.312Z"
      />
    </G>
  </Svg>
);

export const RecurenceIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 8401" transform="translate(-13859 1492)">
      <Circle cx={12} cy={12} r={12} fill="#f2f2fe" data-name="Ellipse 683" transform="translate(13859 -1492)" />
      <G fill="#0820e0">
        <Path d="m13868.132-1481.37-.551.47a3.6 3.6 0 0 1 6.029-1.647l.849-.849a4.8 4.8 0 0 0-8.061 2.277l-.4-.482-1 .6 1.789 2.355 2.123-1.811ZM13877.003-1478.999l-1.681-2.412-2.089 1.878.8.893.508-.457a3.6 3.6 0 0 1-6.025 1.64l-.848.849a4.8 4.8 0 0 0 8.054-2.235l.352.478Z" />
      </G>
    </G>
  </Svg>
);

export const DeleteIconButton = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 7730" transform="translate(-178 -293.031)">
      <Circle cx={16} cy={16} r={16} fill="#fce8ec" data-name="Ellipse 692" transform="translate(178 293.031)" />
      <G fill="none">
        <Path d="M185 300.03h18v18h-18z" data-name="Rectangle 2852" />
        <G
          stroke="#f5365c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          data-name="Orion_bin (1)"
        >
          <Path d="M188.367 304.263h11.267m-7.8 0v-.888a.845.845 0 0 1 .845-.845h2.643a.845.845 0 0 1 .845.845v.888m2.275 0-.628 10.2a1.083 1.083 0 0 1-1.062 1.067h-5.5a1.083 1.083 0 0 1-1.065-1.062l-.628-10.205M194.001 305.997v7.8m-2.167-7.8.433 7.8m3.9-7.8-.433 7.8" />
        </G>
      </G>
    </G>
  </Svg>
);

export const NoShowIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 7735">
      <Path fill="#ffefeb" d="M16 0A16 16 0 1 1 0 16 16 16 0 0 1 16 0Z" data-name="Path 9200" />
      <G
        fill="none"
        stroke="#fb6340"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        transform="translate(17.426 17.426)"
      >
        <Circle cx={6.364} cy={6.364} r={6.364} transform="rotate(-45 -6.935 11.87)" />
        <Path d="M.908-3.76-3.758.907m4.667 0L-3.758-3.76" />
      </G>
    </G>
  </Svg>
);

export const PaymentIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 7736" transform="translate(-241 -255)">
      <Circle cx={16} cy={16} r={16} fill="#f2f2fe" data-name="Ellipse 694" transform="translate(241 255)" />
      <G fill="none" data-name="Group 6841">
        <Path d="M248 262h18v18h-18z" data-name="Rectangle 2" />
        <G transform="translate(248.334 261.44)">
          <Path d="M1 1.56h16.667v16H1z" data-name="Rectangle 2759" />
          <Path
            stroke="#080de0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.2}
            d="m3.348 8.283-.556-1.54a.606.606 0 0 1 .354-.783l10.252-3.712a.606.606 0 0 1 .783.354l2.2 6.06a.606.606 0 0 1-.354.783l-2.651.96M3.298 8.081 14.636 3.94m-2.1 2.475 1.136-.4"
          />
          <Rect
            width={12.12}
            height={7.575}
            stroke="#080de0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.2}
            rx={1}
            transform="translate(1.253 8.283)"
          />
          <Path
            stroke="#080de0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.2}
            d="M3.273 11.818h3.535m3.03-1.515h1.515m-8.08 3.535h.505m2.02 0h.505m2.02 0h.505m2.02 0h.505"
            data-name="layer1"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export const NewAppointmentIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 7736" transform="translate(-241 -255)">
      <Circle cx={16} cy={16} r={16} fill="#f2f2fe" data-name="Ellipse 694" transform="translate(241 255)" />
      <G data-name="Group 6841">
        <Path fill="none" d="M248 262h18v18h-18z" data-name="Rectangle 2" />
        <G fill="#080de0">
          <Path
            stroke="#080de0"
            strokeWidth={0.3}
            d="M256.93 278.95h-6.184a1.384 1.384 0 0 1-1.374-1.39v-11.653a1.384 1.384 0 0 1 1.374-1.39h1.545v-1.043a.52.52 0 0 1 .517-.523.52.52 0 0 1 .516.523v1.043h3.091v-1.043a.52.52 0 0 1 .516-.523.519.519 0 0 1 .514.523v1.043h3.091v-1.043a.52.52 0 0 1 .516-.523.513.513 0 0 1 .514.523v1.043h1.548a1.384 1.384 0 0 1 1.374 1.39v5.544h-1.031v-5.544a.345.345 0 0 0-.343-.347h-1.548v1.043a.519.519 0 0 1-.514.523.52.52 0 0 1-.516-.523v-1.043h-3.091v1.043a.519.519 0 0 1-.514.523.52.52 0 0 1-.516-.523v-1.043h-3.091v1.043a.52.52 0 0 1-.516.523.52.52 0 0 1-.517-.523v-1.043h-1.545a.345.345 0 0 0-.343.347v11.653a.345.345 0 0 0 .343.347h6.184v1.043Z"
            data-name="Subtraction 8"
          />
          <G data-name="Group 8562" transform="translate(251.824 265.289)">
            <Rect width={6} height={1.5} data-name="Rectangle 1508" rx={0.75} transform="translate(6.806 10.011)" />
            <Rect width={1.5} height={6} data-name="Rectangle 1512" rx={0.75} transform="translate(9.056 7.761)" />
          </G>
        </G>
      </G>
    </G>
  </Svg>
);

export const NewAppointmentIconWhite = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 8547">
      <G fill="none" stroke="#fafafa" data-name="Ellipse 694">
        <Circle cx={16} cy={16} r={16} stroke="none" />
        <Circle cx={16} cy={16} r={15.5} />
      </G>
      <G fill="#fafafa">
        <Path
          stroke="#fafafa"
          strokeWidth={0.3}
          d="M16 24H9.816a1.384 1.384 0 0 1-1.374-1.39V10.957a1.384 1.384 0 0 1 1.374-1.39h1.545V8.524a.52.52 0 0 1 .517-.523.52.52 0 0 1 .516.523v1.043h3.091V8.524a.52.52 0 0 1 .516-.523.519.519 0 0 1 .514.523v1.043h3.091V8.524a.52.52 0 0 1 .516-.523.513.513 0 0 1 .514.523v1.043h1.548a1.384 1.384 0 0 1 1.374 1.39v5.544h-1.031v-5.544a.345.345 0 0 0-.343-.347h-1.548v1.043a.519.519 0 0 1-.514.523.52.52 0 0 1-.516-.523V10.61h-3.091v1.043a.519.519 0 0 1-.514.523.52.52 0 0 1-.516-.523V10.61h-3.091v1.043a.52.52 0 0 1-.516.523.52.52 0 0 1-.517-.523V10.61H9.816a.345.345 0 0 0-.343.347V22.61a.345.345 0 0 0 .343.347H16V24Z"
          data-name="Subtraction 8"
        />
        <G data-name="Group 8562" transform="translate(10.894 10.339)">
          <Rect width={6} height={1.5} data-name="Rectangle 1508" rx={0.75} transform="translate(6.806 10.011)" />
          <Rect width={1.5} height={6} data-name="Rectangle 1512" rx={0.75} transform="translate(9.056 7.761)" />
        </G>
      </G>
    </G>
  </Svg>
);

export const GiftCardIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G fill="none" data-name="Group 6736">
      <G stroke="#52577f" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.2}>
        <Path d="M18.128 12.854v7.787H4.778v-7.787M3.666 9.517H19.24v3.337H3.666Z" />
        <Path d="M9.228 9.517v11.125m4.45-11.125v11.125M9.84 4.789a2.058 2.058 0 0 0-1.725-.835 2.225 2.225 0 0 0-1.474 3.895c1.168 1.029 4.812 1.668 4.812 1.668A9.054 9.054 0 0 0 9.84 4.789Zm3.226 0a2.058 2.058 0 0 1 1.722-.835 2.225 2.225 0 0 1 1.474 3.894c-1.166 1.03-4.809 1.669-4.809 1.669a9.054 9.054 0 0 1 1.613-4.728Z" />
      </G>
      <Path d="M0 0h24v24H0z" data-name="Rectangle 2603" />
    </G>
  </Svg>
);

export const CheckoutIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 8364">
      <G fill="none" data-name="Path 9202">
        <Path d="M16 0A16 16 0 1 1 0 16 16 16 0 0 1 16 0Z" />
        <Path
          fill="#fff"
          d="M16 1C11.993 1 8.227 2.56 5.393 5.393A14.902 14.902 0 0 0 1 16c0 4.007 1.56 7.773 4.393 10.607A14.902 14.902 0 0 0 16 31c4.007 0 7.773-1.56 10.607-4.393A14.902 14.902 0 0 0 31 16c0-4.007-1.56-7.773-4.393-10.607A14.902 14.902 0 0 0 16 1m0-1c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0Z"
        />
      </G>
      <G data-name="Group 6972">
        <Path fill="rgba(0,0,0,0)" d="M7 7h18v18H7z" data-name="Rectangle 2671" />
      </G>
      <G data-name="Group 7500">
        <G
          fill="rgba(0,0,0,0)"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.2}
        >
          <Path d="M12.279 16.665h4.257m-4.257 2.129h3.193m3.194-2.129h1.063m-1.065 2.129h1.063M9.623 9.333v13.6l1.591-1.061 1.614 1.064 1.59-1.064 1.6 1.061 1.591-1.061 1.6 1.061 1.591-1.061 1.6 1.061v-13.6m-3.187 13.6h0Zm-3.187 0h0Zm-7.359-13.6h14.67" />
          <Path d="m14.489 13.122 1.09 1.09 1.938-1.937" data-name="layer1" />
        </G>
        <Path fill="none" d="M8 8h16v16H8z" data-name="Rectangle 2701" />
      </G>
    </G>
  </Svg>
);

export const MoreIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 8364" transform="translate(-241 -255)">
      <Circle cx={16} cy={16} r={16} fill="#f2f2fe" data-name="Ellipse 694" transform="translate(241 255)" />
      <G data-name="Group 6841">
        <Path fill="none" d="M248 262h18v18h-18z" data-name="Rectangle 2" />
        <G fill="#080de0" data-name="Group 5789" transform="translate(251.182 269.546)">
          <Ellipse cx={1.455} cy={1.396} opacity={0.9} rx={1.455} ry={1.396} />
          <Ellipse
            cx={1.455}
            cy={1.396}
            data-name="Oval"
            opacity={0.9}
            rx={1.455}
            ry={1.396}
            transform="translate(9.455)"
          />
          <Ellipse
            cx={1.455}
            cy={1.396}
            data-name="Oval"
            opacity={0.9}
            rx={1.455}
            ry={1.396}
            transform="translate(4.727)"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export const CheckoutIconButton = (props: SvgProps) => (
  <Svg width={24} height={24} style={{ marginRight: 8 }} {...props}>
    <G data-name="Group 6972">
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2671" />
    </G>
    <G fill="none" data-name="Group 7500">
      <G stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.3}>
        <Path d="M7.813 12.748h4.789m-4.789 2.395h3.59m3.593-2.4h1.2m-1.2 2.4h1.2M4.826 4.5v15.3l1.79-1.193 1.815 1.2 1.789-1.2 1.8 1.193 1.789-1.193 1.8 1.193 1.79-1.193 1.795 1.193V4.5m-3.585 15.3h0Zm-3.585 0h0ZM3.75 4.5h16.5" />
        <Path d="m10.3 8.762 1.226 1.226 2.18-2.179" data-name="layer1" />
      </G>
      <Path d="M3 3h18v18H3z" data-name="Rectangle 2701" />
    </G>
  </Svg>
);

export const HistoryIcon = () => (
  <Svg width={32} height={32} data-name="Group 5901">
    <Circle cx={16} cy={16} r={16} fill="#e9ecef" />
    <G data-name="Group 6846">
      <Path fill="none" d="M7 7h18v18H7z" data-name="Rectangle 2638" />
    </G>
    <G data-name="Group 6849">
      <G data-name="Group 6848">
        <G data-name="Group 6847">
          <Path
            fill="#52577f"
            d="M22.75 14.59h-5.085l2.055-2.115a5.279 5.279 0 0 0-7.41-.075 5.156 5.156 0 0 0 0 7.343 5.265 5.265 0 0 0 7.41 0 4.885 4.885 0 0 0 1.53-3.668h1.5a7.006 7.006 0 0 1-1.98 4.717 6.79 6.79 0 0 1-9.54 0 6.63 6.63 0 0 1-.015-9.435 6.74 6.74 0 0 1 9.488 0L22.75 9.25ZM16.375 13v3.188L19 17.75l-.54.908-3.21-1.908V13Z"
            data-name="Path 9066"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export const ConfirmPendingIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 8792">
      <Path fill="#e9faf3" d="M16 0A16 16 0 1 1 0 16 16 16 0 0 1 16 0Z" data-name="Path 9200" />
      <G
        fill="none"
        stroke="#2dce89"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        data-name="Circle and Icon"
        transform="translate(14.426 14.427)"
      >
        <Circle cx={6.365} cy={6.365} r={6.365} strokeWidth={1.2} transform="rotate(-45 -1.47 9.891)" />
        <Path strokeWidth={1.5} d="M-.954 1.658.868 3.48 4.109.24" />
      </G>
    </G>
  </Svg>
);

export const ArrowDown = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6963">
      <Path fill="#080de0" d="m6.257 9.407 1.385-1.385 4.489 4.569 4.485-4.569L18 9.407l-5.869 5.954Z" />
      <Path fill="none" d="M24 0v24H0V0z" data-name="Rectangle 2" />
    </G>
  </Svg>
);
