import {
  CalendarEntryDetails,
  DefinedString,
  DefinedTrimedString,
  HasOptionalFirstLastName,
  MembershipPurchaseId,
  UndefinedString,
} from '@mero/api-sdk';
import { CalendarEntry } from '@mero/api-sdk/dist/calendar';
import { ClientPreview } from '@mero/api-sdk/dist/clients';
import { PurchasedMembershipValidity } from '@mero/api-sdk/dist/memberships/purchasedMembershipValidity';
import { ServiceId, WorkerService } from '@mero/api-sdk/dist/services';
import { MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import * as E from 'fp-ts/Either';
import { identity, pipe } from 'fp-ts/function';
import * as t from 'io-ts';

import { AppointmentService } from '../../../contexts/BookingFormContext';

export const OptionalNotes = t.union([DefinedString, UndefinedString], 'OptionalNotes');
export type OptionalNotes = t.TypeOf<typeof OptionalNotes>;

export interface BookedServiceWithWorkerItem {
  readonly type: 'valid' | 'invalid';
  readonly service: AppointmentService;
  readonly worker?: HasOptionalFirstLastName;
  readonly workerServices: WorkerService[];
  readonly startTime?: Date;
}

export type MembershipItem = { _id: ServiceId; quantity: number; type: 'Service' };

export type MembershipServices = {
  _id: MembershipPurchaseId;
  name: string;
  validFor: PurchasedMembershipValidity;
  debt: Money<ScaledNumber, MeroUnits.Any>;
  status: 'Active' | 'Consumed' | 'Cancelled';
  items: MembershipItem[];
};

export const getAppointmentClient = (a: CalendarEntry.Appointment): ClientPreview | undefined => {
  if (a.payload.client && a.payload.user) {
    const { firstname, lastname } =
      a.payload.client.firstname || a.payload.client.lastname ? a.payload.client : a.payload.user?.profile ?? {};

    const isBoost = a.payload.clientBoostStatus?.isBoostClient ?? false;

    return {
      _id: a.payload.client._id,
      userId: a.payload.user._id,
      firstname: firstname,
      lastname: lastname,
      phone: a.payload.user.phone,
      photo: a.payload.user.profile.photo,
      isBlocked: a.payload.client?.isBlocked,
      isWarned: a.payload.client?.isWarned,
      isFavourite: a.payload.client?.isFavourite,
      isBoost: isBoost,
      hideBoostDetails: isBoost && (a.payload.hideBoostClientDetails ?? false),
    };
  } else {
    return undefined;
  }
};

export const getAppointmentNotes = (a: CalendarEntryDetails.Appointment): DefinedTrimedString | undefined =>
  pipe(
    a.payload.note,
    DefinedTrimedString.decode,
    E.fold(() => undefined, identity),
  );

export type ProductPrice = {
  discounted: ScaledNumber;
  total: ScaledNumber;
};
