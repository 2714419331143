import { Body, colors, Column, Icon as MeroIcon, Line, Row, SearchTextInput, sizes, Spacer } from '@mero/components';
import { ListRenderItemInfo } from '@react-native/virtualized-lists/Lists/VirtualizedList';
import * as React from 'react';
import { FlatList, Keyboard, StyleSheet, TouchableOpacity, View } from 'react-native';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
import { FlatListProps } from 'react-native/Libraries/Lists/FlatList';

import { withHoverState } from '../../screens/Authorized/AppointmentScreen/HoverState';

import { useClickOutsideWeb } from '../../hooks/useClickOutsideWeb';

const DownArrow = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6963">
      <Path fill="#080de0" d="m6.257 9.407 1.385-1.385 4.489 4.569 4.485-4.569L18 9.407l-5.869 5.954Z" />
      <Path fill="none" d="M24 0v24H0V0z" data-name="Rectangle 2" />
    </G>
  </Svg>
);
export type Props<T> = {
  Icon?: React.ReactElement;
  zIndex?: number;
  onClose?: () => void;
  onOpen?: () => void;
  defaultState?: boolean;
  onSearch: (query: string) => void;
  data: T[];
  Item: React.FC<ListRenderItemInfo<T>>;
  placeholder?: string;
  disabled?: boolean;
  hasValue?: boolean;
  withHover?: boolean;
} & Omit<FlatListProps<T>, 'data' | 'renderItem'> &
  (
    | {
        Header: React.FC<{ isOpen: boolean }>;
      }
    | {
        Header?: undefined;
        text: string;
      }
  );

export type Ref = {
  onHide: () => void;
  onShow: () => void;
  onToggle: () => void;
  isOpen: boolean;
};

const AdvancedSelect = <T,>(
  {
    Icon,
    onSearch,
    data,
    zIndex = 100,
    defaultState = false,
    onOpen,
    onClose,
    Item,
    disabled = false,
    hasValue = false,
    withHover = true,
    placeholder,
    ...props
  }: Props<T>,
  ref: React.Ref<Ref>,
) => {
  const { Header, ...rest } = props;
  const text = props.Header ? undefined : props.text;

  const dropdownRef = React.useRef<View>(null);

  const [isOpen, setIsOpen] = React.useState(defaultState);

  const dismissKeyboardCallback = React.useCallback(() => {
    Keyboard.dismiss();
  }, [Keyboard]);

  const onToggle = React.useCallback(() => {
    setIsOpen((prev) => {
      if (prev) {
        onClose?.();
      } else {
        onOpen?.();
      }
      return !prev;
    });
  }, []);

  const onHide = React.useCallback(() => {
    setIsOpen(false);
    onClose?.();
  }, []);

  const onShow = React.useCallback(() => {
    setIsOpen(true);
    onOpen?.();
  }, []);

  React.useImperativeHandle(ref, () => ({
    onHide,
    onShow,
    onToggle,
    isOpen,
  }));

  useClickOutsideWeb({
    ref: dropdownRef,
    isVisible: isOpen,
    onClickOutside() {
      onHide();
    },
  });

  const renderItem = (info: ListRenderItemInfo<T>) => {
    if (withHover) {
      const WithHoverState = withHoverState(({ isHovered }) => (
        <Column style={[isHovered && { backgroundColor: colors.SKY_BLUE }]}>
          <Item {...info} />
        </Column>
      ));

      return <WithHoverState isHovered={false} />;
    }

    return <Item {...info} />;
  };

  return (
    <View ref={dropdownRef} style={isOpen && { zIndex }}>
      <TouchableOpacity onPress={onToggle} style={[styles.container, isOpen && styles.openState]} disabled={disabled}>
        {Header ? (
          <Header isOpen={isOpen} />
        ) : (
          <Row style={styles.serviceRow}>
            {Icon}
            <Body style={[styles.title, hasValue && { color: colors.BLACK }]}>{text}</Body>
            {isOpen ? <DownArrow /> : <MeroIcon type="arrow-right" />}
          </Row>
        )}
      </TouchableOpacity>
      <Spacer size={8} />
      <Column style={{ position: 'relative', zIndex: zIndex + 10 }}>
        {isOpen && (
          <Column
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              overflow: 'hidden',
              borderRadius: 12,
              maxHeight: 318,
              shadowColor: '#000000',
              shadowOffset: { width: 1, height: 1 },
              shadowOpacity: 0.16,
              shadowRadius: 16,
              elevation: 16,
              display: isOpen ? 'flex' : 'none',
              zIndex: zIndex + 11,
              backgroundColor: 'white',
            }}
          >
            <Column style={{ paddingHorizontal: 16, paddingTop: 16 }}>
              <SearchTextInput
                autoFocus
                placeholder={placeholder}
                onChange={onSearch}
                onKeyPress={(ev) => {
                  //@ts-expect-error code does exist on web
                  if (ev.code === 'Escape') {
                    onHide();
                  }
                }}
              />
            </Column>
            <Spacer size={8} />
            <FlatList
              data={data}
              keyboardShouldPersistTaps="handled"
              renderItem={renderItem}
              ItemSeparatorComponent={Line}
              onScrollEndDrag={dismissKeyboardCallback}
              {...rest}
            />
          </Column>
        )}
      </Column>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    borderColor: colors.GEYSER,
    borderWidth: 1,
    borderRadius: sizes[6],
    overflow: 'hidden',
  },
  containerError: {
    borderColor: colors.RADICAL_RED,
  },
  serviceRow: {
    flexDirection: 'row',
    paddingTop: sizes[12],
    paddingRight: sizes[12],
    paddingBottom: sizes[12],
    paddingLeft: sizes[16],
    alignItems: 'center',
  },
  title: {
    flex: 1,
    color: colors.COMET,
  },
  errorText: {
    color: colors.RADICAL_RED,
    borderBottomLeftRadius: sizes[6],
    borderBottomRightRadius: sizes[6],
    paddingHorizontal: sizes[12],
    paddingVertical: sizes[4],
    minHeight: 14,
  },
  openState: {
    borderColor: colors.DARK_BLUE,
  },
});

export default React.forwardRef(AdvancedSelect) as <T>(
  props: Props<T> & { ref?: React.Ref<Ref> },
) => ReturnType<typeof AdvancedSelect>;
