import { MembershipPurchaseId, PositiveInt } from '@mero/api-sdk';
import { CreateCheckoutTransactionItem } from '@mero/api-sdk/dist/checkout/checkoutApi';
import { AvailableMembershipItems } from '@mero/api-sdk/dist/memberships/membershipAvailableItems';
import { Body, Button, colors, H1, Spacer, Title, useShowError, useToast } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import { NonEmptyArray } from 'fp-ts/NonEmptyArray';
import { pipe } from 'fp-ts/function';
import { clone, omit, uniqBy } from 'lodash';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

import { LoadingComponent } from '../../../components/LoadingComponent';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import FormCard from '@mero/components/lib/components/FormCard';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CheckoutFormContext, ItemService, UiKey, withUiKey, WithUiKey } from '../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { CheckoutStackParamList, CheckoutSubStackParamList } from '../../../types';
import log from '../../../utils/log';
import { nameGenerator } from '../../../utils/string';
import ClientMembershipsListView from './ClientMembershipsListView';
import { styles } from './EditMembershipScreen.styles';
import { checkoutItemConversion } from './PaymentScreen';

export type AvailableMembershipWithItems = Omit<AvailableMembershipItems<MeroUnits.Any>, 'items'> & {
  items: WithUiKey<AvailableMembershipItems<MeroUnits.Any>['items'][number] & { quantity: PositiveInt }>[];
};

export type SelectedItem = Record<string, MembershipPurchaseId | null>;

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutStackParamList & CheckoutSubStackParamList, 'SelectMembershipPaymentScreen'>;

const SelectMembershipPaymentScreen: React.FC<Props> = ({ navigation, route, page }): React.ReactElement | null => {
  const [formState, { updatePayment, resetMembershipByType }] = CheckoutFormContext.useContext();

  const { t } = useTranslation('checkout');
  const goBack = useGoBack();
  const { isDesktop } = useMediaQueries();
  const toast = useToast();
  const showError = useShowError();
  const { isPhone } = useMediaQueries();

  const [isLoading, setIsLoading] = React.useState(true);

  const [memberships, setMemberships] = React.useState<AvailableMembershipWithItems[]>([]);
  const extractSelectedItems = React.useMemo(() => {
    return formState.draft.payments.reduce((acc, payment) => {
      if (payment.type === 'Membership') {
        return {
          ...acc,
          ...payment.items
            .map((item) => item.uiKey)
            .reduce((acc, key) => ({ ...acc, [key]: payment.membership._id }), {} as SelectedItem),
        };
      }
      return acc;
    }, {} as SelectedItem);
  }, [formState.draft.payments]);
  const [selectedItems, setSelectedItems] = React.useState<SelectedItem>(extractSelectedItems);

  const isEditMode = React.useMemo(() => formState.draft.payments.some((payment) => payment.type === 'Membership'), []);

  const getClientMemberships = async () => {
    if (formState.draft.client.type !== 'existing' || formState.draft.items.length === 0) {
      goBack();
      return;
    }
    try {
      const items = formState.draft.items
        .map(checkoutItemConversion)
        .flatMap((i) => (i.type === 'Service' ? i : i.type === 'Booking' ? i.items : []))
        .filter((i): i is CreateCheckoutTransactionItem.Service<MeroUnits.Any> => i.type === 'Service')
        .map((i) => omit(i, 'saleOwner')) as NonEmptyArray<CreateCheckoutTransactionItem.Service<MeroUnits.Any>>;

      const availableMemberships = await meroApi.memberships.getPurchasedMembershipsAvailableForTransaction({
        page: page.details,
        client: formState.draft.client.client,
        items,
      });

      const serviceItems = formState.draft.items
        .flatMap((i) => (i.type === 'Service' ? i : i.type === 'Booking' ? i.items : []))
        .filter((i): i is WithUiKey<ItemService> => i.type === 'Service');

      const selected = clone(selectedItems);

      // Map the memberships to the checkout items
      const memberships = availableMemberships.reduce((acc, membership) => {
        const items = uniqBy(
          membership.items.flatMap((item) => {
            const services = serviceItems.filter(
              (service) => item.type === 'Service' && item.service._id === service.service._id,
            );
            return services.flatMap((service) => {
              const membershipQuantity =
                item.type === 'Service'
                  ? item.availableQuantity.type === 'Unlimited'
                    ? Infinity
                    : item.availableQuantity.remaining
                  : 0;

              if (membershipQuantity >= service.quantity) {
                selected[service.uiKey] = isEditMode
                  ? selected[service.uiKey]
                  : selected[service.uiKey] ?? membership.membership._id;
                return {
                  ...item,
                  uiKey: service.uiKey,
                  quantity: service.quantity,
                  saleOwner: service.saleOwner,
                };
              }

              return [];
            });
          }),
          /*
           * When a membership has multiple items with the same service, we need to keep only one of them, because
           * we can't track which one was selected by the user, since they don't have a unique differentiator.
           */
          'uiKey',
        );

        return [
          ...acc,
          {
            ...membership,
            items,
          },
        ];
      }, [] as AvailableMembershipWithItems[]);

      setSelectedItems(selected);
      setMemberships(memberships);
    } catch (error) {
      log.error('Error getting client memberships', JSON.stringify(error));
      showError(error);
      goBack();
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSelected = (uiKey: string, membershipId: MembershipPurchaseId) => {
    resetMembershipByType('Membership');
    setSelectedItems((prev) => ({
      ...prev,
      [uiKey]: prev[uiKey] === membershipId ? null : membershipId,
    }));
  };

  React.useEffect(() => {
    getClientMemberships();
  }, []);

  const save = () => {
    const selectedMemberships = memberships
      .map((membership) => ({
        ...membership,
        items: membership.items.filter((item) => selectedItems[item.uiKey] === membership.membership._id),
      }))
      .filter((membership) => membership.items.length > 0);
    // const selectedItems = membership.items.filter(({ isSelected }) => isSelected);
    if (selectedMemberships.length === 0) {
      toast.show({
        type: 'error',
        text: t('selectAtLeastOneService'),
      });
      return;
    }

    updatePayment(selectedMemberships.map((membership) => withUiKey({ type: 'Membership', ...membership })));
    navigation.navigate('PaymentScreen');
  };

  if (formState.draft.client.type !== 'existing') {
    goBack();
    return null;
  }

  return (
    <>
      <ModalScreenContainer style={isDesktop && { borderRadius: 6, overflow: 'hidden' }}>
        {/*{isLoading && <LoadingComponent />}*/}
        <MeroHeader
          canGoBack
          onBack={goBack}
          title={t('selectMembership')}
          containerStyle={{ backgroundColor: colors.ALABASTER }}
        />

        <SafeAreaView
          edges={['bottom']}
          style={{ flex: 1, flexDirection: 'column', backgroundColor: colors.ALABASTER }}
        >
          <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1 }} keyboardShouldPersistTaps="handled">
            <Spacer size={16} />
            <H1 style={{ paddingHorizontal: 24 }}>{t('selectMembership')}</H1>
            <Spacer size={8} />
            <Body style={{ paddingHorizontal: 24 }}>
              <Trans
                ns="checkout"
                t={t}
                i18nKey="selectMembershipDescription"
                values={{
                  name: nameGenerator(formState.draft.client.client.user.profile, ''),
                }}
              >
                0<Title>1</Title>
              </Trans>
            </Body>
            <Spacer size={24} />
            <ClientMembershipsListView
              selectedItems={selectedItems}
              memberships={memberships}
              onSelect={toggleSelected}
            />
            <Spacer size={96} />
          </ScrollView>
        </SafeAreaView>
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            {isPhone ? <Button text={t('continue')} onPress={save} /> : <Button text={t('continue')} onPress={save} />}
          </SafeAreaView>
        </FormCard>
      </ModalScreenContainer>
    </>
  );
};

export default pipe(SelectMembershipPaymentScreen, CurrentBusiness, Authorized);
