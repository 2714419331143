import { ProductId, ProductSearchHit } from '@mero/api-sdk';
import { Body, Column, HSpacer, Row, styles, Line, colors, Icon } from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, TouchableOpacity, View, Text } from 'react-native';
import { Defs, Rect, Svg, Path, G, ClipPath } from 'react-native-svg';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { getMeasure } from '../../../utils/products';
import { scaledToString } from '../../../utils/scaled';

export const convertProductSummaryData = (
  product: ProductSearchHit,
  t: (text: string) => string,
): ProductSummaryCardProps['product'] => {
  const unit = t(product.price.retailPrice.unit);

  const discountedPrice = `${scaledToString(product.price.discountedPrice.amount)} ${t(unit)}`;

  const price = `${scaledToString(product.price.retailPrice.amount)} ${t(unit)}`;

  return {
    _id: product._id,
    name: product.name,
    measure: getMeasure(product.measure),
    barcode: product.barcode?.value,
    price: price,
    discountedPrice: discountedPrice === price ? undefined : discountedPrice,
    imageUrl: product.image?.thumbnail,
    categoryName: product.categoryName,
    stock: ScaledNumber.toNumber(product.stockDescription.quantity),
  };
};

export const DefaultProductImage = (
  <Svg width="48" height="48" viewBox="0 0 48 48">
    <Defs>
      <ClipPath id="clip-path">
        <Rect width="48" height="48" rx="8" transform="translate(2986 1353)" fill="#fff" />
      </ClipPath>
    </Defs>
    <G transform="translate(-2986 -1353)" clipPath="url(#clip-path)">
      <Path
        d="M1.13,0H46.477c.624,0,1.13.853,1.13,1.9V45.7c0,1.052-.506,1.9-1.13,1.9H1.13c-.624,0-1.13-.853-1.13-1.9V1.9C0,.853.506,0,1.13,0Z"
        transform="translate(2986 1353)"
        fill="rgba(233,236,239,0.5)"
      />
      <G transform="translate(2998 1365)">
        <G
          transform="translate(1.001 1.001)"
          fill="none"
          stroke="#cdcfdd"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.3"
        >
          <Path d="M20,5.989,11,2,2,5.989V16.008L11,20l9-3.989Z" />
          <Path d="M2,10.19l9,3.989,9-3.989M6.919,8l9,3.989M11,14.179V24.2" transform="translate(0 -4.2)" />
          <Path d="M45.6,31.6,42,33.19v3.39l3.6-1.59Z" transform="translate(-28.002 -20.721)" />
        </G>
      </G>
    </G>
  </Svg>
);

export type ProductSummaryCardProps = {
  product: {
    _id: ProductId;
    name: string;
    measure: string;
    price: string;
    barcode?: string;
    imageUrl?: string;
    discountedPrice?: string;
    categoryName?: string;
    stock?: number;
  };
  showLine?: boolean;
  showPrice?: boolean;
  showCategoryLabel?: boolean;
  hasPadding?: boolean;
  openProductDetails: (productId: ProductId) => void;
  isStockManagementEnabled?: boolean;
};

const ProductSummaryCard: React.FC<ProductSummaryCardProps> = ({
  product,
  showLine,
  openProductDetails,
  showPrice = true,
  showCategoryLabel = true,
  hasPadding = true,
  isStockManagementEnabled = true,
}) => {
  const { t } = useTranslation('products');

  const { isPhone } = useMediaQueries();
  const fontSize = isPhone ? 16 : 14;

  return (
    <TouchableOpacity onPress={() => openProductDetails(product._id)}>
      <Row style={hasPadding && { paddingVertical: 16 }}>
        {product.imageUrl ? (
          <Image source={{ uri: product.imageUrl }} style={{ width: 47.6, height: 47.6, borderRadius: 4 }} />
        ) : (
          <View style={{ width: 48, height: 48 }}>{DefaultProductImage}</View>
        )}
        <HSpacer left={12} />
        <Column style={{ flex: 1 }}>
          <Body style={[styles.text.semibold, { fontSize }]}>{product.name}</Body>
          <Row>
            {product.stock !== undefined && (
              <Body>
                {isStockManagementEnabled
                  ? t('inStockValue', { value: product.stock.toLocaleString() })
                  : t('unlimitedStock')}
                {showPrice ? ', ' : ''}
              </Body>
            )}
            {showPrice && (
              <>
                <Body
                  style={[
                    { fontSize },
                    !!product.discountedPrice && {
                      textDecorationLine: 'line-through',
                      color: colors.COMET,
                    },
                  ]}
                >
                  {product.price}
                </Body>
                {product.discountedPrice && (
                  <Body style={[{ fontSize, marginLeft: 8 }]}>{product.discountedPrice}</Body>
                )}
              </>
            )}
          </Row>
          <Row>
            <Body style={[styles.text.hint, { fontSize: fontSize - 2 }]}>{`${product.measure}${
              product.barcode ? ` - ${product.barcode}` : ``
            }`}</Body>
          </Row>

          {showCategoryLabel && product.categoryName && (
            <Row>
              <Text
                style={[
                  styles.text.semibold,
                  {
                    borderRadius: 4,
                    paddingHorizontal: 8,
                    paddingVertical: 4,
                    marginTop: 8,
                    color: colors.COMET,
                    fontSize: 12,
                    backgroundColor: colors.ATHENS_GRAY,
                  },
                ]}
              >
                {product.categoryName.toUpperCase()}
              </Text>
            </Row>
          )}
        </Column>
        <Column style={{ paddingLeft: 8 }}>
          <Icon color={colors.DARK_BLUE} type="next" />
        </Column>
      </Row>
      {showLine !== false && <Line />}
    </TouchableOpacity>
  );
};

export default ProductSummaryCard;
