import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import AppointmentScreen from '../screens/Authorized/AppointmentScreen/AppointmentScreen';
import BlockedTimeCreateScreen from '../screens/Authorized/BlockedTimeCreateScreen';
import BlockedTimeEditScreen from '../screens/Authorized/BlockedTimeEditScreen';
import SelectBookingServiceScreen from '../screens/Authorized/SelectBookingServiceScreen';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withBookingFormContextProvider } from '../contexts/BookingFormContext';
import { CalendarEntryContext } from '../contexts/CalendarEntryContext';
import { withSearchClientsContextProvider } from '../contexts/SearchClientsContext';
import { withSelectBookingPerformerContextProvider } from '../contexts/SelectBookingPerformerContext';
import { SelectBookingServiceContext } from '../contexts/SelectBookingServiceContext';
import { BookingStackParamList, SelectBookingServiceStackParamList } from '../types';
import { styles } from './styles';
import { ShortModalFadeTransition } from './utils';

const SelectBookingServiceStack = createStackNavigator<SelectBookingServiceStackParamList>();

const SelectBookingServiceStackNavigator: React.FC = () => {
  return (
    <SelectBookingServiceStack.Navigator
      screenOptions={{
        headerShown: false,
        presentation: 'card',
      }}
    >
      <SelectBookingServiceStack.Screen name="SelectBookingServiceScreen" component={SelectBookingServiceScreen} />
    </SelectBookingServiceStack.Navigator>
  );
};

const BookingStack = createStackNavigator<BookingStackParamList>();

const BookingStackNavigator: React.FC = () => {
  const { isPhone, isDesktop } = useMediaQueries();
  const { t } = useTranslation();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCard
    : styles.tabletModalCard;

  return (
    <CalendarEntryContext.Provider>
      <SelectBookingServiceContext.Provider>
        <BookingStack.Navigator screenOptions={{ headerShown: false, cardOverlayEnabled: true }}>
          <BookingStack.Screen
            name="AppointmentScreen"
            component={AppointmentScreen}
            options={{
              ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalTransition),
              cardStyle: [modalStyle, isDesktop && styles.extraLargeModalCard],
              title: t('booking:appointment'),
            }}
          />
          {/*<BookingStack.Screen*/}
          {/*  name="SelectServiceScreen"*/}
          {/*  component={SelectServiceScreen}*/}
          {/*  options={{*/}
          {/*    ...ShortModalFadeTransition,*/}
          {/*    cardStyle: modalStyle,*/}
          {/*    title: t('booking:selectService'),*/}
          {/*  }}*/}
          {/*/>*/}
          {/*<BookingStack.Screen*/}
          {/*  name="BookingCreateScreen"*/}
          {/*  component={BookingCreateScreen}*/}
          {/*  options={{*/}
          {/*    ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalTransition),*/}
          {/*    cardStyle: modalStyle,*/}
          {/*    title: t('booking:newAppointment'),*/}
          {/*  }}*/}
          {/*/>*/}
          {/*<BookingStack.Screen*/}
          {/*  name="SelectNewBookingClientScreen"*/}
          {/*  component={SelectClientScreen}*/}
          {/*  options={{*/}
          {/*    ...ShortModalFadeTransition,*/}
          {/*    cardStyle: modalStyle,*/}
          {/*    title: t('booking:selectClient'),*/}
          {/*  }}*/}
          {/*/>*/}
          {/*<BookingStack.Screen*/}
          {/*  name="SelectNewBookingServiceScreen"*/}
          {/*  //@ts-expect-error @TODO fix this*/}
          {/*  component={SelectBookingServiceScreen}*/}
          {/*  options={{*/}
          {/*    ...ShortModalFadeTransition,*/}
          {/*    cardStyle: modalStyle,*/}
          {/*    title: t('booking:selectService'),*/}
          {/*  }}*/}
          {/*/>*/}

          {/*<BookingStack.Screen*/}
          {/*  name="ClientFeedbackScreen"*/}
          {/*  component={ClientFeedbackScreen}*/}
          {/*  options={{*/}
          {/*    ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),*/}
          {/*    cardStyle: modalStyle,*/}
          {/*    title: t('booking:describeExperience'),*/}
          {/*  }}*/}
          {/*/>*/}

          {/*<BookingStack.Screen*/}
          {/*  name="BookingDetailsScreen"*/}
          {/*  component={BookingDetailsScreen}*/}
          {/*  options={{*/}
          {/*    ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),*/}
          {/*    cardStyle: modalStyle,*/}
          {/*    title: t('booking:appointmentDetails'),*/}
          {/*  }}*/}
          {/*/>*/}
          {/*<BookingStack.Screen*/}
          {/*  name="BookingEditScreen"*/}
          {/*  component={BookingEditScreen}*/}
          {/*  options={{*/}
          {/*    ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalFadeTransition),*/}
          {/*    cardStyle: modalStyle,*/}
          {/*    title: t('booking:changeAppointment'),*/}
          {/*  }}*/}
          {/*/>*/}
          {/*<BookingStack.Screen*/}
          {/*  name="SelectBookingService"*/}
          {/*  component={SelectBookingServiceStackNavigator}*/}
          {/*  options={{*/}
          {/*    ...ShortModalFadeTransition,*/}
          {/*    cardStyle: modalStyle,*/}
          {/*    title: t('booking:selectService'),*/}
          {/*  }}*/}
          {/*/>*/}

          {/*<BookingStack.Screen*/}
          {/*  name="SelectBookingPerformerScreen"*/}
          {/*  component={SelectBookingPerformerScreen}*/}
          {/*  options={{*/}
          {/*    ...ShortModalFadeTransition,*/}
          {/*    cardStyle: modalStyle,*/}
          {/*    title: t('booking:selectPro'),*/}
          {/*  }}*/}
          {/*/>*/}

          <BookingStack.Screen
            name="BlockedTimeEditScreen"
            component={BlockedTimeEditScreen}
            options={{
              ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalFadeTransition),
              cardStyle: modalStyle,
              title: t('blockedTime:editBlockedTimeTitle'),
            }}
          />

          <BookingStack.Screen
            name="BlockedTimeCreateScreen"
            component={BlockedTimeCreateScreen}
            options={{
              ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalTransition),
              cardStyle: modalStyle,
              title: t('blockedTime:createBlockedTimeTitle'),
            }}
          />
        </BookingStack.Navigator>
      </SelectBookingServiceContext.Provider>
    </CalendarEntryContext.Provider>
  );
};

export default pipe(
  BookingStackNavigator,
  withSearchClientsContextProvider,
  withBookingFormContextProvider,
  withSelectBookingPerformerContextProvider,
);
