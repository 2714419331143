import { RecurrenceRule } from '@mero/api-sdk';
import { SmallBody, formatRecurrenceRule } from '@mero/components';
import * as React from 'react';
import { TextStyle } from 'react-native';

export type Props = {
  readonly recurrenceRule?: RecurrenceRule.Any;
  readonly dot?: boolean;
  readonly style?: TextStyle;
};

/**
 * Formats calendar event recurrence text
 */
const RecurrenceText: React.FC<Props> = ({ recurrenceRule, dot, style }: Props) => {
  return (
    <SmallBody style={style}>
      {formatRecurrenceRule(recurrenceRule)}
      {dot ? '.' : ''}
    </SmallBody>
  );
};

export default RecurrenceText;
