import { UserId } from '@mero/api-sdk/dist/users';
import * as t from 'io-ts';

export type ReadonlyRecursively<T> = T extends Record<string, unknown>
  ? { readonly [K in keyof T]: ReadonlyRecursively<T[K]> }
  : T;

export const DecodedToken = t.type({
  _id: UserId,
});

export type DecodedToken = t.TypeOf<typeof DecodedToken>;

export const isDate = (date: unknown): date is Date => date instanceof Date && !isNaN(date.getTime());

export const DateType = new t.Type<Date, unknown, unknown>(
  'DateType',
  isDate,
  (u, c) => (isDate(u) ? t.success(u) : t.failure(u, c)),
  t.identity,
);

/**
 * A type that receives a string and converts it into an array using split(',')
 */
export const StringArray = new t.Type<unknown[], string, unknown>(
  'StringArray',
  (u): u is unknown[] => Array.isArray(u),
  (u, c) => (typeof u === 'string' ? t.success(u.split(',')) : Array.isArray(u) ? t.success(u) : t.failure(u, c)),
  (a) => a.join(','),
);
