import { Inventory, ProductId, ProductStatus } from '@mero/api-sdk';
import { Column, SmallBody, styles as meroStyles, useShowError } from '@mero/components';
import { flow, pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

import AdvancedSelect, { Ref as AdvanceSelectRef } from '../../../components/AdvancedSelect';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { convertProductToConsumedProduct } from '../../../contexts/BookingFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { SearchProductsContext, withSearchProductsContextProvider } from '../../../contexts/ProductsSearchContext';
import { nullish } from '../../../utils/function';
import ProductSummaryCard, { convertProductSummaryData } from '../CheckoutScreen/ProductSummaryCard';

const Icon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 8471" transform="translate(-234 -465)">
      <Circle cx={12} cy={12} r={12} fill="#e9ecef" transform="translate(234 465)" />
      <G fill="#9da0b6" data-name="Orion_bulleted-list (1)" transform="translate(240.67 470)">
        <Path d="M3.993 2.335h6.667v2H3.993Zm0 7.333h6.667v2H3.993Zm0-3.669h6.667v2H3.993Z" />
        <Circle cx={1.333} cy={1.333} r={1.333} transform="translate(-.004 2)" />
        <Circle cx={1.333} cy={1.333} r={1.333} data-name="layer1" transform="translate(-.004 9.333)" />
        <Circle cx={1.333} cy={1.333} r={1.333} data-name="layer1" transform="translate(-.004 5.667)" />
      </G>
    </G>
  </Svg>
);

const MoreIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 8401" transform="translate(-13859 1492)">
      <Circle cx={12} cy={12} r={12} fill="#f2f2fe" data-name="Ellipse 683" transform="translate(13859 -1492)" />
      <Path
        fill="#080de0"
        d="M13875.594-1480.656h-3.938v-3.938a.656.656 0 1 0-1.312 0v3.938h-3.938a.656.656 0 1 0 0 1.312h3.938v3.937a.656.656 0 1 0 1.312 0v-3.937h3.937a.656.656 0 1 0 0-1.312Z"
      />
    </G>
  </Svg>
);

export type Props = CurrentBusinessProps &
  AuthorizedProps & {
    isMore: boolean;
    disabled?: boolean;
    inventories: Inventory[];
    addProduct: (...args: Parameters<typeof convertProductToConsumedProduct>) => void;
  };

const SelectProducts: React.FC<Props> = ({ page, inventories, addProduct, isMore, disabled = false }) => {
  const advancedSelectRef = React.useRef<AdvanceSelectRef>(null);
  const showError = useShowError();

  const { t } = useTranslation('booking');

  const [searchState, { debounceSearch, loadMore }] = SearchProductsContext.useContext();
  const [showMore, setShowMore] = React.useState(false);

  const onSearch = React.useCallback((query: string) => {
    debounceSearch({ query: { search: query } });
  }, []);

  const onAddProduct = React.useCallback(
    async (productId: ProductId) => {
      try {
        const defaultInventory = inventories.find((inventory) => inventory.isDefault) ?? inventories[0];
        const selectedProduct = searchState.result.products.find((product) => product._id === productId);
        if (!defaultInventory || !selectedProduct) {
          throw new Error(t('productAddError'));
        }

        const productStock = await meroApi.pro.inventories.getProductInventoryStock({
          productId,
          inventoryId: defaultInventory._id,
          pageId: page.details._id,
        });
        addProduct(selectedProduct, defaultInventory._id, productStock);
      } catch (e) {
        showError(e);
      }
    },
    [searchState.result.products, inventories],
  );

  const hideMore = React.useCallback(() => {
    setShowMore(false);
  }, []);

  return (
    <>
      {!showMore && isMore ? (
        <TouchableOpacity
          onPress={() => {
            setShowMore(true);
          }}
          style={{ flexDirection: 'row', alignItems: 'center' }}
          disabled={disabled}
        >
          <MoreIcon />
          <SmallBody style={[meroStyles.text.semibold, meroStyles.text.link, { paddingLeft: 8 }]}>
            {t('addMoreProducts')}
          </SmallBody>
        </TouchableOpacity>
      ) : null}
      {showMore || !isMore ? (
        <AdvancedSelect
          ref={advancedSelectRef}
          Icon={
            <Column style={{ paddingRight: 12 }}>
              <Icon />
            </Column>
          }
          disabled={disabled}
          defaultState={isMore}
          text={t('selectProduct')}
          placeholder={t('searchProductPlaceholder')}
          onSearch={onSearch}
          data={searchState.result.products}
          onClose={flow(hideMore, () => debounceSearch({ query: { search: '' } }))}
          Item={(props) => {
            return (
              <Column style={{ paddingHorizontal: 16 }}>
                <ProductSummaryCard
                  product={convertProductSummaryData(props.item, t)}
                  showLine={false}
                  isStockManagementEnabled={props.item.isStockManagementEnabled}
                  openProductDetails={flow(onAddProduct, hideMore, advancedSelectRef.current?.onHide ?? nullish)}
                />
              </Column>
            );
          }}
          onEndReached={loadMore}
          onEndReachedThreshold={0.9}
          windowSize={11}
          refreshing={searchState.type === 'Loading'}
        />
      ) : null}
    </>
  );
};

export default pipe(
  SelectProducts,
  withSearchProductsContextProvider({ stockValue: 'positive', status: ProductStatus.Active.code }),
  CurrentBusiness,
  Authorized,
);
