import { colors, Column, FormCard, HSpacer, Line, Row, Spacer } from '@mero/components';
import * as React from 'react';

import TextSkeleton from '@mero/components/lib/components/TextSkeleton';

const AppointmentSkeleton: React.FC<React.PropsWithChildren & { isLoading: boolean }> = ({ isLoading, children }) =>
  isLoading ? (
    <>
      <Row flex={1}>
        <Column style={{ flex: 1 }}>
          <Spacer size={16} />
          <HSpacer left={16} right={16}>
            <Column style={{ width: 85 }}>
              <TextSkeleton height="h1" width="fill" />
            </Column>
            <Spacer size={8} />
            <Column style={{ width: 244 }}>
              <TextSkeleton height="h1" width="fill" />
            </Column>
            <Spacer size={16} />
            <Row
              style={{
                borderWidth: 1,
                borderColor: colors.GEYSER,
                borderRadius: 6,
                paddingHorizontal: 14,
                paddingVertical: 16,
              }}
            >
              <Column style={{ height: 32, width: 32, borderRadius: 16, backgroundColor: colors.ATHENS_GRAY }} />
              <HSpacer left={8} />
              <Column>
                <Column style={{ width: 238 }}>
                  <TextSkeleton height="body" width="fill" />
                </Column>
                <Spacer size={4} />
                <Column style={{ width: 92 }}>
                  <TextSkeleton height="body" width="fill" />
                </Column>
              </Column>
            </Row>
          </HSpacer>
          <Spacer size={16} />
          <Spacer size={16} color={colors.ATHENS_GRAY} />
          <Spacer size={16} />
          <HSpacer left={16} right={16}>
            <Column style={{ width: 85 }}>
              <TextSkeleton height="body" width="fill" />
            </Column>
            <Spacer size={16} />
            <Row
              style={{
                borderWidth: 1,
                borderColor: colors.GEYSER,
                borderRadius: 6,
                paddingHorizontal: 14,
                paddingVertical: 16,
              }}
            >
              <Column flex={1}>
                <Column style={{ width: 238 }}>
                  <TextSkeleton height="body" width="fill" />
                </Column>
                <Spacer size={4} />
                <Column style={{ width: 92 }}>
                  <TextSkeleton height="body" width="fill" />
                </Column>
              </Column>
              <Column style={{ width: 47 }}>
                <TextSkeleton height="body" width="fill" />
              </Column>
            </Row>
            <Spacer size={16} />
            <Row
              style={{
                borderWidth: 1,
                borderColor: colors.GEYSER,
                borderRadius: 6,
                paddingHorizontal: 14,
                paddingVertical: 16,
              }}
            >
              <Column flex={1}>
                <Column style={{ width: 238 }}>
                  <TextSkeleton height="body" width="fill" />
                </Column>
                <Spacer size={4} />
                <Column style={{ width: 92 }}>
                  <TextSkeleton height="body" width="fill" />
                </Column>
              </Column>
              <Column style={{ width: 47 }}>
                <TextSkeleton height="body" width="fill" />
              </Column>
            </Row>
            <Spacer size={16} />
            <Column style={{ width: 191 }}>
              <TextSkeleton height="h1" width="fill" />
            </Column>
          </HSpacer>
        </Column>
        <Column style={{ width: 325, borderLeftWidth: 1, borderColor: colors.GEYSER }}>
          <Spacer size={22} />
          <HSpacer left={16} right={16}>
            <Column style={{ width: 92 }}>
              <TextSkeleton height="body" width="fill" />
            </Column>
            <Spacer size={16} />
            <Row>
              <Column flex={1} style={{ backgroundColor: colors.ATHENS_GRAY, height: 43 }} />
              <HSpacer left={8} />
              <Column flex={1} style={{ backgroundColor: colors.ATHENS_GRAY, height: 43, borderRadius: 6 }} />
            </Row>
            <Spacer size={20} />
            <Row>
              <Column style={{ width: 77 }}>
                <TextSkeleton height="body" width="fill" />
              </Column>
              <Column flex={1} />
              <Column style={{ width: 139 }}>
                <TextSkeleton height="body" width="fill" />
              </Column>
            </Row>
            <Spacer size={16} />
            <Row>
              <Column style={{ backgroundColor: colors.ATHENS_GRAY, height: 43, width: 43, borderRadius: 6 }} />
              <HSpacer left={8} />
              <Column flex={1} style={{ backgroundColor: colors.ATHENS_GRAY, height: 43, borderRadius: 6 }} />
              <HSpacer left={8} />
              <Column style={{ backgroundColor: colors.ATHENS_GRAY, height: 43, width: 43, borderRadius: 6 }} />
            </Row>
          </HSpacer>
          <Spacer size={16} />
          <Line />
          <Spacer size={8} />
          <HSpacer left={16}>
            {Array.from({ length: 10 }).map((_, index) => (
              <Row key={index}>
                <Column style={{ width: 32 }}>
                  <TextSkeleton height="body" width="fill" />
                </Column>
                <HSpacer left={4} />
                <Column flex={1}>
                  {index === 2 && (
                    <Column
                      style={{
                        position: 'absolute',
                        top: 26,
                        left: 2,
                        right: 0,
                        bottom: 2,
                        backgroundColor: '#EFEFEF',
                      }}
                    />
                  )}
                  <Column style={{ height: 24, borderColor: '#ADADAD', borderBottomWidth: 1 }} />
                  <Column style={{ height: 24, borderColor: '#E0E0E0', borderBottomWidth: 1 }} />
                  <Column style={{ height: 24, borderColor: '#E0E0E0', borderBottomWidth: 1 }} />
                  <Column style={{ height: 24, borderColor: '#E0E0E0', borderBottomWidth: 1 }} />
                </Column>
              </Row>
            ))}
          </HSpacer>
        </Column>
      </Row>
      <FormCard dropShaddow paddings="button" style={[{ position: 'absolute', bottom: 0, right: 0, left: 0 }]}>
        <Row>
          <Column flex={1}>
            <Column style={{ width: 107 }}>
              <TextSkeleton height="body" width="fill" />
            </Column>
            <Spacer size={4} />
            <TextSkeleton height="body" width="short" />
          </Column>
          <Column alignItems="center" style={{ marginLeft: 24 }}>
            <Column style={{ width: 32, height: 32, borderRadius: 16, backgroundColor: colors.ATHENS_GRAY }} />
            <Spacer size={4} />
            <Column style={{ width: 56 }}>
              <TextSkeleton height="body" width="fill" />
            </Column>
          </Column>
          <Column alignItems="center" style={{ marginLeft: 24 }}>
            <Column style={{ width: 32, height: 32, borderRadius: 16, backgroundColor: colors.ATHENS_GRAY }} />
            <Spacer size={4} />
            <Column style={{ width: 56 }}>
              <TextSkeleton height="body" width="fill" />
            </Column>
          </Column>
          <Column
            style={{ marginLeft: 24, backgroundColor: colors.ATHENS_GRAY, borderRadius: 26, height: 51, width: 174 }}
          />
        </Row>
      </FormCard>
    </>
  ) : (
    children
  );

export default AppointmentSkeleton;
