import { NONE } from '@mero/api-sdk/dist/checkout/checkoutTransactionCompany/checkoutTransactionCompany';
import { computeTransactionTotals } from '@mero/api-sdk/dist/checkout/checkoutTransactionDetails/utils';
import { colors, H1, Icon, Line, Spacer, Title, useToast } from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Path, Rect, Circle } from 'react-native-svg';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import FormCard from '@mero/components/lib/components/FormCard';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { CheckoutFormContext } from '../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { CheckoutStackParamList, CheckoutSubStackParamList } from '../../../types';
import { paymentConversion } from './AddProceedComponent';

export const CardIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G fill="none" data-name="Group 5926">
      <Path d="M0 0h32v32H0z" data-name="Rectangle 59" />
      <G transform="translate(3 3.44)">
        <Path d="M1 1.56h25v24H1z" data-name="Rectangle 2759" />
        <Path
          stroke="#202020"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.3}
          d="m4.523 11.645-.836-2.31a.909.909 0 0 1 .53-1.174L19.598 2.59a.909.909 0 0 1 1.174.53l3.3 9.09a.909.909 0 0 1-.53 1.174l-3.977 1.439M4.447 11.342 21.454 5.13M18.31 8.842l1.7-.606"
        />
        <Rect
          width={18.181}
          height={11.363}
          stroke="#202020"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.3}
          rx={1}
          transform="translate(1.379 11.645)"
        />
        <Path
          stroke="#202020"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.3}
          d="M4.409 16.948h5.3m4.548-2.273h2.272m-12.12 5.3h.758m3.03 0h.758m3.03 0h.758m3.03 0h.758"
          data-name="layer1"
        />
      </G>
    </G>
  </Svg>
);

export const CashIcon = (props: SvgProps) => (
  <Svg width={32} height={31} {...props}>
    <G data-name="Orion_money (1)">
      <Path fill="none" d="M0 0h32v31H0z" data-name="Rectangle 2760" />
      <Path
        fill="rgba(255,255,255,0)"
        stroke="#202020"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.3}
        d="M3.959 22.128V9.04s1.572-1.651 6.289-1.651 5.974 2.358 11.005 2.358a24.919 24.919 0 0 0 6.289-.983v13.364a12.007 12.007 0 0 1-5.5.983c-3.773 0-6.76-2.358-11.005-2.358a17.608 17.608 0 0 0-7.078 1.375Z"
      />
      <Path
        fill="rgba(255,255,255,0)"
        stroke="#202020"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.3}
        d="M19.641 15.092a3.194 3.194 0 0 1-3.184 3.93 4.884 4.884 0 0 1-4.56-3.93 3.194 3.194 0 0 1 3.184-3.93 4.809 4.809 0 0 1 4.56 3.93Z"
      />
      <Path
        fill="rgba(255,255,255,0)"
        stroke="#202020"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.3}
        d="M7.85 7.546a3.93 3.93 0 0 1-3.891 4.559m0 5.5a3.93 3.93 0 0 1 3.891 3.419m19.691-8.136a3.93 3.93 0 0 1-3.891-3.3m.039 13.442a3.86 3.86 0 0 1 3.852-4.638"
        data-name="layer1"
      />
    </G>
  </Svg>
);

export const CardAndCashIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 6877">
      <Path fill="none" d="M0 0h32v32H0z" data-name="Rectangle 59" />
      <G data-name="mark_email_unread-24px (1)">
        <Path fill="none" d="M4.005 2.647h24v24h-24z" data-name="Rectangle 65" />
        <G data-name="Group 7106">
          <G fill="#fff" data-name="Group 7110">
            <G stroke="#000" strokeWidth={1.3} data-name="Rectangle 2690" transform="translate(13.344 8.898)">
              <Rect width={14.66} height={17.511} stroke="none" rx={2} />
              <Rect width={13.36} height={16.211} x={0.65} y={0.65} fill="none" rx={1.35} />
            </G>
            <Path d="m12.724 9.372 9.717-5.354-7.493 26.13-2.224-3.3Z" data-name="Path 9080" />
          </G>
          <G fill="#fff" data-name="Group 7109">
            <G stroke="#000" strokeWidth={1.3} data-name="Rectangle 2690" transform="translate(3.995 6.251)">
              <Rect width={14.253} height={17.511} stroke="none" rx={2} />
              <Rect width={12.953} height={16.211} x={0.65} y={0.65} fill="none" rx={1.35} />
            </G>
            <Path d="m19.33 24.453-7.87.711 5.684-20.36 2.186 1.9Z" data-name="Path 9081" />
          </G>
          <Path d="M12.631 27.902 19.84 3.786l.778.238L13.41 28.14Z" data-name="Path 9082" />
        </G>
      </G>
    </G>
  </Svg>
);

export const CheckIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G fill="none" data-name="Group 5922">
      <Path d="M0 0h32v32H0Z" data-name="Path 8275" />
      <G stroke="#202020" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.3}>
        <Path d="m13.837 10.421 3.557-3.557a1.357 1.357 0 0 1 1.907 0l.477.477m-3.227 3.227 1.943 1.943" />
        <Path
          d="M22.196 8.811a1.393 1.393 0 0 0-1.943-1.947L9.106 17.974l-1.173 3.153 3.153-1.21 11.11-11.106Z"
          data-name="layer2"
        />
        <Path d="M17.943 13.061H27v12.467H5V13.061h9.057m10.01 5.867h-5.83m5.83 2.933h-9.5" />
      </G>
    </G>
  </Svg>
);

export const MembershipIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <Path fill="none" d="M0 0h32v32H0Z" data-name="Path 8275" />
    <G
      fill="none"
      stroke="#202020"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.3}
      transform="translate(2 -3)"
    >
      <Path d="M21.654 10v.393a1.965 1.965 0 1 1-3.931 0V10H9.862v.393a1.965 1.965 0 0 1-3.931 0V10H2v17.3h23.585V10ZM16.151 16.289h6.289m-6.289 3.145h6.289m-6.289 3.144h3.145" />
      <Circle cx={2} cy={2} r={2} data-name="layer1" transform="translate(7 15)" />
      <Path
        d="M9.076 19.434a3.97 3.97 0 0 0-3.93 4.049v.668h7.86v-.668a3.97 3.97 0 0 0-3.93-4.049Z"
        data-name="layer1"
      />
    </G>
  </Svg>
);

export const GiftCardIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G fill="none" data-name="Group 6875">
      <Path d="M0 0h32v32H0z" data-name="Rectangle 59" />
      <G stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.1}>
        <Path d="M24.571 17.157v9.561H7.429v-9.561M6 13.057h20v4.1H6ZM13.093 12.429v14.286m5.815-14.286v14.286M13.893 6.358a2.706 2.706 0 0 0-2.254-1.073 2.906 2.906 0 0 0-2.722 1.847 2.824 2.824 0 0 0 .796 3.153C11.24 11.608 16 12.429 16 12.429a11.5 11.5 0 0 0-2.107-6.071Zm4.216 0a2.706 2.706 0 0 1 2.252-1.073 2.906 2.906 0 0 1 2.722 1.846 2.824 2.824 0 0 1-.796 3.154C20.761 11.608 16 12.429 16 12.429a11.5 11.5 0 0 1 2.108-6.071Z" />
      </G>
    </G>
  </Svg>
);

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutStackParamList & CheckoutSubStackParamList, 'SelectPaymentScreen'>;

export const PaymentMethods = [
  {
    label: 'Card',
    icon: CardIcon,
  } as const,
  {
    label: 'Cash',
    icon: CashIcon,
  } as const,
  {
    label: 'CardAndCash',
    icon: CardAndCashIcon,
  } as const,
  {
    label: 'BankTransfer',
    icon: CheckIcon,
  } as const,
  {
    label: 'Membership',
    icon: MembershipIcon,
  } as const,
  // {
  //   label: 'Giftcard',
  //   icon: GiftCardIcon,
  // },
] as const;

export type PaymentMethod = (typeof PaymentMethods)[number]['label'];

const SelectPaymentScreen: React.FC<Props> = ({ navigation, route, page }): React.ReactElement | null => {
  const [formState, { initiatePayment }] = CheckoutFormContext.useContext();

  const { t } = useTranslation('checkout');
  const goBack = useGoBack();
  const { isDesktop } = useMediaQueries();
  const toast = useToast();

  const totalValues = React.useMemo(() => {
    return computeTransactionTotals(
      {
        unit: 'RON',
        payments: formState.draft.payments.map(paymentConversion),
        items: formState.draft.items,
        company: formState.draft.company
          ? {
              type: 'Company',
              company: formState.draft.company,
              emitReceipt: formState.draft.emitReceipt,
              receipt: formState.draft.receipt,
            }
          : NONE,
        discount: formState.draft.discount,
      },
      2,
    );
  }, [formState]);

  const total = ScaledNumber.toNumber(totalValues.total.amount);

  const selectPayment = (paymentMethod: PaymentMethod) => () => {
    if (paymentMethod === 'Card' || paymentMethod === 'Cash' || paymentMethod === 'BankTransfer') {
      initiatePayment([
        {
          type: paymentMethod,
          total: {
            amount: ScaledNumber.fromNumber(total, 2),
            unit: 'RON',
          },
        },
      ]);
    } else if (paymentMethod === 'CardAndCash') {
      // const cardTotal = Math.ceil(total / 2);
      // const cashTotal = total - cardTotal;
      initiatePayment([
        {
          type: 'Card',
          total: {
            amount: ScaledNumber.fromNumber(0, 2),
            unit: 'RON',
          },
        },
        {
          type: 'Cash',
          total: {
            amount: ScaledNumber.fromNumber(total, 2),
            unit: 'RON',
          },
        },
      ]);
    } else if (paymentMethod === 'Membership') {
      if (formState.draft.client?.type !== 'existing') {
        toast.show({
          type: 'error',
          text: t('membershipPaymentError'),
        });
        return goBack();
      } else {
        return navigation.navigate('SelectMembershipPaymentScreen');
      }
    } else if (paymentMethod === 'Giftcard') {
      initiatePayment([
        {
          type: 'Giftcard',
          code: '',
          total: {
            amount: ScaledNumber.fromNumber(total, 2),
            unit: 'RON',
          },
        },
      ]);
    }

    navigation.navigate('PaymentScreen');
  };

  const itemTypes = React.useMemo(
    () => new Set(formState.draft.items.map((item) => item.type)),
    [formState.draft.items],
  );

  const paymentMethods = React.useMemo(
    () =>
      PaymentMethods.filter((payment) => {
        if (payment.label !== 'Membership') {
          return true;
        } else if (itemTypes.has('Service') /*|| itemTypes.has('Product')*/ || itemTypes.has('Booking')) {
          return true;
        }

        return false;
      }),
    [itemTypes, PaymentMethods],
  );

  return (
    <>
      <ModalScreenContainer style={isDesktop && { borderRadius: 6, overflow: 'hidden' }}>
        <MeroHeader
          canGoBack
          onBack={goBack}
          title={t('selectPayment')}
          containerStyle={{ backgroundColor: colors.ALABASTER }}
        />
        <SafeAreaView
          edges={['bottom']}
          style={{ flex: 1, flexDirection: 'column', backgroundColor: colors.ALABASTER }}
        >
          <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1 }} keyboardShouldPersistTaps="handled">
            <Spacer size={16} />
            <H1 style={{ paddingHorizontal: 24 }}>{t('selectPayment')}</H1>
            <Spacer size={24} />
            <FormCard paddings="none" dropShaddow rounded style={{ marginHorizontal: 16, paddingVertical: 16 }}>
              {paymentMethods.map(({ label, icon: IconPayment }, index) => (
                <React.Fragment key={label}>
                  <TouchableOpacity
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingHorizontal: 16,
                    }}
                    onPress={selectPayment(label)}
                  >
                    <IconPayment />
                    <Title style={{ flex: 1, paddingLeft: 16 }}>{t(label)}</Title>
                    <Icon type="next" color={colors.DARK_BLUE} />
                  </TouchableOpacity>
                  {index < paymentMethods.length - 1 && (
                    <>
                      <Spacer size={16} />
                      <Line />
                      <Spacer size={16} />
                    </>
                  )}
                </React.Fragment>
              ))}
            </FormCard>
          </ScrollView>
        </SafeAreaView>
      </ModalScreenContainer>
    </>
  );
};

export default pipe(SelectPaymentScreen, CurrentBusiness, Authorized);
