import {
  apiError,
  AppointmentId,
  CalendarEntryDetails,
  ClientId,
  Lastname,
  PositiveInt,
  ProductId,
  RecurrenceRule,
  WorkerId,
} from '@mero/api-sdk';
import { CalendarEntry } from '@mero/api-sdk/dist/calendar';
import { AppointmentHistoryRecord } from '@mero/api-sdk/dist/calendar/appointment-history-record';
import { Type } from '@mero/api-sdk/dist/calendar/calendarEntry/type';
import { WaitingListId } from '@mero/api-sdk/dist/calendar/waiting-list-id';
import { CreateCheckoutTransactionItem } from '@mero/api-sdk/dist/checkout';
import { CheckoutTransactionPreview } from '@mero/api-sdk/dist/checkout/checkoutTransactionPreview';
import { AnyDraft } from '@mero/api-sdk/dist/checkout/checkoutTransactionPreview/checkoutTransactionPreview';
import { ClientPreview } from '@mero/api-sdk/dist/clients';
import { MembershipItemConsumptionLock } from '@mero/api-sdk/dist/memberships/membershipConsumptionLock';
import { MembershipItemConsumptionLockDetails } from '@mero/api-sdk/dist/memberships/membershipConsumptionLockDetails';
import { PageId } from '@mero/api-sdk/dist/pages';
import { SavedService, ServiceId } from '@mero/api-sdk/dist/services';
import { Body, ConfirmBox, H1, ModalOverlay, Spacer, useShowError, useToast } from '@mero/components';
import { Firstname, MeroUnits, ScaledNumber, StrictPhoneNumber } from '@mero/shared-sdk';
import { PhoneNumber } from '@mero/shared-sdk/dist/common';
import * as A from 'fp-ts/Array';
import * as E from 'fp-ts/Either';
import * as Id from 'fp-ts/Identity';
import { NonEmptyArray } from 'fp-ts/NonEmptyArray';
import * as O from 'fp-ts/Option';
import { flow, identity, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { DateFromISOString, NonEmptyString, NumberFromString } from 'io-ts-types';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import RecurrenceRuleEditScreen from '../../../components/RecurrenceRuleEditScreen';
import RecurrenceRuleOptionsScreen from '../../../components/RecurrenceRuleOptionsScreen';
import MeroHeader from '@mero/components/lib/components/MeroHeader';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';
import { useSafeInput } from '../../../hooks/useSafeInput';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { addDefaultEventDuration } from '../../../contexts/BlockedTimeFormContext';
import {
  AppointmentService,
  BookingFormContext,
  ConsumedProduct,
  convertProductToConsumedProduct,
  convertServiceToBookedService,
  readConsumedProductToConsumedProduct,
} from '../../../contexts/BookingFormContext';
import { CheckoutsContext } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { SelectBookingPerformerContext } from '../../../contexts/SelectBookingPerformerContext';
import { SelectBookingServiceContext } from '../../../contexts/SelectBookingServiceContext';
import { AuthorizedStackParamList, BookingStackParamList } from '../../../types';
import log, { logCatch } from '../../../utils/log';
import { paramsDecode } from '../../../utils/params';
import { multiplyScaled } from '../../../utils/scaled';
import { sequence } from '../../../utils/sequence';
import {
  computeServicePrice,
  computeServicesTotal,
  getBookingTotalPrice,
  hasServicePriceChanged,
} from '../../../utils/servicePrice';
import { DateType, StringArray } from '../../../utils/types';
import ConfirmDeleteBooking from '../BookingDetailsScreen/ConfirmDeleteBooking';
import ConfirmDeleteRecurrentBooking from '../BookingDetailsScreen/ConfirmDeleteRecurrentBooking';
import ConfirmUpdateRecurrentBooking from '../BookingEditScreen/ConfirmUpdateRecurrentBooking';
import AppointmentScreenDesktop from './AppointmentScreenDesktop';
import ConfirmCloseBooking from './ConfirmCloseBooking';
import {
  BookedServiceWithWorkerItem,
  getAppointmentClient,
  getAppointmentNotes,
  MembershipItem,
  MembershipServices,
  OptionalNotes,
  ProductPrice,
} from './common';

const MARK_NO_SHOW_LIMIT = 2 * 24 * 60 * 60 * 1000; // 2 days
export const TimeSelectStep = 5;

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<BookingStackParamList & AuthorizedStackParamList, 'AppointmentScreen'>;

const AppointmentScreen: React.FC<Props> = ({ navigation, route, page }) => {
  const i18n = useTranslation('booking');

  const goBack = useGoBack();
  const isFocused = useIsFocused();

  const toast = useToast();
  const showError = useShowError();

  const { isPhone } = useMediaQueries();

  const {
    appointmentId,
    occurrenceIndex,
    workerId,
    date,
    clientId,
    serviceIds,
    productIds,
    clientFullName,
    clientPhone,
    waitingListId,
  } = paramsDecode(route.params, {
    appointmentId: AppointmentId,
    occurrenceIndex: t.union([NumberFromString, t.number]),
    workerId: WorkerId.JSON,
    date: t.union([DateType, DateFromISOString]),
    clientId: ClientId,
    serviceIds: sequence(StringArray, t.array(ServiceId)),
    productIds: sequence(StringArray, t.array(ProductId)),
    clientFullName: t.string,
    clientPhone: PhoneNumber,
    waitingListId: WaitingListId,
  });

  const [
    formState,
    {
      reset: resetBookingForm,
      setStart,
      setPerformer,
      setRecurrenceRule,
      setNotes,
      setClient,
      addService,
      deleteServiceAt,
      addMembership,
      updateProductAt,
      deleteProductAt,
    },
  ] = BookingFormContext.useContext();
  const { start, end, recurrenceRule, notes, client, services, products, performer, memberships } = formState;

  const [showErrors, setShowErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);

  const [editableStatus, setEditableStatus] = React.useState<CalendarEntryDetails.AppointmentEditableStatus.Any>();
  const [isDirty, setIsDirty] = React.useState(!appointmentId);
  const [existingAppointment, setExistingAppointment] = React.useState<CalendarEntryDetails.Appointment | undefined>();
  const [history, setHistory] = React.useState<AppointmentHistoryRecord[]>([]);

  const setDirty = () => {
    if (!existingAppointment) {
      setIsDirty(true);
      return;
    }

    if (!canEdit.services) {
      log.debug('Services or products cannot be edited');
      return setIsDirty(false);
    }

    //Check if services has changed
    if (
      existingAppointment.payload.bookedServices.length !== services.length ||
      existingAppointment.payload.bookedServices.some(
        (s, index) =>
          // Check if service price has changed
          hasServicePriceChanged(s.customPrice, services[index]?.service.customPrice) ||
          // Check if quantity has changed
          s.quantity !== services[index]?.service.quantity,
      )
    ) {
      log.debug('Services have changed');
      return setIsDirty(true);
    }

    //Check if products has changed
    if (
      existingAppointment.payload.consumedProducts?.length !== products.length ||
      existingAppointment.payload.consumedProducts.some(
        (p, index) =>
          // Check if product price has changed
          !ScaledNumber.equals(
            p.customPrice?.amount.amount ?? ScaledNumber.zero(),
            products[index]?.product.customPrice?.amount.amount ?? ScaledNumber.zero(),
          ) ||
          //Check if discount has changed
          p.customPrice?.discount?.type !== products[index]?.product.customPrice?.discount?.type ||
          (p.customPrice?.discount?.type === 'Percent' &&
            products[index]?.product.customPrice?.discount?.type === 'Percent' &&
            p.customPrice.discount.percent.value !== products[index]?.product.customPrice.discount.percent.value) ||
          (p.customPrice?.discount?.type === 'Value' &&
            products[index]?.product.customPrice?.discount?.type === 'Value' &&
            p.customPrice.discount.value.amount.value !==
              products[index]?.product.customPrice.discount.value.amount.value) ||
          // Check if quantity has changed
          ScaledNumber.toNumber(p.quantity) !== ScaledNumber.toNumber(products[index]?.product.quantity),
      )
    ) {
      log.debug('Products have changed');
      return setIsDirty(true);
    }

    // Check if client has changed
    if (client.type !== 'existing' || existingAppointment.payload.client?._id !== client.client._id) {
      log.debug('Client has changed');
      return setIsDirty(true);
    }

    // Check if performer has changed
    if (existingAppointment.payload.worker._id !== performer?._id) {
      log.debug('Performer has changed');
      return setIsDirty(true);
    }

    // Check if notes has changed
    if (existingAppointment.payload.note !== notes) {
      log.debug('Notes have changed');
      return setIsDirty(true);
    }

    // Check if start date has changed
    if (existingAppointment.start.getTime() !== start?.getTime()) {
      log.debug('Start date has changed');
      return setIsDirty(true);
    }

    // Check if end date has changed
    if (existingAppointment.end.getTime() !== end?.getTime()) {
      log.debug('End date has changed');
      return setIsDirty(true);
    }

    // Check if recurrence rule has changed
    if (
      existingAppointment.recurrent !== Boolean(recurrenceRule) ||
      (existingAppointment.recurrent &&
        (existingAppointment.recurrenceRule.endsOn.date?.getTime() !== recurrenceRule?.endsOn.date?.getTime() ||
          existingAppointment.recurrenceRule.endsOn.times !== recurrenceRule?.endsOn.times ||
          existingAppointment.recurrenceRule.repeatEvery.value !== recurrenceRule?.repeatEvery.value ||
          existingAppointment.recurrenceRule.repeatEvery.unit !== recurrenceRule?.repeatEvery.unit ||
          existingAppointment.recurrenceRule.repeatEvery.unit !== recurrenceRule?.repeatEvery.unit))
    ) {
      log.debug('Recurrence rule has changed');
      return setIsDirty(true);
    }

    log.debug('Nothing has changed');
    setIsDirty(false);
  };

  React.useEffect(() => {
    if (existingAppointment) {
      if (
        existingAppointment.payload.bookedServices.length !== services.length ||
        existingAppointment.payload.consumedProducts?.length !== products.length ||
        performer?._id !== existingAppointment.payload.worker._id
      ) {
        setDirty();
      }
    }
  }, [services.length, products.length, performer?._id]);

  const initialRender = React.useRef(true);

  /**
   * Time handling
   */
  const timeZone = React.useMemo(() => IANAZone.create('Europe/Bucharest'), []);
  const now = React.useMemo(() => new Date(), []);

  const dateParam = React.useMemo(
    () =>
      pipe(
        date,
        DateType.decode,
        E.fold(() => new Date(), identity),
        Id.map((a) => {
          // round to closes future N minutes
          const timePeriod = 1000 * 60 * 5;
          return new Date(Math.round(a.getTime() / timePeriod) * timePeriod);
        }),
      ),
    [date],
  );

  const startDate = React.useMemo(() => start ?? dateParam, [start, dateParam]);
  const hasPassed = React.useMemo(() => startDate < now, [startDate, now]);

  React.useEffect(() => {
    if (!start) {
      setStart(dateParam);
    }
  }, [start, dateParam]);

  // Round start time to minutes
  const startInMinutes = React.useMemo(
    () => new Date(Math.floor(startDate.getTime() / (TimeSelectStep * 60 * 1000)) * (TimeSelectStep * 60 * 1000)),
    [startDate.getTime(), TimeSelectStep],
  );

  const startDateTime = React.useMemo(() => DateTime.fromJSDate(start ?? now, { zone: timeZone }), [start, timeZone]);
  const endDateTime = React.useMemo(
    () => DateTime.fromJSDate(end ?? addDefaultEventDuration(startDateTime).toJSDate(), { zone: timeZone }),
    [end, timeZone],
  );
  const endDateEnd = React.useMemo(() => startDateTime.plus({ hours: 12 }), [startDateTime]);
  const differenceInDays = React.useMemo(() => endDateTime.diff(startDateTime, 'days').days, [start, end]);
  const durationInMinutes = React.useMemo(
    () => (formState.services.length > 0 ? endDateTime.diff(startDateTime, 'minutes').minutes : 0),
    [start, end, formState.services],
  );

  /**
   * Checkout handling
   */
  const [checkouts, setCheckouts] = React.useState<CheckoutTransactionPreview.Any[]>([]);
  const [checkoutState] = CheckoutsContext.useContext();
  const getCheckoutInfo = React.useCallback(
    async (payload: { pageId: PageId; appointmentId: AppointmentId; occurrenceIndex: number | undefined }) => {
      try {
        const checkout = await meroApi.checkout.listAppointmentTransactions({
          ...payload,
        });

        setCheckouts(checkout.data.filter((c) => c.status !== 'Deleted'));
      } catch (e) {
        log.error('Failed to fetch checkout info', e);
      }
    },
    [],
  );
  const hasFinishedCheckout = React.useMemo(() => checkouts.some((c) => c.status === 'Finished'), [checkouts]);
  const hasDraftCheckout = React.useMemo(() => checkouts.some((c) => c.status === 'Draft'), [checkouts]);
  const checkoutEnabled = checkoutState.type === 'Loaded' && checkoutState.pageSettings.checkoutEnabled;
  const isRestrictedCheckoutEnabled =
    checkoutState.type === 'LoadedRestricted' && checkoutState.restrictedPageSettings.checkoutEnabled;
  const latestDraft = React.useMemo(() => {
    const draftsSorted = checkouts
      .filter((c): c is AnyDraft => c.status === 'Draft')
      .sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });

    return draftsSorted[0];
  }, [checkouts]);
  const finishedCheckout = React.useMemo(
    () => checkouts.find((c) => c.status === 'Finished'),
    [checkouts, hasFinishedCheckout],
  );

  const showCheckoutButton = checkoutEnabled && !hasFinishedCheckout;

  React.useEffect(() => {
    if (isFocused && appointmentId && (checkoutEnabled || isRestrictedCheckoutEnabled)) {
      getCheckoutInfo({
        pageId: page.details._id,
        appointmentId,
        occurrenceIndex,
      });
    }
  }, [isFocused, appointmentId, checkoutEnabled, isRestrictedCheckoutEnabled]);

  const goToCheckoutDetails = () => {
    if (!finishedCheckout || !existingAppointment) {
      return;
    }
    navigation.navigate('CombineCheckout', {
      screen: 'CheckoutStack',
      params: {
        screen: 'ProceedDetailsScreen',
        params: {
          checkoutTransactionId: finishedCheckout._id,
          appointmentId: existingAppointment._id,
          occurrenceIndex: existingAppointment.occurrenceIndex,
          backMode: 'one',
        },
      },
    });
  };

  /**
   * Services handling
   */
  const [selectServiceState, { reset: resetServiceSelect, setServicesFilter }] =
    SelectBookingServiceContext.useContext();
  const [usableMemberships, setUsableMemberships] = React.useState<MembershipServices[]>([]);

  const [selectedService, setSelectedService] = React.useState<{
    service: AppointmentService;
    index: number;
  }>();

  const canAddMoreServices = true; //servicesLimit === undefined || (nonEmptyServices?.length ?? 0) < servicesLimit;

  type ServicesAcc = {
    start: Date;
    nonEmptyServices: BookedServiceWithWorkerItem[];
  };

  const servicesWithWorker = React.useMemo((): BookedServiceWithWorkerItem[] => {
    return pipe(
      services,
      A.map(
        (s): BookedServiceWithWorkerItem => ({
          type: s.type,
          service: s.service,
          worker: performer?.user,
          workerServices: performer?.services ?? [],
        }),
      ),
    );
  }, [services, performer]);

  const nonEmptyServices = servicesWithWorker.reduce(
    ({ start, nonEmptyServices }, row) => ({
      start: new Date(
        start.getTime() +
          (t.number.is(row.service.durationInMinutes)
            ? row.service.durationInMinutes
            : row.service.durationInMinutes.from) *
            60000,
      ),
      nonEmptyServices: nonEmptyServices.concat([{ ...row, startTime: start }]),
    }),
    { start: startDate, nonEmptyServices: [] } as ServicesAcc,
  )?.nonEmptyServices;
  const servicesIsValid = nonEmptyServices.length > 0;

  const addServiceCallback = () => {
    navigation.push('SelectServiceScreen', { workerId: performer?._id });
  };

  const canEdit = React.useMemo((): CalendarEntryDetails.AppointmentEditableStatus.Editable['fields'] => {
    if (appointmentId) {
      const status = editableStatus ?? CalendarEntryDetails.AppointmentEditableStatus.NON_EDITABLE;
      if (status.type === 'Editable' && status.validUntil > now && !(hasFinishedCheckout && finishedCheckout)) {
        return status.fields;
      } else {
        return {
          start: false,
          end: false,
          client: false,
          worker: false,
          notes: false,
          services: false,
          recurrenceRule: false,
        };
      }
    } else {
      return {
        start: true,
        end: true,
        client: true,
        worker: true,
        notes: true,
        services: true,
        recurrenceRule: true,
      };
    }
  }, [now, editableStatus, hasFinishedCheckout, finishedCheckout]);

  const removeServiceCallback = React.useCallback(
    ({ index }: { service: unknown; index: number }) => {
      deleteServiceAt(index);
    },
    [deleteServiceAt],
  );

  // Handle service selection result
  React.useEffect(() => {
    if (selectServiceState.type === 'some') {
      resetServiceSelect();
      if (selectServiceState.service.availableMemberships?.length) {
        addService(
          convertServiceToBookedService({
            ...selectServiceState.service,
            membershipIndex: formState.memberships.length,
            customPrice: undefined,
            quantity: undefined,
          }),
        );

        const selectedMembership = selectServiceState.service.availableMemberships[0];

        const lockMembership: MembershipItemConsumptionLock = {
          membershipPurchaseId: selectedMembership.membership._id,
          quantity: 1 as PositiveInt,
          item: {
            type: 'Service',
            service: {
              _id: selectServiceState.service._id,
            },
            bookingServiceIndex: formState.services.length as PositiveInt,
          },
        };

        addMembership(lockMembership);
      } else {
        addService(
          convertServiceToBookedService({ ...selectServiceState.service, customPrice: undefined, quantity: undefined }),
        );
      }
    }
  }, [selectServiceState, addService, resetServiceSelect, formState.memberships, formState.services]);

  const getMemberships = React.useCallback(
    async (pageId: PageId, clientId: ClientId, services: AppointmentService[]) => {
      try {
        const checkoutItems = services.map((service) => ({
          type: 'Service',
          service: {
            _id: service._id,
          },
          quantity: service.quantity,
        })) as NonEmptyArray<CreateCheckoutTransactionItem.Service<MeroUnits.Any>>;

        const memberships = await meroApi.memberships
          .getPurchasedMembershipsAvailableForTransaction({
            page: {
              _id: pageId,
            },
            client: {
              _id: clientId,
            },
            items: checkoutItems,
            ...(appointmentId ? { appointment: { _id: appointmentId, occurrenceIndex: occurrenceIndex ?? 0 } } : {}),
          })
          .catch(logCatch('getPurchasedMembershipsAvailableForTransaction'));
        setUsableMemberships(
          memberships.map((m) => ({
            _id: m.membership._id,
            name: m.membership.name,
            validFor: m.membership.validFor,
            debt: m.membership.debt,
            status: m.membership.status,
            items: m.items
              .map((i) =>
                i.type === 'Service'
                  ? {
                      _id: i.service._id,
                      quantity: i.availableQuantity.type === 'Unlimited' ? Infinity : i.availableQuantity.remaining,
                      type: 'Service',
                    }
                  : undefined,
              )
              .filter((i): i is MembershipItem => i !== undefined),
          })),
        );
      } catch (e) {
        log.error('Failed to fetch memberships', e);
      }
    },
    [],
  );

  React.useEffect(() => {
    if (client?.type === 'existing' && nonEmptyServices && nonEmptyServices.length > 0) {
      getMemberships(
        page.details._id,
        client.client._id,
        nonEmptyServices.flatMap((r) => r.service),
      );
    } else if (usableMemberships.length > 0) {
      setUsableMemberships([]);
    }
  }, [page.details._id, client?.type === 'existing' && client.client._id, nonEmptyServices?.length]);

  /**
   * Products handling
   */
  const [productsState] = SearchProductsContext.useContext();

  const [selectedProduct, setSelectedProduct] = React.useState<{ product: ConsumedProduct; index: number }>();

  const updateProductCallback = React.useCallback(({ product, index }: { product: ConsumedProduct; index: number }) => {
    updateProductAt({
      index,
      product,
    });
  }, []);

  const removeProductCallback = React.useCallback(
    ({ index }: { product: unknown; index: number }) => {
      deleteProductAt(index);
    },
    [deleteProductAt],
  );

  const showProducts = React.useMemo(
    () =>
      productsState.type === 'Loaded' &&
      productsState.counters.all > 0 &&
      (canEdit.services || (existingAppointment?.payload.consumedProducts?.length ?? 0) > 0),
    [productsState.counters, canEdit.services, existingAppointment?.payload.consumedProducts],
  );

  /**
   * Client handling
   */
  const onClientRemove = React.useCallback(() => {
    setClient({ type: 'none' });
  }, []);

  /**
   * Performer handling
   */
  const [selectPerformerState, { reset: resetPerformerSelect, setSelectedPerformer }] =
    SelectBookingPerformerContext.useContext();

  // Handle performer selection result
  React.useEffect(() => {
    if (selectPerformerState.type === 'selected') {
      // do not reset the context bc it is also used by select services screen
      // resetPerformerSelect();
      setPerformer(selectPerformerState.selected);
    }
  }, [selectPerformerState, resetPerformerSelect, setPerformer]);

  // Set performer from params
  React.useEffect(() => {
    if (workerId) {
      const worker = page.workers.find((w) => w._id === workerId);
      if (worker) {
        setSelectedPerformer(worker);
      }
    }
  }, [workerId]);

  /**
   * Notes handling
   */
  const [notesValue, setNotesValue] = useSafeInput(OptionalNotes)(notes);
  const notesArePresent = Boolean(notesValue.value);
  // User requested to add notes
  const [showNotesClicked, setShowNotesClicked] = React.useState<boolean>(false);
  const notesInputVisible = notesArePresent || showNotesClicked;

  React.useEffect(() => {
    if (notesValue.isValid) {
      setNotes(notesValue.value);
    }
  }, [notesValue]);

  /**
   * Recurrence rule handling
   */
  const recurrenceRuleIsPresent = recurrenceRule !== undefined;
  // Recurrence rule options select modal
  const [showRecurrenceOptions, setShowRecurrenceOptions] = React.useState(false);
  // Custom recurrence rule edit modal
  const [showRecurrenceEditForm, setShowRecurrenceEditForm] = React.useState(false);

  const [recurrentUpdateOption, setRecurrentUpdateOption] = React.useState<'undetermined' | 'once' | 'all'>(
    'undetermined',
  );

  const editRecurrenceRuleCallback = React.useCallback(() => {
    if (RecurrenceRule.Predefined.is(recurrenceRule)) {
      setShowRecurrenceOptions(true);
    } else {
      setShowRecurrenceEditForm(true);
      recurrenceRuleIsPresent;
    }
  }, [recurrenceRule, setShowRecurrenceOptions, setShowRecurrenceEditForm]);

  const recurrenceOptionSelectedCallback = React.useCallback(
    (newRule: RecurrenceRule.Any | undefined | 'custom') => {
      setShowRecurrenceOptions(false);
      if (newRule === 'custom') {
        setShowRecurrenceEditForm(true);
      } else {
        setRecurrenceRule(newRule);
      }
    },
    [setShowRecurrenceEditForm],
  );

  const hasNotesOrRecurrence = notesArePresent || recurrenceRuleIsPresent || canEdit.notes || canEdit.recurrenceRule;

  /**
   * Override handling
   */
  const [showConfirmOverride, setShowConfirmOverride] = React.useState(false);

  const cancelConfirmOverrideCallback = () => {
    setShowConfirmOverride(false);
    setRecurrentUpdateOption('undetermined');
  };

  const confirmBookingOverrideCallback = () => {
    setShowConfirmOverride(false);
    save({ override: true, onlyOnce: recurrentUpdateOption !== 'all' });
  };

  /**
   * Price calculation
   */
  const [servicesPrice, productsPrice] = React.useMemo(() => {
    const productsPrice: ProductPrice[] = products.map(({ product }) => {
      const total = product.customPrice.amount.amount;
      const discount = product.customPrice.discount
        ? product.customPrice.discount.type === 'Percent'
          ? multiplyScaled(total, ScaledNumber.toNumber(product.customPrice.discount.percent) / 100)
          : product.customPrice.discount.value.amount
        : ScaledNumber.zero();
      const discounted = ScaledNumber.sub(total, discount);
      return { discounted, total };
    });

    const servicesPrice = services.map(({ service }) => {
      const quantity = service.quantity;
      const membershipQuantity =
        service.membershipIndex !== undefined ? formState.memberships[service.membershipIndex]?.quantity ?? 0 : 0;

      return computeServicePrice(quantity, membershipQuantity, service.customPrice);
    });
    return [servicesPrice, productsPrice];
  }, [products, services, formState.memberships]);
  const { total, currency } = React.useMemo(() => {
    const totalProducts = productsPrice.reduce(
      (acc, price) => ScaledNumber.add(acc, price.discounted),
      ScaledNumber.zero(),
    );

    const totalServices = computeServicesTotal(servicesPrice);
    const totalGiftCardsNumber =
      existingAppointment?.payload.coupons?.reduce(
        (acc, g) => ScaledNumber.add(acc, g.value.amount),
        ScaledNumber.zero(),
      ) ?? ScaledNumber.zero();

    return {
      total: getBookingTotalPrice(totalServices, totalProducts, ScaledNumber.toNumber(totalGiftCardsNumber)),
      currency: products[0]?.product.customPrice.amount.unit ?? 'RON',
    };
  }, [formState.products, formState.services, formState.memberships, existingAppointment]);

  const resetFormValues = async (appointment: CalendarEntryDetails.Appointment) => {
    setIsDirty(!appointmentId);
    setIsLoading(true);
    try {
      setEditableStatus(appointment.editableStatus);
      if (!appointment.payload.note) {
        setShowNotesClicked(false);
      }
      setNotesValue(appointment.payload.note);

      const client = getAppointmentClient(appointment);

      const [stockStatus, ...stocks] = await Promise.all([
        meroApi.pro.inventories.getProductStockManagementEnabledMap({
          pageId: page.details._id,
          productIds: (appointment.payload.consumedProducts ?? []).map((p) => p._id),
        }),
        ...(appointment.payload.consumedProducts ?? []).map((p) =>
          meroApi.pro.inventories.getProductInventoryStock({
            productId: p._id,
            inventoryId: p.inventoryId,
            pageId: page.details._id,
          }),
        ),
      ]);

      const performer = pipe(
        page.workers,
        A.findFirst((w) => w.calendar._id === appointment.calendarId),
        O.getOrElseW(() => undefined),
      );

      type ExtractItem<T> = T extends { item: infer P } ? (P extends { type: 'Service' } ? { item: P } : never) : never;

      type MembershipService = MembershipItemConsumptionLockDetails<MeroUnits.Any> &
        ExtractItem<MembershipItemConsumptionLockDetails<MeroUnits.Any>>;

      resetBookingForm({
        start: appointment.start,
        end: appointment.end,
        recurrenceRule: appointment.recurrent ? appointment.recurrenceRule : undefined,
        notes: getAppointmentNotes(appointment),
        client,
        services: appointment.payload.bookedServices.map((s) => convertServiceToBookedService(s)),
        products: (appointment.payload.consumedProducts ?? []).map((p, idx) =>
          readConsumedProductToConsumedProduct(p, stocks[idx], stockStatus[p._id]),
        ),
        memberships: (appointment.membershipConsumptionLocks ?? [])
          .filter((m): m is MembershipService => m.item.type === 'Service')
          .map((m) => ({
            membershipPurchaseId: m.membership._id,
            item: {
              type: 'Service',
              service: { _id: m.item.service._id },
              bookingServiceIndex: m.item.bookingServiceIndex,
            },
            quantity: m.quantity,
          })),
        performer,
      });

      if (performer) {
        setSelectedPerformer(performer);
      }
    } catch (e) {
      showError(e);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Edit appointment
   */
  const shouldConfirmRecurrentUpdate = React.useMemo(() => existingAppointment?.recurrent, [existingAppointment]);
  const [showRecurrentUpdateOptions, setShowRecurrentUpdateOptions] = React.useState(false);

  const getAppointment = React.useCallback(async (appointmentId: AppointmentId, occurrenceIndex: number) => {
    setIsLoading(true);
    try {
      const appointment = await meroApi.calendar
        .getCalendarEntryById({
          pageId: page.details._id,
          entryId: appointmentId,
          occurrenceIndex,
        })
        .catch(logCatch('getCalendarEntryById'));

      if (appointment.type === Type.BlockedTime.VALUE) {
        navigation.navigate('Booking', {
          screen: 'BlockedTimeEditScreen',
          params: {
            calendarId: appointment.calendarId,
            calendarEntryId: appointment._id,
            occurrenceIndex: `${appointment.occurrenceIndex}`,
            start: appointment.start.toISOString(),
          },
        });

        return;
      }

      meroApi.calendar.fetchAppointmentHistory({ appointmentId: appointment._id }).then((history) => {
        setHistory(history);
      });

      resetFormValues(appointment);
      setExistingAppointment(appointment);
    } catch (e) {
      showError(e);
      goBack();
    } finally {
      setIsLoading(false);
    }
  }, []);

  const generateAppointment = async () => {
    try {
      setIsLoading(true);
      const getWorker = () => {
        if (workerId) {
          return page.workers.find((w) => w._id === workerId);
        }
      };

      const client = clientId ? await meroApi.clients.getClientById(clientId).catch(() => undefined) : undefined;

      const defaultInventory =
        productsState.inventories.find((inventory) => inventory.isDefault) ?? productsState.inventories[0];

      const stocks = await Promise.all(
        (productIds ?? []).map((productId) =>
          meroApi.pro.inventories.getProductInventoryStock({
            productId,
            inventoryId: defaultInventory._id,
            pageId: page.details._id,
          }),
        ),
      );

      const products = productIds
        ? await meroApi.pro.products
            .getProductsByIds({
              pageId: page.details._id,
              productIds,
            })
            .catch(() => [])
        : [];

      const services = (serviceIds ?? [])
        .map((serviceId) => page.details.services.find((s) => s._id === serviceId))
        .filter((s): s is SavedService => s !== undefined)
        .map((s) =>
          convertServiceToBookedService({
            ...s,
            customPrice: undefined,
            quantity: undefined,
          }),
        );

      resetBookingForm({
        start: startDate,
        recurrenceRule: undefined,
        notes: undefined,
        client:
          formState.client.type === 'existing'
            ? formState.client.client
            : client
            ? ({
                _id: client._id,
                userId: client.user._id,
                firstname: client.user.firstname,
                lastname: client.user.lastname,
                phone: client.user.phone,
                photo: client.user.photo,
                hideBoostDetails: client.hideBoostDetails,
                isBoost: client.isBoost,
                isBlocked: client.isBlocked,
                isFavourite: client.isFavourite,
                isWarned: client.isWarned,
              } satisfies ClientPreview)
            : undefined,
        newClient: clientPhone && clientFullName ? { phone: clientPhone, fullname: clientFullName } : undefined,
        services,
        products: products.map((p, idx) => convertProductToConsumedProduct(p, defaultInventory._id, stocks[idx])),
        memberships: [],
        performer: getWorker(),
      });
    } catch (e) {
      showError(e);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!appointmentId && (productsState.type === 'Loaded' || productsState.type === 'Failed')) {
      generateAppointment();
    }
  }, [appointmentId, occurrenceIndex, productsState.type]);

  React.useEffect(() => {
    if (appointmentId && (productsState.type === 'Loaded' || productsState.type === 'Failed')) {
      getAppointment(appointmentId, occurrenceIndex ?? 0);
    }
  }, [appointmentId, occurrenceIndex, productsState.type]);

  const bookAgain = () => {
    navigation.push('AppointmentScreen', {
      date: startDateTime.toJSDate(),
      workerId: performer?._id,
      serviceIds: services.map((s) => s.service._id),
      productIds: products.map((p) => p.product._id),
      clientId: client.type === 'existing' ? client.client._id : undefined,
    });
  };

  const updateBookingCallback = () => {
    if (existingAppointment && !updateInProgress) {
      if (shouldConfirmRecurrentUpdate) {
        return setShowRecurrentUpdateOptions(true);
      }

      return save({ onlyOnce: true });
    }
  };

  const getClientId = async () => {
    if (formState.client?.type === 'existing') {
      return formState.client.client._id;
    } else if (formState.client?.type === 'new') {
      const findOrCreateClient = async (client: {
        phone: StrictPhoneNumber;
        firstname: Firstname;
        lastname?: Lastname;
      }): Promise<ClientId> => {
        log.debug(`Find or create new client: ${JSON.stringify(client)}`);
        const pageId = page.details._id;

        // Try find user by phone
        const clients = await meroApi.clients.search({ pageId, search: client.phone });

        if (clients.length === 0) {
          log.debug(`No users found with phone number ${client.phone}, goint to create new client`);
          const newClientId = await meroApi.clients.createClientByPhone({
            pageId,
            phone: client.phone,
            firstname: client.firstname,
            lastname: client.lastname,
          });

          return newClientId;
        } else {
          log.debug(`There is a client with phone ${client.phone}`);
          return clients[0]._id;
        }
      };

      if (formState.client.isValid && !formState.client.isEmpty) {
        return await findOrCreateClient({
          phone: formState.client.phone,
          firstname: formState.client.firstname,
          lastname: formState.client.lastname,
        });
      }
    }
  };

  const save = async (payload?: { override?: boolean; onlyOnce?: boolean }) => {
    try {
      const { override = false, onlyOnce = false } = payload ?? {};
      if (formState.type === 'Invalid') {
        log.debug('Form is invalid', formState);
        setShowErrors(true);
        return;
      }
      setIsSaving(true);
      if (appointmentId && existingAppointment) {
        await meroApi.calendar.updateAppointment2({
          pageId: page.details._id,
          appointmentId,
          occurrenceIndex: occurrenceIndex ?? 0,
          start: formState.start,
          end: formState.end,
          recurrenceRule: formState.recurrenceRule,
          workerId: formState.performer._id,
          clientId: formState.client.type === 'existing' ? formState.client.client._id : undefined,
          bookedServices: formState.services.map((s) => s.service),
          consumedProducts: formState.products.map((p) => p.product),
          note: formState.notes,
          recurrent: formState.recurrenceRule !== undefined,
          membershipConsumptionLocks: memberships,
          onlyOnce,
          override,
        });
      } else {
        const clientId = await getClientId();

        await meroApi.calendar.createOwnAppointment2({
          start: formState.start,
          end: formState.end,
          recurrenceRule: formState.recurrenceRule,
          pageId: page.details._id,
          workerId: formState.performer._id,
          clientId,
          bookedServices: formState.services.map((s) => s.service),
          consumedProducts: formState.products.map((p) => p.product),
          membershipConsumptionLocks: memberships,
          note: formState.notes,
          override,
        });
      }

      toast.show({
        type: 'success',
        text: appointmentId ? i18n.t('bookingUpdated') : i18n.t('bookingCreated'),
      });

      if (existingAppointment && existingAppointment.payload.status === 'pending') {
        setIsDirty(false);
        return;
      }
      goBack();
    } catch (e) {
      if (apiError(t.unknown).is(e)) {
        if (e.code === 14 || e.code === 20) {
          // booking override
          setShowConfirmOverride(true);
          return;
        }
      }
      showError(e);
    } finally {
      setIsSaving(false);
    }
  };

  /**
   * Delete appointment
   */
  const canDeleteAppointment = React.useMemo(
    () =>
      existingAppointment &&
      existingAppointment.type === CalendarEntry.Type.Appointment.VALUE &&
      existingAppointment.payload.status === 'accepted' &&
      !hasPassed,
    [existingAppointment, hasPassed],
  );
  const shouldConfirmRecurrentDelete = React.useMemo(() => existingAppointment?.recurrent, [existingAppointment]);
  const [showRecurrentDeleteOptions, setShowRecurrentDeleteOptions] = React.useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
  const [updateInProgress, setUpdateInProgress] = React.useState(false);

  const deleteBooking = React.useCallback(async (appointment: CalendarEntry.Any, onlyOnce: boolean, reason: string) => {
    setUpdateInProgress(true);
    try {
      await meroApi.calendar.cancelAppointment({
        calendarId: appointment.calendarId,
        entryId: appointment._id,
        occurrenceIndex: appointment.occurrenceIndex,
        onlyOnce, // Confirm delete modal should not be show for recurrent bookings
        reason,
      });

      if (appointment.type === CalendarEntry.Type.Appointment.VALUE) {
        const client = getAppointmentClient(appointment);
        if (client?.phone && !client.hideBoostDetails) {
          // sendNotification(appointment._id, client.phone, 'Deleted');
        }
      }
      toast.show({
        type: 'success',
        text: i18n.t('bookingCanceled'),
      });
      goBack();
    } catch (error) {
      showError(error);
    } finally {
      setUpdateInProgress(false);
    }
  }, []);

  const deleteBookingCallback = () => {
    if (existingAppointment && !updateInProgress) {
      if (shouldConfirmRecurrentDelete) {
        return setShowRecurrentDeleteOptions(true);
      }

      const client = getAppointmentClient(existingAppointment);

      if (client?.phone) {
        return setShowConfirmDelete(true);
      }

      return deleteBooking(existingAppointment, true, '');
    }
  };

  const confirmDeleteBookingOnce = (reason: string) => {
    if (existingAppointment) {
      deleteBooking(existingAppointment, true, reason);
    }
  };

  const confirmDeleteBookingAll = (reason: string) => {
    if (existingAppointment) {
      deleteBooking(existingAppointment, false, reason);
    }
  };

  const cancelConfirmDeleteCallback = () => {
    setShowConfirmDelete(false);
  };

  /**
   * No Show
   */
  const canMarkAsNoShow = React.useMemo(
    () =>
      existingAppointment &&
      existingAppointment.type === CalendarEntry.Type.Appointment.VALUE &&
      existingAppointment.payload.status === 'accepted' &&
      hasPassed &&
      existingAppointment.start.getTime() >= now.getTime() - MARK_NO_SHOW_LIMIT,
    [existingAppointment, hasPassed],
  );

  const onMarkAsNoShow = async () => {
    if (existingAppointment && !updateInProgress) {
      setUpdateInProgress(true);
      try {
        await meroApi.calendar.updateCalendarAppointmentStatus({
          calendarId: existingAppointment.calendarId,
          entryId: existingAppointment._id,
          newStatus: 'noShow',
          occurrenceIndex: existingAppointment.occurrenceIndex,
        });
        await getAppointment(existingAppointment._id, existingAppointment.occurrenceIndex);
      } catch (error) {
        showError(error);
      } finally {
        setUpdateInProgress(false);
      }
    }
  };

  /**
   * On close
   */
  const [showConfirmClose, setShowConfirmClose] = React.useState(false);
  const confirmCloseRef = React.useRef(false);

  const toggleConfirmClose = () => {
    setShowConfirmClose((prev) => {
      confirmCloseRef.current = !prev;
      return !prev;
    });
  };

  const onClose = (force = false) => {
    const canClose =
      (formState.client.type === 'none' && formState.services.length === 0 && formState.products.length === 0) ||
      !isDirty;
    if (!canClose && !force) {
      toggleConfirmClose();
      confirmCloseRef.current = true;
    } else {
      goBack();
    }
  };

  /**
   * On pending appointment
   */
  const isPendingAppointment = React.useMemo(
    () => existingAppointment?.payload.status === 'pending',
    [existingAppointment],
  );

  const acceptPendingAppointment = async () => {
    if (!existingAppointment) {
      return;
    }
    try {
      await meroApi.calendar.updateCalendarAppointmentStatus({
        calendarId: existingAppointment.calendarId,
        entryId: existingAppointment._id,
        newStatus: 'accepted',
        occurrenceIndex: existingAppointment.occurrenceIndex,
      });
      getAppointment(existingAppointment._id, existingAppointment.occurrenceIndex);
    } catch (error) {
      showError(error);
    }
  };

  return (
    <ModalScreenContainer edges={['left', 'top', 'right']} style={{ overflow: 'hidden' }}>
      <MeroHeader
        canGoBack
        onBack={() => onClose()}
        title={
          appointmentId
            ? isDirty
              ? i18n.t('changeAppointment')
              : i18n.t('appointmentDetails')
            : i18n.t('newAppointment')
        }
        containerStyle={{
          shadowColor: '#000000',
          shadowOffset: { width: 1, height: 1 },
          shadowOpacity: 0.16,
          shadowRadius: 16,
          elevation: 16,
        }}
      />
      {isPhone ? null : (
        <AppointmentScreenDesktop
          isDirty={isDirty}
          isLoading={isLoading}
          setDirty={setDirty}
          page={page}
          appointmentId={appointmentId}
          existingAppointment={existingAppointment}
          hasPassed={hasPassed}
          startDateTime={startDateTime}
          endDateTime={endDateTime}
          timeZone={timeZone}
          canEdit={canEdit}
          nonEmptyServices={nonEmptyServices}
          usableMemberships={usableMemberships}
          canAddMoreServices={canAddMoreServices}
          showErrors={showErrors}
          showProducts={showProducts}
          hasNotesOrRecurrence={hasNotesOrRecurrence}
          notesInputVisible={notesInputVisible}
          hasFinishedCheckout={hasFinishedCheckout}
          finishedCheckout={finishedCheckout}
          notesValue={notesValue}
          setNotesValue={setNotesValue}
          history={history}
          servicesPrice={servicesPrice}
          productsPrice={productsPrice}
          selectedService={selectedService}
          onSelectService={setSelectedService}
          onRemoveService={removeServiceCallback}
          selectedProduct={selectedProduct}
          onSelectProduct={setSelectedProduct}
          onUpdateProduct={updateProductCallback}
          onRemoveProduct={removeProductCallback}
          editRecurrenceRule={editRecurrenceRuleCallback}
          onClientRemove={onClientRemove}
          onClientChange={setClient}
          setSelectedService={setSelectedService}
          setSelectedProduct={setSelectedProduct}
          setShowNotesClicked={setShowNotesClicked}
          bookAgain={bookAgain}
          goToCheckoutDetails={goToCheckoutDetails}
          total={total}
          isPendingAppointment={isPendingAppointment}
          acceptPendingAppointment={acceptPendingAppointment}
          durationInMinutes={durationInMinutes}
          onDeleteBooking={deleteBookingCallback}
          onMarkAsNoShow={onMarkAsNoShow}
          canDeleteAppointment={Boolean(canDeleteAppointment)}
          canMarkAsNoShow={Boolean(canMarkAsNoShow)}
          showCheckoutButton={showCheckoutButton}
          latestDraft={latestDraft}
          resetFormValues={resetFormValues}
          onSaveBooking={save}
          onUpdateBooking={updateBookingCallback}
          onClose={onClose}
        />
      )}
      {showRecurrenceOptions ? (
        <RecurrenceRuleOptionsScreen
          recurrenceRule={recurrenceRule}
          onOptionSelected={flow(recurrenceOptionSelectedCallback, setDirty)}
          onDismiss={() => {
            setShowRecurrenceOptions(false);
          }}
          differenceInDays={differenceInDays}
        />
      ) : null}

      {showRecurrenceEditForm ? (
        <RecurrenceRuleEditScreen
          recurrenceRule={recurrenceRule}
          style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
          onBackPressed={() => {
            setShowRecurrenceEditForm(false);
          }}
          onSave={flow((newRule) => {
            setShowRecurrenceEditForm(false);
            setRecurrenceRule(newRule);
          }, setDirty)}
        />
      ) : null}

      {showConfirmOverride ? (
        <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
          <ConfirmBox
            type="warn"
            icon="info"
            headerTitle="Actiune Importantă"
            canClose={true}
            onClose={cancelConfirmOverrideCallback}
            leftAction={{
              text: 'Anulare',
              onPress: cancelConfirmOverrideCallback,
            }}
            rightAction={{
              text: 'Confirmă',
              onPress: confirmBookingOverrideCallback,
            }}
          >
            <H1>Confirmă suprapunere programare</H1>
            <Spacer size="8" />
            <Body>Aceasta programare se va suprapune cu altele. Eşti sigur că vrei să continui?</Body>
          </ConfirmBox>
        </ModalOverlay>
      ) : null}
      {showRecurrentDeleteOptions ? (
        <ConfirmDeleteRecurrentBooking
          onDeleteAll={() => confirmDeleteBookingAll('')}
          onDeleteOnlyOne={() => confirmDeleteBookingOnce('')}
          onDismiss={() => {
            setShowRecurrentDeleteOptions(false);
          }}
        />
      ) : null}
      {showConfirmDelete ? (
        <ConfirmDeleteBooking
          canClose={!updateInProgress}
          onClose={cancelConfirmDeleteCallback}
          leftCallback={cancelConfirmDeleteCallback}
          rightCallback={(reason: string) => {
            if (existingAppointment) {
              deleteBooking(existingAppointment, true, reason);
            }
          }}
        />
      ) : null}
      {showRecurrentUpdateOptions ? (
        <ConfirmUpdateRecurrentBooking
          onUpdateAll={() => {
            setShowRecurrentUpdateOptions(false);
            // Save user choice, to avoid infinite loop with confirm override
            setRecurrentUpdateOption('all');
            save({
              onlyOnce: false,
            });
          }}
          onUpdateOnlyOne={() => {
            setShowRecurrentUpdateOptions(false);
            // Save user choice, to avoid infinite loop with confirm override
            setRecurrentUpdateOption('once');
            save({
              onlyOnce: true,
            });
          }}
          onDismiss={() => {
            setShowRecurrentUpdateOptions(false);
          }}
        />
      ) : null}
      {showConfirmClose ? (
        <ConfirmCloseBooking
          canClose
          onClose={toggleConfirmClose}
          leftCallback={toggleConfirmClose}
          rightCallback={() => onClose(true)}
        />
      ) : null}
    </ModalScreenContainer>
  );
};

export default pipe(AppointmentScreen, CurrentBusiness, Authorized);
