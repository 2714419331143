import { ClientPreview } from '@mero/api-sdk/dist/clients';
import { Column } from '@mero/components';
import { flow, pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

import AdvancedSelect, { Ref as AdvanceSelectRef } from '../../../components/AdvancedSelect';
import ClientListItem from '../../../components/ClientListItem';

import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { BookingFormContext } from '../../../contexts/BookingFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { SearchClientsContext, withSearchClientsContextProvider } from '../../../contexts/SearchClientsContext';
import { nullish } from '../../../utils/function';

const Icon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G transform="translate(0 -.5)">
      <Circle cx={12} cy={12} r={12} fill="#e9ecef" transform="translate(0 .5)" />
      <Path
        fill="rgba(82,87,127,0.5)"
        d="M8 17.5a1 1 0 0 1-1-1v-.435a2.4 2.4 0 0 1 1.959-1.753c1.689-.287 1.689-.8 1.689-1.116v-.51a3.125 3.125 0 0 1-1.013-2.36V8.8A2.2 2.2 0 0 1 12 6.5a2.2 2.2 0 0 1 2.365 2.3v1.53a3.122 3.122 0 0 1-1.013 2.36v.51c0 .319 0 .813 1.689 1.116A2.427 2.427 0 0 1 17 16.065v.435a1 1 0 0 1-1 1Z"
        data-name="Union 5"
      />
    </G>
  </Svg>
);

type ItemProps = {
  item: ClientPreview;
  onClientSelected?: (client: ClientPreview) => void;
};

const Item: React.FC<ItemProps> = React.memo(function ItemComponent({ item, onClientSelected }: ItemProps) {
  return (
    <Column style={{ paddingHorizontal: 16 }}>
      <ClientListItem
        client={item}
        onPress={() => {
          if (onClientSelected !== undefined) {
            onClientSelected(item);
          }
        }}
      />
    </Column>
  );
});

export type Props = CurrentBusinessProps & AuthorizedProps & { onClientSelected: (client: ClientPreview) => void };

const SelectClient: React.FC<Props> = ({ page, authorization, onClientSelected }) => {
  const advancedSelectRef = React.useRef<AdvanceSelectRef>(null);

  const { t } = useTranslation('clients');
  const [{ type: stateType, query, clients }, { init, search, reload, loadMore }] = SearchClientsContext.useContext();

  React.useEffect(() => {
    init({
      pageId: page.details._id,
      minQueryLength: page.permissions.clients.getSearchMinSymbols(),
    });
  }, [init, authorization, page]);

  const queryChangeCallback = React.useCallback(
    (q: string): void => {
      search({ pageId: page.details._id, query: { search: q, flagFilter: query.flagFilter } }); // async effect
    },
    [search, query],
  );

  return (
    <>
      <AdvancedSelect
        ref={advancedSelectRef}
        Icon={
          <Column style={{ paddingRight: 12 }}>
            <Icon />
          </Column>
        }
        text={t('selectClient')}
        placeholder={t('searchClientPlaceholder')}
        onSearch={queryChangeCallback}
        data={clients.type === 'ClientsResult' ? clients.clients : []}
        Item={(props) => (
          <Item {...props} onClientSelected={flow(onClientSelected, advancedSelectRef.current?.onHide ?? nullish)} />
        )}
        onEndReached={loadMore}
        onEndReachedThreshold={0.9}
        windowSize={11}
        refreshing={stateType === 'Loading'}
        onRefresh={reload}
      />
    </>
  );
};

export default pipe(SelectClient, withSearchClientsContextProvider, CurrentBusiness, Authorized);
