import { BookingServiceCustomPrice } from '@mero/api-sdk';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { DiscountPercent } from '@mero/shared-sdk/dist/numbers/discountPercent';
import { TFunction } from 'i18next';

import { multiplyScaled, scaledToString } from './scaled';

export const hasServicePriceChanged = (oldPrice?: BookingServiceCustomPrice, newPrice?: BookingServiceCustomPrice) => {
  if (!oldPrice && !newPrice) {
    return false;
  }

  if (!oldPrice || !newPrice) {
    return true;
  }

  if (oldPrice.type !== newPrice.type) {
    return true;
  }

  if (oldPrice.type === 'Fixed' && newPrice.type === 'Fixed') {
    return oldPrice.fixed.amount.value === newPrice.fixed.amount.value;
  }

  if (oldPrice.type === 'Range' && newPrice.type === 'Range') {
    return (
      oldPrice.range.from.amount.value === newPrice.range.from.amount.value &&
      oldPrice.range.to.amount.value === newPrice.range.to.amount.value
    );
  }

  if (oldPrice.discount?.type !== newPrice.discount?.type) {
    return true;
  }

  if (oldPrice.discount?.type === 'Percent' && newPrice.discount?.type === 'Percent') {
    return oldPrice.discount.percent.value === newPrice.discount.percent.value;
  }

  if (oldPrice.discount?.type === 'Value' && newPrice.discount?.type === 'Value') {
    return oldPrice.discount.value === newPrice.discount.value;
  }

  return false;
};

export type ServicePrice =
  | {
      type: 'Hidden';
      discounted: ScaledNumber;
      total: ScaledNumber;
    }
  | {
      type: 'Fixed';
      discounted: ScaledNumber;
      total: ScaledNumber;
    }
  | {
      type: 'Range';
      discounted: { from: ScaledNumber; to: ScaledNumber };
      total: { from: ScaledNumber; to: ScaledNumber };
    };

export const computeServicePrice = (
  quantity: number,
  membershipQuantity: number,
  serviceTotal?: BookingServiceCustomPrice,
): ServicePrice => {
  if (!serviceTotal) {
    return { type: 'Hidden', discounted: ScaledNumber.zero(), total: ScaledNumber.zero() };
  }

  if (serviceTotal.type === 'Fixed') {
    const price = serviceTotal.fixed.amount;
    const total = multiplyScaled(price, quantity);
    const totalWithMembership = multiplyScaled(price, quantity - membershipQuantity);
    const discount = serviceTotal?.discount
      ? serviceTotal.discount.type === 'Percent'
        ? multiplyScaled(totalWithMembership, ScaledNumber.toNumber(serviceTotal.discount.percent) / 100)
        : serviceTotal.discount.value.amount
      : ScaledNumber.zero();
    const discounted = ScaledNumber.sub(totalWithMembership, discount);
    return { type: serviceTotal.type, discounted, total };
  }

  const from = serviceTotal.range.from.amount;
  const to = serviceTotal.range.to.amount;
  const totalFrom = multiplyScaled(from, quantity);
  const totalTo = multiplyScaled(to, quantity);
  const totalWithMembershipFrom = multiplyScaled(from, quantity - membershipQuantity);
  const totalWithMembershipTo = multiplyScaled(to, quantity - membershipQuantity);
  const discountFrom = serviceTotal?.discount
    ? serviceTotal.discount.type === 'Percent'
      ? multiplyScaled(totalWithMembershipFrom, ScaledNumber.toNumber(serviceTotal.discount.percent) / 100)
      : serviceTotal.discount.value.amount
    : ScaledNumber.zero();
  const discountTo = serviceTotal?.discount
    ? serviceTotal.discount.type === 'Percent'
      ? multiplyScaled(totalWithMembershipTo, ScaledNumber.toNumber(serviceTotal.discount.percent) / 100)
      : serviceTotal.discount.value.amount
    : ScaledNumber.zero();
  const discountedFrom = ScaledNumber.sub(totalWithMembershipFrom, discountFrom);
  const discountedTo = ScaledNumber.sub(totalWithMembershipTo, discountTo);
  return {
    type: serviceTotal.type,
    discounted: { from: discountedFrom, to: discountedTo },
    total: {
      from: totalFrom,
      to: totalTo,
    },
  };
};

export type PricesTotal =
  | { type: 'Hidden' }
  | { type: 'Fixed'; total: ScaledNumber }
  | { type: 'Range'; total: { from: ScaledNumber; to: ScaledNumber } };

export const computeServicesTotal = (prices: ServicePrice[]) => {
  return prices.reduce(
    (acc: PricesTotal, price) => {
      if (price.type === 'Hidden' || acc.type === 'Hidden') {
        return { type: 'Hidden' as const };
      }

      if (price.type === 'Fixed' && acc.type === 'Fixed') {
        return {
          type: 'Fixed' as const,
          total: ScaledNumber.add(acc.total, price.discounted),
        };
      }

      if (price.type === 'Range' && acc.type === 'Fixed') {
        return {
          type: 'Range' as const,
          total: {
            from: ScaledNumber.add(acc.total, price.discounted.from),
            to: ScaledNumber.add(acc.total, price.discounted.to),
          },
        };
      }

      if (price.type === 'Fixed' && acc.type === 'Range') {
        return {
          type: 'Range' as const,
          total: {
            from: ScaledNumber.add(acc.total.from, price.discounted),
            to: ScaledNumber.add(acc.total.to, price.discounted),
          },
        };
      }

      if (price.type === 'Range' && acc.type === 'Range') {
        return {
          type: 'Range' as const,
          total: {
            from: ScaledNumber.add(acc.total.from, price.discounted.from),
            to: ScaledNumber.add(acc.total.to, price.discounted.to),
          },
        };
      }

      return acc;
    },
    { type: 'Fixed' as const, total: ScaledNumber.zero() },
  );
};

export const getBookingTotalPrice = (
  servicesPrice: PricesTotal,
  productsPrice: ScaledNumber,
  giftCardValue: number,
): PricesTotal => {
  if (servicesPrice.type === 'Hidden') {
    return servicesPrice;
  }

  if (servicesPrice.type === 'Fixed') {
    return {
      type: 'Fixed',
      total: ScaledNumber.sub(
        ScaledNumber.add(productsPrice, servicesPrice.total),
        ScaledNumber.fromNumber(giftCardValue, servicesPrice.total.scale ?? productsPrice.scale ?? 2),
      ),
    };
  }

  return {
    type: 'Range',
    total: {
      from: ScaledNumber.sub(
        ScaledNumber.add(productsPrice, servicesPrice.total.from),
        ScaledNumber.fromNumber(giftCardValue, servicesPrice.total.from.scale ?? productsPrice.scale ?? 2),
      ),
      to: ScaledNumber.sub(
        ScaledNumber.add(productsPrice, servicesPrice.total.to),
        ScaledNumber.fromNumber(giftCardValue, servicesPrice.total.to.scale ?? productsPrice.scale ?? 2),
      ),
    },
  };
};

type PriceTextArgs =
  | {
      type: 'total' | 'discounted';
      price: ServicePrice;
      t: TFunction;
    }
  | {
      type?: undefined;
      price: PricesTotal;
      t: TFunction;
    };

export const getPriceText = ({ t, ...rest }: PriceTextArgs) => {
  if (rest.price.type === 'Hidden') {
    return t('Hidden');
  }

  if (rest.type && rest.price.type === 'Fixed') {
    const { type, price } = rest;
    return `${scaledToString(price[type])}`;
  }

  if (rest.type && rest.price.type === 'Range') {
    const { type, price } = rest;
    return `${scaledToString(price[type].from)}-${scaledToString(price[type].to)}`;
  }

  if (rest.price.type === 'Fixed') {
    return `${scaledToString(rest.price.total)}`;
  }

  return `${scaledToString(rest.price.total.from)}-${scaledToString(rest.price.total.to)}`;
};

export const isDiscountedPrice = (price: ServicePrice) => {
  if (price.type === 'Hidden') {
    return false;
  }

  if (price.type === 'Fixed') {
    return !ScaledNumber.equals(price.discounted, price.total);
  }

  return (
    !ScaledNumber.equals(price.discounted.from, price.total.from) ||
    !ScaledNumber.equals(price.discounted.to, price.total.to)
  );
};

export const getUnit = (price?: BookingServiceCustomPrice): MeroUnits.Any => {
  if (!price) {
    return MeroUnits.RON.code;
  }

  if (price.type === 'Fixed') {
    return price.fixed.unit;
  }

  return price.range.from.unit;
};

export const getScale = (price?: BookingServiceCustomPrice): number => {
  if (!price) {
    return 2;
  }

  if (price.type === 'Fixed') {
    return price.fixed.amount.scale ?? 2;
  }

  return price.range.from.amount.scale ?? 2;
};

type ApplyNewPriceArgs = {
  price: ServicePrice;
  unit: MeroUnits.Any;
  discount?: BookingServiceCustomPrice['discount'];
};

export const applyNewPrice = ({ price, unit, discount }: ApplyNewPriceArgs): BookingServiceCustomPrice | undefined => {
  if (price.type === 'Hidden') {
    return undefined;
  }

  if (price.type === 'Fixed') {
    return {
      type: 'Fixed',
      fixed: {
        amount: price.total,
        //@ts-expect-error BE limitation in should be fixed
        unit,
      },
      discount,
    };
  }

  return {
    type: 'Range',
    range: {
      from: {
        amount: price.total.from,
        //@ts-expect-error BE limitation in should be fixed
        unit,
      },
      to: {
        amount: price.total.to,
        //@ts-expect-error BE limitation in should be fixed
        unit,
      },
    },
    discount,
  };
};

export const applyQuantity = (price: ServicePrice, quantity: number): ServicePrice => {
  if (price.type === 'Hidden') {
    return { type: 'Hidden', discounted: ScaledNumber.zero(), total: ScaledNumber.zero() };
  }

  if (price.type === 'Fixed') {
    return {
      type: 'Fixed',
      discounted: multiplyScaled(price.discounted, quantity),
      total: multiplyScaled(price.total, quantity),
    };
  }

  return {
    type: 'Range',
    discounted: {
      from: multiplyScaled(price.discounted.from, quantity),
      to: multiplyScaled(price.discounted.to, quantity),
    },
    total: {
      from: multiplyScaled(price.total.from, quantity),
      to: multiplyScaled(price.total.to, quantity),
    },
  };
};

type ComputePriceArgs = (
  | {
      type: 'Fixed';
      amount: number;
    }
  | {
      type: 'Range';
      amount: {
        from: number;
        to: number;
      };
    }
  | { type: 'Hidden' }
) & { discount: number; quantity: number; scale: number };

export const computeTotalPrice = ({ discount, quantity, scale, ...price }: ComputePriceArgs): ServicePrice => {
  if (price.type === 'Hidden') {
    return { type: 'Hidden', discounted: ScaledNumber.zero(), total: ScaledNumber.zero() };
  }

  if (price.type === 'Fixed') {
    const total = ScaledNumber.fromNumber(price.amount * quantity, scale);
    const discounted = ScaledNumber.fromNumber(price.amount * quantity - discount, scale);
    return { type: 'Fixed', discounted, total };
  }

  const from = ScaledNumber.fromNumber(price.amount.from * quantity, scale);
  const to = ScaledNumber.fromNumber(price.amount.to * quantity, scale);
  const discountedFrom = ScaledNumber.fromNumber(price.amount.from * quantity - discount, scale);
  const discountedTo = ScaledNumber.fromNumber(price.amount.to * quantity - discount, scale);

  return {
    type: 'Range',
    discounted: { from: discountedFrom, to: discountedTo },
    total: { from, to },
  };
};

export const convertBookingPriceToComputePrice = (
  quantity: number,
  price?: BookingServiceCustomPrice,
): ComputePriceArgs => {
  if (!price) {
    return { type: 'Hidden', discount: 0, quantity, scale: 2 };
  }

  if (price.type === 'Fixed' && price.discount?.type === 'Value') {
    return {
      type: 'Fixed',
      amount: ScaledNumber.toNumber(price.fixed.amount),
      discount: ScaledNumber.toNumber(price.discount.value.amount),
      quantity,
      scale: price.fixed.amount.scale ?? 2,
    };
  }

  if (price.type === 'Fixed' && price.discount?.type === 'Percent') {
    return {
      type: 'Fixed',
      amount: ScaledNumber.toNumber(price.fixed.amount),
      discount: (ScaledNumber.toNumber(price.fixed.amount) * ScaledNumber.toNumber(price.discount.percent)) / 100,
      quantity,
      scale: price.fixed.amount.scale ?? 2,
    };
  }

  if (price.type === 'Fixed') {
    return {
      type: 'Fixed',
      amount: ScaledNumber.toNumber(price.fixed.amount),
      discount: 0,
      quantity,
      scale: price.fixed.amount.scale ?? 2,
    };
  }

  return {
    type: 'Range',
    amount: {
      from: ScaledNumber.toNumber(price.range.from.amount),
      to: ScaledNumber.toNumber(price.range.to.amount),
    },
    discount: 0,
    quantity,
    scale: price.range.from.amount.scale ?? 2,
  };
};

export const computeUnitPrice = ({ discount, quantity, scale, ...price }: ComputePriceArgs): ServicePrice => {
  if (price.type === 'Hidden') {
    return { type: 'Hidden', discounted: ScaledNumber.zero(), total: ScaledNumber.zero() };
  }

  if (price.type === 'Fixed') {
    const total = ScaledNumber.fromNumber(price.amount, scale);
    const discounted = ScaledNumber.fromNumber(price.amount - discount / quantity, scale);
    return { type: 'Fixed', discounted, total };
  }

  const from = ScaledNumber.fromNumber(price.amount.from, scale);
  const to = ScaledNumber.fromNumber(price.amount.to, scale);
  const discountedFrom = ScaledNumber.fromNumber(price.amount.from - discount / quantity, scale);
  const discountedTo = ScaledNumber.fromNumber(price.amount.to - discount / quantity, scale);

  return {
    type: 'Range',
    discounted: { from: discountedFrom, to: discountedTo },
    total: { from, to },
  };
};

export const computeTotalMembershipPrice = (price: ServicePrice, membershipQuantity: number): ServicePrice => {
  if (price.type === 'Hidden') {
    return { type: 'Hidden', discounted: ScaledNumber.zero(), total: ScaledNumber.zero() };
  }

  if (price.type === 'Fixed') {
    return {
      type: 'Fixed',
      discounted: multiplyScaled(price.discounted, membershipQuantity),
      total: multiplyScaled(price.total, membershipQuantity),
    };
  }

  return {
    type: 'Range',
    discounted: {
      from: multiplyScaled(price.discounted.from, membershipQuantity),
      to: multiplyScaled(price.discounted.to, membershipQuantity),
    },
    total: {
      from: multiplyScaled(price.total.from, membershipQuantity),
      to: multiplyScaled(price.total.to, membershipQuantity),
    },
  };
};

export const substractServicePrice = (price: ServicePrice, substractedPrice: ServicePrice): ServicePrice => {
  if (price.type === 'Fixed' && substractedPrice.type === 'Fixed') {
    return {
      type: 'Fixed',
      discounted: ScaledNumber.sub(price.discounted, substractedPrice.discounted),
      total: ScaledNumber.sub(price.total, substractedPrice.total),
    };
  }

  if (price.type === 'Range' && substractedPrice.type === 'Range') {
    return {
      type: 'Range',
      discounted: {
        from: ScaledNumber.sub(price.discounted.from, substractedPrice.discounted.from),
        to: ScaledNumber.sub(price.discounted.to, substractedPrice.discounted.to),
      },
      total: {
        from: ScaledNumber.sub(price.total.from, substractedPrice.total.from),
        to: ScaledNumber.sub(price.total.to, substractedPrice.total.to),
      },
    };
  }

  if (price.type === 'Fixed' && substractedPrice.type === 'Range') {
    return {
      type: 'Range',
      discounted: {
        from: ScaledNumber.sub(price.discounted, substractedPrice.discounted.from),
        to: ScaledNumber.sub(price.discounted, substractedPrice.discounted.to),
      },
      total: {
        from: ScaledNumber.sub(price.total, substractedPrice.total.from),
        to: ScaledNumber.sub(price.total, substractedPrice.total.to),
      },
    };
  }

  if (price.type === 'Range' && substractedPrice.type === 'Fixed') {
    return {
      type: 'Range',
      discounted: {
        from: ScaledNumber.sub(price.discounted.from, substractedPrice.discounted),
        to: ScaledNumber.sub(price.discounted.to, substractedPrice.discounted),
      },
      total: {
        from: ScaledNumber.sub(price.total.from, substractedPrice.total),
        to: ScaledNumber.sub(price.total.to, substractedPrice.total),
      },
    };
  }

  return price;
};

export const percentageDiscountFromNumber = (value: number, unit = 2) =>
  DiscountPercent.build(ScaledNumber).unsafeFrom(ScaledNumber.fromNumber(value, unit));
