import { MembershipPurchaseId, PositiveInt } from '@mero/api-sdk';
import { MembershipItemConsumptionLock } from '@mero/api-sdk/dist/memberships/membershipConsumptionLock';
import { ServiceId } from '@mero/api-sdk/dist/services';
import {
  colors,
  Column,
  H2,
  Label,
  Line,
  Row,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Button,
  H3s,
  Title,
  Select,
} from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import * as E from 'fp-ts/Either';
import { flow, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { isNaN } from 'lodash';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView, TextInput, TouchableOpacity } from 'react-native';
import Svg, { Circle, G, Path, Rect, SvgProps } from 'react-native-svg';

import HSpacer from '@mero/components/lib/components/HSpacer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import TypeSafeTextInput, { ValueIO } from '@mero/components/lib/components/TypeSafeTextInput';

import { AppointmentService, BookingFormContext } from '../../../contexts/BookingFormContext';
import { NumberFromString, roundToDecimals } from '../../../utils/number';
import {
  localeNumberValidator,
  localeStringToNumber,
  multiplyScaled,
  replaceDecimalSeparator,
  scaledToString,
  stripLocalThousandsSeparators,
} from '../../../utils/scaled';
import {
  applyNewPrice,
  applyQuantity,
  computeServicePrice,
  computeTotalMembershipPrice,
  computeTotalPrice,
  computeUnitPrice,
  getPriceText,
  getScale,
  getUnit,
  percentageDiscountFromNumber,
  ServicePrice,
  substractServicePrice,
} from '../../../utils/servicePrice';
import DiscountComponent, { MinusIcon, PlusIcon } from '../CheckoutScreen/DiscountComponent';
import SelectMembership from './SelectMembership';
import { MembershipServices } from './common';

const CloseIcon = (props: SvgProps) => (
  <Svg width={18} height={18} {...props}>
    <Path fill="none" d="M0 0h18v18H0Z" data-name="Path 8343" />
    <Path
      fill="#52577f"
      d="M15 5.108 13.892 4 9.5 8.392 5.108 4 4 5.108 8.392 9.5 4 13.892 5.108 15 9.5 10.608 13.892 15 15 13.892 10.608 9.5Z"
      data-name="Path 8344"
    />
  </Svg>
);

const DiscountIcon = (props: SvgProps) => (
  <Svg width={24} height={24} data-name="Group 6969" {...props}>
    <Rect width={24} height={24} fill="#f2f2fe" rx={12} />
    <G
      fill="none"
      stroke="#080de0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      data-name="Orion_discount (2)"
      transform="translate(8 8.287)"
    >
      <Circle cx={1} cy={1} r={1} />
      <Circle cx={1} cy={1} r={1} data-name="layer2" transform="translate(6 5.427)" />
      <Path d="M7.453.244.245 7.38" data-name="layer2" />
    </G>
  </Svg>
);

type Props = {
  disabled?: boolean;
  onClose: () => void;
  onSave: (service: AppointmentService) => void;
  onRemove: () => void;
  serviceIndex: number;
  serviceId: ServiceId;
  memberships: MembershipServices[];
};

const PriceTypes: { label: string; value: 'Fixed' | 'Range' | 'Hidden' }[] = [
  { label: 'Fixed', value: 'Fixed' },
  { label: 'Range', value: 'Range' },
  { label: 'Hidden', value: 'Hidden' },
];

const Service: React.FC<Props> = ({
  serviceId,
  serviceIndex,
  onSave,
  onClose,
  onRemove,
  memberships,
  disabled = false,
}) => {
  const i18n = useTranslation('services');

  const [formState, { addMembership, updateMembershipAt }] = BookingFormContext.useContext();

  const initialRender = React.useRef(true);

  const service = formState.services[serviceIndex];
  const existingMembership = t.number.is(service.service.membershipIndex)
    ? formState.memberships[service.service.membershipIndex]
    : undefined;

  const serviceTotalPrice = React.useMemo(
    () => computeServicePrice(service.service.quantity, 0, service.service.customPrice),
    [service.service.customPrice, service.service.quantity],
  );

  const availableMemberships = React.useMemo(() => {
    return memberships.filter((membership) => {
      return (
        membership._id === existingMembership?.membershipPurchaseId ||
        formState.memberships.every((m) => m.membershipPurchaseId !== membership._id)
      );
    });
  }, []);

  const preselectedMembership = React.useMemo(() => {
    if (!existingMembership) {
      return undefined;
    }
    return availableMemberships.find((m) => m._id === existingMembership.membershipPurchaseId);
  }, [existingMembership, availableMemberships]);

  const [showErrors, setShowErrors] = React.useState(false);

  const [showDiscountInput, setShowDiscountInput] = React.useState(service.service.customPrice?.discount !== undefined);
  const [discount, setDiscount] = React.useState({
    value: 0,
    percentage: 0,
    type: 'Value',
  });

  const { discountValue, discountPercentage } = React.useMemo(() => {
    if (!service.service.customPrice?.discount) {
      return {
        discountValue: 0,
        discountPercentage: 0,
      };
    }
    const total = ScaledNumber.toNumber(
      service.service.customPrice?.type === 'Fixed'
        ? service.service.customPrice.fixed.amount
        : service.service.customPrice.type === 'Range'
        ? service.service.customPrice.range.from.amount
        : ScaledNumber.zero(),
    );
    if (service.service.customPrice.discount.type === 'Value') {
      const value = ScaledNumber.toNumber(service.service.customPrice.discount.value.amount);
      return {
        discountValue: value,
        discountPercentage: roundToDecimals((value * 100) / total),
      };
    }

    if (service.service.customPrice.discount.type === 'Percent') {
      const percentage = ScaledNumber.toNumber(service.service.customPrice.discount.percent);
      return {
        discountValue: roundToDecimals((percentage * total) / 100),
        discountPercentage: percentage,
      };
    }

    return {
      discountValue: 0,
      discountPercentage: 0,
    };
  }, [service.service.customPrice?.discount]);

  const [selectedMembership, setSelectedMembership] = React.useState<MembershipServices | undefined>(
    preselectedMembership,
  );
  const quantityTimer = React.useRef(0);

  const [priceFixed, setPriceFixed] = React.useState({
    input: scaledToString(
      service.service.customPrice?.type === 'Fixed' ? service.service.customPrice.fixed.amount : ScaledNumber.zero(),
    ),
    decoded: NumberFromString.decode(
      scaledToString(
        service.service.customPrice?.type === 'Fixed' ? service.service.customPrice.fixed.amount : ScaledNumber.zero(),
      ),
    ),
  });
  const priceFixedValid = E.isRight(priceFixed.decoded);

  const [priceFrom, setPriceFrom] = React.useState({
    input: scaledToString(
      service.service.customPrice?.type === 'Range'
        ? service.service.customPrice.range.from.amount
        : ScaledNumber.zero(),
    ),
    decoded: NumberFromString.decode(
      scaledToString(
        service.service.customPrice?.type === 'Range'
          ? service.service.customPrice.range.from.amount
          : ScaledNumber.zero(),
      ),
    ),
  });
  const priceFromValid = E.isRight(priceFrom.decoded);

  const [priceTo, setPriceTo] = React.useState({
    input: scaledToString(
      service.service.customPrice?.type === 'Range' ? service.service.customPrice.range.to.amount : ScaledNumber.zero(),
    ),
    decoded: NumberFromString.decode(
      scaledToString(
        service.service.customPrice?.type === 'Range'
          ? service.service.customPrice.range.to.amount
          : ScaledNumber.zero(),
      ),
    ),
  });
  const priceToValid = E.isRight(priceTo.decoded);

  const [quantity, setDiscountQuantity] = React.useState(service.service.quantity);
  const [tempQuantity, setTempQuantity] = React.useState(roundToDecimals(quantity, 0).toString() ?? '1');
  const [priceType, setPriceType] = React.useState(service.service.customPrice?.type ?? ('Hidden' as const));

  const [totalValue, setTotalValue] = React.useState(applyQuantity(serviceTotalPrice, quantity));

  const consumed = React.useMemo(() => {
    if (!selectedMembership) {
      return 0;
    }
    const service = selectedMembership.items.find((item) => item._id === serviceId);
    return service ? (service.quantity > quantity ? quantity : service.quantity) : 0;
  }, [selectedMembership, quantity]);

  const updateTotalValue = () => {
    if (priceType === 'Fixed') {
      const priceValue = localeStringToNumber(priceFixed.input);
      setTotalValue(
        computeTotalPrice({
          type: 'Fixed',
          amount: isNaN(priceValue) ? 0 : priceValue,
          discount: discountValue,
          quantity,
          scale: getScale(service.service.customPrice),
        }),
      );
    }

    if (priceType === 'Range') {
      const priceFromValue = localeStringToNumber(priceFrom.input);
      const priceToValue = localeStringToNumber(priceTo.input);
      setTotalValue(
        computeTotalPrice({
          type: 'Range',
          amount: {
            from: isNaN(priceFromValue) ? 0 : priceFromValue,
            to: isNaN(priceToValue) ? 0 : priceToValue,
          },
          discount: discountValue,
          quantity,
          scale: getScale(service.service.customPrice),
        }),
      );
    }

    if (priceType === 'Hidden') {
      setTotalValue(
        computeTotalPrice({
          type: 'Hidden',
          discount: discountValue,
          quantity,
          scale: getScale(service.service.customPrice),
        }),
      );
    }
  };

  const updateQuantity = (value: string = tempQuantity) => {
    window.clearTimeout(quantityTimer.current);

    const parsedValue = localeStringToNumber(value);
    const computedValue = PositiveInt.JSON.is(parsedValue) ? parsedValue : (1 as PositiveInt);

    setDiscountQuantity(computedValue);
    setTempQuantity(computedValue.toString());
  };

  const incrementValue = () => {
    updateQuantity((quantity + 1).toString());
  };

  const decrementValue = () => {
    updateQuantity((quantity - 1).toString());
  };

  const numberValidator =
    (prev: string) =>
    <A extends t.Mixed>(next: ValueIO<t.TypeOf<A>>) => {
      const parsed = replaceDecimalSeparator(next.input);
      return localeNumberValidator(parsed)
        ? { input: parsed, decoded: next.decoded }
        : { input: prev, decoded: next.decoded };
    };

  const quantityValidator = (prev: string) => (next: string) => {
    const parsed = replaceDecimalSeparator(next);
    return localeNumberValidator(parsed) ? parsed : prev;
  };

  const getPrice = (): ServicePrice => {
    if (priceType === 'Fixed') {
      return {
        type: 'Fixed',
        discounted: ScaledNumber.fromNumber(localeStringToNumber(priceFixed.input), 2),
        total: ScaledNumber.fromNumber(localeStringToNumber(priceFixed.input), 2),
      };
    }

    if (priceType === 'Range') {
      return {
        type: 'Range',
        discounted: {
          from: ScaledNumber.fromNumber(localeStringToNumber(priceFrom.input), 2),
          to: ScaledNumber.fromNumber(localeStringToNumber(priceTo.input), 2),
        },
        total: {
          from: ScaledNumber.fromNumber(localeStringToNumber(priceFrom.input), 2),
          to: ScaledNumber.fromNumber(localeStringToNumber(priceTo.input), 2),
        },
      };
    }

    return { type: 'Hidden', discounted: ScaledNumber.zero(), total: ScaledNumber.zero() };
  };
  const handleSave = () => {
    if (priceType === 'Fixed' && !priceFixedValid) {
      setShowErrors(true);
      return;
    }

    if (priceType === 'Range' && (!priceFromValid || !priceToValid)) {
      setShowErrors(true);
      return;
    }

    const membershipIndex = selectedMembership
      ? t.number.is(service.service.membershipIndex)
        ? service.service.membershipIndex
        : formState.memberships.length
      : undefined;
    onSave({
      ...service.service,
      quantity,
      customPrice: applyNewPrice({
        price: getPrice(),
        unit: getUnit(service.service.customPrice),
        discount:
          discount.value > 0
            ? {
                type: discount.type === 'Value' ? 'Value' : 'Percent',
                value: {
                  amount: ScaledNumber.fromNumber(discount.value, 2),
                  unit: getUnit(service.service.customPrice) as MeroUnits.RON,
                },
                percent: percentageDiscountFromNumber(discount.percentage),
              }
            : undefined,
      }),
      membershipIndex,
    });

    if (t.number.is(membershipIndex) && selectedMembership) {
      const lockMembership: MembershipItemConsumptionLock = {
        membershipPurchaseId: selectedMembership._id,
        quantity: consumed as PositiveInt,
        item: {
          type: 'Service',
          service: {
            _id: serviceId,
          },
          bookingServiceIndex: serviceIndex as PositiveInt,
        },
      };
      t.number.is(service.service.membershipIndex)
        ? updateMembershipAt({ index: service.service.membershipIndex, membership: lockMembership })
        : addMembership(lockMembership);
    }
  };

  const selectMembership = (membershipId?: MembershipPurchaseId) => {
    if (!membershipId) {
      setSelectedMembership(undefined);
      return;
    }
    const membership = availableMemberships.find((m) => m._id === membershipId);
    setSelectedMembership(membership);
  };

  const unitPrice: ServicePrice = React.useMemo(() => {
    if (priceType === 'Fixed') {
      const priceValue = localeStringToNumber(priceFixed.input);
      return computeUnitPrice({
        type: 'Fixed',
        amount: isNaN(priceValue) ? 0 : priceValue,
        discount: discountValue,
        quantity,
        scale: getScale(service.service.customPrice),
      });
    }

    if (priceType === 'Range') {
      const priceFromValue = localeStringToNumber(priceFrom.input);
      const priceToValue = localeStringToNumber(priceTo.input);
      return computeUnitPrice({
        type: 'Range',
        amount: {
          from: isNaN(priceFromValue) ? 0 : priceFromValue,
          to: isNaN(priceToValue) ? 0 : priceToValue,
        },
        discount: discountValue,
        quantity,
        scale: getScale(service.service.customPrice),
      });
    }

    return { type: 'Hidden', discounted: ScaledNumber.zero(), total: ScaledNumber.zero() };
  }, [priceType, priceFixed.input, priceFrom.input, priceTo.input, quantity, discountValue]);

  const consumedFromMembership: ServicePrice = React.useMemo(() => {
    return computeTotalMembershipPrice(unitPrice, consumed);
  }, [consumed, unitPrice]);

  /**
   * Trigger save on value change
   */
  React.useEffect(() => {
    updateTotalValue();
    if (!initialRender.current) {
      handleSave();
    }
    initialRender.current = false;
  }, [priceType, priceFixed, priceFrom, priceFrom, quantity, discount, selectedMembership]);

  const formattedPriceTypes = React.useMemo(
    () =>
      PriceTypes.map((v) => ({
        label: i18n.t(v.label),
        value: v.value,
      })),
    [PriceTypes],
  );

  if (!service) {
    return null;
  }

  return (
    <ScrollView style={{ paddingHorizontal: 16 }}>
      <Spacer size="16" />
      <Row justifyContent="space-between">
        <Label style={[{ color: colors.COMET }, meroStyles.text.semibold]}>{i18n.t('changeService')}</Label>
        <TouchableOpacity onPress={onClose}>
          <CloseIcon />
        </TouchableOpacity>
      </Row>
      <Spacer size="8" />
      <H2>{service.service.name}</H2>
      <Spacer size="16" />
      <Row style={{ alignItems: 'flex-start' }}>
        <Column style={{ flex: 1 }}>
          <InputWithLabel label={i18n.t('priceType')}>
            <Select items={formattedPriceTypes} value={priceType} onChange={setPriceType} editable={!disabled} />
          </InputWithLabel>
        </Column>
        <HSpacer left={16} />
        <Column style={{ flex: 1 }}>
          <Title style={{ fontFamily: 'open-sans-semibold' }}>{i18n.t('quantity')}</Title>
          <Spacer size={8} />
          <Row
            style={[
              {
                padding: 8,
                borderWidth: 1,
                borderRadius: 4,
                borderColor: colors.GEYSER,
                alignItems: 'center',
                width: '100%',
              },
              disabled && { backgroundColor: colors.ATHENS_GRAY, height: 43 },
            ]}
          >
            {!disabled && (
              <TouchableOpacity
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                  backgroundColor: colors.SKY_BLUE,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={decrementValue}
                disabled={disabled}
              >
                <MinusIcon />
              </TouchableOpacity>
            )}
            <Column style={[{ flex: 1, paddingHorizontal: 4 }]}>
              <TextInput
                style={[
                  { flex: 1, textAlign: 'center', fontSize: 16, fontFamily: 'open-sans' },
                  disabled && { textAlign: 'left' },
                ]}
                value={tempQuantity}
                onChangeText={setTempQuantity}
                onBlur={flow(() => tempQuantity, quantityValidator(quantity.toString()), updateQuantity)}
                keyboardType={'numeric'}
                editable={!disabled}
              />
            </Column>
            {!disabled && (
              <TouchableOpacity
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                  backgroundColor: colors.SKY_BLUE,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={incrementValue}
                disabled={disabled}
              >
                <PlusIcon />
              </TouchableOpacity>
            )}
          </Row>
        </Column>
      </Row>
      {priceType === 'Fixed' && (
        <>
          <Spacer size="16" />
          <InputWithLabel
            label={i18n.t('unitPrice')}
            isError={showErrors && !priceFixedValid}
            errorText={i18n.t('priceError')}
          >
            <TypeSafeTextInput
              editable={!disabled}
              codec={NumberFromString}
              value={priceFixed.input}
              onChange={flow(numberValidator(priceFixed.input), setPriceFixed)}
              keyboardType="numeric"
              placeholder={i18n.t('pricePlaceholder')}
              onFocus={() => {
                setPriceFixed({
                  input: stripLocalThousandsSeparators(priceFixed.input),
                  decoded: NumberFromString.decode(stripLocalThousandsSeparators(priceFixed.input)),
                });
              }}
              onBlur={() => {
                const valueAsNumber = localeStringToNumber(priceFixed.input);
                const parsed = ScaledNumber.fromNumber(
                  isNaN(valueAsNumber) ? 0 : valueAsNumber,
                  getScale(service.service.customPrice),
                );
                setPriceFixed({
                  input: priceFixed.input ? scaledToString(parsed) : '',
                  decoded: NumberFromString.decode(stripLocalThousandsSeparators(priceFixed.input)),
                });
                setTotalValue(
                  computeTotalPrice({
                    type: 'Fixed',
                    amount: isNaN(valueAsNumber) ? 0 : valueAsNumber,
                    discount: discountValue,
                    quantity,
                    scale: getScale(service.service.customPrice),
                  }),
                );
              }}
            />
          </InputWithLabel>
        </>
      )}
      {priceType === 'Range' && (
        <>
          <Spacer size="16" />
          <Row>
            <Column style={{ flex: 1 }}>
              <InputWithLabel
                label={i18n.t('priceFrom')}
                isError={showErrors && !priceFromValid}
                errorText={i18n.t('priceError')}
              >
                <TypeSafeTextInput
                  editable={!disabled}
                  codec={NumberFromString}
                  value={priceFrom.input}
                  onChange={flow(numberValidator(priceFrom.input), setPriceFrom)}
                  keyboardType="numeric"
                  placeholder={i18n.t('pricePlaceholder')}
                  onFocus={() => {
                    setPriceFrom({
                      input: stripLocalThousandsSeparators(priceFrom.input),
                      decoded: NumberFromString.decode(stripLocalThousandsSeparators(priceFrom.input)),
                    });
                  }}
                  onBlur={() => {
                    const valueAsNumber = localeStringToNumber(priceFrom.input);
                    const parsed = ScaledNumber.fromNumber(
                      isNaN(valueAsNumber) ? 0 : valueAsNumber,
                      getScale(service.service.customPrice),
                    );
                    setPriceFrom({
                      input: priceFrom.input ? scaledToString(parsed) : '',
                      decoded: NumberFromString.decode(stripLocalThousandsSeparators(priceFrom.input)),
                    });
                    setTotalValue(
                      computeTotalPrice({
                        type: 'Range',
                        amount: {
                          from: isNaN(valueAsNumber) ? 0 : valueAsNumber,
                          to: localeStringToNumber(priceTo.input),
                        },
                        discount: discountValue,
                        quantity,
                        scale: getScale(service.service.customPrice),
                      }),
                    );
                  }}
                />
              </InputWithLabel>
            </Column>
            <HSpacer left={16} />
            <Column style={{ flex: 1 }}>
              <InputWithLabel
                label={i18n.t('priceTo')}
                isError={showErrors && !priceToValid}
                errorText={i18n.t('priceError')}
              >
                <TypeSafeTextInput
                  editable={!disabled}
                  codec={NumberFromString}
                  value={priceTo.input}
                  onChange={flow(numberValidator(priceTo.input), setPriceTo)}
                  keyboardType="numeric"
                  placeholder={i18n.t('pricePlaceholder')}
                  onFocus={() => {
                    setPriceTo({
                      input: stripLocalThousandsSeparators(priceTo.input),
                      decoded: NumberFromString.decode(stripLocalThousandsSeparators(priceTo.input)),
                    });
                  }}
                  onBlur={() => {
                    const valueAsNumber = localeStringToNumber(priceTo.input);
                    const parsed = ScaledNumber.fromNumber(
                      isNaN(valueAsNumber) ? 0 : valueAsNumber,
                      getScale(service.service.customPrice),
                    );
                    setPriceTo({
                      input: priceTo.input ? scaledToString(parsed) : '',
                      decoded: NumberFromString.decode(stripLocalThousandsSeparators(priceTo.input)),
                    });
                    setTotalValue(
                      computeTotalPrice({
                        type: 'Range',
                        amount: {
                          from: localeStringToNumber(priceFrom.input),
                          to: isNaN(valueAsNumber) ? 0 : valueAsNumber,
                        },
                        discount: discountValue,
                        quantity,
                        scale: getScale(service.service.customPrice),
                      }),
                    );
                  }}
                />
              </InputWithLabel>
            </Column>
          </Row>
        </>
      )}
      <Spacer size="16" />
      <Row justifyContent="space-between">
        <Column>
          {!showDiscountInput && !disabled && priceType === 'Fixed' && (
            <TouchableOpacity
              style={{ flexDirection: 'row', alignItems: 'center' }}
              onPress={() => setShowDiscountInput(true)}
            >
              <DiscountIcon />
              <SmallBody style={[{ color: colors.DARK_BLUE, paddingLeft: 8 }, meroStyles.text.semibold]}>
                {i18n.t('addDiscount')}
              </SmallBody>
            </TouchableOpacity>
          )}
        </Column>
        {!showDiscountInput && (
          <SmallBody style={[meroStyles.text.semibold, { textAlign: 'right', flex: 1 }]}>
            {i18n.t('total')}:{' '}
            <SmallBody>
              {getPriceText({
                type: 'discounted',
                price: totalValue,
                t: i18n.t,
              })}{' '}
              {priceType !== 'Hidden' && i18n.t(getUnit(service.service.customPrice))}
            </SmallBody>
          </SmallBody>
        )}
      </Row>
      {showDiscountInput && (
        <>
          <DiscountComponent
            disabled={disabled}
            price={
              priceFixedValid
                ? ScaledNumber.toNumber(
                    multiplyScaled(ScaledNumber.fromNumber(localeStringToNumber(priceFixed.input), 2), quantity),
                  )
                : 0
            }
            value={discountValue}
            percentage={discountPercentage}
            onUpdate={(value, percentage, type) => setDiscount({ value, percentage, type })}
            type={service.service.customPrice?.discount?.type === 'Percent' ? 'percentage' : 'value'}
          />
          <Spacer size="8" />
          <SmallBody style={[meroStyles.text.semibold, { textAlign: 'right', flex: 1 }]}>
            {i18n.t('total')}:{' '}
            <SmallBody>
              {getPriceText({
                type: 'discounted',
                price: totalValue,
                t: i18n.t,
              })}{' '}
              {i18n.t(getUnit(service.service.customPrice))}
            </SmallBody>
          </SmallBody>
        </>
      )}

      {availableMemberships.length > 0 && !disabled && (
        <>
          <Spacer size="24" />
          <Line />
          <Spacer size="24" />
          <H3s>{i18n.t('membershipConsumption')}</H3s>
          <Spacer size={2} />
          <SmallBody>{i18n.t('membershipConsumptionCount', { count: consumed })}</SmallBody>
          <Spacer size={16} />
          <SelectMembership
            selected={selectedMembership}
            onSelect={selectMembership}
            list={availableMemberships}
            serviceId={serviceId}
          />
          <Row justifyContent={consumed > 0 ? 'space-between' : 'flex-end'}>
            {consumed > 0 && (
              <SmallBody style={{ flex: 1 }}>
                <Trans
                  ns="services"
                  i18nKey="consumedFromMembership"
                  values={{
                    count: consumed,
                    value: getPriceText({
                      type: 'discounted',
                      price: unitPrice,
                      t: i18n.t,
                    }),
                    currency: i18n.t(getUnit(service.service.customPrice)),
                  }}
                >
                  <SmallBody style={meroStyles.text.semibold}>0</SmallBody>1
                </Trans>
              </SmallBody>
            )}
            <SmallBody style={{ marginLeft: 8, textAlign: 'right' }}>
              <Trans
                ns="services"
                i18nKey="remainingPayment"
                values={{
                  value: getPriceText({
                    type: 'discounted',
                    price: substractServicePrice(totalValue, consumedFromMembership),
                    t: i18n.t,
                  }),
                  currency: i18n.t(getUnit(service.service.customPrice)),
                }}
              >
                <SmallBody style={meroStyles.text.semibold}>0</SmallBody>1
              </Trans>
            </SmallBody>
          </Row>
        </>
      )}
      {!disabled && (
        <>
          <Spacer size={32} />
          <Row flex={1} alignItems="center" justifyContent="center">
            {/*<TouchableOpacity onPress={onRemove}>*/}
            {/*  <DeleteIcon />*/}
            {/*</TouchableOpacity>*/}
            {/*<HSpacer left={8} />*/}
            <Button
              size="small"
              text={i18n.t('deleteFromAppointment')}
              color={colors.RADICAL_RED}
              backgroundColor={colors.WHITE}
              onPress={onRemove}
            />
          </Row>
        </>
      )}
      <Spacer size={326} />
    </ScrollView>
  );
};

const withForceReload = (Component: React.FC<Props>) =>
  function WithForceReload(props: Props) {
    const [reload, setReload] = React.useState(false);

    React.useEffect(() => {
      window.setTimeout(() => setReload(false), 100);

      return () => {
        setReload(true);
      };
    }, [props.serviceId, props.memberships.length, props.serviceIndex]);

    return reload ? null : <Component {...props} />;
  };

export default pipe(Service, withForceReload);
