import { ClientId } from '@mero/api-sdk';
import { PageClientStats } from '@mero/api-sdk/dist/clients/page-client-stats';
import { PageId } from '@mero/api-sdk/dist/pages';
import { Column, useShowError } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';

import { LoadingComponent } from '../../../components/LoadingComponent';

import { meroApi } from '../../../contexts/AuthContext';
import { ClientDetailsContext } from '../../../contexts/ClientDetailsContext';
import { withClientHistoryNoteCreateContextProvider } from '../../../contexts/ClientHistoryNoteAddContext';
import { withClientHistoryNoteDeleteContextProvider } from '../../../contexts/ClientHistoryNoteDeleteContext';
import { withClientHistoryNoteUpdateContextProvider } from '../../../contexts/ClientHistoryNoteUpdateContext';
import log from '../../../utils/log';
import ClientInfo from './ClientInfo';

type Props = {
  clientId: ClientId;
  pageId: PageId;
  onClientRemove: () => void;
};

const Client: React.FC<Props> = ({ clientId, pageId, onClientRemove }) => {
  const showError = useShowError();

  const [state] = ClientDetailsContext.useContext();

  const [reports, setReports] = React.useState<PageClientStats<MeroUnits.Any>>();

  const getReportsData = async () => {
    try {
      const reports = await meroApi.clients.getPageClientStats({
        pageId,
        clientId,
        currency: 'RON',
      });
      setReports(reports);
    } catch (error) {
      log.error('Failed to get reports data', error);
      showError(error);
    }
  };

  React.useEffect(() => {
    getReportsData();
  }, []);

  switch (state.type) {
    case 'New':
      return null;
    case 'Loading':
      return (
        <Column style={{ position: 'relative', flex: 1 }}>
          <LoadingComponent />
        </Column>
      );
    case 'Loaded':
      return reports ? (
        <ClientInfo
          size="small"
          {...state}
          clientId={clientId}
          pageId={pageId}
          reports={reports}
          onClientRemove={onClientRemove}
        />
      ) : null;
  }
};

export default pipe(
  Client,
  withClientHistoryNoteUpdateContextProvider,
  withClientHistoryNoteCreateContextProvider,
  withClientHistoryNoteDeleteContextProvider,
);
