import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { Platform } from 'react-native';

import ActivateOnlinePayment from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/ActivateOnlinePaymentsScreen';

import {
  getStateFromPath,
  LinkingOptions,
  NavigationState,
  PartialState,
  PathConfigMap,
} from '@react-navigation/native';

import config from '../config';
import { RootStackParamList } from '../types';
import log from '../utils/log';

const REDIRECTS: Record<string, string> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  '/abonament': '/page/current/subscription',
  '/rapoarte': Platform.OS === 'web' ? 'page/current/analytics' : 'page/current/reports',
  '/page/current/shop/category': '/page/current/shop',
  '/page/current/shop/category/subcategory': '/page/current/shop',
  '/page/current/shop/filters': '/page/current/shop',
  '/page/current/shop/sort': '/page/current/shop',
  '/page/current/shop/barber-store': '/page/current/shop',
  '/page/current/notification-settings/manual-methods': '/page/current/notification-settings',
  '/checkout/add-proceed/select-payment': '/checkout',
  '/checkout/add-proceed/payment': '/checkout',
  '/checkout/add-proceed/company': '/checkout',
  '/checkout/add-proceed/success': '/checkout',
  /* eslint-enable @typescript-eslint/naming-convention */
};

const getUrlByPlatform = (data?: { webLinkUrl?: string; appLinkUrl?: string; url?: string }) => {
  if (!data) {
    return undefined;
  }

  if (Platform.OS === 'web') {
    return data.webLinkUrl ?? data.url;
  }

  return data.appLinkUrl ?? data.url;
};

const LinkingConfiguration: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/'), ...config.linkingPrefixes],
  config: {
    screens: {
      Index: '/',
      Open: 'open',
      Anonymous: {
        screens: {
          SignInScreen: 'onboarding/cont-existent',
          SignInPhoneScreen: 'onboarding/cont-existent/telefon',
          SignInOtpScreen: 'onboarding/cont-existent/cod-sms',
          SignUpScreen: 'onboarding/cont-nou',
          SignUpPhoneScreen: 'onboarding/cont-nou/telefon',
          SignUpOtpScreen: 'onboarding/cont-nou/cod-sms',
        },
      },
      Authorized: {
        screens: {
          Home: {
            screens: {
              HomeTabs: {
                screens: {
                  CalendarTab: {
                    screens: {
                      CalendarScreen: 'calendar',
                    },
                  },
                  ClientsTab: {
                    screens: {
                      ClientsScreen: 'clients',
                    },
                  },
                  CheckoutTab: {
                    screens: {
                      CheckoutTabScreen: 'checkout',
                    },
                  },
                  CashRegistryScreen: 'checkout/cash-registry',
                  NotificationsTab: {
                    screens: {
                      NotificationsScreen: 'notifications',
                    },
                  },
                  ReportsTab: {
                    screens: {
                      ReportsScreen: 'reports',
                    },
                  },
                  ProductsTab: {
                    screens: {
                      ProductsMenuScreen: 'products-menu',
                      ProductsDashboardScreen: 'products',
                      ProductSearchScreen: 'products/search',
                      CategoryProductsListScreen: '/categories/:category',
                      BrandProductsListScreen: '/brands/:brandId',
                    },
                  },
                  ActivateOnlinePayments: 'page/current/online-payments/activate',
                  ServicesTab: {
                    screens: {
                      ServicesWeb: 'services-dashboard',
                      ServicesMobile: 'services',
                    },
                  },
                  ProsTab: {
                    screens: {
                      ProsWeb: 'pros-dashboard',
                      ProsMobile: 'pros',
                    },
                  },
                  MenuTab: {
                    screens: {
                      MenuScreen: 'menu',
                      PageReportsScreen: 'page/current/analytics',
                    },
                  },
                },
              },
            },
          },
          NoAccess: 'no-access',
          Onboarding: {
            screens: {
              OnboardingScreen: 'onboarding/configureaza',
              OnboardingQuestion1Screen: 'onboarding/q1',
              OnboardingQuestion2Screen: 'onboarding/q2',
              OnboardingQuestion3Screen: 'onboarding/q3',
              OnboardingSuccessScreen: 'onboarding/cont/sumar',
            },
          },
          Booking: {
            screens: {
              AppointmentScreen: 'appointments/:appointmentId?',
              BookingCreateScreen: 'add-appointment',
              SelectNewBookingClientScreen: 'add-appointment/select-client',
              SelectNewBookingServiceScreen: 'add-appointment/select-service',
              SelectBookingPerformerScreen: 'add-appointment/select-performer',
              SelectBookingService: {
                screens: {
                  SelectBookingServiceScreen:
                    'calendar/:calendarId/appointments/:calendarEntryId/:occurrenceIndex/select-service',
                },
              },
              BookingDetailsScreen: 'calendar/appointments/:calendarEntryId/:occurrenceIndex',
              ClientFeedbackScreen:
                'calendar/:calendarId/appointments/:calendarEntryId/:occurrenceIndex/feedback/:clientFeedbackId',
              BookingEditScreen: 'calendar/:calendarId/appointments/:calendarEntryId/:occurrenceIndex/edit',
              BlockedTimeCreateScreen: 'calendar/blocked-time',
              BlockedTimeEditScreen: 'calendar/:calendarId/blocked-time/:calendarEntryId',
            },
          },
          ClientDetails: {
            screens: {
              DetailsScreen: 'page/:pageId/clients/:clientId',
              MembershipDetailsScreen: 'page/:pageId/clients/:clientId/memberships/:membershipPurchaseId',
            },
          },
          NotificationsOptionsScreen: '/notifications-options',
          ClientSalesReportScreen: 'page/:pageId/clients/:clientId/sales-stats',
          ClientEditScreen: 'page/:pageId/clients/:clientId/edit',
          NewClientScreen: 'clients/add-client',
          ImportContactsScreen: 'clients/import-contacts',
          TransactionScreen: `/cash-registry/transaction/:transactionId`,
          ProductEdit: {
            screens: {
              ProductScreen: '/products/product/:productId?',
              ProductDescriptionScreen: '/products/product/:productId/description',
              ProductGalleryScreen: '/products/product/:productId/gallery',
              ProductImageMenu: '/products/product/:productId/gallery/:imageId',
              ProductMenu: '/products/product/:productId/menu',
              ProductPrice: '/products/product/:productId/price',
              ProductStock: '/products/product/:productId/inventories',
            },
          },
          DeleteBrandScreen: '/products/brand/:brandId/delete',
          ProductBrandScreen: '/products/brand/:brandId?',
          ProductCategoryScreen: '/products/category/:categoryId?',
          DeleteCategoryScreen: '/products/category/:categoryId/delete',
          DeleteProductScreen: '/products/:productId/delete',
          ImportProductsScreen: '/products/import',
          SortServices: 'services/sort',
          Service: 'services/:serviceId',
          SelectPro: 'services/:serviceId/select-pro',
          Settings: 'services/:serviceId/settings',
          ServicesBulkOperations: 'services/bulk-operations',
          ProsEdit: {
            screens: {
              SortPros: 'pros/sort',
              ProDetails: 'pros/:userId',
              ProProfile: 'pros/:userId/profile',
              ProCalendar: 'pros/:userId/calendar',
              ProSchedule: 'pros/:workerId/schedule',
              ProServices: 'pros/:workerId/services',
              ProPermissions: 'pros/:userId/permissions',
              ProServicesSelect: 'pros/:workerId/services/select',
              ProEditService: 'pros/:workerId/services/:serviceId/edit',
              ProEditServiceSettings: 'pros/:workerId/services/:serviceId/edit/settings',
              ProInviteStack: {
                screens: {
                  ProInvite: 'pros/invite',
                  ProInviteServices: 'pros/invite/services',
                  ProInvitePermissions: 'pros/invite/permissions',
                  ProInviteSuccess: 'pros/invite/success',
                },
              },
              ProAddNewStack: {
                screens: {
                  ProAddNew: 'pros/new',
                  ProAddNewPermissions: 'pros/new/permissions',
                  ProAddNewCalendar: 'pros/new/calendar',
                  ProAddNewServices: 'pros/new/services',
                  ProAddNewServicesSelect: 'pros/new/services/select',
                  ProAddNewEditService: 'pros/new/services/:serviceId/edit',
                  ProAddNewEditServiceSettings: 'pros/new/services/:serviceId/edit/settings',
                  ProAddNewSchedule: 'pros/new/schedule',
                },
              },
            },
          },
          CombineCheckout: {
            screens: {
              CheckoutStack: {
                screens: {
                  AddProceedScreen: 'checkout/add-proceed/:transactionId?',
                  SelectClientScreen: 'checkout/add-proceed/select-client',
                  SelectBookingScreen: 'checkout/add-proceed/select-booking',
                  SelectPaymentScreen: 'checkout/add-proceed/select-payment',
                  PaymentScreen: 'checkout/add-proceed/payment',
                  AddProceedSuccessScreen: 'checkout/add-proceed/success',
                  CompanyScreen: 'checkout/add-proceed/company',
                  ProceedDetailsScreen: 'checkout/details/:checkoutTransactionId',
                  AllDraftsScreen: 'checkout/drafts',
                  DeleteDraftsScreen: 'checkout/drafts/delete',
                },
              },
              CheckoutSubStack: {
                screens: {
                  SelectClientScreen: 'checkout/add-proceed/:transactionId?/select-client',
                  SelectBookingScreen: 'checkout/add-proceed/:transactionId?/select-booking',
                  SelectPaymentScreen: 'checkout/add-proceed/:transactionId?/select-payment',
                  SelectMembershipPaymentScreen: 'checkout/add-proceed/:transactionId?/select-membership-payment',
                  SelectMembershipInstallmentScreen:
                    'checkout/add-proceed/:transactionId?/select-membership-installment',
                  EditMembershipPaymentScreen: 'checkout/add-proceed/:transactionId?/edit-membership-payment',
                  SelectServiceScreen: 'checkout/add-proceed/:transactionId?/select-service',
                  SelectProductScreen: 'checkout/add-proceed/:transactionId?/select-product',
                  SelectMembershipScreen: 'checkout/add-proceed/:transactionId?/select-membership',
                  EditMembershipScreen: 'checkout/add-proceed/:transactionId?/edit-membership',
                },
              },
              CheckoutMembershipStack: {
                screens: {
                  ClientMembershipDetailsScreen: 'checkout/memberships/:membershipPurchaseId',
                },
              },
            },
          },
          Menu: {
            screens: {
              MarketingSettingsScreen: {
                screens: {
                  MarketingSettings: 'page/current/marketing',
                  Boost: 'page/current/marketing/boost',
                  ConfigureBoost: 'page/current/marketing/boost/configure',
                  BoostMarketing: 'page/current/marketing/boost/marketing',
                },
              },
              LocationProfileScreen: {
                screens: {
                  LocationMenu: 'page/current/location',
                  LocationProfile: 'page/current/location/profile',
                  LocationGallerySettingsScreen: {
                    screens: {
                      PageGallerySettings: 'page/current/location/gallery',
                      PageGalleryOrder: 'page/current/location/gallery/order',
                      PageMultipleDelete: 'page/current/location/gallery/multiple-delete',
                      SelectedImageMenu: '/page/current/gallery/:imageId',
                      AttachedServices: '/page/current/gallery/:imageId/services',
                    },
                  },
                  LocationAddress: 'page/current/location/address',
                  LocationSchedule: 'page/current/location/schedule',
                  OnlineLink: 'page/current/location/online-link',
                  DeleteLocation: 'page/current/location/delete',
                },
              },
              PagePermissionsSettingsScreen: 'page/current/permissions',
              PageSubscriptionSettingsScreen: {
                screens: {
                  Subscription: 'page/current/subscription',
                  BillingCycleList: 'page/current/subscription/billing-cycle',
                  BillingDetailsList: 'page/current/subscription/billings',
                  BillingDetails: 'page/current/subscription/billings/:type/:billingId?',
                  PaymentMethod: 'page/current/subscription/payment',
                  CardPayment: 'page/current/subscription/card-payment',
                },
              },
              PageCheckoutSettingsScreen: {
                screens: {
                  CheckoutSettings: 'page/current/checkout',
                  BillingDetailsList: 'page/current/checkout/billings',
                  BillingDetails: 'page/current/checkout/billings/:type/:billingId?',
                  CompanyScreen: 'page/current/checkout/company/:companyId?',
                },
              },
              PageMembershipSettingsScreen: {
                screens: {
                  MembershipSettings: 'page/current/membership',
                  MembershipDetails: 'page/current/checkout/membership/:membershipId?',
                  MembershipItems: 'page/current/checkout/membership/:membershipId?/items',
                  SelectMembershipService: 'page/current/checkout/membership/:membershipId?/select-service',
                },
              },
              PageReportsMobileScreen: 'page/current/reports',
              PagePendingClientsScreen: 'page/current/appointment-opening-interests',
              PageReviewsScreen: {
                screens: {
                  Reviews: 'page/current/reviews',
                  Settings: 'page/current/reviews/settings',
                },
              },
              PageOnlinePaymentsScreen: {
                screens: {
                  PageOnlinePaymentsSettings: 'page/current/online-payments',
                  PageOnlinePaymentsBalance: 'page/current/online-payments/account-balance',
                  BillingDetailsList: 'page/current/online-payments/billings',
                  BillingDetails: 'page/current/online-payments/billings/:type/:billingId?',
                  PageOnlinePaymentsBillingDetails: 'page/current/online-payments/details',
                  PageOnlinePaymentsAdvancedPayment: 'page/current/online-payments/advanced-payment',
                  PageOnlinePaymentsServiceSettings: 'page/current/online-payments/advanced-payment/services',
                  PageOnlinePaymentsTermsAndConditions: 'page/current/online-payments/terms-and-conditions',
                  PageOnlinePaymentsStripe: 'page/current/online-payments/stripe',
                  PageOnlinePaymentsSchedule: 'page/current/online-payments/schedule',
                  PageOnlinePaymentsAppointment: 'page/current/online-payments/appointment-payments',
                  PageGiftCardsSettingsScreen: {
                    screens: {
                      GiftCards: 'page/current/gift-cards',
                      GiftCardDetails: 'page/current/gift-cards/details',
                    },
                  },
                },
              },
              PageNotificationsSettingsScreen: {
                screens: {
                  NotificationsSettings: 'page/current/notification-settings',
                  NotificationsSettingsManualMethod: 'page/current/notification-settings/manual-methods',
                  NotificationsMessagesFormat: 'page/current/notification-settings/messages',
                  NotificationsEditTemplate: 'page/current/notification-settings/messages/:type/:id?',
                },
              },
              WorkerCalendarSettingsScreen: 'page/current/workers/:workerId/calendar-settings',
              WorkerNotificationsSettingsScreen: 'page/current/workers/:workerId/appointment-reminders',
              WorkerProfileSettingsScreen: 'page/current/workers/:workerId/edit',
              WorkerScheduleSettingsScreen: 'page/current/workers/:workerId/business-hours',
              WorkerServicesSettingsScreen: {
                screens: {
                  WorkerServices: 'page/current/workers/:workerId/services',
                  WorkerServiceDetails: 'page/current/workers/:workerId/services/:serviceId',
                  WorkerServiceSettings: 'page/current/workers/:workerId/services/:serviceId/settings',
                },
              },
              ShopScreen: {
                screens: {
                  ShopCategories: 'page/current/shop',
                  ShopProducts: 'page/current/shop/category',
                  ShopProductsSubcategory: 'page/current/shop/category/subcategory',
                  ShopFilters: 'page/current/shop/filters',
                  ShopSort: 'page/current/shop/sort',
                  BarberStore: 'page/current/shop/barber-store',
                },
              },
              SharePageProfileScreen: 'page/current/share',
              HelpCenterScreen: 'centru-de-ajutor',
              ContactSupportScreen: 'contact-support',
            },
          },
          SelectAccount: 'select-account',
          CashRegistryCompanyMenu: 'checkout/cash-registry/companies',
          CashRegistryOptionsMenu: 'checkout/cash-registry/options',
          InitialBalance: 'checkout/cash-registry/initial-balance',
          CreateLocation: 'create-location',
          OnlineLink: 'online-link',
          ManualSmsReminder: 'manual-sms-reminder',
          PendingList: 'pending-list',
          MonthlyReports: 'r/:shortUrl',
          CustomWebview: 'webview',
          ChangePhone: {
            screens: {
              ChangePhoneNumber: '/page/current/change-phone',
              Otp: '/page/current/change-phone/otp',
            },
          },
          ChatRedirectScreen: 'chat-asistenta',
        },
      },
      TermsOfServiceScreen: 'termeni-si-conditii',
      PrivacyPolicyScreen: 'confidentialitate',
      ImpersonateScreen: 'impersonate',
      ManagementRedirectScreen: 'management',
      ManagementChildRedirectScreen: 'management/*',
      NotFound: '*',
    },
  },
  subscribe: (listener: (url: string) => void): (() => void) => {
    const onReceiveURL = ({ url }: { url: string }) => {
      log.debug('onReceiveUrl', url);
      listener(url);
    };

    const onReceiveSubscription = Linking.addEventListener('url', onReceiveURL);

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener((response) => {
      try {
        log.debug('Got notification', JSON.stringify(response));

        const url: string | undefined = pipe(response.notification.request, (request): string | undefined => {
          // "request.content.data" field happens to may be null!!! although defined required in type specs
          if (t.string.is(request.content.data?.url)) {
            return getUrlByPlatform(request.content.data);
          } else if (request.trigger && request.trigger.type === 'push') {
            log.debug('got trigger.type === push');
            if ('remoteMessage' in request.trigger) {
              if (t.string.is(request.trigger.remoteMessage?.data?.url)) {
                return getUrlByPlatform(request.trigger.remoteMessage?.data);
              }
            } else if ('payload' in request.trigger) {
              if (t.string.is(request.trigger.payload?.url)) {
                return getUrlByPlatform(request.trigger.payload);
              }
            }
          }

          return undefined;
        });

        if (url !== undefined) {
          log.debug('Got notification with url', url);
          listener(url);
        }
      } catch (e) {
        log.exception(e);
      }
    });

    return () => {
      // Clean up the event listeners
      onReceiveSubscription.remove();
      subscription.remove();
    };
  },
  getStateFromPath: (path: string, options?: Options): ResultState | undefined => {
    if (path.startsWith('expo-auth-session') || path.startsWith('/#state')) {
      // Expo Linking doesn't seem to catch links with "#", treating it as part of the segment or Google redirects
      return getStateFromPath('/', options);
    }

    const pathWithoutHash = path.split('?')[0];

    if (REDIRECTS[pathWithoutHash]) {
      return getStateFromPath(REDIRECTS[pathWithoutHash], options);
    }

    return getStateFromPath(path, options);
  },
  getInitialURL: async () => {
    const initialUrl = await Linking.getInitialURL();
    if (initialUrl && (initialUrl.startsWith('expo-auth-session') || initialUrl.startsWith('/#state'))) {
      return null;
    }

    return initialUrl;
  },
};

export default LinkingConfiguration;

declare type Options = {
  initialRouteName?: string;
  screens: PathConfigMap<object>;
};

declare type ResultState = PartialState<NavigationState> & {
  state?: ResultState;
};
