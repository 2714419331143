import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { Avatar, Body, colors, Column, Icon as MeroIcon, Row } from '@mero/components';
import { flow, pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

import AdvancedSelect, { Ref as AdvanceSelectRef } from '../../../components/AdvancedSelect';
import WorkerListItem from '../../../components/WorkerListItem';

import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { SelectBookingPerformerContext } from '../../../contexts/SelectBookingPerformerContext';
import { nullish } from '../../../utils/function';
import { nameGenerator } from '../../../utils/string';
import { withHoverState } from './HoverState';

const Icon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G transform="translate(0 -.5)">
      <Circle cx={12} cy={12} r={12} fill="#e9ecef" transform="translate(0 .5)" />
      <Path
        fill="rgba(82,87,127,0.5)"
        d="M8 17.5a1 1 0 0 1-1-1v-.435a2.4 2.4 0 0 1 1.959-1.753c1.689-.287 1.689-.8 1.689-1.116v-.51a3.125 3.125 0 0 1-1.013-2.36V8.8A2.2 2.2 0 0 1 12 6.5a2.2 2.2 0 0 1 2.365 2.3v1.53a3.122 3.122 0 0 1-1.013 2.36v.51c0 .319 0 .813 1.689 1.116A2.427 2.427 0 0 1 17 16.065v.435a1 1 0 0 1-1 1Z"
        data-name="Union 5"
      />
    </G>
  </Svg>
);

const DownArrow = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6963">
      <Path fill="#080de0" d="m6.257 9.407 1.385-1.385 4.489 4.569 4.485-4.569L18 9.407l-5.869 5.954Z" />
      <Path fill="none" d="M24 0v24H0V0z" data-name="Rectangle 2" />
    </G>
  </Svg>
);

type HeaderProps = {
  isHovered: boolean;
  isOpen: boolean;
  t: (key: string) => string;
  selected?: SavedWorker;
};

const Header: React.FC<HeaderProps> = ({ isHovered, isOpen, t, selected }) => (
  <Column
    style={[
      selected && isHovered && !isOpen ? { backgroundColor: colors.ALABASTER } : null,
      { margin: 8, borderRadius: 6, paddingVertical: 6, paddingLeft: 6, paddingRight: 4 },
    ]}
  >
    <Row>
      <Column style={{ paddingRight: 12 }}>
        {selected ? (
          <Avatar
            firstname={selected.user.firstname ?? ''}
            lastname={selected.user.lastname ?? ''}
            source={selected.profilePhoto?.thumbnail}
            size={24}
          />
        ) : (
          <Icon />
        )}
      </Column>
      <Body style={{ flex: 1 }}>{selected ? nameGenerator(selected.user, t('noName')) : t('selectPerformer')}</Body>
      {isOpen ? <DownArrow /> : <MeroIcon type="arrow-right" />}
    </Row>
  </Column>
);

const WithHover = withHoverState(Header);

export type Props = CurrentBusinessProps & AuthorizedProps & { disabled?: boolean };

const SelectPerformer: React.FC<Props> = ({ page, disabled = false }) => {
  const advancedSelectRef = React.useRef<AdvanceSelectRef>(null);

  const { t } = useTranslation('booking');

  const [state, { setSelectedPerformer }] = SelectBookingPerformerContext.useContext();

  const [workers, setWorkers] = React.useState<SavedWorker[]>(page.workers);

  const selectWorkerCallback = React.useCallback(
    (selected: SavedWorker) => {
      setSelectedPerformer(selected);
    },
    [setSelectedPerformer],
  );

  const onSearch = React.useCallback((query: string) => {
    setWorkers(
      page.workers.filter((worker) =>
        `${worker.user.firstname} ${worker.user.lastname}`.toLowerCase().includes(query.toLowerCase()),
      ),
    );
  }, []);

  return (
    <>
      <AdvancedSelect
        ref={advancedSelectRef}
        Header={({ isOpen }) => (
          <WithHover
            isHovered={false}
            isOpen={isOpen}
            t={t}
            selected={state.type === 'selected' ? state.selected : undefined}
          />
        )}
        placeholder={t('searchPerformerPlaceholder')}
        disabled={disabled}
        hasValue={state.type === 'selected'}
        onSearch={onSearch}
        data={workers}
        onClose={() => onSearch('')}
        Item={(props) => {
          return (
            <WorkerListItem
              worker={props.item}
              onPress={flow(() => selectWorkerCallback(props.item), advancedSelectRef.current?.onHide ?? nullish)}
              itemStyle={{ paddingHorizontal: 16 }}
            />
          );
        }}
      />
    </>
  );
};

export default pipe(SelectPerformer, CurrentBusiness, Authorized);
