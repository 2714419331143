import { AvailableServiceItem } from '../screens/Authorized/CheckoutScreen/SelectMembership';

import { UiKey } from '../contexts/CheckoutFormContext';

export const BACK_REF = 'https://pro1.mero.ro/404?hideLayout=true';

export const distributeAmount = (
  amount: number,
  amountUsed: number,
  totalSlots: number,
  position: number,
  exclude: number[],
) => {
  const remainingAmount = amount - amountUsed;
  const remainingSlots = Math.max(totalSlots - 1 - exclude.length, 1);

  const amountPerSlot = remainingAmount / remainingSlots;
  // const remainder = remainingAmount % remainingSlots;

  // const distributeRemainder = (index: number) => (index < remainder ? 1 : 0);

  return Array.from({ length: totalSlots }, (_, index) =>
    exclude.includes(index) ? 0 : index === position ? amountUsed : amountPerSlot,
  );
};

export const findAllIndexes = <T>(array: T[], condition: (element: T) => boolean): number[] => {
  return array.reduce((acc, element, index) => {
    if (condition(element)) {
      return [...acc, index];
    }
    return acc;
  }, [] as number[]);
};

export const getMembershipQuantity = (quantity: AvailableServiceItem['availableQuantity']): number => {
  if (quantity.type === 'Unlimited') {
    return Infinity;
  }

  return quantity.remaining;
};

export const uiKeyToTransactionItemId = <T extends { uiKey: UiKey }>(item: T) => ({
  ...item,
  transactionItemId: item.uiKey,
});
