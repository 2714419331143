import { FullnameFromString, Lastname } from '@mero/api-sdk';
import {
  Column,
  H3s,
  sizes,
  Spacer,
  styles as meroStyles,
  Switch,
  TextInput,
  SmallBody,
  colors,
} from '@mero/components';
import { Firstname } from '@mero/shared-sdk';
import { StrictPhoneNumberParsed } from '@mero/shared-sdk/dist/common';
import { isRight } from 'fp-ts/lib/Either';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useSafeInput } from '../../../hooks/useSafeInput';

import { BookingClient } from '../../../contexts/BookingFormContext';
import SelectClient from './SelectClient';

type Props = {
  showErrors: boolean;
  onClientChange: (client: BookingClient) => void;
};

const ClientDetails: React.FC<Props> = ({ showErrors, onClientChange }) => {
  const { t } = useTranslation('clients');

  const [clientName, setClientName] = useSafeInput(NonEmptyString)();
  const [phone, setPhone] = useSafeInput(StrictPhoneNumberParsed)();

  const [client, setClient] = React.useState<BookingClient>({
    type: 'none',
  });

  const setClientCallback = (client: BookingClient) => {
    setClient(client);
    onClientChange(client);
  };

  React.useEffect(() => {
    if (client.type !== 'new') {
      return;
    }

    const clientFormIsEmpty = (clientName.value ?? '').trim() === '' && (phone.value ?? '').trim() === '';

    if (clientName.isValid && phone.isValid) {
      const decodedClientName = FullnameFromString.decode(clientName.value);
      const { firstname, lastname } = isRight(decodedClientName)
        ? decodedClientName.right
        : { firstname: '', lastname: '' };
      setClientCallback({
        type: 'new',
        isValid: true,
        isEmpty: false,
        fullname: clientName.value,
        firstname: firstname as Firstname,
        lastname: lastname as Lastname,
        phone: phone.value,
      });
    } else if (clientFormIsEmpty) {
      setClientCallback({
        type: 'new',
        isValid: true,
        isEmpty: true,
      });
    } else {
      setClientCallback({
        type: 'new',
        isValid: false,
        isEmpty: false,
        fullname: clientName.value,
        phone: phone.value,
      });
    }
  }, [clientName, phone]);

  const getContent = React.useCallback(() => {
    switch (client.type) {
      case 'none':
        return (
          <>
            <Spacer size={16} />
            <SelectClient onClientSelected={(client) => setClientCallback({ type: 'existing', client })} />
          </>
        );
      case 'new': {
        return (
          <>
            {client?.type === 'new' && (
              <>
                <Spacer size="16" />
                <TextInput
                  value={clientName.value}
                  onChange={setClientName}
                  placeholder={t('clientName')}
                  textContentType="name"
                  isError={showErrors && !clientName.isValid}
                />
                {showErrors && !clientName.isValid && (
                  <>
                    <Spacer size="4" />
                    <SmallBody style={{ color: colors.RADICAL_RED }}>{t('lastNameError')}</SmallBody>
                  </>
                )}
                <Spacer size="8" />
                <TextInput
                  value={phone.value}
                  onChange={setPhone}
                  placeholder={t('clientPhone')}
                  textContentType="telephoneNumber"
                  isError={showErrors && !phone.isValid}
                />
                {showErrors && !phone.isValid && (
                  <>
                    <Spacer size="4" />
                    <SmallBody style={{ color: colors.RADICAL_RED }}>{t('mobilePhoneError')}</SmallBody>
                  </>
                )}
                <Spacer size="16" />
              </>
            )}
          </>
        );
      }
      default:
        return null;
    }
  }, [client.type, showErrors, clientName, phone]);

  return (
    <Column style={{ width: '100%', zIndex: 1 }}>
      <H3s>{t('selectClient')}</H3s>
      <Spacer size={16} />
      <Switch
        height={sizes[32]}
        textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
        buttons={ClientTabsOptions.map((tab) => ({ ...tab, label: t(tab.label) }))}
        defaultValue={client?.type === 'none' ? 'existing' : 'new'}
        onChange={(value) => {
          if (value === 'existing') {
            setClientCallback({
              type: 'none',
            });
          } else if (value === 'new') {
            setClientCallback({
              type: 'new',
              isValid: true,
              isEmpty: true,
            });
          }
        }}
      />
      {getContent()}
    </Column>
  );
};

const ClientTabs = {
  new: { label: 'newClient', value: 'new' },
  existing: { label: 'existingClient', value: 'existing' },
} as const;

export type ClientTabs = keyof typeof ClientTabs;

const ClientTabsOptions = [ClientTabs.existing, ClientTabs.new];

export default ClientDetails;
