import { CalendarEntryDetails } from '@mero/api-sdk';
import { AppointmentId } from '@mero/api-sdk/dist/calendar';
import { AppointmentHistoryRecord } from '@mero/api-sdk/dist/calendar/appointment-history-record';
import {
  Body,
  colors,
  Column,
  FormCard,
  H1,
  H2,
  H3s,
  Row,
  SmallBody,
  Spacer,
  styles as meroStyles,
  TextInput,
  Title,
} from '@mero/components';
import { capitalize } from '@mero/shared-sdk/dist/common';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { FlashyLabel } from '../../../components/FlashyLabel';
import HSpacer from '@mero/components/lib/components/HSpacer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import RecurrenceText from '@mero/components/lib/components/Mero/RecurrenceText';
import { formatTimeDiff } from '@mero/shared-components/dist/utils/format';

import { ComputedInputState } from '../../../hooks/useSafeInput';

import {
  AppointmentService,
  BookingClient,
  BookingFormContext,
  ConsumedProduct,
  convertProductToConsumedProduct,
} from '../../../contexts/BookingFormContext';
import { CurrentPageInfo } from '../../../contexts/CurrentBusiness';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { national } from '../../../utils/phone';
import { scaledToString } from '../../../utils/scaled';
import { ServicePrice } from '../../../utils/servicePrice';
import ClientDetails from './ClientDetails';
import ClientListItem from './ClientListItem';
import { GiftCardIcon, HistoryIcon, NotesIcon, RecurenceIcon } from './Icons';
import ProductListItem from './ProductListItem';
import RecurrenceListItem from './RecurrenceListItem';
import SelectPerformer from './SelectPerformer';
import SelectProducts from './SelectProducts';
import SelectService from './SelectService';
import ServiceListItem from './ServiceListItem';
import { BookedServiceWithWorkerItem, MembershipServices, OptionalNotes, ProductPrice } from './common';

type Props = {
  page: CurrentPageInfo;
  appointmentId?: AppointmentId;
  existingAppointment?: CalendarEntryDetails.Appointment;
  hasPassed: boolean;
  startDateTime: DateTime;
  canEdit: CalendarEntryDetails.AppointmentEditableStatus.Editable['fields'];
  nonEmptyServices: BookedServiceWithWorkerItem[];
  usableMemberships: MembershipServices[];
  canAddMoreServices: boolean;
  showErrors: boolean;
  showProducts: boolean;
  hasNotesOrRecurrence: boolean;
  notesInputVisible: boolean;
  notesValue: ComputedInputState<OptionalNotes>;
  history: AppointmentHistoryRecord[];
  checkClientSelected: () => boolean;
  checkServiceSelected: (idx: number) => boolean;
  checkProductSelected: (idx: number) => boolean;
  servicesPrice: ServicePrice[];
  productsPrice: ProductPrice[];
  onClientPress: () => void;
  onClientChange: (client: BookingClient) => void;
  onClientRemove: () => void;
  onSelectService: (payload: { service: AppointmentService; index: number }) => void;
  onRemoveService: (payload: { service: unknown; index: number }) => void;
  onSelectProduct: (payload: { product: ConsumedProduct; index: number }) => void;
  onRemoveProduct: (payload: { product: unknown; index: number }) => void;
  setNotesValue: (value: string) => void;
  editRecurrenceRule: () => void;
  setShowNotesClicked: (value: boolean) => void;
};

const AppointmentDetails: React.FC<Props> = ({
  page,
  appointmentId,
  existingAppointment,
  hasPassed,
  startDateTime,
  canEdit,
  onClientRemove,
  onClientChange,
  nonEmptyServices,
  usableMemberships,
  canAddMoreServices,
  showErrors,
  showProducts,
  hasNotesOrRecurrence,
  notesInputVisible,
  notesValue,
  history,
  checkClientSelected,
  checkServiceSelected,
  checkProductSelected,
  onClientPress,
  servicesPrice,
  productsPrice,
  onSelectService,
  onRemoveService,
  onSelectProduct,
  onRemoveProduct,
  setNotesValue,
  editRecurrenceRule,
  setShowNotesClicked,
}) => {
  const i18n = useTranslation('booking');

  const [formState, { addProduct }] = BookingFormContext.useContext();
  const [productsState] = SearchProductsContext.useContext();

  const { client, memberships, performer, products, recurrenceRule } = formState;

  const status = React.useMemo(() => {
    if (existingAppointment) {
      switch (existingAppointment.payload.status) {
        case 'pending':
          return {
            text: i18n.t('pending'),
            type: 'custom',
            backgroundColor: colors.WHITE,
            color: colors.OUTRAGEOUS_ORANGE,
            style: { borderWidth: 1, borderColor: colors.OUTRAGEOUS_ORANGE },
          } as const;
        case 'accepted':
          return {
            text: hasPassed ? i18n.t('finalised') : i18n.t('accepted'),
            type: hasPassed ? 'info' : 'success',
          } as const;
        case 'noShow':
          return {
            text: i18n.t('noShow'),
            type: 'warning',
          } as const;
        case 'cancelled':
          return {
            text: i18n.t('cancelled'),
            type: 'error',
          } as const;
        case 'rejected':
          return {
            text: i18n.t('rejected'),
            type: 'error',
          } as const;
      }
    }
    return undefined;
  }, [existingAppointment, hasPassed]);

  const recurrenceRuleIsPresent = recurrenceRule !== undefined;

  /**
   * History handling
   */
  const generateHistoryText = React.useCallback((history: AppointmentHistoryRecord) => {
    switch (history.type) {
      case 'appointmentCreatedByClient':
        return (
          <Trans
            ns="booking"
            t={i18n.t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.client.firstname} ${history.payload.client.lastname}`,
              date: DateTime.fromJSDate(history.payload.start).setLocale('ro').toFormat('dd MMMM yyyy'),
              hour: DateTime.fromJSDate(history.payload.start).setLocale('ro').toFormat('HH:mm'),
              worker: `${history.payload.worker.firstname} ${history.payload.worker.lastname}`,
            }}
          >
            0<Title>1</Title>2<Title>3</Title>4<Title>5</Title>
          </Trans>
        );
      case 'appointmentCreatedByPro':
        return (
          <Trans
            ns="booking"
            t={i18n.t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
              date: DateTime.fromJSDate(history.payload.start).setLocale('ro').toFormat('dd MMMM yyyy'),
              hour: DateTime.fromJSDate(history.payload.start).setLocale('ro').toFormat('HH:mm'),
              worker: `${history.payload.worker.firstname} ${history.payload.worker.lastname}`,
            }}
          >
            <Title>0</Title>1<Title>2</Title>3<Title>4</Title>5<Title>6</Title>
          </Trans>
        );
      case 'appointmentCancelledByClient':
        return (
          <Trans
            ns="booking"
            t={i18n.t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.client.firstname} ${history.payload.client.lastname}`,
              reason: history.payload.reason
                ? i18n.t('withReason', { reason: history.payload.reason })
                : i18n.t('noReason'),
            }}
          >
            0<Title>1</Title>
          </Trans>
        );
      case 'appointmentCancelledByPro':
        return (
          <Trans
            ns="booking"
            t={i18n.t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
              reason: history.payload.reason
                ? i18n.t('withReason', { reason: history.payload.reason })
                : i18n.t('noReason'),
            }}
          >
            <Title>0</Title>1<Title>2</Title>
          </Trans>
        );
      case 'appointmentModifiedByPro':
        return (
          <Trans
            ns="booking"
            t={i18n.t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
              startDate: DateTime.fromJSDate(history.payload.start.from).setLocale('ro').toFormat('ccc dd MMMM yyyy'),
              endDate: DateTime.fromJSDate(history.payload.start.to).setLocale('ro').toFormat('ccc dd MMMM yyyy'),
              startHour: DateTime.fromJSDate(history.payload.start.from).setLocale('ro').toFormat('HH:mm'),
              endHour: DateTime.fromJSDate(history.payload.start.to).setLocale('ro').toFormat('HH:mm'),
            }}
          >
            <Title>0</Title>1<Title>2</Title>3<Title>4</Title>5<Title>6</Title>7<Title>8</Title>
          </Trans>
        );
      case 'appointmentConfirmed':
        return (
          <Trans
            ns="booking"
            t={i18n.t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
            }}
          >
            <Title>0</Title>1
          </Trans>
        );
      case 'appointmentRefused':
        return (
          <Trans
            ns="booking"
            t={i18n.t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
            }}
          >
            <Title>0</Title>1
          </Trans>
        );
      case 'appointmentMarkedAsNoShow':
        return (
          <Trans
            ns="booking"
            t={i18n.t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
            }}
          >
            <Title>0</Title>1<Title>2</Title>
          </Trans>
        );
    }
  }, []);

  const addProductCallback = React.useCallback((...args: Parameters<typeof convertProductToConsumedProduct>) => {
    addProduct(convertProductToConsumedProduct(...args));
  }, []);

  return (
    <>
      {appointmentId && existingAppointment ? (
        <>
          <Column style={{ paddingHorizontal: 16 }}>
            {status && <FlashyLabel {...status} />}
            <Spacer size={4} />
            <H1>{capitalize(startDateTime.toFormat('EEE d LLL - H:mm', { locale: 'ro' }))}</H1>
            {existingAppointment.recurrent ? (
              <>
                <Spacer size="8" />
                <RecurrenceText
                  recurrenceRule={existingAppointment.recurrent ? existingAppointment.recurrenceRule : undefined}
                  dot
                />
              </>
            ) : null}
          </Column>
        </>
      ) : null}

      <Column style={{ zIndex: 4 }}>
        {appointmentId ? <Spacer size={16} /> : null}
        {client.type === 'existing' ? (
          <Column style={{ paddingHorizontal: 16 }}>
            <ClientListItem
              isSelected={checkClientSelected()}
              disabled={!canEdit.client}
              onPress={onClientPress}
              client={client.client}
              onDelete={onClientRemove}
              isHovered={false}
            />
            <Spacer size={8} />
          </Column>
        ) : canEdit.client ? (
          <Column style={{ paddingHorizontal: 16 }}>
            <ClientDetails showErrors={showErrors} onClientChange={onClientChange} />
          </Column>
        ) : (
          <Column style={{ marginHorizontal: 16, paddingBottom: 8 }}>
            <Title>{i18n.t('noClientSelected')}</Title>
          </Column>
        )}
      </Column>
      <Spacer size={8} />
      <Spacer size={16} color={colors.ALABASTER} />
      <Column style={{ paddingHorizontal: 16, zIndex: 3 }}>
        <Spacer size="16" />
        <H3s>{!appointmentId ? i18n.t('selectServiceTitle') : i18n.t('services')}</H3s>
        <Spacer size="16" />
        {nonEmptyServices.map((row, idx) => (
          <React.Fragment key={`service-at-${idx}`}>
            <ServiceListItem
              disabled={!canEdit.services}
              isHovered={false}
              service={row.service}
              clientId={client?.type === 'existing' ? client.client._id : undefined}
              page={page.details}
              worker={row.worker}
              workerServices={row.workerServices}
              computedPrice={servicesPrice[idx]}
              onSelect={() => onSelectService({ service: row.service, index: idx })}
              onDelete={() => onRemoveService({ service: row, index: idx })}
              isSelected={checkServiceSelected(idx)}
              memberships={usableMemberships.filter((m) =>
                row.service.membershipIndex !== undefined
                  ? m._id === memberships[row.service.membershipIndex].membershipPurchaseId
                  : false,
              )}
            />
            {idx < nonEmptyServices.length - 1 && <Spacer size="16" />}
          </React.Fragment>
        ))}
        {canAddMoreServices && canEdit.services ? (
          <>
            {nonEmptyServices.length > 0 && <Spacer size="16" />}
            <SelectService
              clientId={client.type === 'existing' ? client.client._id : undefined}
              isMoreServices={nonEmptyServices.length > 0}
              workerId={performer?._id}
            />
            {showErrors && nonEmptyServices.length === 0 ? (
              <>
                <SmallBody style={{ color: colors.RADICAL_RED }}>{i18n.t('selectService')}</SmallBody>
              </>
            ) : null}
            <Spacer size="8" />
          </>
        ) : null}
        {nonEmptyServices.length > 0 && <Spacer size="16" />}
        {existingAppointment?.payload.coupons?.map((giftCard, idx) => (
          <React.Fragment key={giftCard._id}>
            <Row
              style={[
                {
                  paddingTop: 12,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 12,
                  borderWidth: 1,
                  borderColor: colors.GEYSER,
                  borderRadius: 6,
                  alignItems: 'center',
                },
              ]}
            >
              <GiftCardIcon />
              <Title style={{ flex: 1, paddingHorizontal: 12 }}>
                {i18n.t('giftCardTitle', { code: giftCard.code })}
              </Title>
              <Body>
                -{scaledToString(giftCard.value.amount)} {i18n.t(giftCard.value.unit)}
              </Body>
            </Row>
            <Spacer size="16" />
          </React.Fragment>
        ))}
        <SelectPerformer disabled={!canEdit.worker} />
        {showErrors && !performer ? (
          <>
            <SmallBody style={{ color: colors.RADICAL_RED }}>{i18n.t('selectAPro')}</SmallBody>
            <Spacer size="8" />
          </>
        ) : null}
        <Spacer size="8" />
      </Column>
      {showProducts && (
        <>
          <Column style={{ paddingHorizontal: 16, zIndex: 2 }}>
            <Spacer size="16" />
            <H3s>{!appointmentId ? i18n.t('selectProducts') : i18n.t('products')}</H3s>
            <Spacer size="16" />
            {products.length > 0 && (
              <>
                {products.map((product, idx) => (
                  <React.Fragment key={`product-at-${idx}`}>
                    <ProductListItem
                      //@TODO Replace this with products
                      disabled={!canEdit.services}
                      isHovered={false}
                      product={product.product}
                      computedPrice={productsPrice[idx]}
                      onSelect={() => onSelectProduct({ product: product.product, index: idx })}
                      onDelete={() => onRemoveProduct({ product: product.product, index: idx })}
                      isSelected={checkProductSelected(idx)}
                    />
                    {idx < products.length - 1 && <Spacer size="16" />}
                  </React.Fragment>
                ))}
              </>
            )}
            {productsState.inventories.length > 0 && canEdit.services && (
              <>
                {products.length > 0 && <Spacer size="16" />}
                <SelectProducts
                  //@TODO Replace this with products
                  disabled={!canEdit.services}
                  isMore={products.length > 0}
                  inventories={productsState.inventories}
                  addProduct={addProductCallback}
                />
                {products.length > 0 ? <Spacer size={16} /> : <Spacer size={8} />}
              </>
            )}
          </Column>
        </>
      )}
      <Spacer size={16} color={colors.ALABASTER} />
      <Column style={{ paddingHorizontal: 16, zIndex: 1 }}>
        {hasNotesOrRecurrence && <Spacer size="16" />}
        {notesInputVisible ? (
          <>
            <InputWithLabel
              label={i18n.t('notes')}
              isError={showErrors && !notesValue.isValid}
              errorText={i18n.t('errorObservations')}
            >
              <TextInput
                value={notesValue.value}
                placeholder={i18n.t('onlyForPro')}
                editable={canEdit.notes}
                onChangeText={setNotesValue}
                multiline
                numberOfLines={3}
              />
            </InputWithLabel>
            <Spacer size="16" />
          </>
        ) : null}

        {recurrenceRuleIsPresent && (
          <>
            <Title>{i18n.t('repeat')}</Title>
            <Spacer size="8" />
            <RecurrenceListItem
              recurrenceRule={recurrenceRule}
              onEdit={editRecurrenceRule}
              disabled={!canEdit.recurrenceRule}
              isHovered={false}
            />
            <Spacer size="16" />
          </>
        )}

        <Row>
          {!notesInputVisible && canEdit.notes && (
            <>
              <TouchableOpacity
                onPress={() => {
                  setShowNotesClicked(true);
                }}
                style={{ flexDirection: 'row', alignItems: 'center' }}
              >
                <NotesIcon />
                <SmallBody style={[meroStyles.text.semibold, meroStyles.text.link, { paddingLeft: 8 }]}>
                  {i18n.t('notes')}
                </SmallBody>
              </TouchableOpacity>
              <HSpacer left={12} right={12} />
            </>
          )}
          {!recurrenceRuleIsPresent && canEdit.recurrenceRule && (
            <>
              <TouchableOpacity onPress={editRecurrenceRule} style={{ flexDirection: 'row', alignItems: 'center' }}>
                <RecurenceIcon />
                <SmallBody style={[meroStyles.text.semibold, meroStyles.text.link, { paddingLeft: 8 }]}>
                  Repetare
                </SmallBody>
              </TouchableOpacity>
            </>
          )}
        </Row>
        {hasNotesOrRecurrence && <Spacer size="16" />}
      </Column>
      {history.length === 0 ? null : (
        <>
          <FormCard paddings="none">
            <Spacer size="16" />
            <H3s style={{ paddingHorizontal: 16 }}>{i18n.t('historyAppointment')}</H3s>
            {history.map((action, idx) => (
              <Row
                key={idx}
                style={{
                  paddingVertical: 16,
                  marginHorizontal: 16,
                  borderBottomColor: colors.ATHENS_GRAY,
                  borderBottomWidth: 1,
                }}
              >
                <Column>
                  <HistoryIcon />
                </Column>
                <Column style={{ flex: 1, paddingLeft: 12 }}>
                  <Body>{generateHistoryText(action)}</Body>
                  <Spacer size="6" />
                  <SmallBody style={{ fontSize: 12, color: colors.COMET }}>
                    {formatTimeDiff(action.date, new Date())}
                  </SmallBody>
                </Column>
              </Row>
            ))}
          </FormCard>
        </>
      )}
    </>
  );
};

export default AppointmentDetails;
