/* eslint-disable @typescript-eslint/naming-convention */
import { ScaledNumber } from '@mero/shared-sdk';

import { scaledToString } from '../../utils/scaled';

/**
 * Check this snippet for correct suffixes https://jsfiddle.net/sm9wgLze :(
 */
const ro = {
  login: {
    onboarding: 'Onboarding',
    accountConfiguration: 'Configurare cont',
    placeName: 'Nume profil',
    placeNameError: 'Introdu numele profilului',
    placeNamePlaceholder: 'Ex. Andreea Nails',
    placeNameHint1: 'VA DETERMINA LINKUL PROFILULUI TĂU MERO',
    placeNameHint2: 'ex. {{value}}',
    cityName: 'Oraș',
    cityNameError: 'Introdu orașul în care îți desfășori activitatea',
    cityNamePlaceholder: 'Oraș',
    category: 'Serviciu principal',
    categoryError: 'Te rugăm sa alegi tipul serviciului principal',
    categoryPlaceholder: 'Alege tip serviciu',
    firstName: 'Prenume tău',
    firstNameError: 'Te rugăm sa introduci un prenume valid',
    firstNamePlaceholder: 'Introdu prenumele',
    lastName: 'Numele tău de familie',
    lastNameError: 'Te rugăm sa introduci un nume de familie valid',
    lastNamePlaceholder: 'eg. Popescu',
    businessPhone: 'Număr de telefon al afacerii',
    businessPhoneError: 'Te rugăm sa introduci un număr de telefon valid',
    businessPhonePlaceholder: 'Introdu telefon',
    email: 'Adresa de e-mail',
    emailError: 'Te rugăm sa introduci o adresă de e-mail validă',
    emailPlaceholder: 'Introdu adresa de e-mail',
    change: 'Modifică',
    continue: 'Continuă',
    addBusinessDetails: 'Adaugă detalii\nprofil MERO',
    stepXofY: 'Pasul {{x}} din {{y}}',
    invalidDataError: 'Vă rugăm să verificați datele si încercați in cateva minute',
    question1: 'Câte programări ai\npe săptămână?',
    question1Description: 'Aproximează numărul de clienți care se\nprogramează prin telefon, mesaje, etc.',
    question1Error: 'Te rugăm să alegi un răspuns',
    'answer1.1': 'Nu am programări deloc',
    'answer1.2': '1-5 programări / săptămână',
    'answer1.3': '6-15 programări / săptămână',
    'answer1.4': '16-30 programări / săptămână',
    'answer1.5': 'Peste 30 programări / săptămână',
    question2: 'Ce ar trebui să facă\nMERO pentru tine?',
    question2Error: 'Te rugăm să alegi un răspuns',
    'answer2.1': 'Să mă ajute să mă organizez mai bine și să câștig timp ',
    'answer2.1.1': 'Clienții se programează singuri',
    'answer2.1.2': 'Mesaje de reamintire la programare',
    'answer2.1.3': 'Fără suprapuneri',
    'answer2.2': 'Să mă promoveze online și să îmi aducă clienți noi, fără comision',
    'answer2.3': 'Ambele, în egală măsură',
    question3: 'Când vrei să dăm drumul\nla treabă?',
    question3Description:
      'Pentru a începe pe MERO trebuie să aloci puțin timp să finalizezi profilul adăugând serviciile, programul de lucru și programările viitoare',
    question3Error: 'Te rugăm să alegi un răspuns',
    'answer3.1': 'Cât mai repede',
    'answer3.2': 'În 1-2 săptămâni',
    'answer3.3': 'Mai mult de 2 săptămâni',
    'answer3.4': 'Nu sunt hotărât, momentan doar explorez',
  },
  clients: {
    importContacts: 'Importă contacte',
    importNContactsAction_one: 'Importă {{count}} contact',
    importNContactsAction_other: 'Importă {{count}} contacte',
    importNContactsAction_few: 'Importă {{count}} contacte',
    searchNameOrPhone: 'Caută după nume sau telefon',
    clients: 'Clienți',
    blockedClients: 'Blocați',
    warnClients: 'Avertizați',
    filter: 'Filtreaza',
    noResultsFor: 'Niciun rezultat pentru',
    checkSearch: 'Verifică ca termenul de căutare sa fie corect și încearca din nou',
    noneBlocked: 'Nici un client blocat',
    blockedInfo: 'Clienții blocați nu vor mai putea face programări la niciun profesionist din profilul tău.',
    noneWarned: 'Nici un client avertizat',
    warnedInfo: 'Clienții avertizați vor fi marcați pentru a atrage atenția la următoarele programări.',
    addClient: 'Adaugă client',
    noClients: 'Nu există clienți',
    clientShownInfo: 'Clienții vor fi afișați aici după ce îi adaugi sau își fac singuri programare',
    searchRequirement:
      'Introdu minim 3 caractare din numele clientului, în bara de căutare de mai sus pentru a vedea fișa clientului',
    selectClient: 'Alege client',
    clientDetails: 'Detalii client',
    changeClientDetails: 'Modifică date client',
    canNotBeUndoneAction: 'Actiune ireversibilă',
    confirmClientDeletion: 'Confirmă ștergere client',
    selectAndConfirmDeletion: 'Bifează și confirmă ștergerea',
    accountDetailsWillBeDeleted: 'Detaliile contului vor fi șterse',
    irreversible: 'IREVERSIBIL',
    cancel: 'Anulează',
    deleteClient: 'Șterge client',
    savedClientDetails: 'Detaliile clientului au fost salvate',
    saveChanges: 'Salvează modificări',
    mobilePhone: 'Telefon mobil',
    mobilePhoneError: 'Te rugam sa introduci un număr de telefon valid',
    mobilePhonePlaceholder: 'Introdu numărul de telefon',
    mobilePhoneInfo: 'Numărul de telefon este important pentru a trimite notificări pentru programări prin SMS',
    firstName: 'Prenume',
    firstNameError: 'Te rugam sa introduci un prenume valid',
    firstNamePlaceholder: 'Introdu prenumele',
    lastName: 'Nume',
    lastNameError: 'Te rugam sa introduci un nume valid',
    lastNamePlaceholder: 'Introdu numele',
    email: 'Email',
    emailError: 'Te rugam sa introduci un email valid',
    emailPlaceholder: 'Introdu adresa de email',
    birthday: 'Data nașterii',
    birthdayPlaceholder: 'Alege data nașterii',
    observations: 'Observații',
    observationsPlaceholder: 'Observațiile vizibile doar pt. profesioniști',
    maskedPhone: '07xx xxx xxx',
    maskedPhoneDescription:
      'Numărul de telefon va fi afișat după încheierea perioadei în care poți revendica acest client.',
    viewCommission: 'Vezi comision',
    claimBoostClient: 'Revendică client',
    claimBoostClientProcessing: 'REVENDICARE CLIENT ÎN CURS DE PROCESARE',
    claimBoostClientTitle: 'Acceptă comision boost',
    acceptClaim: 'Acceptă comisionul și afișează telefonul',
    acceptClaimDescription: 'După achitare, clientul nu va mai fi comisionat niciodată.',
    acceptClaimCheck1:
      'Accept să plătesc comisionul estimat la <1>{{amount}} lei + TVA</1> din prima programare finalizată, conform <3>termeni și condiții</3>',
    boostDetails: 'Profit {{profit}} lei • Cost {{cost}} lei ',
    close: 'Închide',
    confirm: 'Confirm',
    enableCommissionError: 'S-a produs o eroare, te rugăm să încerci din nou',
    call: 'Apel',
    message: 'SMS',
    whatsApp: 'WhatsApp',
    more: 'Mai mult',
    boostDescription: 'Vei putea contacta clientul după ce accepți comisionul sau revendici clientul ca fiind al tău.',
    appointmentReports: 'programări',
    canceledReports: 'ANULĂRI',
    noShowReports: 'NEPREZENTĂRI',
    lastAppointmentReports: 'Ultima vizită',
    revenueReports: 'venituri',
    unpaidReports: 'datorii',
    RON: 'lei',
    newAppointment: 'Programare nouă',
    Active: 'Activ',
    Consumed: 'Consumat',
    Cancelled: 'Anulat',
    Expired: 'Expirat',
    Inactive: 'Inactiv',
    Unlimited: 'nelimitat',
    membershipDetails: 'Detalii abonament',
    debtAmount: 'REST DE PLATĂ',
    statusItem: '{{consumed}} / {{total}} ședințe rămase',
    partialPayment: 'Achitat parțial',
    fullPayment: 'Achitat integral',
    partialPaymentStatusFinalized: 'Finalizată',
    partialPaymentStatusCanceled: 'Anulată',
    newCharge: 'Încasare nouă',
    addNote: 'Adaugă observații',
    appointments: 'Ședințe efectuate',
    history: 'Istoric',
    MembershipPurchasedByClientOnline: '<0>Clientul</0> a achiziționat abonamentul online ',
    MembershipPurchasedByClientOffline: 'Abonamentul a fost creat',
    PaymentCollectedByProFull: '<0>{{name}}</0> ({{phone}}) a încasat total abonamentul #{{code}}',
    PaymentCollectedByProPartial: '<0>{{name}}</0> ({{phone}}) a încasat parțial abonamentul #{{code}}',
    PaymentCancelledByPro: '<0>{{name}}</0> ({{phone}}) a anulat încasarea #{{code}}',
    AppointmentMembershipPaymentCancelled:
      '<0>{{name}}</0> ({{phone}}) a încasat programarea din <2>{{date}}</2> ora <4>{{hour}}</4> folosind abonamentul',
    AppointmentPaidUsingMembership:
      '<0>{{name}}</0> ({{phone}}) a încasat programarea din <2>{{date}}</2> ora <4>{{hour}}</4> folosind abonamentul',
    ServicesPaidUsingMembership:
      '<0>{{name}}</0> ({{phone}}) a încasat serviciul “{{service}}” în <2>{{date}}</2> ora <4>{{hour}}</4> folosind abonamentul',
    ServicesMembershipPaymentCancelled:
      '<0>{{name}}</0> ({{phone}}) a anulat încasarea serviciului “{{service}}” în {{date}} ora {{hour}} din abonament',
    MembershipCancelledByPro: '<0>{{name}}</0> ({{phone}}) a anulat abonamentul',
    addNotes: 'Adaugă observații',
    changeNotes: 'Modifică observații',
    addNotesDescription: 'Observațiile vor fi vizibile doar intern, pentru profesioniști',
    addNotesPlaceholder: 'Introdu observații',
    save: 'Salvează',
    membershipOptions: 'Opțiuni',
    addAppointment: 'Programează',
    sellAgainMembership: 'Vinde din nou',
    cancelMembership: 'Anulează abonament',
    clientNotFound: 'Clientul nu a fost găsit',
    membershipCharged: 'ÎNCASARE #{{code}}',
    membershipServiceUsed_one: 'x {{count}} ședință - {{name}}',
    membershipServiceUsed_other: 'x {{count}} ședințe - {{name}}',
    membershipServiceUsed_few: 'x {{count}} ședințe - {{name}}',
    unknownUser: 'Fară nume',
    newClient: 'Client nou',
    existingClient: 'Client existent',
    clientName: 'Introdu nume client',
    clientPhone: 'Introdu telefon client',
    searchClientPlaceholder: 'Caută după nume sau telefon',
  },
  calendar: {
    title: 'Calendar',
    onlineLink: 'Programări online',
    filters: 'Filtre',
    selectPro: 'Specialiști',
    selectLocation: 'Alege profil locație',
    viewPros: 'Vezi profesioniștii din locația aleasă.',
    allPros: 'Toți profesioniștii',
    activePros: 'Doar cei care lucrează',
    selectedPro: `{{count}} profesioniștii`,
    blockedTimeReasonBreak: 'Pauză',
    blockedTimeReasonDayOff: 'Zi liberă',
    blockedTimeReasonVacation: 'Concediu de odihnă',
    blockedTimeReasonSickLeave: 'Concediu medical',
    blockedTimeReasonTraining: 'Training',
    pending: 'În așteptare',
    done: 'Finalizat',
    confirmed: 'Confirmat',
    canceled: 'Anulat',
    noShow: 'Neprezentat',
    declined: 'Refuzat',
    displayAppointments: 'Afișare programări',
    includeDeleted: 'Include programări anulate',
    hasFinishedCheckoutTransactions: 'Doar programări neîncasate',
    displaySchedule: 'Afișare interval calendar',
    completeSchedule: 'Complet (0-24h)',
    workingHours: 'Program de lucru',
    noProsAvailable: 'Nici un profesionist nu are program în\naceastă zi.',
    expired: 'Abonament expirat',
    newAppointment: 'Programare nouă',
    blockTime: 'Blochează timp',
    newCheckout: 'Încasare nouă',
    viewMode: 'Calendar',
  },
  booking: {
    newAppointment: 'Programare nouă',
    changeAppointment: 'Modifică programare',
    searchClient: 'Caută după nume sau telefon',
    existingClient: 'Client existent',
    newClient: 'Client nou',
    selectClient: 'Alege client',
    selectClientInfo: 'Alege un client pentru a putea trimite mesaje de reamintire și a obține programări repetate.',
    clientName: 'Introdu nume client',
    addPhoneNumber: 'Introdu telefon mobil client',
    addPhoneTo: 'Completează telefonul pentru a trimite notificări de reamintire la programare, prin SMS.',
    noClientSelected: 'Niciun client selectat',
    noClient: 'Fără client',

    noName: 'Fără nume',
    service: 'Serviciu',
    selectService: 'Alege serviciu',
    selectAService: 'Alege un serviciu',
    selectPro: 'Alege profesionist',
    selectAPro: 'Alege un profesionist',
    dateHour: 'Data și ora',
    until: 'Până la',
    observations: 'Observații',
    errorObservations: 'Ai introdus o valoare invalidă pentru notițe',
    onlyForPro: 'Observațiile vizibile doar pt. profesioniști',
    repeat: 'Repetare',
    deleteAppointment: 'Șterge programare',
    duration: 'Durata',
    total: 'Total',
    saveAppointment: 'Salvează programare',
    saveChanges: 'Salvează modificări',
    doesntRepeat: 'Nu se repetă',
    daily: 'Zilnic',
    weekly: 'Săptămânal',
    every2Weeks: 'La 2 săptămâni',
    monthly: 'Lunar',
    custom: 'Personalizat',
    day_one: 'zi',
    day_other: 'zile',
    day_few: 'zile',
    week_one: 'săptămână',
    week_other: 'săptămâni',
    week_few: 'săptămâni',
    month_one: 'lună',
    month_other: 'luni',
    month_few: 'luni',
    repeatOptions: 'Opțiuni repetare',
    repeatsEvery: 'Se repetă la fiecare',
    repeatStop: 'Se oprește',
    noEndDate: 'Nu are o dată finală',
    atCertainDate: 'La o anumită dată',
    after: 'După un anumit număr de repetări',
    stopRepeat: 'Anulează repetare',
    selectDate: 'Alege data limită',
    selected: 'Selectat',
    save: 'Salvează',
    appointmentDetails: 'Detalii programare',
    giftCard: 'Card cadou',
    giftCardTitle: 'Card cadou {{code}}',
    discountCode: 'Cod reducere',
    ron: 'lei',
    appointmentOptions: 'Opțiuni programare',
    appointmentOptionsShort: 'Opțiuni',
    checkout: 'Încasează',
    addClient: 'Adaugă client',
    notProvidedBySelectedPro: 'Serviciul nu mai e alocat profesionistului',
    notProvidedByPage: 'Serviciu din profilul conectat cu pagina ta',
    noLongerAvailable: 'Serviciul nu mai există',
    historyAppointment: 'Istoric programare',
    historyAppointmentInfo: '<0>{{name}}</0> {{status}} <2>{{date}}</2> ora <4>{{hour}}</4>',
    appointmentCreatedByClient:
      'Clientul a creat programarea pentru <1>{{date}}</1> ora <3>{{hour}}</3> la <5>{{worker}}</5>',
    appointmentCreatedByPro:
      '<0>{{name}}</0> ({{phone}}) a creat programarea pentru <2>{{date}}</2> ora <4>{{hour}}</4> la <6>{{worker}}</6>',
    appointmentCancelledByClient: 'Clientul a anulat programarea. <1>{{reason}}</1>',
    appointmentCancelledByPro: '<0>{{name}}</0> ({{phone}}) a anulat programarea. <2>{{reason}}</2>',
    appointmentModifiedByPro:
      '<0>{{name}}</0> ({{phone}}) a mutat programarea de <2>{{startDate}}</2> ora <4>{{startHour}}</4> pe <6>{{endDate}}</6> ora <8>{{endHour}}</8>',
    appointmentConfirmed: '<0>{{name}}</0> ({{phone}}) a confirmat programarea',
    appointmentRefused: '<0>{{name}}</0> ({{phone}}) a refuzat programarea',
    appointmentMarkedAsNoShow: '<0>{{name}}</0> ({{phone}}) a marcat programarea ca <2>Neprezentat</2>',
    withReason: 'Motiv: {{reason}}',
    noReason: '',
    boostClient: 'Client boost',
    cancelBookingTitle: 'Confirmă anulare programare',
    cancelBookingMessage: 'Alege un motiv pentru anulare. Clientul va fi notificat.',
    cancelBookingReason1: 'A intervenit ceva în plan personal',
    cancelBookingReason2: 'Există deja o programare cu aceleași date',
    cancelBookingReason3: 'Alt motiv',
    cancelBookingReason3Placeholder: 'Introdu motivul anulării',
    cancelBookingReason3Error: 'Motivul trebuie să aibă cel puțin 10 caractere',
    appointmentOptionsMin: 'Opțiuni',
    RON: 'lei',
    EURO: 'euro',
    withCheckoutError: 'Pentru a putea anula programarea, trebuie mai întâi să anulezi încasarea acesteia.',
    appointmentBilled: 'Încasare finalizată',
    appointmentProtocol: 'Protocol',
    totalAppointment: 'Total',
    pendingList: 'Lista de așteptare',
    noPendingClientsTitle: 'Niciun client în așteptare',
    noPendingClientsDescription:
      'Clienții înscriși pe lista de așteptare vor fi\nnotificați imediat ce se va elibera un\ninterval de timp în ziua solicitată.',
    statusPending: 'În așteptare',
    callClient: 'Sună client',
    cancel: 'Anulează',
    delete: 'Șterge',
    deleteButton: 'Anulează',
    confirmButton: 'Confirmă',
    rejectButton: 'Refuză',
    irreversibleAction: 'Acțiune ireversibilă',
    cancelPendingListTitle: 'Confirmă ștergerea solicitării',
    cancelPendingListDescription:
      'Solicitarea de programare va dispărea permanent din listă iar clientul NU va fii notificat.',
    hiddenPrice: 'preț variabil',
    usableMembership_one: '{{total}}/{{count}} abonamente compatibile',
    usableMembership_other: '{{total}}/{{count}} abonamente compatibile',
    usableMembership_few: '{{total}}/{{count}} abonamente compatibile',
    membershipLeft_one: '{{count}} ședință rămasă',
    membershipLeft_other: '{{count}} ședințe rămase',
    membershipLeft_few: '{{count}} ședințe rămase',
    membershipLeftUnlimited: 'ȘEDINȚE NELIMITATE',
    allPros: 'Toți profesioniștii',
    viewCommission: 'Vezi comision',
    clickStarToAddReview: 'Click pe o stea pentru a evalua clientul',
    editFeedback: 'Modifică recenzie',
    deleteFeedback: 'Șterge recenzie',
    describeExperience: 'Descrie experiența ta',
    pro: 'Profesionist',
    decribeYourExperienceWith: 'Descrie experiența avută cu',
    reviewDescription: 'Ajută colegii de salon oferind detalii despre experiența ta… ',
    reviewCheck1: 'Recenziile ajută echipa MERO să combată comportamente abuzive din partea clienților',
    reviewCheck2: 'Evaluarea este vizibilă doar echipei din profilul tău MERO',
    reviewCheck3: 'Clientul nu este notificat și nu poate vedea evaluarea ta',
    send: 'Trimite',
    clientFeedbackDeleted: 'Evaluarea clientului a fost ștearsă cu succes!',
    requiredReviewError: 'Adaugă detalii cu privire la evaluarea ta',
    boostVoucherTooltip:
      'CUM FUNCȚIONEAZĂ?\nOferi reducerea din voucher clientului iar MERO va reduce comisionul Boost cu valoarea voucherului.',
    contact: 'Contactează-ne',
    ok: 'Ok, am înțeles',
    appointment: 'Programare',
    selectPerformer: 'Alege profesionist',
    selectProduct: 'Alege produs',
    selectProducts: 'Alege produse',
    notes: 'Observații',
    selectServiceTitle: 'Alege serviciu',
    selectDateAndTime: 'Alege data și ora',
    dateAndTime: 'Data și ora',
    recurrence: 'Repetare',
    availableTimes: 'Listă ore disponibile',
    addMoreServices: 'Adaugă încă un serviciu',
    services: 'Servicii',
    productAddError: 'Nu am putut adăuga produsul, te rugăm să încerci din nou',
    products: 'Produse',
    addMoreProducts: 'Adaugă încă un produs',
    totalPayment: '<0>Total:</0> {{value}} {{currency}}',
    totalPaymentWithoutCurrency: '<0>Total:</0> {{value}}',
    durationInMinutes: 'Durată: {{value}}',
    noShow: 'Neprezentat',
    paymentLink: 'Link plată',
    newAppointmentButton: 'Programare nouă',
    checkoutButton: 'Vezi încasare',
    more: 'Mai mult',
    pending: 'În așteptare',
    cancelled: 'Anulat',
    rejected: 'Refuzat',
    finalised: 'Finalizat',
    accepted: 'Confirmat',
    revertChanges: 'Anulează modificări',
    closeBookingTitle: 'Confirmare închidere fără a salva modificările',
    closeBookingDescription: 'Ai modificări nesalvate. Ești sigur că vrei să închizi?',
    importantAction: 'Acțiune importantă',
    irrevocableAction: 'Acțiune ireversibilă',
    close: 'Anulează',
    cancelChanges: 'Închide fără a salva',
    bookingUpdated: 'Programarea a fost modificată',
    bookingCreated: 'Programarea a fost adăugată',
    bookingCanceled: 'Programarea a fost anulată',
    Hidden: 'Preț ascuns',
    searchPerformerPlaceholder: 'Caută profesionist',
    searchServicePlaceholder: 'Caută serviciu',
    searchProductPlaceholder: 'Caută după nume sau cod de bare',
  },
  notificationOptions: {
    ownOptions: 'Opțiuni notificări proprii',
    title: 'Opțiuni notificări',
    description: 'Poţi opta să primești notificări atunci când există acţiuni asupra programărilor',
    ownBookings: 'Programările proprii',
    otherCalendars: 'Calendarele altor profesionişti',
    ownCalendar: 'Calendarul tău',
    saveChanges: 'Salvează modificări',
    saveChangesError: 'Nu am putut salva modificările, te rugăm să încerci din nou',
    changesSaved: 'Modificările au fost salvate',
    clientChangesAppointment: 'Clientul adaugă sau anulează o programare',
    clientChangesAppointmentOwnCalendar: 'Atunci când un client adaugă sau anulează o programare în calendarul tău',
    clientChangesAppointmentOtherCalendar: 'Atunci când un client adaugă sau anulează o programare în alte calendare',
    proChangesAppointment: 'O persoană din echipă adaugă, modifică sau anulează o programare',
    proChangesAppointmentOwnCalendar:
      'Atunci când o persoană din echipă adaugă, modifică sau anulează o programare din calendarul tău',
    proChangesAppointmentOtherCalendar:
      'Atunci când o persoană din echipă adaugă, modifică sau anulează o programare în alte calendare',
    smsReminder: 'Reamintire trimitere SMS-uri semi-automate către clienți',
    clientAddedToOwnWaitingList: 'Înscriere client pe lista de așteptare',
    clientAddedToWaitingList: 'Înscrieri pe listele de așteptare',
    clientAddedToWaitingListDescription:
      'Atunci când un client se înscrie pe lista de așteptare la oricare persoană din echipă',
    proFulfillsRequest: 'Acceptare / refuz programare cu confirmare manuală',
    proFulfillsRequestDescription:
      'Atunci când o persoană din echipă acceptă sau refuză orice programare cu confirmare manuală',
  },
  notifications: {
    notifications: 'Notificări',
    notificationsSettings: 'Mesaje programări',
    addReminderMessage: 'Adaugă încă un mesaj de reamintire',
    options: 'Opțiuni',
    deliveryMethod: 'Alege metodă trimitere',
    deliveryMethodDescription: 'Persoana care face trimiterea va fi notificată zilnic',
    emptyNotificationState: 'Aici vei putea vedea notificări cu privire la contul și programările tale',
    appointmentRequest: 'Cereri de programare',
    privateProfileRequest: 'Cereri înscriere pe profil privat',
    invitation: 'Invitații',
    activity: 'Activitate',
    errorUnknown: 'S-a întâmplat ceva neprevăzut ...',
    viewDetails: 'Vezi detalii',
    reject: 'Refuză',
    confirm: 'Confirmă',
    canNotBeUndoneAction: 'Actiune ireversibilă',
    cancel: 'Anulează',
    cancelAppointmentRequestTitle: 'Ești sigur că vrei să refuzi programarea?',
    cancelAppointmentRequestSubtitle: 'Clientul va fi notificat că cererea de programare a fost refuzată.',
    cancelClientRequestTitle: 'Ești sigur că vrei să refuzi cererea?',
    cancelClientRequestSubtitle: 'Clientul va fi notificat că cererea de înscriere la profilul privat a fost refuzată.',
    cancelInviteWorkTitle: 'Ești sigur că vrei să refuzi invitația?',
    cancelInviteWorkSubtitle: 'Salonul va fi notificat că invitația a fost refuzată.',
    notNow: 'Nu acum',
    checkPaymentDetails: 'Vezi detalii plată',
    contact: 'Contactează-ne',
    selectNotification: 'Trimitere mesaje',
    freeTrialDays: 'Perioada de gratuitate expiră în {{days}} zile. Plătește și evită suspendarea contului.',
    freeTrialToday: 'Perioada de gratuitate expiră astăzi. Plătește și evită suspendarea contului.',
    subscriptionsExpiresDays: 'Abonamentul tău expiră în {{days}} zile. Plătește și evită suspendarea contului.',
    subscriptionsExpiresToday: 'Abonamentul tău expiră astăzi. Plătește și evită suspendarea contului.',
    subscriptionWillExpireWithAutocharge:
      'PLATA ABONAMENTULUI SE APROPIE\nÎn mai puțin de 48 de ore se va efectua automat plata abonamentului MERO. Asigură-te că ai suficienți bani pe card și evită restricționarea accesului la calendar.',
    subscriptionWillExpireWithoutAutocharge:
      'EVITĂ RESTRICȚIONAREA ACCESULUI\nÎn mai puțin de 48 de ore abonamentul tău MERO expiră. Evită restricționarea accesului la calendar și fă plata cât mai repede.',
    close: 'Închide',
    selectWhenWeShouldCall: 'Alege când să te contactăm',
    trialPeriodExtended:
      'Bravo, perioada de gratuitate s-a prelungit cu 14 de zile! Acum că ai început să îți adaugi programările ai posibilitatea să primești clienți noi prin listarea profilului tău pe MERO. Te vom contacta în scurt timp.',
    titleSms: 'Automat de la număr scurt',
    smsCost: 'Cost operator 0.03 € + TVA / SMS',
    smsCostDetails: 'Se trimit de la un număr scurt al unui operator de telefonie, contra-cost.',
    androidSmsApp: 'Automat de la numărul unde se instalează aplicația MERO Sms',
    androidSmsAppDetails: 'La trimitere se va folosi cartela SIM din telefonul unde se instalează MERO Sms.',
    androidSmsAppStep1: 'Vezi detalii instalare MERO Sms',
    androidSmsAppStep2: 'Instalează aplicația și autentifică-te',
    androidSmsAppStep3: 'Telefonul unde rulează MERO SMS trebuie să fie pornit și conectat la internet',
    androidSmsAppStep4: 'Aplicația MERO SMS trebuie să ruleze în fundal',
    androidSmsAppStep5: 'Abonamentul sau oferta cartelei din telefon trebuie să permită trimiterea de SMS-uri',
    contactDetails: 'Nu ezita să <1>contactezi echipa de asistență</1> pentru orice nelămurire.',
    smsNotifications: 'Mesaje active',
    smsNotifications1: 'Anulare programare',
    smsNotifications2: 'Aprobare / respingere programări cu confirmare manuală',
    smsNotifications3: 'Reamintire programare',
    smsNotifications4: 'Adăugare programare',
    smsNotifications5: 'Modificare programare',
    smsNotifications6: 'Solicitare recenzie după programare',
    reminderNotification_one: 'Reamintire programare ({{count}} activă)',
    reminderNotification_other: 'Reamintire programare ({{count}} active)',
    reminderNotification_few: 'Reamintire programare ({{count}} active)',
    smsNotificationDisabled: 'NOTIFICĂRI PRIN SMS DEZACTIVATE',
    smsNotificationDisabledDetails:
      'Notificările prin SMS sunt dezactivate.\nClienții tăi vor primi doar notificări de tip push în aplicația MERO.',
    androidNotificationsDisabled: 'MERO SMS ESTE INACTIV',
    androidNotificationsDisabledDetails: 'Urmărește aceste instrucțiuni:',
    androidNotificationsEnabled: 'MERO SMS ESTE ACTIV',
    androidNotificationsOffline: 'MERO SMS ESTE INACTIV',
    androidNotificationsUnchecked: 'Necesită un telefon Android cu SIM',
    androidNotificationsEnabledDetails: 'Vezi instrucțiuni MERO SMS',
    ok: 'Ok, am înțeles',
    importantActionTitle: 'Actiune importantă',
    enableAndroidSmsDialogTitle: 'Urmează pașii următori și activează MERO SMS',
    enableAndroidSmsDialogDescription:
      'Bifează și asigură-te că ai urmat toți pașii de mai jos. Toți pașii sunt necesari ca MERO SMS să funcționeze corespunzător.',
    enableSmsDialogTitle: 'Confirmă activare SMS-uri cu plată',
    enableSmsDialogDescription: 'Bifează și confirmă activarea',
    enableSmsDialogStep1:
      'Sunt de acord să activez notificări prin SMS trimise printr-un operator de telefonie la cost de\n<1>0.03 € / SMS (ex. 100 SMS = 3 €)</1>',
    enableSms: 'Activez SMS-uri plătite',
    disableSmsDialogTitle: 'Confirmă dezactivare notificări prin SMS',
    disableSmsDialogDescription: 'Bifează și confirmă dezactivarea',
    disableSmsDialogStep1:
      'Dezactivând notificările prin SMS, clienții <1>NU</1> vor mai primi notificări prin SMS, ca de exemplu înainte de programare',
    disableSms: 'Dezactivare SMS',
    updateSuccess: 'Setările au fost actualizate cu succes',
    updateError: 'A apărut o eroare la actualizarea setărilor',
    '2h': 'cu 2 ore înainte',
    '24h': 'cu 24 ore înainte',
    '2d': 'cu 2 zile înainte',
    '3d': 'cu 3 zile înainte',
    '1w': 'cu o săptămână înainte',
    '2w': 'cu 2 săptămâni înainte',
    semiAutomaticTitle: 'Semi-automat de la numărul ales de tine, prin aplicația MERO Pro',
    semiAutomaticDetails: 'Trimitere mesaje pre-completate, folosind cartela SIM din telefonul cu MERO Pro.',
    semiAutomaticIndividual: 'Trimitere individuală',
    semiAutomaticIndividualDescription: 'Fiecare specialist de pe numărul propriu pentru programările sale',
    semiAutomaticIndividualWarning: 'Doar profesioniștii cu access la telefonul clienților vor putea trimite',
    semiAutomaticGlobal: 'Trimitere globală',
    semiAutomaticGlobalDescription: 'Un membru al echipei face trimiterea pentru întreaga echipă (eg. recepția)',
    semiAutomaticGlobalSelector: 'Alege cine face trimiterea',
    okSimple: 'Ok',
    reminder: 'TRIMITE SMS PROGRAMĂRI ({{sent}}/{{total}})',
    messageReminder: 'Trimite SMS programări',
    olderAppointments: 'Programări anterioare',
    todayAppointments: 'Programări azi',
    nextAppointments: 'Programări ulterioare',
    sendSmsManually: 'Mesaj SMS',
    sendWhatsAppManually: 'Mesaj WhatsApp',
    messageSent: 'Mesaj trimis',
    sendAgain: 'Retrimite SMS',
    deleteReminder: 'Șterge din lista de trimiteri',
    reviewNotification: 'SOLICITARE RECENZIE',
    reminderNotification: 'REAMINTIRE PROGRAMARE',
    previewMessage: 'Previzualizează mesaj',
    done: 'Gata',
    bookingReminder: 'Reamintire ({{count}})',
    askForReview: 'Solicitare recenzii ({{count}})',
    noBookingRemindersTitle: 'Niciun mesaj de reamintire de trimis',
    noBookingRemindersDescription: 'Mesajele de reamintire ce trebuie trimise vor apărea aici',
    noAskForReviewsTitle: 'Niciun mesaj de solicitare recenzii de trimis',
    noAskForReviewsDescription:
      'Mesajele de solicitare recenzii ce trebuie trimise vor apărea aici după finalizarea unei programări',
    messagesFormat: 'Personalizare mesaje',
    customMessages: 'Personalizare',
    appointmentConfirmedNotification: 'Confirmare programare',
    appointmentMadeByPageNotification: 'Programare creată',
    appointmentCancelledByWorkerNotification: 'Anulare programare',
    appointmentModifiedNotification: 'Modificare programare',
    appointmentReminderNotification: 'Reamintire programare',
    appointmentReviewReminderNotification: 'Solicitare recenzie după programare',
    countCharacters: '{{total}}/{{limit}} caractere (consum {{smsCount}} SMS / mesaj)',
    previewMessageLabel: 'PREVIZUALIZARE MESAJ',
    messageContent: 'Conținut mesaj',
    messageContentDescription:
      'Caracterele speciale, diacriticile, emoji, linkurile și cuvintele nepotrivite vor fi eliminate automat. Linkul MERO este adăugat la finalul mesajului și nu poate fi înlăturat. <1>Vezi detalii</1>',
    resetToDefault: 'Resetează la varianta implicită',
    saveChanges: 'Salvează modificări',
    tagPage: '[[NUME_PROFIL]]',
    tagStatus: '[[STATUS_PROGRAMARE]]',
    tagDateTime: '[[DATA_SI_ORA_PROGRAMARII]]',
    tagDateTimeChange: '[[DATA_SI_ORA_MODIFICARII]]',
    tagPageAddress: '[[ADRESA]]',
    tagWorkerFirstName: '[[PRENUME_PROFESIONIST]]',
    invalidTemplate: 'ETICHETĂ INVALIDĂ',
    templateDoesNotExist: 'Eticheta nu exista',
    Added: 'adăugat',
    Modified: 'modificat',
    Cancelled: 'anulat',
    Accepted: 'acceptat',
    Rejected: 'refuzat',
  },
  settings: {
    selectLocation: 'Alege profil locație',
    addLocation: '+ Profil nou',
    logout: 'Ieși din cont',
    pageDetails: 'Profil online locație',
    profileDetails: 'Detalii profil',
    photoGallery: 'Galerie foto',
    services: 'Servicii',
    clientNotifications: 'Notificări clienți',
    smsMessages: 'Mesaje programări',
    notificationsSettings: 'Setări notificări',
    personalServices: 'Personalizare servicii',
    schedule: 'Program de lucru',
    calendarSettings: 'Setări programări',
    pros: 'Echipă',
    accountPermissions: 'Conturi și permisiuni',
    reviews: 'Recenzii clienți',
    pendingClients: 'Clienți în așteptare',
    reports: 'Rapoarte',
    onlinePayments: 'Plăți online și decontare',
    marketing: 'Promovare',
    subscriptions: 'Abonament și facturi MERO',
    checkout: 'Punct de vânzare',
    memberships: 'Abonamente și pachete',
    giftCards: 'Carduri cadou',
    selectAccount: 'Alege cont',
    canNotBeUndoneAction: 'Actiune ireversibilă',
    businessProfileDetails: 'Detalii Profil Business',
    contact: 'Contact Asistență MERO',
    pendingDeletion: 'Solicitarea ta de ștergere a locației este în procesare.',
    viewProfile: 'Vezi profil',
    shareProfile: 'Distribuie profil',
    generalSettings: 'SETĂRI GENERALE',
    marketingSettings: 'MARKETING și COMUNICARE',
    checkoutAndReportsSettings: 'ÎNCASĂRI ȘI RAPOARTE',
    otherSettings: 'ALTELE',
    updateLocation: 'Schimbă locație',
    noReviews: 'evaluări',
    helpCenter: 'Centru de ajutor',
    signOut: 'Ieși din cont',
    unlistedProfile: 'Profilul nu este listat pe MERO',
    searchableRequested: 'Cererea de listare este în așteptare',
    notEnoughReviews: 'Nu sunt suficiente evaluări pentru a afișa o medie',
    location: 'Locație',
    buyProducts: 'Cumpără produse',
    invited: 'invitat',
    onlinePaymentsBalance: 'Balanță plăți online',
    onlinePaymentsBalanceDesc: 'Reprezintă suma plăților online, realizate de clienții tăi',
    EUR: '€',
    RON: 'lei',
  },
  accountBalance: {
    onlinePaymentsBalance: 'Balanță plăți online',
    EUR: '€',
    RON: 'lei',
    total: 'Total balanță',
    pendingTotal: 'Tranzacții în așteptare',
    availableTotal: 'Disponibil transfer',
    manualPayout: 'Decontare manuală',
    manualPayoutDesc: 'Faci transferul manual în contul bancar **{{last4}}',
    automaticPayout: 'Decontare automată',
    automaticPayoutDesc: 'Transferul se face {{frequency}} în contul bancar **{{last4}}',
    Daily: 'automat (zilnic)',
    Weekly: 'automat (săptămânal în ziua de {{day}})',
    Monthly: 'automat (lunar în data de {{day}} a lunii)',
    monday: 'luni',
    tuesday: 'marți',
    wednesday: 'miercuri',
    thursday: 'joi',
    friday: 'vineri',
    saturday: 'sâmbătă',
    sunday: 'duminică',
    onlinePayments: 'Plăți online și decontare',
    onlinePaymentsSettings: 'Setări plăți online și decontare',
    createPayout: 'Transferă {{amount}} {{unit}} în contul **{{last4}}',
    errorUnknown: 'S-a întâmplat ceva neprevăzut ...',
    payoutSuccess: 'Transferul a fost inițiat cu succes',
    transactions: 'Tranzacții',
    finishedTransfers: 'Transferuri efectuate',
    download: 'Descarcă extras',
    noPayments: 'Nu există tranzacții în perioada selectată',
    noPayouts: 'Nu există transferuri efectuate',
    new: 'INCOMPLET',
    newDesc: 'Tranzacție începută de client dar care așteaptă o acțiune suplimentară pentru a putea fi completă.',
    started: 'INCOMPLET',
    failed: 'ANULAT',
    canceled: 'ANULAT',
    canceledDesc: 'Tranzacție anulată de către client.',
    succeeded: 'FINALIZAT',
    succeededDesc: 'Tranzacție finalizată cu succes.',
    paid: 'TRANSFERAT',
    refunded: 'RETURNAT',
    partiallyRefunded: 'RETURNAT PARȚIAL',
    paidDesc: 'Transferul a fost finalizat cu succes. Banii au fost transferați în contul bancar asociat.',
    pending: 'ÎN CURS',
    pendingDesc: 'Transferul a fost inițiat, banii vor ajunge în contul bancar asociat în 1-4 zile bancare. ',
    in_transit: 'ÎN CURS',
    unknownUser: 'Fără nume',
    destinationAccount: 'Către cont bancar IBAN **{{last4}}',
    transferredAt: 'Transmis în',
    failedToDownloadPayments: 'Nu am putut descărca extrasul, te rugăm să încerci din nou',
    totalDesc: 'Total valori tranzacții în așteptare și cele disponibile pentru transfer',
    pendingTotalDesc:
      'Valoarea tranzacțiilor care sunt în procesare și care vor fi disponibile în perioada imediat următare',
    availableTotalDesc:
      'Valoarea tranzacțiilor care s-a procesat și este disponibilă pentru transfer în contul tău bancar',
    transactionStatuses: 'Status tranzacții',
    payoutStatuses: 'Status transferuri bancare',
    returned: 'RETURNAT',
    returnedDesc:
      'Tranzacție returnată de salon. Banii se vor întoarce înapoi pe cardul clientului în 5-10 zile lucrătoare.',
    failedToLoadOnlinePayments: 'Nu am putut încărca balanța plăților online, te rugăm să încerci din nou',
  },
  blockedTime: {
    createBlockedTimeTitle: 'Adaugă timp blocat',
    editBlockedTimeTitle: 'Modifică timp blocat',
    editThisBlockedTime: 'Modifică doar aceast timp blocat',
    editAllBlockedTimeRecurrences: 'Modifică aceast timp blocat și toate repetările viitoare',
    newBlockedTime: 'Adaugă timp blocat',
    selectPro: 'Alege profesionist',
    fullDay: 'Toată ziua',
    proLabel: 'Profesionist',
    reasonLabel: 'Motiv',
    saveChanges: 'Salvează modificări',
    recurrenceRuleError: 'Perioada de repetare trebuie să fie mai mare decât intervalul ales',
    cancelBlockedTimeButton: 'Anulează timp blocat',
    reasonBreak: 'Pauză',
    reasonDayOff: 'Zi liberă',
    reasonVacation: 'Concediu de odihnă',
    reasonSickLeave: 'Concediu medical',
    reasonTraining: 'Training',
    reasonCustom: 'Alt motiv',
    selectReasonError: 'Te rog sa alegi un motiv pentru timpul blocat',
    setCustomReasonTextError: 'Introdu textul motivului',
    selectReason: 'Alege motiv',
    repeat: 'Repetare',
    confirmOverrideTitle: 'Confirmă suprapunere timp blocat',
    confirmOverrideText: 'Aceast timp blocat se va suprapune cu altele. Eşti sigur că vrei să continui?',
    confirmButton: 'Confirmă',
    cancelButton: 'Anulează',
    irreversibleAction: 'Acțiune ireversibilă',
    confirmDeleteTitle: 'Confirmă ștergere timp blocat',
    confirmDeleteText: 'Intervalul de timp blocat va deveni disponibil pentru programări.',
    confirmDeleteButton: 'Șterge timp blocat',
    cancelRecurrentTitle: 'Anulează timp blocat recurent',
    cancelThisBlockedTime: 'Anulează acest interval de timp blocat',
    cancelAllBlockedTimeRecurrences: 'Anulează acest interval și toate repetările viitoare',
  },
  subscription: {
    subscription: 'Abonament',
    subscriptionAndBilling: 'Abonament și facturare',
    billingDetails: 'Date de facturare',
    billingCycle: 'Perioadă de facturare',
    billingCycleSelected: 'Perioada de facturare a fost selectată cu succes',
    availability: 'Valabilitate abonament',
    expired: 'Abonament expirat',
    availabilityWithAutomaticRenewal: 'Abonamentul tău se va reînnoi automat la data de <1>{{date}}</1>',
    availabilityWithoutAutomaticRenewal: 'Plătește abonamentul înainte de data de <1>{{date}}</1>',
    nextPayment: 'Următoarea plată',
    toPay: 'De plată',
    toPayBy: 'De plată în <1>{{date}}</1>',
    payToAvoidAccessRestrictions: 'Plătește și evită restricționarea accesului',
    addVat: '+ TVA',
    withVat: 'TVA inclus',
    EUR: '€',
    RON: 'lei',
    payOnline: 'Plătește cu cardul online',
    otherPaymentMethods: 'Alternative de plată',
    cancelAutoRenewal: 'Anulează plata automată',
    billing: 'Facturare',
    billingInfo: 'Date de facturare',
    download: 'Descarcă',
    company: 'Persoană juridică (PFA/SRL)',
    individual: 'Persoană fizică',
    companyName: 'Denumire persoană juridică',
    companyNameError: 'Te rugăm sa introduci o denumire validă',
    companyNamePlaceholder: 'ex. Barbers SRL',
    cui: 'Cod indentificare fiscală (CIF/CUI)',
    cuiError: 'Te rugăm sa introduci un cod indentificare fiscală valid',
    cuiPlaceholder: 'Introdu codul de identificare fiscală',
    registrationNumber: 'Număr de înregistrare',
    registrationNumberError: 'Te rugăm sa introduci un număr de înregistrare valid',
    registrationNumberPlaceholder: 'Introdu numărul de înregistrare',
    phone: 'Număr de telefon',
    phoneError: 'Te rugăm sa introduci un număr de telefon valid',
    phonePlaceholder: 'Introdu telefon',
    iban: 'Cont IBAN',
    ibanError: 'Te rugăm sa introduci un cont IBAN valid',
    ibanPlaceholder: 'Introdu contul IBAN',
    firstName: 'Prenume',
    firstNameError: 'Te rugăm sa introduci un prenume valid',
    firstNamePlaceholder: 'Introdu prenumele',
    lastName: 'Numele',
    lastNameError: 'Te rugăm sa introduci un nume valid',
    lastNamePlaceholder: 'Introdu numele',
    email: 'Email',
    emailError: 'Te rugăm sa introduci o adresă de e-mail validă',
    emailPlaceholder: 'Introdu adresa de e-mail',
    address: 'Adresă',
    addressError: 'Te rugăm sa introduci o adresă validă',
    addressPlaceholder: 'Introdu adresa',
    county: 'Județ',
    countyPlaceholder: 'Alege județ',
    countyError: 'Te rugăm să alegi un județ',
    city: 'Oraș',
    cityPlaceholder1: 'Alege județ mai întâi',
    cityPlaceholder2: 'Alege oraș',
    cityError: 'Te rugăm să alegi un oraș',
    area: 'Sector',
    areaPlaceholder1: 'Alege oraș mai întâi',
    areaPlaceholder2: 'Alege sector',
    areaError: 'Te rugăm să alegi un sector',
    saveBillingDetailsError: 'Nu am putut salva datele de facturare, te rugăm să încerci din nou',
    selectPaymentMethod: 'Alege metodă de plată',
    errorGettingPendingOrders: 'Nu am putut obține facturile în așteptare',
    cardPayment: 'Plata online cu cardul',
    cardPaymentInfo: 'Suma se va retrage automat de pe card la începutul unei perioade noi de abonament',
    transferPayment: 'Transfer bancar sau numerar',
    transferPaymentInfo:
      'Poți achita abonamentul la bancă prin transfer bancar sau depunere de numerar pe baza facturii proforme. Confirmarea plății poate dura câteva zile și e necesar să ne trimiți o dovadă a plății la contact@mero.ro',
    downloadProforma: 'Descarcă factura proformă',
    uploadProofOfPayment: 'Încarcă dovada plății',
    downloadInvoiceError: 'Nu am putut descărca factura',
    youAreInTrial: 'Ești în perioada de\nevaluare gratuită',
    freemiumText: 'Adaugă 10 programări pentru a\nbeneficia de 14 zile de acces nelimitat',
    freeTrialText_one: 'Mai ai o zi ramasă din \nevaluarea gratuită cu access nelimitat',
    freeTrialText_other: 'Mai ai {{count}} zile ramase din \nevaluarea gratuită cu access nelimitat',
    freeTrialText_few: 'Mai ai {{count}} zile ramase din \nevaluarea gratuită cu access nelimitat',
    step1Of4: 'Pasul 1 din 4',
    step2Of4: 'Pasul 2 din 4',
    step3Of4: 'Pasul 3 din 4',
    stepXofY: 'Pasul {{x}} din {{y}}',
    daysLeft: '{{value}} zile rămase din evaluarea gratuită',
    expiredText: 'Pentru a continua să folosești platforma abonează-te sau <1>contactează-ne</1>',
    notExpired: 'Abonează-te acum sau <1>contactează-ne</1>.\nPoți anula abonamentul oricând.',
    continue: 'Continuă',
    uploadFromGallery: 'Încarcă din galerie foto',
    uploadFromFiles: 'Încarcă din fișiere',
    cancel: 'Anulează',
    downloadEstimateError: 'Nu am putut descărca estimarea',
    uploadPaymentProofError: 'Nu am putut încărca dovada plății',
    payWithCreditCardError: 'Nu am putut plăti cu cardul de credit',
    subscriptionBase: 'Abonament',
    subscriptionBaseSubtitle: '1 profesionist inclus - {{price}} {{currency}} / lună',
    subscriptionWorkerSeat: 'Profesioniști adiționali',
    subscriptionWorkerSeatSubtitle_one: '{{count}} profesionist adițional x {{price}} {{currency}} / lună',
    subscriptionWorkerSeatSubtitle_other: '{{count}} profesioniști adiționali x {{price}} {{currency}} / lună',
    subscriptionWorkerSeatSubtitle_few: '{{count}} profesioniști adiționali x {{price}} {{currency}} / lună',
    subscriptionWorkerSeatProRata: 'Pro-rata profesioniști adiționali',
    subscriptionWorkerSeatProRataSubtitle: 'Rest de plată perioadă anterioară',
    subscriptionSmsNotifications: 'Notificări SMS',
    subscriptionSmsNotificationsSubtitle_one: '{{count}} notificare trimisă x {{price}} {{currency}}',
    subscriptionSmsNotificationsSubtitle_other: '{{count}} notificări trimise x {{price}} {{currency}}',
    subscriptionSmsNotificationsSubtitle_few: '{{count}} notificări trimise x {{price}} {{currency}}',
    subscriptionOutstandingBalance: 'Diferențe plăți anterioare',
    subscriptionOutstandingBalanceSubtitle: '',
    subscriptionBoostCommission: 'Comision MERO Boost',
    subscriptionBoostCommissionDiscount: 'Discount Comision MERO Boost',
    subscriptionBoostCommissionSubtitle: '',
    subscriptionBoostCommissionDiscountSubtitle: '',
    cancelAutomaticRenewalError: 'Nu am putut anula plata automată',
    importantAction: 'Actiune importantă',
    cancelAutoCharge: 'Anulează plata automată a facturilor',
    checkAndConfirm: 'Bifează și confirmă',
    cancelAutoChargeText: 'Va trebui ca la fiecare plată să reintroduci datele de pe card',
    confirmCancel: 'Confirmă anulare',
    almostExpired_one: 'VALABILITATE {{count}} zi',
    almostExpired_other: 'VALABILITATE {{count}} zile',
    almostExpired_few: 'VALABILITATE {{count}} zile',
    almostExpiredText: 'Plătește abonamentul pentru evitarea suspendării contului sau <1>contactează-ne</1>',
    paymentSuccessful: 'Plata a fost efectuată cu succes',
    automaticRenewalError: 'EROARE LA PROCESARE CARD',
    pendingPayment: 'Plată în curs de procesare...',
    subscriptionExpiredText: 'Plătește și reactivează abonamentul sau <1>contactează-ne</1>',
    noBills: 'Nu există facturi',
    noBillsText: 'Facturile pentru abonamentul tău vor aparea aici după efectuarea primei plăți',
    missingBillingInfo: 'Alege date de facturare',
    trial: 'Evaluare gratuită',
    trialDescription: 'Ai nevoie de ajutor pentru a începe sau vrei mai multe detalii? <1>Contactează-ne</1>',
    monthly: '1 lună',
    quarterly: '3 luni',
    halfYear: '6 luni',
    annually: '12 luni',
    stripeTitle: 'Plătește în siguranță',
    stripeSelectPayment: 'Alege metodă de plată',
    stripeNewCard: 'Card nou',
    cardDetails: 'Introdu datele de pe card',
    cardPlaceholder: 'Număr card',
    expirationPlaceholder: 'LL/AA',
    total: 'Total de plată',
    totalValue: '{{value}} lei',
    securePage: 'Pagină securizată',
    stripePaymentSuccess: 'Plata a fost efectuată cu succes',
    stripePaymentFailed: 'Plata nu a putut fi efectuată [{{error}}]',
    stripeCardErrorFailed: 'Plată refuzată de banca emitentă a cardului. Sună la numărul afișat pe card. [{{error}}]',
    paymentIntentError: 'Nu am putut inițializa procesul de plată',
    totalWithVAT: 'Total inclusiv TVA',
    totalWithoutVAT: 'Valoare fără TVA',
    discount: 'Discount',
    discountPercentage: 'Discount {{value}}%',
    noName: 'Fără nume',
    selectBillingDetails: 'Alege date de facturare',
    options: 'Opțiuni',
    editBillingDetails: 'Modifică date de facturare',
    deleteBillingDetails: 'Șterge date de facturare',
    addNewBillingDetails: 'Adaugă date de facturare',
    billingNotFound: 'Nu am putut găsi datele de facturare',
    addCompany: 'Adaugă persoană juridică',
    editCompany: 'Modifică persoană juridică',
    addPerson: 'Adaugă persoană fizică',
    editPerson: 'Modifică persoană fizică',
    addCompanyButton: 'Adaugă persoană juridică (SRL, PFA, etc.)',
    addPersonButton: 'Adaugă persoană fizică',
    name: 'Denumire persoană juridică',
    namePlaceholder: 'ex. Barbers SRL',
    nameError: 'Introdu denumirea persoanei juridice',
    fiscalCode: 'Cod indentificare fiscală (CIF/CUI)',
    fiscalCodePlaceholder: 'Introdu codul de identificare fiscală',
    fiscalCodeError: 'Introdu codul de identificare fiscală',
    regNo: 'Nr. registrul comerțului',
    regNoPlaceholder: 'Introdu nr. reg. com.',
    regNoError: 'Introdu nr. reg. com.',
    saveCompany: 'Adaugă persoană juridică',
    savePerson: 'Adaugă persoană fizică',
    saveChanges: 'Salvează modificări',
    vatChecked: 'Plătitor de TVA ({{value}}%)',
    vatUnchecked: 'Plătitor de TVA',
    deleteCompany: 'Șterge date de facturare',
    contactInfo: 'Date de contact',
    contactName: 'Nume și prenume',
    contactNamePlaceholder: 'Introdu numele și prenumele',
    contactNameError: 'Introdu numele și prenumele',
    firstname: 'Prenume',
    firstnamePlaceholder: 'Introdu prenumele',
    firstnameError: 'Introdu prenumele',
    lastname: 'Nume',
    lastnamePlaceholder: 'Introdu numele',
    lastnameError: 'Introdu numele',
    done: 'Gata!',
    billingDetailsUpdated: 'Datele de facturare au fost actualizate cu succes',
    billingDetailsSaved: 'Datele de facturare au fost salvate cu succes',
    billingDetailsSelected: 'Datele de facturare au fost selectate cu succes',
    back: 'Înapoi',
    irreversibleAction: 'Acțiune ireversibilă',
    checkAndConfirmDelete: 'Bifează și confirmă ștergerea',
    deleteBilling: 'Confirmă ștergerea',
    deleteBillingDetailsCompany: 'Confirmă ștergere persoană juridică',
    deleteBillingDetailsPerson: 'Confirmă ștergere persoană fizică',
    deleteBillingDetailsCompanyCheck1: 'Încasările finalizate și rapoartele <1>NU</1> vor fi afectate.',
    deleteBillingDetailsCompanyCheck2:
      'Datele vor fi șterse permanent și persoana juridică nu va mai fi afișată pentru încasări.',
    deletePerson: 'Datele persoanei fizice vor fi șterse permanent',
  },
  reviews: {
    reviews: 'Recenzii clienți',
    totalReviews_one: '(o evaluare)',
    totalReviews_other: '({{count}} evaluări)',
    totalReviews_few: '({{count}} evaluări)',
    noReviewsTitle: 'Nu există recenzii',
    noReviewsText: 'Amintește-le clienților să lase o recenzie pe mero.ro după încheierea programării',
    notEnoughReviews: 'Media evaluărilor va fi afișată pe pagina ta de pe mero.ro la minim {{value}} evaluări',
    allPros: 'Toți profesioniștii',
    allStars: 'Toate evaluările',
    oneStar: 'Evaluări de 1 stea',
    twoStar: 'Evaluări de 2 stele',
    threeStar: 'Evaluări de 3 stele',
    fourStar: 'Evaluări de 4 stele',
    fiveStar: 'Evaluări de 5 stele',
    noReviews: 'Nu există recenzii',
    addReply: 'Adaugă răspuns',
    changeReply: 'Modifică răspuns',
    deleteReply: 'Șterge răspuns',
    replyDialogDescription: 'Răspunsul va fi vizibil pentru toți clienții pe profilul tău MERO',
    replyPlaceholder: 'Introdu răspunsul',
    replyError: 'Trebuie să adaugi un răspuns',
    replySuccess: 'Răspunsul a fost adăugat cu succes',
    addReplyButton: 'Adaugă',
    changeReplyButton: 'Modifică',
    cancelButton: 'Anulează',
    deleteAction: 'Actiune ireversibilă',
    deleteTitle: 'Confirmă ștergerea răspunsului',
    deleteDescription: 'Răspunsul la această evaluare va fi șters de pe profilul tău MERO.',
    deleteButton: 'Confirmă ștergere',
    options: 'Opțiuni',
    ownReviewsSettings: 'Opțiuni notificări proprii',
    reviewsSettings: 'Opțiuni recenzii',
    proReviews: 'Recenzii per profesionist',
    enableProOwnReviews: 'Afișează media și recenzii individuale pe fiecare profesionist',
    enableProOwnReviewsDescription: 'Clienții vor putea vedea media și recenziile fiecărui profesionist',
    saveChanges: 'Salvează modificări',
    savedSuccessfully: 'Modificările au fost salvate cu succes',
  },
  giftCards: {
    giftCards: 'Carduri Cadou',
    giftCardsText: 'Vrei să știi exact cum funcționează? <1>Vezi detalii</1>',
    detailsGiftCards: 'Detalii Carduri Cadou',
    salonGiftCardTitle: 'Activează Carduri Cadou Proprii',
    salonGiftCardSubtitle:
      'Vinzi carduri cadou direct pe profilul tău MERO iar clienții le pot folosi la programări exclusiv în salonul tău.',
    salonGiftCardFees: 'Comision procesator plăți {{feeText}}',
    generalGiftCardTitle: 'Activează Carduri Cadou MERO',
    generalGiftCardSubtitle:
      'Cardurile Cadou MERO sunt valabile în locațiile care activează opțiunea, oferind expunere mai mare salonului și clienți noi',
    monthlyInvoicesError: 'Nu am putut descărca facturile lunare',
    q1Title: 'Care sunt avantajele plății cu cardul?',
    q1Answer:
      'Interesul clienților e mai mare atunci când au multiple opțiuni de plată. Astfel îți crești baza de clienți și ești mai competitiv.',
    q2Title: 'Cum activez plata cu cardul?',
    q2Answer:
      'Activarea plății cu cardul se face prin crearea unui un cont Stripe unde vei introduce datele despre firma/PFA şi IBAN-ul contului bancar unde vor intra banii.',
    q3Title: 'Care sunt costurile?',
    q3Answer:
      'Comisionul Stripe este de 1.4% + 1RON din fiecare tranzacţie procesată cu succes. **MERO are 0 COMISION**.\n<1>Exemplu: pentru o programare cu valoarea de 50 de lei, comisionul este de 1.7 RON (1RON + 1.4%x50).</1>',
    q4Title: 'Trebuie să emit factură fiscală?',
    q4Answer:
      'MERO generează automat factură fiscală pentru fiecare plată procesată în contul tău. **Nu trebuie emis bon fiscal.**',
    q5Title: 'Ai nevoie de ajutor sau ai alte întrebări?',
    q5Answer: 'Ne ezita să <1>contactezi echipa de suport</1> pentru orice nelămurire.',
    download: 'Descarcă',
    bills: 'Facturi clienți și decont',
    downloadInvoiceError: 'Nu am putut descărca factura',
    payStripe: 'Continuă la activarea plății prin Stripe',
    addBusinessDetails: 'Introdu datele de persoană juridică',
    noBills: 'Facturile emise către clienții tăi și\ndeconturile bancare vor fi afișate aici',
    billingDetails: 'Date persoană juridică',
    billingDetailsDescription:
      'Acestea sunt necesare pentru a putea emite facturi către clienți iar contul bancar IBAN pentru a încasa valoarea Cardurilor Cadou folosite la programări.',
    companyName: 'Denumire persoană juridică',
    companyNameError: 'Te rugăm sa introduci o denumire validă',
    companyNamePlaceholder: 'ex. Barbers SRL',
    cui: 'Cod indentificare fiscală (CIF/CUI)',
    cuiError: 'Te rugăm sa introduci un cod indentificare fiscală valid',
    cuiPlaceholder: 'Introdu codul de identificare fiscală',
    registrationNumber: 'Număr de înregistrare',
    registrationNumberError: 'Te rugăm sa introduci un număr de înregistrare valid',
    registrationNumberPlaceholder: 'Introdu numărul de înregistrare',
    phone: 'Număr de telefon',
    phoneError: 'Te rugăm sa introduci un număr de telefon valid',
    phonePlaceholder: 'Introdu telefon',
    iban: 'Cont IBAN pentru decontare',
    ibanError: 'Te rugăm sa introduci un cont IBAN valid',
    ibanPlaceholder: 'Introdu contul IBAN',
    firstName: 'Prenume',
    firstNameError: 'Te rugăm sa introduci un prenume valid',
    firstNamePlaceholder: 'Introdu prenumele',
    lastName: 'Numele',
    lastNameError: 'Te rugăm sa introduci un nume valid',
    lastNamePlaceholder: 'Introdu numele',
    email: 'Adresă de e-mail',
    emailError: 'Te rugăm sa introduci o adresă de e-mail validă',
    emailPlaceholder: 'Introdu adresa de e-mail',
    address: 'Adresă',
    addressError: 'Te rugăm sa introduci o adresă validă',
    addressPlaceholder: 'Introdu adresa',
    county: 'Județ',
    countyPlaceholder: 'Alege județ',
    countyError: 'Te rugăm să alegi un județ',
    city: 'Oraș',
    cityPlaceholder1: 'Alege județ mai întâi',
    cityPlaceholder2: 'Alege oraș',
    cityError: 'Te rugăm să alegi un oraș',
    saveBillingDetailsError: 'Nu am putut salva datele de facturare, te rugăm să încerci din nou',
    stripe: 'Activează contul Stripe',
    connectStripeError: 'Nu am putut activa contul Stripe',
    goToStripeError: 'Nu am putut activa contul Stripe',
    toggleSalonGiftCardError: 'Nu am putut modifica setările la cardul cadou propriu',
    toggleSalonGiftCardSuccess: 'Cardul cadou propriu a fost activat',
    toggleUniversalGiftCardError: 'Nu am putut modifica setările la cardul cadou universal',
    toggleUniversalGiftCardSuccess: 'Cardul cadou universal a fost activat',
    billingInfo: 'Date de facturare și decont',
    missingBillingInfo: 'Alege date de facturare și decont',
    saveChanges: 'Salvează modificări',
    enableCardPayment: 'Activează plata online cu cardul',
    billingInfoError: 'Te rugăm să completezi datele de facturare și decontare',
    changesSaved: 'Modificările au fost salvate cu succes',
  },
  pros: {
    noAccess: 'Nu ai acces la această pagină',
    pros: 'Echipă',
    sort: 'Ordonează',
    allLocations: 'Toate locațiile',
    add: 'Adaugă',
    admin: 'Administrator',
    frontOffice: 'Recepție',
    invited: 'invitat',
    worker: 'Profesionist',
    addPro: 'Adaugă persoană / angajat',
    invitePro: 'Invită profesionist cu profil existent MERO',
    inviteProSubtitle: 'Profesionistul achită propriul abonament',
    acceptsAppointments: 'Calendar programări',
    doesntAcceptAppointments: 'Administrativ',
    sortPros: 'Setează ordine profesioniști',
    team: 'Echipă',
    sortProsDescription: 'Ordinea profesioniștilor se va reflecta pe profilul online MERO.',
    pro: 'Profesionist',
    guest: 'Profesionist (invitat)',
    profileDetails: 'Detalii profil',
    calendarSettings: 'Setări programări',
    calendarSettingsDescriptionPositive: 'Acceptă programări',
    calendarSettingsDescriptionNegative: 'Nu acceptă programări',
    calendarSettingsDescription2: 'Profil privat',
    schedule: 'Program de lucru',
    servicesCustom: 'Personalizare servicii',
    activeServices_one: '{{count}} serviciu selectat',
    activeServices_other: '{{count}} servicii selectate',
    activeServices_few: '{{count}} servicii selectate',
    permissions: 'Acces și permisiuni',
    editPhotoDetail: 'Poza de profil dă încredere clienților că se programează la persoana potrivită.',
    firstName: 'Prenume',
    firstNameError: 'Trebuie să introduci un prenume',
    firstNamePlaceholder: 'Introdu prenumele',
    lastName: 'Nume',
    lastNameError: 'Trebuie să introduci un nume',
    lastNamePlaceholder: 'Introdu numele',
    phone: 'Telefon',
    phoneError: 'Trebuie să introduci un număr de telefon valid',
    phonePlaceholder: 'Introdu numărul de telefon',
    location: 'Locație activitate',
    addPhoto: 'Încarcă o nouă poză profil',
    deletePhoto: 'Șterge poză profil',
    deleteProfile: 'Șterge profesionist',
    save: 'Salvează',
    saveChanges: 'Salvează modificări',
    profileCalendar: 'Setări programări',
    isActiveTitle: 'Calendar de programări',
    isActiveDescription: 'Persoana va avea calendar propriu și acceptă programări pe MERO',
    isPrivateTitle: 'Profil privat pentru clienții noi',
    isPrivateDescription:
      'Doar clienții noi aprobați de tine se vor putea programa. Clienții existenți se pot programa direct.',
    customBookingIntervalTitle: 'Intervale programări afișate clienților',
    customBookingIntervalSubtitle: 'Definește intervalele de timp la care clienții își pot face programări.',
    calendarSlotsIntervalsTitle: 'Intervale programări clienți',
    calendarSlotsIntervalsDescription: 'Ex: serviciul de 1h 30m, va rezulta în intervalele: 9:00, 10:30, 12:00, 13:30',
    calendarIntervalTitle: 'Disponibilitate programări în viitor',
    calendarIntervalDescription: 'Limitează perioada calendarului în care clienții își pot face programare.',
    calendarAvailabilityWeek: 'Maxim {{count}} săptămâni',
    appointmentPerClientLabel_one: 'Maxim {{count}} programare pe client',
    appointmentPerClientLabel_other: 'Maxim {{count}} programări pe client',
    appointmentPerClientLabel_few: 'Maxim {{count}} programări pe client',
    maxAppointmentsPerClientTitle: 'Programări în avans de la același client',
    maxAppointmentsPerClientDescription:
      'Limitează numărul maxim de programări pe care un client le poate face în avans.',
    minHoursBeforeClientsCanCancelAppointmentsTitle: 'Limită anulare programări',
    minHoursBeforeClientsCanCancelAppointmentsDescription:
      'Limitează cu cât timp înainte clienții își vor putea anula programarea.',
    cancelHourLabel: '{{count}}h înainte de programare',
    minHoursBeforeTodayAppointmentsTitle: 'Limită programări în ziua curentă',
    minHoursBeforeTodayAppointmentsDescription:
      'Limitează cu cât timp înainte de ora curentă un client face programarea, evitând suprapunerile cu clienții fără programare (drop-in)',
    maxWaitingListDaysPerClientTitle: 'Limită înscrieri pe lista de așteptare',
    maxWaitingListDaysPerClientDescription:
      'Limitează numărul de zile viitoare în care același client se poate înscrie pe lista de așteptare',
    waitingListDayPerClientLabel: 'Maxim 1 zi de la același client',
    waitingListDaysPerClientLabel: 'Maxim {{count}} zile de la același client',
    beforeAppointmentLabel: '{{count}}h înainte de ora curentă',
    synchronizeTitle: 'Sincronizare cu calendarul telefonului',
    synchronizeDescription:
      'Afișează programările în aplicaţia de calendar a telefonului. Actualizarea se face din oră în oră.',
    synchronizeCalendar: 'Sincronizează calendar',
    proSchedule: 'Program de lucru',
    proScheduleDescription: 'Alege intervalul orar în care <1>{{name}}</1> poate primi programări.',
    proScheduleNewDescription: 'Alege intervalul orar în care se pot primi programări.',
    free: 'NU LUCREAZĂ',
    proServices: 'Personalizare servicii',
    proServicesDescription: 'Personalizează prețul, durata și alte detalii ale serviciilor pentru <1>{{name}}</1>',
    proServicesNewDescription: 'Personalizează prețul, durata și alte detalii ale serviciilor',
    services: '{{count}} serviciu',
    services_one: '{{count}} serviciu',
    services_other: '{{count}} servicii',
    services_few: '{{count}} servicii',
    selectServices: 'Alege serviciile realizate',
    selectServicesDescription: 'Alege serviciile care vor fi oferite de acest profesionist',
    allServices: 'Toate serviciile ({{count}})',
    proEditServiceDescription:
      'Poți seta preț, durată și setări personalizate pentru <1>{{name}}</1>. Restul detaliilor pot fi schimbate doar la nivel de salon.',
    proEditServiceNewDescription:
      'Poți seta preț, durată și setări personalizate. Restul detaliilor pot fi schimbate doar la nivel de salon.',
    serviceDuration: 'Durată serviciu',
    serviceDurationError: 'Te rugăm să alegi o durată mai mare de sau egală cu 1 minute',
    serviceAvailability: 'Disponibilitate serviciu',
    serviceAvailabilityError: 'Alege cel puțin o zi in care vei oferi serviciul',
    serviceColor: 'Culoare în calendarul de programări',
    priceType: 'Tip preț',
    priceOffer: 'Promoție',
    pricePlaceholder: 'Introdu prețul',
    priceError: 'Trebuie să introduci preț pentru serviciu',
    discount: 'Preț promoțional (lei)',
    discountPlaceholder: 'Introdu prețul',
    discountError: 'Te rugăm să introduci preț pentru promoție',
    paymentValueError: 'Trebuie să introduci o valoare mai mare de 0',
    rangePrice: 'Valoare (lei)',
    startPricePlaceholder: 'Prețul minim',
    startPriceError: 'Trebuie să introduci un preț minim',
    endPricePlaceholder: 'Prețul maxim',
    endPriceError: 'Trebuie să introduci un preț maxim',
    resetService: 'Resetează detalii personalizate',
    resetServiceDescription:
      'Odată cu resetarea detaliilor personalizate,\nse vor folosi cele definite la nivel de cont.',
    savedSuccessfully: 'Modificările au fost salvate cu succes',
    errorDetails: 'Nu am putut salva modificările, încercă din nou.',
    addNewPro: 'Adaugă profesionist',
    email: 'Adresă e-mail',
    emailPlaceholder: 'Introdu adresa de e-mail',
    emailError: 'Te rugăm sa introduci o adresă de e-mail validă',
    inviteProTitle: 'Invită și creează cont MERO Pro',
    inviteProDescription:
      'Trimite un link pentru descărcarea aplicației MERO Pro și accesarea calendarului propriu de programări',
    noServicesTitle: 'Nu există servicii selectate',
    noServicesDescription: 'Te rugăm să alegi cel puțin un serviciu',
    inviteProScreen: 'Invită profesionist',
    inviteProScreenDescription:
      'Introdu numărul profesionistului, cu cont existent MERO PRO, pe care vrei să îl inviți în echipa ta.',
    searchByNumber: 'Caută după telefon',
    noProFound: 'Nu am găsit nici un profesionist cu\nnumărul <1>{{number}}</1>',
    contact: 'Contactează-ne',
    sendInvitation: 'Trimite invitație',
    selectedServices: 'Servicii selectate',
    invitationSent: 'Invitație trimisă',
    invitationSentDescription:
      '<0>{{name}}</0> trebuie mai întâi să accepte invitația ta, iar apoi va apărea în lista profesioniștilor din contul tău. ',
    ok: 'Ok, am înțeles',
    searchError: 'S-a produs o eroare la căutare',
    invite: 'Invită',
    cancel: 'Anulează',
    deleteWorker: 'Șterge profesionistul',
    irreversibleAction: 'Actiune ireversibilă',
    confirmDelete: 'Confirmă ștergere profesionist',
    checkToConfirm: 'Bifează și confirmă ștergerea',
    deleteCheck1: 'Toate detalile profesionistului vor fi șterse și nu va mai avea acces la contul afacerii tale.',
    deleteCheck2: 'Profesionistul nu va mai fi vizibil pe profilul online al locației.',
    deleteCheck3: 'Calendarul și programările profesionistului vor fi șterse <1>IREVERSIBIL</1>.',
    confirmDeleteWorkerCalendar: 'Confirmă ștergere calendar profesionist',
    deleteWorkerCalendarCheck1:
      'Toate detaliile profesionistului vor fi șterse și nu va mai avea un calendar pentru programări.',
    deleteWorkerCalendarCheck2: 'Profesionistul nu va mai fi vizibil pe profilul online al locației.',
    deleteWorkerCalendarCheck3: 'Calendarul și programările profesionistului vor fi șterse <1>IREVERSIBIL</1>.',
    confirmDeleteWorkerCalendarButton: 'Șterge calendar',
    confirm: 'Confirmă',
    addProSeat: 'Adaugă profesionist',
    addProSeatDescription1: 'Prețul unui profesionist adițional este de {{addonPrice}} € / lună + TVA',
    addProSeatDescription2:
      'La reînnoirea abonamentului, în <1>{{expiresAt}}</1>, vei plăti proporțional cu numărul de zile rămase suma adițională de <3>{{totalPrice}} €</3> + TVA.',
    proPermissions: 'Acces și permisiuni',
    accessType: 'Tip acces',
    rolePermission1: 'Acces calendar programări',
    rolePermission2: 'Acces profil propriu',
    rolePermission3: 'Acces clienți',
    rolePermission4: 'Acces setări servicii',
    rolePermission5: 'Acces setări profesioniști',
    rolePermission6: 'Acces clienți în așteptare',
    rolePermission7: 'Acces rapoarte vânzări',
    rolePermission8: 'Acces setări profil',
    rolePermission9: 'Acces setări marketing',
    rolePermission10: 'Acces setări notificări',
    rolePermission11: 'Poate șterge pagina',
    rolePermission12: 'Recenzii',
    noPhone: 'Fără telefon',
    proServiceSettingsLabel1: 'Afișează serviciul pe profilul MERO',
    proServiceSettingsDescription1:
      'Activează pentru a afișa serviciul pe profilul tău online și a permite clienților să își facă programări.',
    proServiceSettingsLabel2: 'Confirmare manuală programări',
    proServiceSettingsDescription2:
      'Activează pentru a putea confirma sau refuza fiecare solicitare de programare de la clienți.',
    done: 'Gata',
    continue: 'Continuă',
    phoneNotVisible: 'Telefonul NU va fi afișat clienților pe MERO',
    phoneAlreadyExists: 'Numărul de telefon introdus este deja utilizat în echipa ta. Introdu un alt număr de telefon',
    daySelectorDescription: 'Alege zilele în care clienții pot face programare la acest serviciu',
    invitedProWarning: 'Doar profesionistul invitat poate modifica aceste detalii din contul propriu',
    invitedProWarning2: 'Poți modifica aceste detalii din profilul propriu',
    noName: 'Fără nume',
  },
  marketing: {
    title: 'Marketing',
    activated: 'Activ',
    deactivated: 'Dezactivat',
    boost: 'Boost',
    boostActivated: 'Atragi clienti noi cu profilul tău promovat',
    boostDeactivated: 'Activează pentru a obține clienți noi',
    totalValue: 'Val. prog.',
    cost: 'Cost',
    scheduled: 'Programări',
    profit: 'Profit',
    currency: 'lei',
    currentMonth: 'Luna curentă ({{month}})',
    profitBoost: 'Profit Boost',
    profitBoostTooltip:
      'Profit Boost\nReprezintă valoarea totală a programărilor generată de către clienții veniți prin MERO Boost, minus comisionul primei programări.',
    costBoost: 'Cost Boost',
    newClientsBoost: 'Clienți noi boost',
    newClientsBoostTooltip:
      'Clienți noi Boost\nReprezintă numărul total de clienți noi veniți prin MERO Boost , în perioada selectată.',
    appointmentsFromBoost: 'Total programări clienți boost',
    appointmentsFromBoostTooltip:
      'Total programări clienți boost\nTotalul programărilor clienților veniți prin Boost inclusiv programări recurente de la aceștia.',
    valueAppointments: 'Valoare programări',
    valueAppointmentsTooltip:
      'Valoare programări\nReprezintă valoarea totală a programărilor generată de către clienții veniți prin MERO Boost, bazată pe prețul întreg (exclusiv reducere), în perioada selectată.',
    averageAppointmentValue: 'Valoare medie programare',
    averageAppointmentValueTooltip:
      'Valoare medie programare\nReprezintă valoarea medie a programărilor Boost, calculată ca suma totală a programărilor bazată pe prețul întreg (exclusiv reducere), împărțit la numărul total al programărilor, în perioada selectată',
    newClients: 'Clienți noi',
    returnedClients: 'Reveniți',
    claimedClients: 'Revendicați',
    emptyNew: 'Clienții noi aduși prin MERO Boost vor apărea aici',
    emptyClaimed: 'Clienții aduși prin Boost revendicați ca fiind clienții tăi',
    emptyReturned: 'Clienții care revin pentru o nouă programare vor apărea aici',
    clientDetails: 'Profit {{profit}} lei • Cost {{cost}} lei',
    claimPending: 'REVENDICARE ÎN AȘTEPTARE',
    newClientsCount: 'Clienți noi ({{count}})',
    returnedClientsCount: 'Reveniți ({{count}})',
    claimedClientsCount: 'Revendicați ({{count}})',
    claim: 'Revendică',
    pending: 'În așteptare',
    cancelClaim: 'Închide',
    sendClaim: 'Trimite solicitare',
    claimHeader: 'solicită revendicare client',
    claimDialogTitle: 'Alege motivul pentru care acest client nu ar trebui comisionat',
    claimDialogText1: 'Clientul a avut programări anterioare',
    claimDialogText2: 'Cunosc clientul personal',
    claimDialogText2Error: 'Te rugăm sa introduci un număr de telefon valid',
    claimDialogText3: 'Comisionul aplicat e incorect',
    claimDialogText4: 'Alt motiv',
    onlineLink: 'Adresă profil MERO',
    share: 'Distribuie',
    viewProfile: 'Vezi profil',
    claimFailed: 'Nu am putut revendica clientul, te rugăm să încerci din nou',
    ok: 'Ok, am înțeles',
    dummyPhone: '07xx xxx xxx',
    phonePlaceholder: 'Introdu numărul de telefon',
    enableBoostButton: 'Activează și obține mai mulți clienți noi',
    boostDescriptionCanBeEnabled: 'Activează pentru a obține clienți noi',
    boostDescriptionCantBeEnabled: 'Te vom anunța când vei putea activa Boost',
    enableBoostDisabledDescription:
      'Boost nu poate fie activat momentan datorită scorului de calitate al profilului tău. <1>Vezi detalii</1>',
    cancelBoostButton: 'Anulare reînnoire automată Boost',
    checkBoostReport: 'Vezi raport performanță',
    configureBoost: 'Configurare Boost',
    budget: 'Buget',
    budgetDescription: 'Bugetul reprezintă comisionul maxim acumulat în fiecare perioadă de 14 zile Boost',
    budgetInputLabel: 'Buget maxim pe 14 zile (inclusiv TVA)',
    budgetInputError: 'Te rugăm să introduci un buget mai mare de {{value}} {{currency}}',
    budgetInputPlaceholder: 'Introdu buget',
    budgetWarning: 'La depășirea bugetului, ori prin scăderea scorului de calitate, Boost se va dezactiva automat',
    period: 'Perioadă',
    periodDescription:
      'Boost se va reînnoi automat cu perioade consecutive de câte 14 zile.\n<1>Poți opri reînnoirea automată oricând.</1>',
    from: 'De la',
    to: 'Până la',
    boostConditions:
      'Accept să plătesc <1>{{percentage}}% + TVA (minim {{value}} {{currency}} + TVA)</1> din valoarea primei programări a unui client nou venit din Boost.',
    boostConditions2: 'Programările ulterioare ale clientului <1>nu vor fi comisionate</1>',
    boostConditions3: 'Programările cu voucher de reducere oferit de MERO vor fi compensate din comisionul Boost',
    boostConditionError: 'Pentru a activa Boost trebuie să accepți condițiile de utilizare',
    enableBoost: 'Activează Boost',
    boostMarketing: 'Activează Boost',
    boostMarketingLabel: 'Câștigă mai mulți clienți noi cu MERO Boost',
    boostMarketingList1: 'Listare promovată a profilului în topul orașului pe MERO și la parteneri',
    boostMarketingList2: 'Plătești doar dacă clientul nou se programează din listarea promovată',
    boostMarketingList3: 'Clienții tăi, clienții reveniți, anulările și neprezentările <1>nu se comisionează</1>',
    boostMarketingList4: '{{percentage}}% + TVA <1>doar din prima programare</1> a unui client nou',
    unavailableBoostMarketingTitle: 'BOOST NU POATE FI ACTIVAT ACUM',
    unavailableBoostMarketingDescription:
      'Scorul Boost are la bază următorii factori:\n' +
      '• trecerea programărilor viitoare\n' +
      '• performanța Boost în perioada anterioară\n' +
      '• rata de revendicare a clienților\n' +
      '• numărul de recenzii și media acestora',
    continue: 'Continuă',
    marketingMoreDetails: 'Vrei mai multe detalii? <1>Contactează-ne</1>',
    whyNotDetails:
      'BOOST NU POATE FI ACTIVAT MOMENTAN.\n' +
      'Scorul Boost al profilului tău e calculat pe baza la următorii factori:\n' +
      '• numărul de recenzii sau media acestora\n' +
      '• trecerea programărilor viitoare\n' +
      '• performanța Boost în perioada anterioară\n' +
      '• rata de revendicare a clienților',
    confirm: 'Ok, am înțeles',
    whyNotWarning:
      'Boost nu poate fi activat momentan datorită scorului de calitate al profilului tău. <1>Vezi detalii</1>',
    RON: 'lei',
    EUR: 'euro',
    confirmCancelBoost: 'Confirmă anulare Boost',
    confirmCancelBoostDescription: 'Confirmă anulare reînnoire automată Boost',
    cancelBoostEnd: 'Boost nu va mai fi reînnoit automat după {{date}}',
    cancelBoostCheck1: 'Boost va continua să fie activ și să promoveze profilul tău până în {{date}}',
    close: 'Închide',
    cancelBoost: 'Anulează reînnoire',
    boostWillStartNoBudget: 'Activare în {{date}}',
    boostWillStart: 'Max. {{value}} {{currency}} / 2 săpt. • Activare în {{date}}',
    boostWillEndNoBudget: 'Activ până în {{date}}',
    boostWillEnd: 'Max. {{value}} {{currency}} / 2 săpt. • Activ până în {{date}}',
    boostWillRenewNoBudget: 'Reînnoire în {{date}}',
    boostWillRenew: 'Max. {{value}} {{currency}} / 2 săpt. • Reînnoire {{date}}',
    boostInactive: 'Activează pentru a obține clienți noi',
    boostUnavailable: 'Te vom anunța când vei putea activa Boost',
  },
  reports: {
    title: 'Rapoarte',
    currentMonth: '{{start}} - {{end}}',
    all: 'Toți profesioniștii',
    allAppointments: 'Programări',
    finalAppointments: 'Finalizate',
    addedAppointments: 'Adăugate',
    canceledAppointments: 'Anulate',
    missingAppointments: 'Neprezentări',
    unconfirmedAppointments: 'Neconfirmate',
    futureAppointments: 'Viitoare',
    currency: 'LEI',
    totalSales: 'Programări finalizate',
    newClients: 'Clienți noi',
    existingClient: 'Clienți reveniți',
    totalClient: 'Total clienți',
    rangeValue: 'Valoare medie programare',
    occupancy: 'Ocupare calendar',
    allocatedTime: 'Timp alocat',
    reservedTime: 'Timp rezervat',
    blockedTime: 'Timp blocat',
    unreservedTime: 'Timp nerezervat',
    topServices: 'Top servicii rezervate',
    service: 'Serviciu',
    reservations: 'Programări',
    value: 'Valoare',
    interval: 'Perioadă  raportare',
    cancel: 'Anulează',
    save: 'Confirmă',
    customInterval: 'Altă perioadă',
    summary: 'Sumar',
    clients: 'Clienți',
    services: 'Servicii',
    pros: 'Profesioniști',
    filters: 'Filtre',
    noName: 'Fără nume',
    clientColumn: 'Client',
    appointmentsColumn: 'Programări',
    appointmentsCountColumn: 'Nr. programări',
    allColumn: 'Toate',
    servicesColumn: 'Servicii',
    servicesCountColumn: 'Nr. servicii',
    servicesValueColumn: 'Val. servicii prestate',
    productsColumn: 'Produse',
    productsCountColumn: 'Nr. produse',
    membershipsColumn: 'Abonamente',
    membershipsCountColumn: 'Nr. abonamente',
    membershipConsumptionColumn: 'Val. consum abonamente',
    netColumn: 'Vânzări nete',
    netTotalColumn: 'Total net',
    grossColumn: 'Vânzări brute',
    grossTotalColumn: 'Total brut',
    vatColumn: 'TVA',
    protocolColumn: 'Protocol',
    discountColumn: 'Reduceri',
    totalColumn: 'Total vânzări',
    totalWithVatColumn: 'Total incl. TVA',
    saleType: 'Tip vânzare',
    allSales: 'Toate vânzările',
    servicesSales: 'Vânzări servicii',
    clientDownload: 'Descarcă',
    clientDownloadXlxs: 'Descarcă în format .xlsx',
    clientDownloadCsv: 'Descarcă în format .csv',
    failedToDownloadReport: 'Nu am putut descărca raportul, te rugăm să încerci din nou',
    serviceNameColumn: 'Serviciu',
    productNameColumn: 'Produs',
    chargedColumn: 'Încasări',
    quantityColumn: 'Cantitate',
    RON: 'lei',
    allPros: 'Toți profesioniștii',
    groups: 'Categorii',
    allGroups: 'Toate categoriile',
    proNameColumn: 'Profesionist',
    payment: 'Metode de plată',
    dateColumn: 'Data',
    cashColumn: 'Numerar',
    cardColumn: 'Card',
    bankTransferColumn: 'Transfer bancar',
    paymentDistribution: 'Distribuție metode de plată',
    totalRow: 'Total',
    revenue: 'Venituri',
    membership: 'Abonamente',
    products: 'Produse',
    giftCards: 'Carduri cadou',
    revenueMissed: 'Venituri nerealizate',
    revenueFuture: 'Venituri viitoare',
    newClientsOnline: 'NOI (online)',
    newClientsOffline: 'NOI (alte surse)',
    returnedClients: 'REVENIȚI',
    amounts: 'Alte sume',
    top10Services: 'Top servicii programate',
    unknownUser: 'Fără nume',
    monthlyReport: 'Raport lunar',
    monthlyReportTitle: 'Raport lunar de performanță {{company}}',
    monthlyReportSubtitle: 'Informațiile sunt 100% confidențiale și NU sunt partajate cu terțe părți',
    appointments: 'Programări',
    clientsOnlineMonthly: 'Clienți noi (online)',
    clientsOfflineMonthly: 'Clienți noi (alte surse)',
    returnedClientsMonthly: 'Clienți reveniți',
    monthlyReportDetails:
      'Vezi rapoarte detaliate venituri, profesioniști,\nclienți, servicii în aplicația MERO Pro\nsecțiunea Rapoarte',
    detailedReportsButton: 'Vezi rapoarte detaliate',
    proDetailsTitle: `Fișă detaliată {{name}}`,
    proServiceDateName: 'Data',
    proServiceClientName: 'Client',
    proServicesServiceName: 'Serviciu',
    proServicesServiceTotal: 'Încasare',
    proServicesMembership: 'Abonament',
    proServicesProtocol: 'Protocol',
    proServicesTotal: 'Total',
    proServicesEmpty: 'Pentru perioda selectată nu există date',
    consumableProducts: 'Produse consumate',
    offlineRetailProducts: 'Produse vândute',
    proProductDateName: 'Data',
    proProductClientName: 'Client',
    proProductName: 'Produs',
    proProductTotal: 'Încasare',
    proProductMembership: 'Abonament',
    proProductProtocol: 'Protocol',
    proProductQuantity: 'Cantitate',
    proProductsEmpty: 'Pentru perioda selectată nu există date',
  },
  cashRegistry: {
    title: 'Registru de casă',
    currentMonth: '{{start}} - {{end}}',
    options: 'Opțiuni',
    chooseCashRegister: 'Alege registru de casă',
    exportPDF: 'Exportă în format PDF',
    exportExcel: 'Exportă în format Excel',
    setInitialBalance: 'Setează soldul inițial',
    addInitialBalance: 'Adaugă soldul inițial',
    intialBalance: 'Sold inițial (lei)',
    setInitialBalanceDescription: 'Acest sold este soldul de pornire pentru Registrul de casă',
    deleteCashRegistry: 'Șterge registrul de casă',
    noCompany: 'Nu există persoane juridice',
    noCompanyDescription: 'Pentru a utiliza registrul de casă adaugă o persoană juridică în pagina Încasări',
    addCompany: 'Adaugă persoana juridică',
    date: 'La data',
    dateLabel: 'Data',
    cancelButton: 'Anulează',
    addButton: 'Adaugă',
    currentBalance: 'Sold curent:',
    addTransaction: 'Adaugă operațiune',
    editTransaction: 'Modifică operațiune',
    transactionType: 'Operațiune',
    lastUpdate: 'Ultima modificare',
    details: 'Explicație',
    documentNo: 'Nr. document',
    documentNumber: 'Număr document',
    enterDocumentNumber: 'Introdu numărul documentului',
    credit: 'Încasare',
    credits: 'Încasări',
    debit: 'Plată',
    debits: 'Plăți',
    sold: 'Sold',
    initialSold: 'Sold inițial',
    finalSold: 'Sold final',
    newSold: 'Sold nou',
    firstInitialBalance: 'Preluare sold inițial',
    transaction: 'Operațiune',
    sum: 'Sumă (lei)',
    transactionDetails: 'Detalii operațiune / explicații',
    saveChanges: 'Salvează modificări',
    save: 'Salvează',
    changesSaved: 'Modificările au fost salvate',
    deleteTransaction: 'Șterge operațiune',
    confirmDeleteTransaction: 'Confirmă ștergere operațiune',
    noTransactions: 'Nu există operațiuni în intervalul selectat',
    modifiedSoldWarning: 'Soldul din ziua operațiunii precum și soldul curent vor fi afectate',
    checkboxDescription: 'Bifează și confirmă ștergerea',
    errorUnknown: 'S-a întâmplat ceva neprevăzut ...',
    saveTransactionConfirmation: 'Ești sigur că vrei să adaugi operațiunea în trecut?',
    confirmAction: 'Confirmare acțiune',
    negativeBalanceError:
      'Aceasta operațiune produce un sold negativ. Verifică registrul sau modifică tipul operațiunii.',
    detailsError: 'Trebuie să adaugi detaliile (max. 100 caractere)',
    docNoError: 'Trebuie să introduci numărul documentului',
    amountError: 'Trebuie să introduci o sumă mai mare de 0',
    initialBalanceError: 'Trebuie sa introduci un sold inițial',
  },
  onlinePayments: {
    addBankAccount: 'Adaugă cont bancar',
    appointmentPayments: 'Plată online a programării',
    appointmentPaymentsHeader: 'Plată online programare',
    appointmentPaymentsDesc: 'Clientul poate plăti online programarea',
    appointmentPaymentsDescLong:
      'Permite clientului să plătească online o programare imediat după adăugarea acesteia sau când vine la programare',
    requireAdvancePayment: 'Solicitare avans la programare',
    requireAdvancePaymentDesc: 'Clientul se poate programa doar după plata unui avans minim',
    memberships: 'Abonamente și pachete',
    membershipsDesc: '{{count}} / {{total}} pot fi cumpărate online',
    giftCards: 'Carduri cadou proprii',
    giftCardsDesc: 'Clientul poate cumpăra carduri cadou',
    inactive: 'inactiv',
    active: 'activ',
    invoices: 'Facturi și deconturi',
    settings: 'Setări decontare',
    bankAccount: 'Cont bancar decont',
    payoutFrequency: 'Perioadă de decontare',
    payoutFrequencyDesc: 'Alege modul de transfer al banilor din balanța de plăți în contul tău bancar',
    appointmentAdvancedPayment: 'Solicitare avans la programare',
    inactiveSettings: 'Activează mai jos unde dorești să oferi plata online clienților tăi.',
    bankAccountTitle: 'MODIFICĂ CONT BANCAR DECONT',
    enterBankAccount: 'Introdu contul bancar',
    save: 'Salvează',
    updateIBANSuccess: 'Contul bancar a fost modificat cu succes',
    weeklyNoDay: 'Automat (săptămânal)',
    monday: 'luni',
    tuesday: 'marți',
    wednesday: 'miercuri',
    thursday: 'joi',
    friday: 'vineri',
    saturday: 'sâmbătă',
    sunday: 'duminică',
    Manual: 'Manual',
    manualDesc: 'Faci transferul simplu și rapid atunci când dorești',
    frequency: 'Frecvența',
    frequency1: 'Zilnic',
    frequency2: 'Săptămânal',
    frequency3: 'Lunar',
    automatic: 'Automat',
    Daily: 'Automat (zilnic)',
    Weekly: 'Automat (săptămânal în ziua de {{day}})',
    Monthly: 'Automat (lunar în data de {{day}} a lunii)',
    dayName: 'În ziua de',
    dayNumber: 'În data de',
    dayNumberDesc: '{{day}} a lunii',
    activationSuccess: 'Plata online conectată cu succes',
    activateHeader: 'Crește veniturile cu soluția de plată online MERO',
    activateButton: 'Activează plățile online',
    activatePayments1: 'Minimează neprezentările și anulările solicitând <0>avans la programare</0>',
    activatePayments2: '<0>Crește veniturile</0> prin vânzarea de abonamente și carduri cadou online',
    activatePayments3: 'Te înrolezi rapid cu SRL / PFA și ai <0>decontare în 1-4 zile lucrătoare</0>',
    activatePayments4: 'Comision tranzacție de ',
    title: 'Plăți online și decontare',
    description: 'Activează opțiunea de plată online pentru clienți pentru a încasa valoarea programării în avans.',
    activateOnlinePayment: 'Activează plăți online programări',
    activateError: 'Nu am putut activa plata online, te rugăm să încerci din nou',
    billingInfo: 'Date de facturare',
    missingBillingInfo: 'Alege date de facturare',
    vat: 'Cotă TVA',
    defaultVatValue: '0%',
    advancedPayment: 'Avans programare',
    noAdvancedPayment: 'Fără avans',
    allServicesAdvancedPayment: 'Avans la toate serviciile',
    allCustomers: 'Toți clienții',
    newCustomers: 'Doar clienți noi',
    someServicesAdvancedPayment: 'Avans doar la anumite servicii',
    deactivateOnlinePayment: 'Dezactivează plăți online programări',
    bills: 'Facturi clienți',
    noBills: 'Nu există facturi',
    noBillsText: 'Facturile vor aparea aici după emitera lor',
    download: 'Descarcă',
    downloadInvoiceError: 'Nu am putut descărca factura, te rugăm să încerci din nou',
    saveBillingDetailsError: 'Nu am putut salva datele de facturare, te rugăm să încerci din nou',
    companyName: 'Denumire persoană juridică',
    companyNameError: 'Te rugăm sa introduci o denumire validă',
    companyNamePlaceholder: 'ex. Barbers SRL',
    cui: 'Cod indentificare fiscală (CIF/CUI)',
    cuiError: 'Te rugăm sa introduci un cod indentificare fiscală valid',
    cuiPlaceholder: 'Introdu codul de identificare fiscală',
    registrationNumber: 'Număr de înregistrare',
    registrationNumberError: 'Te rugăm sa introduci un număr de înregistrare valid',
    registrationNumberPlaceholder: 'Introdu numărul de înregistrare',
    phone: 'Număr de telefon',
    phoneError: 'Te rugăm sa introduci un număr de telefon valid',
    phonePlaceholder: 'Introdu telefon',
    iban: 'Cont bancar nou (IBAN)',
    ibanError: 'Te rugăm sa introduci un cont IBAN valid',
    ibanPlaceholder: 'Introdu contul IBAN',
    firstName: 'Prenume',
    firstNameError: 'Te rugăm sa introduci un prenume valid',
    firstNamePlaceholder: 'Introdu prenumele',
    lastName: 'Numele',
    lastNameError: 'Te rugăm sa introduci un nume valid',
    lastNamePlaceholder: 'Introdu numele',
    email: 'Adresă de e-mail',
    emailError: 'Te rugăm sa introduci o adresă de e-mail validă',
    emailPlaceholder: 'Introdu adresa de e-mail',
    address: 'Adresă',
    addressError: 'Te rugăm sa introduci o adresă validă',
    addressPlaceholder: 'Introdu adresa',
    county: 'Județ',
    countyPlaceholder: 'Alege județ',
    countyError: 'Te rugăm să alegi un județ',
    city: 'Oraș',
    cityPlaceholder1: 'Alege județ mai întâi',
    cityPlaceholder2: 'Alege oraș',
    cityError: 'Te rugăm să alegi un oraș',
    stripe: 'Activează contul Stripe',
    errorDeactivatingOnlinePayment: 'Nu am putut dezactiva plata programări, te rugăm să încerci din nou',
    importantAction: 'Actiune importantă',
    disableTitle: 'Confirmă dezactivare plăți online programări',
    disableDescription: 'Bifează și confirmă dezactivarea',
    disableCheck1: 'Plata online cu cardul pentru programări va fi dezactivată pentru profilul tău.',
    cancel: 'Anulează',
    deactivate: 'Dezactivează',
    getInvoicesError: 'Nu am putut obține facturile, te rugăm să încerci din nou',
    connectStripeError: 'Nu am putut conecta contul Stripe, te rugăm să încerci din nou',
    continue: 'Continuă',
    saveChanges: 'Salvează modificări',
    advancedPaymentTitle: 'Avans programare',
    advancedPaymentDescription: 'Solicită clienților să plătească un avans pentru a se programa',
    off: 'Fără avans',
    allServices: 'Avans la toate serviciile',
    allServicesDescription: 'Clientul va trebui să achite avansul pentru a putea face programarea.',
    specificServices: 'Avans doar la anumite servicii',
    specificServicesDescription:
      'Clientul va trebui să achite avansul solicitat doar la programările care conțin serviciile selectate.',
    minPercentage: 'Avans minim',
    servicesWithAdvanced: 'Servicii cu avans',
    servicesWithAdvancedDescription: '{{value}} din {{total}} servicii selectate',
    advancedServices: 'Servicii cu avans',
    advancedServicesDescription: 'Alege pentru care servicii se va solicita avans și valoarea acestuia',
    advancedServicesSearchPlaceholder: 'Caută serviciu',
    changesSaved: 'Modificările au fost salvate',
    termsAndConditions: 'Termeni și condiții afișați clientului',
    termsAndConditionsDescription: 'Termeni și condiții programare achitată online',
    termsAndConditionsTitle: 'Termeni și condiții',
    termsAndConditionsSubtitle: 'Termeni și condiții plata online cu cardul',
    termsAndConditionsText: 'Clienții trebuie să îi accepte termenii și condițiile odată cu plata online a programării',
    termsAndConditionsLabel: 'Termeni și condiții',
    billingDetails: 'Alege date de facturare',
    addNewBillingDetails: 'Adaugă date de facturare',
    billingDetailsSelected: 'Persoană juridică selectată',
    done: 'Gata!',
  },
  gallery: {
    title: 'Galerie foto',
    sort: 'Ordonează',
    description: 'Adaugă fotografii descriptive ale serviciilor tale (ex. înainte și după).',
    addPhoto: 'Adaugă foto',
    addPhotos: 'Adaugă fotografii',
    mainImage: 'Imagine principală',
    setAsMain: 'Setează ca imagine principală',
    deleteImage: 'Șterge imagine',
    orderTitle: 'Setează ordine imagini',
    saveChanges: 'Salvează modificări',
    saveChangesError: 'Nu am putut salva modificările, te rugăm să încerci din nou',
    saveChangesSuccess: 'Modificările au fost salvate',
    selectImages: 'Selectează imagini',
    selectedImages: 'Imagini selectate',
    selectedImages_one: '{{count}} imagine selectată',
    selectedImages_other: '{{count}} imagini selectate',
    selectedImages_few: '{{count}} imagini selectate',
    save: 'Salvează',
    noPhoto: 'Nu există poze în galerie',
    addImages: 'Adaugă poze',
    failedToAddImages: 'Nu am putut adăuga pozele, te rugăm să încerci din nou',
    failedToDeleteImage: 'Nu am putut șterge imaginea, te rugăm să încerci din nou',
    failedToSetAsMain: 'Nu am putut seta imaginea ca principală, te rugăm să încerci din nou',
    failedToChangeOrder: 'Nu am putut schimba ordinea imaginilor, te rugăm să încerci din nou',
    isLoading: 'Se încarcă...',
    error: 'Reîncearcă',
    toBig: 'Prea mare',
    more: 'Mai mult',
    changeOrder: 'Reordonează imagini',
    multipleDelete: 'Ștergere multiplă',
    deleteSelected: 'Șterge imagini selectate',
    imageAttachedServices: 'Servicii vizibile în imagine',
    chooseServices: 'Alege serviciile',
    imageAttachedServicesDescription:
      'Alege serviciile vizibile în imagine și persoana care le-a realizat, pentru a evidenția profilul tău în căutări',
    addService: 'Adaugă serviciu',
    addWorker: 'Alege profesionist',
    selectedServices_one: '1 serviciu selectat',
    selectedServices_many: '{{count}} servicii selectate',
    changesSaved: 'Modificările au fost salvate',
  },
  location: {
    title: 'Profil online locație',
    details: 'Detalii profil',
    gallery: 'Galerie foto',
    address: 'Adresă',
    schedule: 'Program de lucru',
    onlineLink: 'Programări online',
    delete: 'Șterge locație și profil MERO',
    irreversibleAction: 'Actiune ireversibilă',
    deleteLocation: 'Confirmă ștergere locație și profil MERO',
    checkToConfirm: 'Bifează și confirmă ștergerea',
    deleteCheck1:
      'Toate detaliile locației, echipa, clienții, setările vor fi șterse.\n<1>Pagina ta MERO se va șterge.</1>',
    deleteCheck2: 'Profesioniștii adăugați în locație vor pierde accesul imediat',
    deleteCheck3: 'Toate calendarele și programările și recenziile vor fi șterse\n<1>IREVERSIBIL</1>',
    deleteCancel: 'Anulează',
    deleteConfirm: 'Șterge definitiv',
    locationDetails: 'Detalii profil',
    photoGallery: 'Galerie foto',
    editPhotoDetails: 'Poza de profil / sigla dă încredere clienților că se programează în locația potrivită',
    addPhoto: 'Încarcă o nouă poză profil',
    deletePhoto: 'Șterge poză profil',
    name: 'Nume profil',
    nameError: 'Introdu numele profilului',
    namePlaceholder: 'Ex. Andreea Nails',
    placeNameHint1: 'VA DETERMINA LINKUL PROFILULUI TĂU MERO',
    placeNameHint2: 'ex. {{value}}',
    phone: 'Telefon contact',
    phoneError: 'Te rugăm să introduci un număr de telefon valid',
    phonePlaceholder: 'Introdu numărul de telefon',
    email: 'Email contact',
    emailError: 'Te rugăm să introduci un email valid',
    emailPlaceholder: 'Introdu emailul',
    description: 'Descriere (max. 300 caractere)',
    descriptionPlaceholder: 'Descrie pe scurt oferta și calitatea serviciilor oferite',
    saveChanges: 'Salvează modificări',
    saveChangesError: 'Nu am putut salva modificările, te rugăm să încerci din nou',
    saveChangesSuccess: 'Modificările au fost salvate',
    noLocation: 'Adaugă o locație pentru a putea modifica profilul',
    county: 'Județ',
    countyPlaceholder: 'Alege județ',
    countyError: 'Te rugăm să alegi un județ',
    city: 'Oraș',
    cityPlaceholder1: 'Alege județ mai întâi',
    cityPlaceholder2: 'Alege oraș',
    cityError: 'Te rugăm să alegi un oraș',
    referencePoint: 'Reper adresă locație',
    referencePointPlaceholder: 'Ex. intrarea se face prin stânga clădirii',
    addressError: 'Te rugăm să introduci o adresă validă',
    addressPlaceholder: 'Introdu strada și numărul',
    map: 'Locație pe hartă',
    mapDescription: 'Alege punctul pe hartă prin mișcarea hărții.',
    mapDescription2: 'Alege punctul pe hartă după setarea adresei.',
    markerTitle: 'VERIFICĂ PUNCTUL PE HARTĂ',
    markerDescription: 'Asigură-te că punctul pe hartă e poziționat corespunzător adresei.',
    isLoading: 'Se încarcă...',
    getLocation: 'Locația curentă',
    savedSuccessfully: 'Modificările au fost salvate',
    errorDetails: 'Nu am putut salva modificările, te rugăm să încerci din nou',
    proSchedule: 'Program de lucru',
    proScheduleDescription: 'Programul locației se referă la programul în care locația este deschisă clienților',
    free: 'NU SE LUCREAZĂ',
    center: 'Recentrează',
    locationPermission: 'Acces la locație',
    locationIosPermissionTitle: 'Permite accesul la serviciile de localizare',
    locationIosStep1: 'Deschide <1>Configurări</1>',
    locationIosStep2: ' Click pe <1>MERO Pro</1>',
    locationIosStep3: 'Click pe <1>Localizare</1>',
    locationIosStep4: 'Click pe  <1>La utilizarea aplicației</1>',
    iosOpenSettings: 'Deschide Configurări',
    locationAndroidPermissionTitle: 'Permite accesul la locație',
    locationAndroidStep1: 'Deschide <1>Setări</1>',
    locationAndroidStep2: ' Click pe <1>Aplicații</1>',
    locationAndroidStep3: 'Click pe <1>MERO Pro</1>',
    locationAndroidStep4: 'Click pe  <1>Permisiuni</1>',
    locationAndroidStep5: 'Click pe  <1>Când folosiți aplicația</1>',
    androidOpenSettings: 'Deschide Setări',
    enableOnlineProfileTitle: 'Finalizează-ți profilul',
    enableOnlineProfileDescription: 'Completează pașii de mai jos pentru a-ți publica profilul mai rapid pe MERO.',
    enableOnlineProfileBoost: 'În plus, cu fiecare pas completat câștigi promovare GRATUITĂ prin MERO Boost',
    progress: 'PROGRES',
    profileCompleted: 'PROFIL COMPLET',
    enableOnlineProfileStep1: 'Crează cont',
    enableOnlineProfileStep2: 'Adaugă adresa',
    enableOnlineProfileStep2Description: 'Adaugă adresa locației unde vei primi clienții pentru programări.',
    enableOnlineProfileStep2Button: 'Adaugă adresa locației',
    enableOnlineProfileStep3: 'Adaugă foto cu lucrările tale',
    enableOnlineProfileStep3Description:
      'Adaugă minim 5 fotografii descriptive ale serviciilor tale (ex. înainte și după.)',
    enableOnlineProfileStep3Button: 'Adaugă fotografii',
    enableOnlineProfileStep4: 'Setează programul de lucru',
    enableOnlineProfileStep4Description:
      'Salvează programul de lucru al salonului și pe cel al fiecărui profesionist din salon.',
    enableOnlineProfileStep4Button: 'Adaugă program de lucru',
    enableOnlineProfileStep5: 'Adaugă serviciile oferite',
    enableOnlineProfileStep5Description:
      'Oferă clienților tăi posibilitatea să se programeze la tine 24/7. Adaugă minim 3 servicii cu prețuri.',
    enableOnlineProfileStep5Button: 'Adaugă servicii',
    enableOnlineProfileStep6: 'Adaugă programările viitoare',
    enableOnlineProfileStep6Description:
      'Adaugă toate programările viitoare de la clienții tăi pentru a evita suprapunerile.\nAtenție însă că doar pentru programările unde adaugi datele clientului poți primi recenzii și doar astfel poți să atragi clienți noi.',
    enableOnlineProfileStep6Button: 'Adaugă programare',
    extraBoost: '+{{count}} zi de promovare',
    extraBoost_one: '+{{count}} zi de promovare',
    extraBoost_other: '+{{count}} zile de promovare',
    extraBoost_few: '+{{count}} zile de promovare',
    incomplete: 'Profil incomplet',
    inactive: 'Profil inactiv',
    active: 'Profil public',
    publishOnlineProfile: 'Listează profilul pe MERO',
    pendingListing: 'Solicitarea ta de listare a fost trimisă. Te vom contacta în curând.',
    pendingUnlisting: 'Solicitarea ta de retragere a fost trimisă. Te vom contacta în curând.',
    enableTitle: 'Listează profilul și afișează-l la căutările clienților pe MERO',
    enableDescription: 'Permite clienților să te găsească atunci când caută servicii pe MERO.',
    linkTitle: 'Link profil MERO',
    linkPreDescription: 'Vei putea vedea cum arată profilul tău pentru clienți.',
    createLink: 'Crează linkul profilului tău',
    linkDescription:
      'Folosește linkul tău MERO peste tot: la butonul de acțiune pe Facebook, la bio pe Instagram, în postări, mesaje, cod QR etc.',
    share: 'Distribuie',
    viewProfile: 'Vezi profil',
    locationTooFar: 'Selecția punctului pe hartă nu corespunde cu adresa introdusă.',
    addLocation: 'Adaugă profil\nnou MERO',
    addLocationTitle: 'Adaugă profil nou',
    addLocationDescription: 'Un profil nou te ajută când deschizi un salon nou cu adresă diferită',
    selectActivity: 'Serviciu principal',
    selectActivityPlaceholder: 'Alege tip serviciu',
    selectActivityError: 'Alege activitatea principală',
    somethingWentWrong: 'S-a întamplat ceva neașteptat. Te rugăm să încerci din nou.',
    continue: 'Continuă',
    locationCreated: 'Locația a fost adăugată.',
    confirmDelete: 'Confirmă ștergerea',
    deleteReason: 'Alege motivul ștergerii',
    reason1: 'Folosesc o altă platformă',
    reason2: 'Nu mai lucrez în domeniu / iau o pauză',
    reason3: 'Platforma nu mi-a adus clienți noi',
    reason4: 'Alt motiv',
    otherReasonPlaceholder: 'Introdu motivul pentru care vrei să ștergi pagina ta MERO',
    unlistButton: 'Vreau să delistez profilul MERO',
    unlistDescription:
      'Profilul tău MERO, cât datele rămân disponibile încă o perioadă în cazul în care vei dori să revii pe platformă.',
    deletePermanentlyButton: 'Șterge profilul MERO permanent',
    unlistSuccess: 'Solicitarea ta de delistare a fost trimisă. Te vom contacta în curând.',
    private: 'PROFIL NELISTAT',
    public: 'PROFIL PUBLIC',
    listingToggleSuccess: 'Solicitarea ta a fost trimisă. Te vom contacta în curând.',
    finishProfile: 'Finalizează-ți profilul',
    unlistingCancel: 'Anulează',
    unlistingConfirm: 'Delistează',
    importantAction: 'Acțiune importantă',
    unlistingTitle: 'Confirmă delistare  profil MERO',
    unlistingDescription: 'Bifează și confirmă delistarea',
    unlistingCheckbox1: 'Profilul tău nu va mai mai apărea în căutările clienților de pe MERO.',
    unlistingCheckbox2: 'Persoanele care au salvat linkul profilului vor putea în continuare să îl acceseze.',
    deleteLocationReasonRequired: 'Alege motivul ștergerii',
    deleteLocationOtherReasonRequired: 'Introdu motivul pentru care vrei să ștergi locația',
    failedToLoadDistricts: 'Nu am putut încărca lista de județe. Te rugăm să încerci din nou.',
    shareProfile: 'Distribuie profil',
    meroQrCode: 'Cod QR profil MERO',
    download: 'Descarcă',
    nonSearchable: 'Profil nelistat',
    searchable: 'Profil public',
    copyToClipboard: 'Copiază link',
    downloadQRSuccess: 'Codul QR a fost descărcat cu succes',
    downloadQRToGallerySuccess: 'Codul QR a fost descărcat cu succes în galeria foto',
    downloadQRFailed: 'Nu am putut descarca codul QR',
    shareQRFail: 'Nu am putut distribui codul QR',
    copiedLink: 'Link copiat',
    allowAccessToGallery: 'Permite accesul la galerie',
    open: 'Deschide ',
    clickOn: 'Click pe ',
    settings: 'Setări',
    apps: 'Aplicații',
    meroPro: 'MERO Pro',
    mediaLibrary: 'Poze/Galerie',
    fullAccess: 'Acces complet',
    openSettings: 'Deschide Setări',
  },
  checkout: {
    proceeds: 'Încasări',
    proceedWith: 'Alege ce încasezi',
    proceedWithDescription: 'Poți adăuga ulterior mai multe articole de încasat',
    proceedBooking: 'Programare existentă',
    proceedService: 'Serviciu fără programare',
    proceedProduct: 'Produs',
    proceedMembership: 'Abonament nou',
    proceedMembershipInstallment: 'Plată parțială abonament existent',
    proceedAmount: 'Sumă de plată',
    proceedDiscount: 'Reducere',
    sales: 'Vânzări',
    newProceed: 'Încasare nouă',
    selectBooking: 'Programări neîncasate',
    existingClient: 'Client existent',
    newClient: 'Client nou',
    searchClient: 'Caută după nume sau telefon',
    clientName: 'Introdu nume client',
    addPhoneNumber: 'Introdu telefon mobil client',
    addItem: 'Adaugă încă un articol de încasat',
    selectService: 'Alege serviciu',
    RON: 'lei',
    toPaymentButton: '{{value}} {{currency}} - Alege metodă de plată',
    toPaymentButtonDisabled: 'Continuă la plată',
    selectPayment: 'Alege metodă de plată',
    Card: 'Card bancar',
    Cash: 'Numerar',
    CardAndCash: 'Card bancar + Numerar',
    BankTransfer: 'Ordin de plată',
    Membership: 'Abonament',
    Giftcard: 'Card cadou',
    finalizePayment: 'Finalizează încasarea',
    selectPaymentMethod: 'Alege metodă de plată',
    finalizeProtocol: 'Finalizează protocol',
    paymentProtocolDescription:
      'Prin protocol, suma este evidențiată separat în rapoartele de încasări și nu afectează totalul veniturilor.',
    paymentTotal: 'Total de plată',
    paymentAdded: 'Încasează',
    paymentProtocol: 'Protocol',
    paymentRemaining: 'Rest de plată',
    completeProtocol: 'Salvează protocol',
    addPayment: 'Încasează',
    completePayment: 'Încasează {{value}}',
    partialPayment: 'Încasează parțial {{value}}',
    paymentWithPrinting: 'Încasează și emite bon',
    addNewPayment: 'Adaugă încă o metodă de plată',
    company: 'Persoană juridică',
    addCompany: 'Adaugă pers. juridică',
    noCompany: 'Alege persoană jurdică',
    addNewCompany: 'Adaugă persoană juridică',
    selectNewCompany: 'Setează persoană juridică',
    selectAnotherCompany: 'Setează încă o persoană juridică',
    name: 'Denumire persoană juridică',
    namePlaceholder: 'ex. Barbers SRL',
    nameError: 'Introdu denumirea persoanei juridice',
    fiscalCode: 'Cod indentificare fiscală (CIF/CUI)',
    fiscalCodePlaceholder: 'Introdu codul de identificare fiscală',
    fiscalCodeError: 'Introdu codul de identificare fiscală',
    regNo: 'Nr. registrul comerțului',
    regNoPlaceholder: 'Introdu nr. reg. com.',
    regNoError: 'Introdu nr. reg. com.',
    address: 'Adresă',
    addressPlaceholder: 'Introdu adresa',
    addressError: 'Introdu adresa',
    county: 'Județ',
    countyPlaceholder: 'Alege județ',
    countyError: 'Alege județ',
    city: 'Localitate',
    cityPlaceholder1: 'Alege județ mai întâi',
    cityPlaceholder2: 'Alege localitate',
    cityError: 'Alege localitate',
    saveCompany: 'Adaugă persoană juridică',
    finished: 'Finalizate ({{count}})',
    closed: 'FINALIZATĂ',
    noClient: 'Fără client',
    noName: 'Fără nume',
    noProceedsAvailable: 'Nu există încasări',
    noProceedsAvailableDescription: 'Schimbă perioada, filtrele, sau termenul de căutare.',
    proceed: 'Încasare',
    options: 'Opțiuni',
    checkoutId: 'Încasare {{value}}',
    items: 'Articole',
    subtotal: 'Subtotal',
    discount: 'Reducere',
    vat: 'TVA',
    total: 'Total',
    regNoV: 'Reg. com',
    cui: 'CIF',
    checkoutSettings: 'Punct de vânzare',
    enableCheckoutTitle: 'Activează interfață vânzări',
    enableCheckoutDescription:
      '- gestionezi încasările simplu și rapid\n' +
      '- rapoarte de performanța ale afacerii\n' +
      '- încasări detaliate clienți, angajați, plăți\n' +
      '- printare bon casă de marcat',
    emitReceiptWarning: 'NECESITĂ CONECTARE LA CASĂ DE MARCAT',
    emitReceipt: 'Emite bon fiscal casă de marcat',
    customerFiscalCode: 'CUI pe bonul fiscal',
    emitReceiptDescription: 'Va afișa posibilitatea de a printa bon fiscal odată cu o încasare numerar.',
    cashRegisterName: 'Denumire casă de marcat',
    cashRegisterNamePlaceholder: 'Nume casă de marcat',
    selectPrinter: 'Alege driver casă de marcat',
    selectPrinterError: 'Alege un driver',
    selectPrinterPlaceholder: 'Alege un driver',
    contactDescription: 'Ai nevoie de ajutor sau vrei mai multe detalii? <1>Contactează-ne</1>',
    changeCompany: 'Modifică persoană juridică',
    saveChanges: 'Salvează modificări',
    enableReceiptTitle: 'Permite emitere bon fiscal',
    enableReceiptWarning: 'NECESITĂ CONECTARE LA CASĂ DE MARCAT',
    enableReceiptDescription: 'Va afișa posibilitatea de a printa bon fiscal odată cu o încasare.',
    deleteCompany: 'Șterge persoană juridică',
    deleteCompanyError: 'Nu am putut șterge persoana juridică, te rugăm să încerci din nou',
    noCompanies: 'Nicio persoană juridică\nînregistrată',
    companySaved: 'Persoana juridică a fost salvată',
    checkoutSettingsUpdated: 'Setările încasării au fost salvate',
    checkoutSettingsActivated: 'Interfața de încasări a fost activată',
    checkoutSettingsDeactivated: 'Interfața de încasări a fost dezactivată',
    unfinished: 'nefinalizată',
    showAllDrafts: 'Vezi toate nefinalizate',
    currentMonth: 'Luna curentă ({{month}})',
    allDrafts: 'Încasări nefinalizate',
    deleteOneByOne: 'Șterge una câte una',
    deleteAll: 'Șterge toate nefinalizate',
    completePaymentLabel: 'FINALIZATĂ',
    partialPaymentLabel: 'PLATĂ PARȚIALĂ',
    payedCard: 'Achitat cu card bancar',
    payedCash: 'Achitat cu numerar',
    payedBankTransfer: 'Achitat cu ordin de plată',
    payedGiftcard: 'Achitat cu card cadou',
    payedMembership: 'Achitat cu abonament',
    totalPayed: 'Total încasat',
    changeService: 'Modifică serviciu',
    changeProduct: 'Modifică produs',
    price: 'Preț (lei)',
    unitPrice: 'Preț unitar (lei)',
    pricePlaceholder: scaledToString(ScaledNumber.fromNumber(0, 2)),
    priceError: 'Introdu prețul',
    quantityPlaceholder: 'Introdu cantitatea',
    quantityError: 'Introdu cantitatea',
    discountPercentage: 'Reducere %',
    discountValue: 'Valoare reducere',
    delete: 'Elimină',
    save: 'Salvează',
    cancel: 'Anulează',
    currency: 'lei',
    hiddenPrice: 'Preț variabil',
    addAmount: 'Adaugă sumă de plată',
    changeAmount: 'Modifică sumă de plată',
    otherAmount: 'Altă sumă',
    addDiscount: 'Aplică reducere',
    addProtocol: 'Protocol',
    changeDiscount: 'Modifică reducere',
    discountPercent: 'Reducere {{value}}%',
    remainingAppointments_one: 'CLIENTUL MAI ARE {{count}} PROGRAMARE NEÎNCASATĂ',
    remainingAppointments_other: 'CLIENTUL MAI ARE {{count}} PROGRAMĂRI NEÎNCASATE',
    remainingAppointments_few: 'CLIENTUL MAI ARE {{count}} PROGRAMĂRI NEÎNCASATE',
    emitReceiptCheck: 'Emite bon fiscal casă de marcat',
    addBankTransfer: 'Adaugă valoare ordin de plată',
    changeBankTransfer: 'Modifică valoare ordin de plată',
    bankTransferValue: 'Valoare ordin de plată',
    bankTransferValuePlaceholder: 'Introdu valoarea ordinului de plată',
    bankTransferValueError: 'Introdu valoarea ordinului de plată',
    type: 'Tip',
    amount: 'Suma',
    client: 'Client',
    id: 'Încasare',
    status: 'Status',
    date: 'Data ultimei modificări',
    proceedProtocol: 'Protocol finalizat',
    proceedProtocolDeleted: 'Protocol anulat',
    Finished: 'Încasare Finalizată',
    Deleted: 'Încasare Anulată',
    Draft: 'Încasare Nefinalizată',
    paymentSuccess: 'Încasare finalizată!',
    viewDetails: 'Vezi detalii',
    deleteDrafts: 'Șterge încasări nefinalizate',
    deleteDraftsButton: 'Șterge',
    cancelProceed: 'Anulează încasare',
    globalDiscount: 'Reducere suplimentară',
    irreversibleAction: 'Acțiune ireversibilă',
    confirmDelete: 'Confirmă anulare încasare',
    checkToConfirm: 'Bifează și confirmă anularea',
    deleteCheck1: '<0>BONUL FISCAL PRINTAT NU VA FI ANULAT.</0> Anulează bonul conform procedurii proprii',
    deleteCheck2: '<0>ABONAMENTELE ACHIZIȚIONATE  NU VOR FI ANULATE.</0> Anulează conform procedurii proprii',
    deleteCheck3: 'Programările și serviciile din încasare vor reveni la starea inițială',
    vatChecked: 'Plătitor de TVA ({{value}}%)',
    vatUnchecked: 'Plătitor de TVA',
    noBookingsClient: 'Nicio programare de încasat\npentru {{firstName}} {{lastName}}',
    noBookings: 'Nicio programare de încasat',
    noBookingsDescription: 'Aici apar programările finalizate, care\nnu au fost încasate',
    selectBookingClientDescription:
      'Alege din lista programărilor neîncasate pentru clientul <1>{{firstName}} {{lastName}}</1>',
    clientSaveError: 'Nu am putut salva clientul, te rugăm să încerci din nou',
    deleteProceed: 'Șterge încasare nefinalizată',
    proceedDeleteError: 'Nu am putut șterge încasarea, te rugăm să încerci din nou',
    noCompanyError: 'Alege persoana juridică',
    today: 'Azi',
    completePaymentWithReceipt: 'Încasează și emite bon',
    invalidItemAmount: 'Setează un preț pentru acest serviciu',
    checkScreenInfo: 'Verifică informațiile de pe ecran',
    confirmDeleteDraft_one: 'Confirmă ștergere încasare nefinalizată',
    confirmDeleteDraft_other: 'Confirmă ștergere încașări nefinalizate',
    confirmDeleteDraft_few: 'Confirmă ștergere încasări nefinalizate',
    confirmDeleteDraftDescription_one: 'Încasarea nefinalizată va fi ștearsă și scoasă din lista de nefinalizate',
    confirmDeleteDraftDescription_other: 'Încasările nefinalizate vor fi șterse și scoase din lista de nefinalizate',
    confirmDeleteDraftDescription_few: 'Încasările nefinalizate vor fi șterse și scoase din lista de nefinalizate',
    importantAction: 'Acțiune importantă',
    deleteDraft: 'Șterge',
    pickCheckout: 'Alege ce încasezi',
    missingCompany: 'Alege o persoană juridică',
    deleteCompanyDialog: 'Confirmă ștergere persoană juridică',
    checkAndConfirmDelete: 'Bifează și confirmă ștergerea',
    deleteCompanyCheck1: 'Încasările finalizate și rapoartele <1>NU</1> vor fi afectate.',
    deleteCompanyCheck2: 'Datele vor fi șterse permanent și persoana juridică nu va mai fi afișată pentru încasări.',
    deleteCompanyCheck3: 'Registrul de casă (dacă există unul creat) va fi șters',
    confirmDeleteCompany: 'Confirmă ștergere',
    proceedAmountInput: 'Sumă de plată (lei)',
    proceedAmountError: 'Alegeți suma',
    noPrice: 'Nesetat',
    selectCompanyError: 'Nu am putut selecta persoana juridică, te rugăm să încerci din nou',
    selectPro: 'Profesionist',
    selectAvailability: 'Tip produs',
    OfflineRetail: 'Revânzare',
    Consumable: 'Consumabil',
    byPro: 'din care {{name}}',
    saveDraft: 'Salvează pentru mai târziu',
    updateDraft: 'Salvează modificări',
    cancelDraft: 'Închide fără a salva',
    cancelDraftChanges: 'Închide fără a salva modificări',
    deleteDraftAction: 'Șterge încasare nefinalizată',
    selectMembership: 'Alege abonament',
    selectMembershipType: 'Alege tip abonament',
    searchMembership: 'Caută abonament',
    paymentValue: 'Plată unică',
    unlimitedValidity: 'Valabil nelimitat',
    validityValue_one: 'Valabil {{count}} lună',
    validityValue_other: 'Valabil {{count}} luni',
    validityValue_few: 'Valabil {{count}} luni',
    membershipNotFound: 'Nu am găsit abonamentul',
    customiseMembership: 'Personalizează abonamentul',
    steps: 'Pasul {{start}} din {{end}}',
    changeMembership: 'Modifică abonament',
    membershipName: 'Denumire abonament',
    membershipNameError: 'Introdu denumirea abonamentului',
    variablePrice: 'Preț variabil',
    availability: 'Valabil timp de',
    unlimited: 'Nelimitat',
    limited: 'Limitat',
    unlimitedAvailability: 'Nelimitat',
    availabilityValue_one: '{{count}} lună',
    availabilityValue_other: '{{count}} luni',
    availabilityValue_few: '{{count}} luni',
    validFrom: 'Valabil de la',
    sessions: 'Număr de ședințe',
    quantity: 'Cantitate',
    totalPrice: 'Preț normal',
    addItems: 'Adaugă articol nou',
    noItems: 'Nu ai ales nici un articol',
    addPartialPaymentDescriptioin: 'Introdu suma de achitat din abonament',
    totalPaymentText: 'Plătește integral',
    partialPaymentText: 'Plată parțială',
    amountToPay: 'Sumă de achitat',
    amountToPayError: 'Introdu suma de achitat',
    totalMembership: 'Total abonament',
    payMembershipAmount: 'Sumă de achitat',
    remainingPayMembership: 'Rest de plată',
    removeMembership: 'Elimină din încasare',
    selectMembershipItems: 'Modifică abonament',
    membershipPayment: 'Abonament',
    membershipPaymentError: 'Alege un client pentru plata cu abonament',
    selectMembershipDescription: 'Alege din abonamentele active ale clientului <1>{{name}}</1>',
    membershipActive: 'Activ',
    membershipExpired: 'Expirat',
    debtAmount: 'Rest de plată',
    editMembershipPayment: 'Alege consum',
    editMembership: 'Editează abonamentul',
    selectedMembership: 'Abonament ales:',
    selectMembershipPayItems: 'Alege consum',
    selectMembershipPayItemsDescription: 'Articolele selectate se vor scădea valoric din totalul de plată',
    servicePay_one: '{{count}} x {{total}} sedință',
    servicePay_other: '{{count}} x {{total}} sedințe',
    servicePay_few: '{{count}} x {{total}} sedințe',
    serviceNotAvailable: 'Consum epuizat',
    selectAtLeastOneService: 'Alege cel puțin un articol',
    completePaymentWithMembership: 'Finalizează încasarea',
    paidWithMembership: 'Consumat din abonament',
    paidWithMembershipUnlimited: 'Consumat din abonament nelimitat',
    cannotSelectMoreThanAvailable: 'Nu poți selecta mai multe decât sunt disponibile',
    membershipPaidPartial: 'Plată parțială {{paid}} din {{total}}',
    membershipPaidFully: 'Plată integrală',
    clientMembershipDebt: 'Datorii acumulate',
    clientMembershipActive_one: '{{count}} abonament activ',
    clientMembershipActive_other: '{{count}} abonamente active',
    clientMembershipActive_few: '{{count}} abonamente active',
    clientDetails: 'Vezi detalii client',
    clientChange: 'Schimbă cu un alt client',
    clientRemove: 'Elimină din încasare',
    memberships: 'Abonamente',
    checkout: 'Încasări',
    searchCheckoutPlaceholder: 'Caută client, telefon, nr. încasare',
    searchMembershipPlaceholder: 'Caută abonament, client, telefon',
    searchProductsPlaceholder: 'Caută produs, client, încasare',
    Active: 'Activ',
    Consumed: 'Consumat',
    Cancelled: 'Anulat',
    Inactive: 'Inactiv',
    Expired: 'Expirat',
    HasDebt: 'Abonamente achitate parțial',
    membershipPaid: 'Achitat',
    membershipAvailability: 'Valabilitate',
    membershipDebt: 'Rest de plată {{value}}',
    Unlimited: 'nelimitat',
    totalMembershipPrice: 'Total {{value}} {{currency}}.',
    clientIsRequired: 'Trebuie să alegi un client pentru a putea continua',
    membershipDurationSelectPlaceholder: 'Alege nr. de ședințe incluse',
    quantityRequired: 'Alege numărul de ședințe incluse',
    enableCheckoutLabel: 'Utilizare gratuită',
    enableCheckoutHeader: 'Activează punct de vânzare',
    enableCheckoutPoint1: 'Încasezi cu ușurință programări, abonamente și produse',
    enableCheckoutPoint2: 'Centralizator sume de plată către colaboratori și rapoarte performanță',
    enableCheckoutPoint3: 'Rapoarte detaliate top clienți, servicii, angajați și metode de plată',
    enableCheckoutPoint4: 'Conectare la casă de marcat și printare automată bon (opțional)',
    enableCheckoutButton: 'Activează punct vânzare',
    enableCheckoutFooter: 'Vrei mai multe detalii? <1>Contactează-ne</1>',
    noMembership: 'Niciun abonament',
    noMembershipSearch: 'Niciun abonament',
    noMembershipSearchDescription: 'Schimbă perioada, filtrele, sau termenul de căutare.',
    noMembershipsAvailable: 'Nu există nici un tip de abonament',
    noProductAvailable: 'Niciun produs',
    noMembershipDescription: 'Creează șabloane pentru a combina mai multe servicii într-un tip de abonament',
    searchPlaceholder: 'Caută tip abonament',
    addNewTemplate: 'Adaugă tip abonament',
    membershipCharged: 'Încasat {{value}} din {{total}} {{currency}}',
    usableMembership_one: '{{total}}/{{count}} abonamente compatibile',
    usableMembership_other: '{{total}}/{{count}} abonamente compatibile',
    usableMembership_few: '{{total}}/{{count}} abonamente compatibile',
    payableFromMembership: 'Poate fi plătit cu abonament',
    partialMembershipTitle: 'Alege abonament existent',
    priceSaleUnlimited: 'Preț vânzare',
    priceSale_one: 'Preț vânzare {{count}} ședință',
    priceSale_other: 'Preț vânzare {{count}} ședințe',
    priceSale_few: 'Preț vânzare {{count}} ședințe',
    priceSaleInitial_one: 'Preț {{count}} ședință fără reducere {{sum}} lei',
    priceSaleInitial_other: 'Preț {{count}} ședințe fără reducere {{sum}} lei',
    priceSaleInitial_few: 'Preț {{count}} ședințe fără reducere {{sum}} lei',
    errorPriceTooBig: 'Prețul trebuie să fie mai mic sau egal cu valoarea implicită a serviciilor',
    unknownUser: 'Fără nume',
    membershipLeft_one: '{{count}} ședință rămasă',
    membershipLeft_other: '{{count}} ședințe rămase',
    membershipLeft_few: '{{count}} ședințe rămase',
    membershipLeftUnlimited: 'ȘEDINȚE NELIMITATE',
    checkoutSaved: 'Încasare finalizată',
    membershipServiceItem: `{{count}} x 1 ședință`,
    continue: 'Continuă',
    products: 'Produse',
    productColumn: 'Produs',
    quantityColumn: 'Cantitate',
    clientColumn: 'Client',
    saleDateColumn: 'Data vânzării',
    paymentColumn: 'Încasare',
    vatColumn: 'TVA',
    totalColumn: 'Valoare',
    paymentId: 'ÎNCASARE #{{value}}',
    insufficientStock: 'Stoc insuficient',
    receiptFiscalCode: 'CUI pe bonul fiscal',
    addReceiptFiscalCode: 'Adaugă CUI pe bonul fiscal',
    receiptFiscalCodeError: 'Introdu un CUI valid',
    billingDetails: 'Alege persoană juridică',
    addNewBillingDetails: 'Adaugă persoană juridică',
    billingDetailsSelected: 'Persoană juridică selectată',
    done: 'Gata!',
    changeCompanyDetails: 'Modifică date persoană juridică',
    deleteCompanyBilling: 'Elimină persoană juridică',
    enableReceipt: 'Activează printare bon fiscal',
    receiptDialogTitle: 'Printare bon fiscal',
    receiptDialogLabel: 'NECESITĂ CONECTARE LA CASĂ DE MARCAT',
    receiptDialogDescription: 'Afișează opțiunea de printare bon fiscal la încasare',
    receiptDialogDisableButton: 'Dezactivează',
    receiptDialogConfirmButton: 'Salvează',
    totalDiscount: 'Total reduceri',
    addCustomVat: 'Setează cote TVA personalizate',
    customVatValue: 'Cotă TVA {{index}} ({{letter}})',
    vatGroup0: '0%',
    vatGroup5: '5%',
    vatGroup9: '9%',
    vatGroup19: '19%',
    vatGroupNoVat: 'Scutit TVA',
    selectCustomVatPlaceholder: 'Alege',
    Retail: 'Revânzare',
  },
  changePhone: {
    changePhone: 'Schimbă telefon',
    otp: 'Introdu codul primit prin SMS',
    errorInvalidPhone: 'Numărul de telefon introdus nu este valid',
    errorPhoneAlreadyUsed: 'Acest număr de telefon este deja utilizat',
    errorFailedToSendOtp: 'Nu am putut trimite codul de verificare, te rugăm să încerci din nou',
    phoneDescription: 'Introdu noul număr\nde telefon',
    phoneNumber: 'Telefonul tău',
    phoneNumberPlaceholder: 'Introdu telefonul mobil',
    phoneButtonLoading: 'Trimitem codul de verificare ...',
    phoneButton: 'Schimbă telefon',
    successPhoneUpdated: 'Numărul de telefon a fost actualizat',
    errorInvalidOtp: 'Codul de verificare introdus nu este valid',
    otpDescription: 'Introdu codul\nformat din 6 cifre',
    otpSentTo: 'Codul a fost <1>trimis\nprin SMS</1> la <3>{{phone}}</3>',
    otpPlaceholder: 'Introdu codul de verificare',
    otpButtonLoading: 'Verificăm codul ...',
    otpButton: 'Verifică codul',
    otpTimer: 'Codul ajunge în maxim {{timer}} secunde',
    otpNotReceived: 'Nu ai primit un cod prin SMS?',
    otpResend: 'Retrimite codul',
    otpCancel: 'Anulează',
  },
  membership: {
    membershipSettings: 'Setări abonamente',
    membershipDetails: 'Detalii abonament',
    membershipItems: 'Servicii incluse',
    order: 'Ordonează',
    types: 'Abonamente și pachete',
    searchPlaceholder: 'Caută abonament',
    filters: 'Filtre',
    allTypes: 'Toate',
    active: 'Active',
    inactive: 'Inactive',
    consumed: 'Consumate',
    expired: 'Expirate',
    cancelled: 'Anulate',
    add: 'Adaugă',
    addNewTemplate: 'Adaugă tip abonament',
    noMembershipSearch: 'Niciun abonament',
    noMembershipSearchType: 'Niciun tip de abonament',
    noMembershipSearchDescription: 'Schimbă perioada, filtrele, sau termenul de căutare.',
    noMembershipsAvailable: 'Nu există nici un tip de abonament',
    noMembershipDescription: 'Crează șabloane pentru a combina mai multe servicii într-un tip de abonament',
    addMembership: 'Adaugă tip abonament',
    addMembershipType: 'Adaugă tip abonament',
    changeMembershipType: 'Modifică tip abonament',
    membershipTypeDetails: 'Detalii abonament',
    name: 'Denumire abonament',
    namePlaceholder: 'Introdu denumire',
    nameError: 'Introdu denumirea abonamentului',
    description: 'Descriere',
    descriptionPlaceholder: 'Descrie pe scurt abonamentul',
    availability: 'Valabilitate',
    unlimitedAvailability: 'Nelimitat',
    availabilityValue_one: '{{count}} lună',
    availabilityValue_other: '{{count}} luni',
    availabilityValue_few: '{{count}} luni',
    continue: 'Continuă',
    selectMembershipItems: 'Alege articole incluse',
    selectService: 'Alege serviciu',
    noItems: 'Nu ai ales nici un articol',
    addItems: 'Adaugă articol nou',
    save: 'Salvează',
    saveChanges: 'Salvează modificări',
    sessions: 'Număr de ședințe',
    limited: 'Limitat',
    unlimited: 'Nelimitat',
    price: 'Preț',
    quantity: 'Cantitate',
    variablePrice: 'Preț variabil',
    addDiscount: 'Adaugă reducere',
    totalPrice: 'Preț normal',
    RON: 'lei',
    total: 'Total',
    discount: 'Reducere',
    removeItem: 'Elimină din abonament',
    nameRequired: 'Introdu denumirea abonamentului',
    itemsRequired: 'Alege cel puțin un articol',
    validForRequired: 'Alege valabilitatea abonamentului',
    deleteMembership: 'Confirm ștergerea',
    confirmDelete: 'Confirmă ștergere tip abonament',
    checkToConfirm: 'Bifează și confirmă ștergerea',
    deleteCheck1: 'Tipul abonamentului nu va mai putea fi afișat pentru vânzare',
    deleteCheck2: 'Abonamentele deja vândute, nu vor fi afectate',
    deleteMembershipButton: 'Șterge tip abonament',
    cancel: 'Anulează',
    Active: 'Activ',
    Inactive: 'Inactiv',
    Deleted: 'Șters',
    HasDebt: 'Achitate parțial',
    paymentValue: 'Plată unică',
    unlimitedValidity: 'Valabil nelimitat',
    validityValue_one: 'Valabil {{count}} lună',
    validityValue_other: 'Valabil {{count}} luni',
    validityValue_few: 'Valabil {{count}} luni',
    activateDescription: 'Activează pentru a putea vinde acest tip de abonament',
    items: 'Articole incluse',
    itemsPrice: 'Preț {{ value }} {{ currency }}',
    confirmDeleteMembership: 'Confirmă anulare abonament',
    checkAndConfirmDelete: 'Bifează și confirmă anularea',
    deleteMembershipCheck1: 'Abonament nu va mai putea fi folosit pentru programări',
    deleteMembershipCheck2: 'Ședințele deja consumate și încasate <1>NU vor fi afectate</1>',
    deleteMembershipCheck3: 'Încasările cu privire la achiziția abonamentului <1>NU vor fi afectate</1>',
    close: 'Închide',
    confirmMembershipDelete: 'Confirmă anularea',
    membershipDurationSelectPlaceholder: 'Alege nr. de ședințe incluse',
    quantityRequired: 'Alege cantitatea',
    salePoint: 'Punct de vânzare',
    irreversibleAction: 'Acțiune ireversibilă',
    membershipTypeSaved: 'Modificările au fost salvate',
    priceSaleUnlimited: 'Preț vânzare',
    priceSale_one: 'Preț vânzare {{count}} ședință',
    priceSale_other: 'Preț vânzare {{count}} ședințe',
    priceSale_few: 'Preț vânzare {{count}} ședințe',
    priceSaleInitial_one: 'Preț {{count}} ședință fără reducere {{sum}} lei',
    priceSaleInitial_other: 'Preț {{count}} ședințe fără reducere {{sum}} lei',
    priceSaleInitial_few: 'Preț {{count}} ședințe fără reducere {{sum}} lei',
    errorPriceTooBig: 'Prețul trebuie să fie mai mic sau egal cu valoarea implicită a serviciilor',
    unknownUser: 'Fară nume',
  },
  general: {
    upgradeAppTitle: 'Aplicația are nevoie de actualizare',
    upgradeAppDescription: 'Descarcă ultima versiune a aplicației\npentru a primi ultimele îmbunătățiri',
    upgradeAppButton: 'Descarcă și instalează',
    updateAppTitle: 'O nouă versiune e disponibilă',
    updateAppDescription: 'Descarcă ultima versiune a aplicației\npentru a primi ultimele îmbunătățiri',
    updateAppButton: 'Descarcă și instalează',
    updateCancelButton: 'Mai târziu',
  },
  products: {
    noName: 'Fără nume',
    productsTitle: 'Produse',
    productsAndInventory: 'Produse și gestiune',
    filters: 'Filtre',
    brandsTitle: 'Branduri',
    suppliersTitle: 'Furnizori',
    documentsTitle: 'Documente',
    inventoriesTitle: 'Gestiuni',
    searchProducts: 'Caută după nume sau cod de bare',
    searchProductsShort: 'Caută după nume sau cod',
    add: 'Adaugă',
    addBrand: 'Adaugă brand',
    updateBrand: 'Modifică brand',
    products: 'produse',
    product: 'produs',
    addProduct: 'Adaugă produs',
    importProducts: 'Importă produse',
    addCategory: 'Adaugă categorie',
    otherCategory: 'Fără categorie',
    otherBrand: 'Fără brand',
    inactive: 'Arhivate',
    active: 'Toate produsele',
    reorderProducts: 'Setează ordine produse',
    multipleActions: 'Acțiuni multiple produse',
    nameColumn: 'Produs',
    brandColumn: 'Brand',
    quantityColumn: 'Cantitate',
    vatColumn: 'TVA',
    priceColumn: 'Preț vânzare',
    RON: 'lei',
    editProduct: 'Detalii produs',
    consumable: 'Consum',
    consumableExplanation: 'Permite atașarea produsului și utilizarea unei cantități odată cu prestarea unui serviciu',
    retailOffline: 'Revânzare',
    retailOfflineExplanation: 'Permite vânzarea produsului la casă',
    productName: 'Denumire produs',
    productNamePlaceholder: 'Adaugă denumirea produsului',
    productNameError: 'Trebuie să adaugi denumirea produsului (max. 50 caractere)',
    productBarcode: 'Cod de bare',
    productBarcodePlaceholder: 'Scanează sau adaugă cod de bare',
    productBarcodeError: 'Codul de bare nu este valid',
    productCategoryPlaceholder: 'Alege o categorie',
    productCategory: 'Categorie',
    productBrand: 'Brand',
    productBrandPlaceholder: 'Alege brand',
    deleteProduct: 'Șterge produs',
    archiveProduct: 'Arhivează produs',
    productPrice: 'Preț (lei)',
    priceError: 'Trebuie să introduci o sumă mai mare de 0',
    productDescription: 'Descriere',
    productGallery: 'Galerie',
    productSuppliers: 'Furnizori',
    saveChanges: 'Salvează modificări',
    save: 'Salvează',
    inStock: 'în stoc',
    updateCategory: 'Modifică categorie',
    deleteCategory: 'Șterge categorie',
    editBrand: 'Modifică brand',
    deleteBrand: 'Șterge brand',
    vat: 'TVA',
    vatPlaceholder: 'Alege tip TVA',
    productPriceVatIncluded: 'Preț vânzare (inclusiv TVA)',
    discount: 'Promoție',
    measureUnit: 'Unitate de măsură',
    measureUnitPlaceholder: 'Alege unitate de măsură',
    measureValue: 'Unități per 1 bucată',
    existingProductDescriptionInstruction: 'Adaugă descriere pentru produsul',
    newProductDescriptionInstruction: 'Adaugă descriere pentru produs',
    cancel: 'Anulează',
    categoryDescription: 'Categoria te ajuta să organizezi produsele similare în același grup',
    addCategorySuccess: 'Categoria a fost adăugată cu succes',
    updateCategorySuccess: 'Categoria a fost modificată cu succes',
    addBrandSuccess: 'Brandul a fost adăugat cu succes',
    updateBrandSuccess: 'Brandul a fost modificat cu succes',
    categoryPlaceholder: 'Introdu numele categoriei',
    categoryNameError: 'Trebuie să adaugi numele categoriei',
    deleteCategoryCheck1: 'Toate detaliile categoriei vor fi șterse',
    deleteBrandCheck1: 'Toate detaliile brandului vor fi șterse',
    deleteBrandCheck2: 'Produsele care au setat acest brand vor avea câmpul Brand nesetat',
    deleteCategoryCheck2: 'Produse adăugate în această categorie vor fi mutate în secțiunea Fără categorie.',
    noCategory: 'Fără categorie',
    noBrand: 'Fără brand',
    deleteCheckboxDescription: 'Bifează și confirmă ștergerea',
    confirmDeleteCategory: 'Confirmă ștergere categorie',
    confirmDeleteBrand: 'Confirmă ștergere brand',
    deleteCategorySuccess: 'Categoria a fost ștearsă cu succes',
    deleteBrandSuccess: 'Brand-ul a fost șters cu succes',
    deleteProductSuccess: 'Produsul a fost șters cu succes',
    addProductSuccess: 'Produsul a fost adăugat cu succes',
    modifyProductSuccess: 'Produsul a fost modificat cu succes',
    editCategory: 'Modifică categorie',
    brandName: 'Nume brand',
    brandNamePlaceholder: 'Adaugă numele brandului',
    ready: 'Gata!',
    options: 'Opțiuni',
    importFirstStep: 'Pasul 1 - Descarcă model fișier',
    importFirstStepDescription: 'Descarcă și completează modelul de fișier.',
    importFirstStepWarning: 'NU MODIFICA NUMELE COLOANELOR',
    downloadSampleXLSXFile: 'Descarcă model fișier .xls',
    importSecondStep: 'Pasul 2 - Încarcă fișier completat',
    importSecondStepDescription: 'Încarcă fișierul completat cu datele produselor în baza modelului de mai sus',
    importCompletedFile: 'Încarcă fișier completat pentru import',
    failedToImportFile: 'Nu am putut importa fișierul, te rugăm să încerci din nou',
    importWarningMessage: 'Nu am putut importa toate produsele din fișier',
    importResult: 'Rezultat import',
    importFailDetailsMessageSingle: 'Nu am putut importa produsul. Remediază erorile și importă din nou.',
    importFailDetailsMessageMany:
      'Nu am putut importa {{skippedCount}} produse. Remediază produsele cu erori și importă-le din nou.',
    productMovedToArchived: 'Produsul a fost mutat în categoria Arhivate',
    productMovedOutOfArchived: 'Produsul a fost scos din categoria Arhivate',
    unarchiveProduct: 'Dezarhivează produs',
    discountPrice: 'Preț promoțional',
    chooseProductDescriprion: 'Adaugă descrierea produsului...',
    noProducts: 'Niciun produs',
    noBrandProductsDescription: 'Produsele care vor fi alocate acestui brand vor apărea aici',
    noCategoryProductsDescription: 'Produsele care vor fi alocate acestei categorii vor apărea aici',
    discountedPriceColumn: 'Preț promoțional',
    chooseProductAvailabilityError: 'Alege tipul produsului',
    chooseMeasureUnitError: 'Alege o unitate de măsură',
    discountGreaterThanFinalPriceError: 'Prețul promoțional trebuie să fie mai mic decât prețul final',
    importIsReady: 'Importul este gata!',
    processed: 'PROCESATE',
    added: 'ADĂUGATE',
    errors: 'ERORI',
    downloadErrorFile: 'Descarcă fișierul cu produsele cu erori',
    importInProgress: 'Import în curs…',
    importInProgressDescription: 'Poți închide această fereastră ',
    photoGallery: 'Galerie foto',
    noProductPhoto: 'Nu există poze în galerie',
    productPhotoDescription: 'Adaugă fotografii descriptive ale produselor tale.',
    addPhotos: 'Adaugă fotografii',
    all: 'Toate',
    irreversibleAction: 'Acțiune ireversibilă',
    deleteProductBtn: 'Șterge produsul',
    confirmDeleteProduct: 'Confirmă ștergere produs',
    deleteProductCheck1: 'Toate detalile produsului vor fi șterse permanent',
    deleteProductCheck2: 'Produsul nu va mai fi vizibil pe profilul online al locației',
    deleteProductCheck3: 'Încasările și rapoartele de consum nu vor fi afectate',
    failedToAddImages: 'Nu am putut adăuga pozele, te rugăm să încerci din nou',
    stockTotalColumn: 'Stoc',
    price: 'Preț',
    productDetails: 'Detalii produs',
    manageInventory: 'Gestionare stoc',
    setPriceFor: 'Setează prețul pentru',
    activateStock: 'Activează gestionarea stocului',
    currentStock: 'Stoc curent',
    unset: 'Nesetat',
    set: 'Setat',
    adjustStock: 'Ajustează stoc',
    history: 'Istoric',
    noStockOperations: 'Operațiunile asupra stocului vor apărea aici.',
    activatedStockManagement: ' a activat gestionarea stocului',
    deactivatedStockManagement: ' a dezactivat gestionarea stocului',
    affectedAdjustedStockValue: ' a ajustat stocul ({{change}})',
    unaffectedAdjustedStockValue: ' a ajustat stocul ({{change}}). Stocul nu a fost afectat.',
    affectedTransactionConsumption: ' a făcut o vânzare ({{change}})',
    unaffectedTransactionConsumption: ' a făcut o vânzare ({{change}}). Stocul nu a fost afectat',
    affectedCancelledTransactionConsumption: ' a anulat o vânzare ({{change}})',
    unaffectedCancelledTransactionConsumption: ' a anulat o vânzare ({{change}}). Stocul nu a fost afectat',
    cancelButton: 'Anulează',
    adjustment: 'Ajustare',
    stockMustBeGreaterThanZero: 'Stocul trebuie să fie cel puțin 0',
    newValue: 'Stoc nou',
    chooseReason: 'Alege motivul',
    reason: 'Motiv',
    New: 'Stoc nou',
    CustomerReturn: 'Retur client',
    TransferredTo: 'Transfer în gestiune',
    TransferredFrom: 'Transfer către altă gestiune',
    Adjusted: 'Ajustare',
    Other: 'Alt motiv',
    UsedInternally: 'Consum',
    Deteriorated: 'Deteriorat',
    Lost: 'Pierdut',
    Expired: 'Expirat',
    ReturnedToSupplier: 'Returnat furnizor',
    pieces: '{{stock}} buc.',
    priceUpdateSuccess: 'Prețul produsului a fost modificat cu succes!',
    stockUpdateSuccess: 'Stocul produsului a fost modificat cu succes!',
    activateStockSuccess: 'Stocul a fost activat cu succes!',
    deactivateStockSuccess: 'Stocul a fost dezactivat cu succes!',
    otherReason: 'Alt motiv',
    otherReasonPlaceholder: 'Introdu alt motiv',
    requiredOtherText: 'Trebuie sa introduci alt motiv',
    duplicateBrandName: 'Un alt brand cu același nume exista deja',
    duplicateCategoryName: 'O altă categorie cu același nume exista deja',
    importantAction: 'Acțiune importantă',
    disableStockWarning: 'Confirmă dezactivare gestionare stoc',
    errorUnknown: 'S-a întâmplat ceva neprevăzut ...',
    disableStockWarningMessage:
      'Stocul curent este păstrat și nu va fi afectat de vânzare sau consum. Produsul va putea fi vândut în continuare',
    allProducts: 'Toate produsele',
    invalidFileFormat: 'Fișier necorespunzător',
    retry: 'Reia procesul',
    inStockValue: '{{value}} în stoc',
    unlimitedStock: 'Stoc nelimitat',
    archived: 'Arhivat',
    selectProduct: 'Alege produs',
    failedToDownloadSampleFile: 'Nu am putut descărca modelul de fișier, te rugăm să încerci din nou',
    totalQuantity: '(total {{quantity}} {{unit}})',
    adjustmentQuantity: 'Ajustare {{quantity}} {{unit}}',
    newStockQuantity: 'Stoc nou {{quantity}} {{unit}}',
    defaultStock: 'Gestiune implicită ({{count}} în stoc)',
    defaultStockUnlimited: 'Gestiune implicită (stoc nelimitat)',
    changeProduct: 'Modifică produs',
    quantity: 'Cantitate',
    unitPrice: 'Preț unitar',
    pricePlaceholder: 'Introdu prețul',
    total: 'Total',
    EURO: 'euro',
    quantityError: 'Stoc insuficient',
    addDiscount: 'Aplică reducere',
    deleteFromAppointment: 'Elimină din programare',
  },
  services: {
    services: 'Servicii',
    searchServices: 'Caută serviciu',
    servicesCount: '{{count}} servicii',
    add: 'Adaugă',
    options: 'Opțiuni',
    selectionOptions: 'Opțiuni selecție',
    servicesMultipleActions: 'Acțiuni multiple servicii',
    addService: 'Adaugă serviciu',
    addCategory: 'Adaugă categorie',
    allServices: 'Toate serviciile',
    otherServices: 'Alte servicii',
    updateCategoryName: 'Modifică nume categorie',
    deleteCategory: 'Șterge categorie',
    price: 'Preț vânzare',
    durationInMinutes: 'Durată',
    name: 'Serviciu',
    noServicesDescription: 'Produsele care vor fi alocate acestei categorii vor apărea aici',
    noServices: 'Niciun serviciu',
    manualApproval: 'confirmare manuală',
    private: 'nelistat online',
    selectServices: 'Selectează serviciile',
    searchByName: 'Caută după numele serviciului',
    selectedServices: '{{count}} servicii selectate',
    selectAll: 'Selectează tot',
    chooseBulkOperation: 'Alege opțiune aplicată selecției',
    listProfileOperation: 'Afișează serviciile la programări online',
    unlistProfileOperation: 'Ascunde serviciile la programări online',
    disableAutomaticApprovalOperation: 'Activează confirmare manuală programări',
    enableAutomaticApprovalOperation: 'Dezactivează confirmare manuală programări',
    deleteOperation: 'Șterge servicii',
    updateServicesSuccess: 'Serviciile au fost modificate cu succes',
    deleteServicesSuccess: 'Serviciile au fost șterse cu succes',
    deleteServicesPartialSuccess_one:
      'Un serviciu selectat nu poate fi șters cât timp face parte dintr-un abonament activ sau tip de abonament.',
    deleteServicesPartialSuccess_few:
      '{{count}} servicii selectate nu pot fi șterse cât timp fac parte dintr-un abonament activ sau tip de abonament.',
    deleteServicesPartialSuccess_other:
      '{{count}} servicii selectate nu pot fi șterse cât timp fac parte dintr-un abonament activ sau tip de abonament.',
    unselectAll: 'Deselectează tot',
    sortServices: 'Ordonează serviciile',
    sort: 'Ordonează',
    updateCategoryBulkOperation: 'Modifică tipul serviciilor',
    updateGroupBulkOperation: 'Modifică categoria serviciilor',
    resetDefaultBulkOperation: 'Resetează detaliile personalizate',
    updateServicesCategory: 'Modifică tipul serviciilor',
    updateServicesGroup: 'Modifică categoria serviciilor',
    category: 'Tipul serviciului',
    group: 'Categorie',
    save: 'Salvează',
    cancel: 'Anulează',
    categoryLabel: 'Alege tipul serviciului',
    groupLabel: 'Alege categoria serviciului',
    confirmDeleteServices: 'Confirmă ștergerea serviciilor',
    irreversibleAction: 'Acțiune ireversibilă',
    checkDeleteServices: 'Bifează și confirmă ștergerea',
    deleteServicesCheck1: 'Toate detalile serviciilor vor fi șterse, programările existente nu vor fi afectate',
    deleteServicesCheck2: 'Serviciile nu vor mai fi vizibile pe profilul online al locației',
    deleteServicesCheck3: 'Serviciile nu vor mai fi disponibile pentru programări pentru niciunul din profesioniști',
    noResultsFor: 'Niciun rezultat pentru',
    checkSearch: 'Verifică ca termenul de căutare sa fie corect și încearca din nou',
    changeService: 'Modifică serviciu',
    quantity: 'Cantitate',
    unitPrice: 'Preț unitar (lei)',
    pricePlaceholder: 'Introdu prețul',
    total: 'Total',
    RON: 'lei',
    EURO: 'euro',
    membershipConsumption: 'Consumat abonament',
    membershipConsumptionCount_one: '{{count}} ședință alocată din abonament ales',
    membershipConsumptionCount_other: '{{count}} ședințe alocate din abonament ales',
    membershipConsumptionCount_few: '{{count}} ședințe alocate din abonament ales',
    membership: 'Abonament',
    selectMembership: 'Alege abonament',
    membershipNameWithAvailability_one: '{{count}} ședință rămasă',
    membershipNameWithAvailability_other: '{{count}} ședințe rămase',
    membershipNameWithAvailability_few: '{{count}} ședințe rămase',
    membershipNameWithAvailabilityUnlimited: 'ședințe nelimitate',
    consumedFromMembership: '<0>Valoare:</0> {{count}}x{{value}} {{currency}}',
    consumedFromMembershipWithoutCurrency: '<0>Valoare:</0> {{count}}x{{value}}',
    remainingPayment: '<0>De plată:</0> {{value}} {{currency}}',
    Unlimited: 'Nelimitat',
    debtAmount: 'Rest de plată',
    deleteFromAppointment: 'Elimină din programare',
    addDiscount: 'Adaugă reducere',
    priceError: 'Prețul este invalid',
    priceType: 'Tip preț',
    Fixed: 'Preț fix',
    Range: 'Preț variabil',
    Hidden: 'Preț ascuns',
    priceFrom: 'Preț minim (lei)',
    priceTo: 'Preț maxim (lei)',
  },
  proUserSatisfaction: {
    scoreQuestion: 'Care este probabilitatea să recomanzi MERO unui prieten sau coleg?',
    highProbability: '10 - Foarte probabil',
    lowProbability: '0 - Puțin probabil',
    feedbackQuestion: 'Mulțumim pentru răspuns. Ne puteți spune de ce?',
    feedbackPlaceholder: 'Ajută-ne să înțelegem de ce ai acordat acest punctaj',
    send: 'Trimite',
    close: 'Închide',
    errorUnknown: 'S-a întâmplat ceva neprevăzut ...',
    thankYou: 'Mulțumim pentru feedback',
  },
};

export default ro;
