import { AvoidKeyboard, Body, ConfirmBox, H1, ModalOverlay, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ScrollView, useWindowDimensions } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';

export type Props = Readonly<{
  canClose: boolean;
  onClose: () => void;
  leftCallback: () => void;
  rightCallback: () => void;
}>;
const ConfirmCloseBooking: React.FC<Props> = ({ canClose, onClose, rightCallback, leftCallback }) => {
  const { t } = useTranslation('booking');
  const { width } = useWindowDimensions();

  useEscPressWeb({
    onPress: (event) => {
      event.stopPropagation();
      leftCallback();
    },
  });

  const leftAction = {
    text: t('close'),
    onPress: leftCallback,
    flex: 10,
  };

  const rightAction = {
    text: t('cancelChanges'),
    onPress: rightCallback,
    flex: 15,
  };

  return (
    <Modal animationType="none" transparent={true} visible={true}>
      <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
        <AvoidKeyboard style={{ width: width > 500 ? 500 : width }}>
          <MobileWebModalWrapper position="center">
            <ConfirmBox
              type="warn"
              icon="info"
              headerTitle={t('irrevocableAction')}
              canClose={canClose}
              onClose={onClose}
              leftAction={leftAction}
              rightAction={rightAction}
            >
              <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
                <H1>{t('closeBookingTitle')}</H1>
                <Spacer size="8" />
                <Body>{t('closeBookingDescription')}</Body>
              </ScrollView>
            </ConfirmBox>
          </MobileWebModalWrapper>
        </AvoidKeyboard>
      </ModalOverlay>
    </Modal>
  );
};

export default ConfirmCloseBooking;
