import * as React from 'react';
import { Platform } from 'react-native';

import { useIsFocused } from '@react-navigation/native';

export type Props = {
  onPress: (event: KeyboardEvent) => void;
};

export const useEscPressWeb = ({ onPress }: Props, dependencies: unknown[] = []) => {
  const isFocused = useIsFocused();

  React.useEffect(() => {
    const escFunction = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onPress(event);
      }
    };

    if (Platform.OS === 'web' && isFocused) {
      document.addEventListener('keydown', escFunction);
    }

    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('keydown', escFunction);
      }
    };
  }, [isFocused, ...dependencies]);
};
