import { mapWithIndex } from 'fp-ts/Record';
import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';

export const paramsDecode = <A extends Record<string, unknown>, B extends Record<keyof A, t.Mixed>>(
  data: A,
  decoders: B,
): { [K in keyof B]: B[K] extends t.Type<infer O, unknown> ? O | undefined : never } => {
  return pipe(
    data,
    mapWithIndex((key: keyof A, value) =>
      pipe(
        decoders[key].decode(value),
        E.getOrElseW(() => undefined),
      ),
    ),
  ) as { [K in keyof B]: B[K] extends t.Type<infer O, unknown> ? O | undefined : never };
};
