import { Row } from '@mero/components';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import Svg, { SvgProps, Path, G, Rect } from 'react-native-svg';

const DeleteIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G transform="translate(13.426 13.427)">
      <Rect
        width={24}
        height={24}
        fill="#e9ecef"
        data-name="Rectangle 2856"
        rx={12}
        transform="translate(-13.426 -13.427)"
      />
      <G
        fill="none"
        stroke="#52577f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        data-name="Orion_bin (1)"
      >
        <Path d="M-6.192-5.46H3.34m-6.6 0v-.751a.715.715 0 0 1 .716-.715h2.236a.715.715 0 0 1 .715.715v.752m1.925 0L1.8 3.174a.917.917 0 0 1-.9.9h-4.654a.917.917 0 0 1-.9-.9l-.53-8.634M-1.426-3.993v6.6m-1.833-6.6.367 6.6m3.3-6.6-.367 6.6" />
      </G>
    </G>
  </Svg>
);

const EditIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G transform="translate(13.426 13.427)">
      <Rect
        width={24}
        height={24}
        fill="#e9ecef"
        data-name="Rectangle 2856"
        rx={12}
        transform="translate(-13.426 -13.427)"
      />
      <G fill="none" stroke="#52577f" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
        <Path d="m3.472-3.878.175-.175a1.534 1.534 0 0 0-.175-2.269 1.534 1.534 0 0 0-2.269-.177l-.175.175" />
        <Path d="m2.404-2.812 1.07-1.069-2.445-2.44L-.04-5.256" />
        <Path
          d="m-3.729 3.321 6.133-6.136-2.444-2.44L-6.177.877l-.753 3.2 3.2-.753ZM-6.177.877l2.444 2.444"
          data-name="layer1"
        />
      </G>
    </G>
  </Svg>
);

type Props = {
  onDeletePress?: () => void;
  onEditPress?: () => void;
  showEditIcon?: boolean;
};

const HoverButtons: React.FC<Props> = ({ onEditPress, onDeletePress, showEditIcon = true }) => {
  return (
    <Row alignItems="center" style={{ alignSelf: 'flex-start' }}>
      {onDeletePress && (
        <TouchableOpacity onPress={onDeletePress}>
          <DeleteIcon />
        </TouchableOpacity>
      )}
      {showEditIcon && (
        <TouchableOpacity style={{ paddingLeft: 8 }} onPress={onEditPress}>
          <EditIcon />
        </TouchableOpacity>
      )}
    </Row>
  );
};

export default HoverButtons;
