import { FeedbackId, ProfileImage } from '@mero/api-sdk';
import { ClientAppointment } from '@mero/api-sdk/dist/clients/client-appointment';
import {
  Avatar,
  Body,
  colors,
  Column,
  FormCard,
  Icon,
  ReadMore,
  Row,
  SmallBody,
  Spacer,
  Title,
} from '@mero/components';
import { capitalize } from '@mero/shared-components';
import { Reply } from '@mero/shared-sdk';
import * as Clipboard from 'expo-clipboard';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import DoubleTapView from '../../../../../components/DoubleTap/DoubleTap';
import { styles as text } from '@mero/components/lib/styles/text';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import config from '../../../../../config';
import { AuthorizedStackParamList } from '../../../../../types';

const CalendarIcon = () => (
  <Svg width={24} height={24}>
    <G data-name="Group 6242">
      <Path
        data-name="Union 3"
        d="M4.62 20.924a1.639 1.639 0 0 1-1.631-1.647V5.49A1.638 1.638 0 0 1 4.62 3.842h1.834V2.607a.612.612 0 1 1 1.223 0v1.235h3.669V2.607a.612.612 0 1 1 1.223 0v1.235h3.669V2.607a.611.611 0 1 1 1.222 0v1.235h1.835a1.638 1.638 0 0 1 1.632 1.648v13.787a1.639 1.639 0 0 1-1.63 1.647ZM4.212 5.49v13.787a.41.41 0 0 0 .408.412h14.676a.41.41 0 0 0 .407-.412V5.49a.41.41 0 0 0-.407-.411h-1.835v1.233a.611.611 0 1 1-1.222 0V5.077h-3.67v1.235a.612.612 0 1 1-1.223 0V5.077H7.677v1.235a.612.612 0 1 1-1.223 0V5.077H4.62a.41.41 0 0 0-.408.413Zm6.523 11.935v-1.852h2.446v1.852Zm-4.281 0v-1.852H8.9v1.852Zm8.561-3.087V12.49h2.446v1.852Zm-4.28 0V12.49h2.446v1.852Zm-4.281 0V12.49H8.9v1.852Zm8.561-3.087V9.399h2.446v1.852Zm-4.28 0V9.399h2.446v1.852Zm-4.281 0V9.399H8.9v1.852Z"
        fill="#52577f"
      />
      <Path data-name="Rectangle 2491" fill="none" d="M0 0h24v24H0z" />
    </G>
  </Svg>
);

const NextIcon = () => (
  <Svg width={24} height={24}>
    <G data-name="Group 7242">
      <Path d="m9.407 17.743-1.385-1.385 4.569-4.489-4.569-4.485L9.407 6l5.954 5.869Z" fill="#080de0" />
      <Path data-name="Rectangle 2" fill="none" d="M0 0h24v24H0z" />
    </G>
  </Svg>
);

export const AnonymousProfile: ProfileImage = {
  _id: 'anonymous',
  thumbnail: `${config.marketPlaceUrl}/default_profile_image.jpg`,
  small: `${config.marketPlaceUrl}/default_profile_image.jpg`,
  medium: `${config.marketPlaceUrl}/default_profile_image.jpg`,
  large: `${config.marketPlaceUrl}/default_profile_image.jpg`,
  original: `${config.marketPlaceUrl}/default_profile_image.jpg`,
};

type Props = {
  readonly id: FeedbackId;
  readonly firstname: string;
  readonly lastname: string;
  readonly image?: string;
  readonly score: number;
  readonly review?: string;
  readonly replies?: Reply[];
  readonly clientAppointment?: ClientAppointment;
  readonly onSelect?: () => void;
  readonly onAddReply?: () => void;
  readonly canAddReply: boolean;
};

const Review = ({
  id,
  firstname,
  lastname,
  image,
  score,
  review,
  replies = [],
  clientAppointment,
  onSelect,
  onAddReply,
  canAddReply,
}: Props): React.ReactElement => {
  const { t } = useTranslation('reviews');
  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList>>();

  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(id);
  };

  return (
    <DoubleTapView onDoubleClick={copyToClipboard}>
      <Row>
        <Column>
          <Avatar size={24} firstname={firstname} lastname={lastname} source={image} />
        </Column>
        <Column style={{ marginLeft: 12 }} flex={1}>
          <Row>
            <Column style={{ flex: 1 }}>
              <Body style={text.semibold}>
                {firstname} {lastname}
              </Body>
              <Row alignItems="center">
                {Array(5)
                  .fill('')
                  .map((_, i) => (
                    <Column key={i} style={{ marginRight: 2 }}>
                      <Icon
                        type="star"
                        size="tiny"
                        backgroundColor={i <= score - 1 ? '#FBAC40' : undefined}
                        color="#FBAC40"
                      />
                    </Column>
                  ))}
                <SmallBody style={{ paddingLeft: 8 }}>{score} din 5</SmallBody>
              </Row>
            </Column>
          </Row>
          <Spacer size="xxs" />
          {!!review && (
            <ReadMore numberOfLines={3}>
              <SmallBody style={text.hint}>{review}</SmallBody>
            </ReadMore>
          )}
          {replies?.length === 0 && canAddReply ? (
            <TouchableOpacity style={{ paddingTop: 16 }} onPress={onAddReply}>
              <SmallBody style={{ color: colors.DARK_BLUE, fontFamily: 'open-sans-semibold' }}>
                {t('addReply')}
              </SmallBody>
            </TouchableOpacity>
          ) : null}
          {canAddReply
            ? replies.map((reply, index) => (
                <Row key={`${index}-${reply.message}`} style={{ paddingTop: 16 }}>
                  <Column>
                    <Avatar
                      size={24}
                      firstname={reply.user.firstname ?? ''}
                      lastname={reply.user.lastname ?? ''}
                      source={reply.user.profilePhoto?.small}
                    />
                  </Column>
                  <Column style={{ marginLeft: 12 }} flex={1}>
                    <Row>
                      <Body style={[text.semibold, { paddingBottom: 2, flex: 1 }]}>
                        {reply.user.firstname} {reply.user.lastname}
                      </Body>
                      <TouchableOpacity onPress={onSelect}>
                        <Icon type="options-dots" />
                      </TouchableOpacity>
                    </Row>
                    <ReadMore numberOfLines={3}>
                      <SmallBody style={text.hint}>{reply.message}</SmallBody>
                    </ReadMore>
                  </Column>
                </Row>
              ))
            : null}
        </Column>
      </Row>
      {clientAppointment ? (
        <>
          <Spacer size={16} />
          <FormCard paddings="none" style={{ padding: 12 }} dropShaddow>
            <TouchableOpacity
              style={{ flexDirection: 'row' }}
              onPress={() => {
                navigation.navigate('Booking', {
                  screen: 'AppointmentScreen',
                  params: {
                    appointmentId: clientAppointment?.appointmentId,
                    occurrenceIndex: 0,
                  },
                });
              }}
            >
              <CalendarIcon />
              <Column style={{ flex: 1, paddingLeft: 8 }}>
                <Row>
                  <Title style={{ flex: 1 }} numberOfLines={1} ellipsizeMode="tail">
                    {capitalize(DateTime.fromJSDate(clientAppointment.start).setLocale('ro').toFormat('EEE d LLL'))}
                  </Title>
                  <NextIcon />
                </Row>
                <SmallBody>{clientAppointment.bookedServices[0]?.name}</SmallBody>
                <SmallBody style={{ color: colors.COMET }}>
                  {clientAppointment.worker.firstname} {clientAppointment.worker.lastname}
                </SmallBody>
              </Column>
            </TouchableOpacity>
          </FormCard>
        </>
      ) : null}
    </DoubleTapView>
  );
};

export default Review;
