import { ClientId } from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import { Eq } from 'fp-ts/lib/Eq';
import * as React from 'react';
import { debounce } from 'throttle-debounce';

import log from '../../utils/log';
import { AuthContext, meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

export const LIMIT = 10;

type SearchQueryParams = Parameters<typeof meroApi.pro.servicesSearch.searchServices>[0];
type ServiceSearchType = Partial<SearchQueryParams['filters']>;
type ServiceSearchResult = Awaited<ReturnType<typeof meroApi.pro.servicesSearch.searchServices>>;

type SearchServicesState =
  | {
      readonly type: 'New';
      readonly query: ServiceSearchType;
      readonly result: ServiceSearchResult;
      readonly clientId?: ClientId;
    }
  | {
      readonly type: 'Loading';
      readonly pageId: PageId;
      readonly query: ServiceSearchType;
      readonly result: ServiceSearchResult;
      readonly clientId?: ClientId;
    }
  | {
      readonly type: 'Loaded';
      readonly pageId: PageId;
      readonly query: ServiceSearchType;
      readonly result: ServiceSearchResult;
      readonly clientId?: ClientId;
    }
  | {
      readonly type: 'Failed';
      readonly pageId: PageId;
      readonly query: ServiceSearchType;
      readonly result: ServiceSearchResult;
      readonly clientId?: ClientId;
      readonly error: unknown;
    };

const defaultState = (): SearchServicesState => ({
  type: 'New',
  query: {
    search: '',
  },
  result: {
    data: [],
    next: undefined,
    prev: undefined,
  },
});

const servicesSearchQuery: Eq<ServiceSearchType> = {
  equals: (a, b) => a?.search === b?.search && a?.workerId === b?.workerId,
};

export const SearchServicesContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      payload: {
        pageId: PageId;
        query: ServiceSearchType;
        result: ServiceSearchResult;
        clientId?: ClientId;
      },
    ) => {
      if (
        state.type === 'Loading' &&
        state.pageId === payload.pageId &&
        servicesSearchQuery.equals(payload.query, state.query)
      ) {
        return {
          type: 'Loaded',
          pageId: payload.pageId,
          query: payload.query,
          result: payload.result,
          clientId: payload.clientId ?? state.clientId,
        };
      } else {
        return state;
      }
    },
    trySetFailed: (
      state,
      payload: {
        pageId: PageId;
        query: ServiceSearchType;
        clientId?: ClientId;
        error: unknown;
      },
    ) => {
      if (
        state.type === 'Loading' &&
        state.pageId === payload.pageId &&
        servicesSearchQuery.equals(payload.query, state.query)
      ) {
        return {
          ...state,
          type: 'Failed',
          pageId: payload.pageId,
          query: payload.query,
          result: state.result,
          clientId: payload.clientId ?? state.clientId,
          error: payload.error,
        };
      } else {
        return state;
      }
    },
    tryResetFailed: (state) => {
      if (state.type === 'Failed') {
        return {
          ...state,
          type: 'Loaded',
          pageId: state.pageId,
          query: state.query,
          result: state.result,
        };
      } else {
        return state;
      }
    },
    updateSearch: (state, search: string) => {
      if (state.type === 'Loaded') {
        return {
          ...state,
          type: 'Loaded',
          pageId: state.pageId,
          query: {
            ...state.query,
            search,
          },
          result: state.result,
        };
      } else {
        return state;
      }
    },
    reset: () => defaultState(),
    mutate: (s, fn: (s: SearchServicesState) => SearchServicesState) => {
      return fn(s);
    },
  },
  (dispatch) => {
    log.debug('Init SearchServicesContext methods');
    type SearchFn = (pageId: PageId, query: ServiceSearchType, clientId?: ClientId) => Promise<void>;

    const search: SearchFn = async (pageId, query, clientId): Promise<void> => {
      try {
        log.debug('search services for page', pageId, 'query', query);

        const result = await meroApi.pro.servicesSearch.searchServices({
          pageId,
          filters: query,
          clientId,
          limit: LIMIT,
        });

        dispatch.trySetResult({
          pageId,
          query,
          result,
          clientId,
        });
      } catch (e) {
        log.exception(e);
        dispatch.trySetFailed({
          pageId,
          query,
          clientId,
          error: e,
        });
      }
    };

    const debounceSearch = debounce(250, search);

    return {
      init: (payload: { pageId: PageId; query?: Partial<ServiceSearchType>; clientId?: ClientId }): void => {
        dispatch.mutate((state) => {
          const query: ServiceSearchType = {
            ...payload.query,
          };

          search(payload.pageId, query, payload.clientId);

          return {
            type: 'Loading',
            pageId: payload.pageId,
            query: query,
            result: state.result,
          };
        });
      },
      search: (payload: { query?: Partial<ServiceSearchType>; clientId?: ClientId }): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loading' || state.type === 'Loaded') {
            const query: ServiceSearchType = payload.query ?? state.query;

            search(state.pageId, query, payload.clientId);

            return {
              type: 'Loading',
              pageId: state.pageId,
              query,
              result: state.result,
              clientId: payload.clientId,
            };
          } else {
            return state;
          }
        });
      },
      debounceSearch: (payload: { query?: Partial<ServiceSearchType>; clientId?: ClientId }): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loading' || state.type === 'Loaded') {
            const query: ServiceSearchType = payload.query ?? state.query;

            debounceSearch(state.pageId, query, payload.clientId);

            return {
              type: 'Loading',
              pageId: state.pageId,
              query,
              result: state.result,
              clientId: payload.clientId,
            };
          } else {
            return state;
          }
        });
      },

      updateSearch: dispatch.updateSearch,

      reset: dispatch.reset,

      loadMore: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded' && state.result.next) {
            const { pageId, query, result } = state;

            const loadMore = async (): Promise<void> => {
              try {
                log.debug('loading more services for page', pageId, 'query', query, 'next', result.next);

                const moreServices = await meroApi.pro.servicesSearch.searchServices({
                  pageId,
                  filters: query,
                  clientId: state.clientId,
                  page: result.next,
                  limit: LIMIT,
                });

                log.debug('loaded', LIMIT, 'more services for page', pageId, 'query', query);

                const newResult = {
                  ...moreServices,
                  data: result.data.concat(moreServices.data),
                };

                dispatch.trySetResult({
                  pageId,
                  query,
                  result: newResult,
                });
              } catch (e) {
                log.exception(e);
                dispatch.trySetFailed({
                  pageId,
                  query,
                  error: e,
                });
              }
            };

            loadMore();

            return {
              type: 'Loading',
              pageId: state.pageId,
              query,
              result: state.result,
              clientId: state.clientId,
            };
          } else {
            return state;
          }
        });
      },

      reload: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loading' || state.type === 'Loaded') {
            log.debug('reload services for page', state.pageId, 'query', state.query);
            search(state.pageId, state.query, state.clientId);

            return {
              type: 'Loading',
              pageId: state.pageId,
              query: state.query,
              result: state.result,
              clientId: state.clientId,
            };
          } else {
            return state;
          }
        });
      },
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    withInit: boolean;
    query?: Partial<ServiceSearchType>;
  }>
> = ({ children, query, withInit }) => {
  const [authState] = AuthContext.useContext();
  const [currentBusinessState] = CurrentBusinessContext.useContext();
  const [, { init }] = SearchServicesContext.useContext();

  const page = currentBusinessState.type === 'Loaded' ? currentBusinessState.page : undefined;
  const user = authState.type === 'Authorized' ? authState.user : undefined;

  React.useEffect(() => {
    const initSearch = async () => {
      if (page && user && withInit) {
        init({
          pageId: page.details._id,
          query,
        });
      }
    };
    initSearch();
  }, [init, page, user]);

  return <>{children}</>;
};

export const withSearchServicesContextProvider =
  (withInit = true, query?: Partial<ServiceSearchType>) =>
  <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
    return function WithSearchServicesContextProvider(props: P) {
      return (
        <SearchServicesContext.Provider>
          <ContextInit query={query} withInit={withInit}>
            <Content {...props} />
          </ContextInit>
        </SearchServicesContext.Provider>
      );
    };
  };
