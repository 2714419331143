import { ClientId } from '@mero/api-sdk';
import * as MeroApi from '@mero/api-sdk';
import { HasOptionalFirstLastName } from '@mero/api-sdk/dist/common';
import { WorkerService } from '@mero/api-sdk/dist/services';
import {
  Body,
  colors,
  Column,
  formatDurationInMinutes,
  HSpacer,
  Icon,
  Label,
  Row,
  sizes,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Title,
} from '@mero/components';
import { formatRangeDuration } from '@mero/shared-components';
import { pipe } from 'fp-ts/lib/function';
import { number } from 'io-ts';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { SvgProps, Path, G, Circle } from 'react-native-svg';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { AppointmentService } from '../../../contexts/BookingFormContext';
import { AuthorizedStackParamList } from '../../../types';
import {
  computeServicePrice,
  getPriceText,
  getUnit,
  isDiscountedPrice,
  ServicePrice,
} from '../../../utils/servicePrice';
import HoverButtons from './HoverButtons';
import { withHoverState } from './HoverState';
import { MembershipServices } from './common';

const MembershipIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <Path fill="none" d="M0 0h24v24H0Z" data-name="Path 8275" />
    <G
      fill="none"
      stroke="#52577f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      transform="translate(3 5.25)"
    >
      <Path d="M14.741 0v.295a1.474 1.474 0 0 1-2.948 0V0H5.9v.295a1.474 1.474 0 0 1-2.948 0V0H0v12.972h17.689V0ZM10.613 4.717h4.717m-4.717 2.358h4.717m-4.717 2.359h2.359" />
      <Circle cx={1.598} cy={1.598} r={1.598} data-name="layer1" transform="translate(3.732 3.834)" />
      <Path
        d="M5.307 7.075a2.978 2.978 0 0 0-2.947 3.037v.5h5.9v-.5a2.978 2.978 0 0 0-2.953-3.037Z"
        data-name="layer1"
      />
    </G>
  </Svg>
);

type Props = {
  isHovered: boolean;
  disabled?: boolean;
  service: AppointmentService;
  computedPrice?: ServicePrice;
  onSelect: (service: AppointmentService) => void;
  onDelete: (service: AppointmentService) => void;
  isSelected: boolean;
  memberships: MembershipServices[];
  worker?: HasOptionalFirstLastName;
  clientId?: ClientId;
  workerServices: WorkerService[];
  page: MeroApi.pages.PageDetails;
};

const ServiceListItem: React.FC<Props> = ({
  isHovered,
  service,
  onSelect,
  onDelete,
  disabled = false,
  isSelected,
  computedPrice,
  workerServices,
  worker,
  page,
  memberships,
  clientId,
}) => {
  const { t } = useTranslation('services');
  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList>>();

  const duration = number.is(service.durationInMinutes)
    ? formatDurationInMinutes(service.durationInMinutes)
    : formatRangeDuration(service.durationInMinutes.from, service.durationInMinutes.to);

  const warningLabel = React.useMemo(() => {
    if (!worker) {
      return undefined;
    }

    const isWorkerService = workerServices.some((ws) => ws._id === service._id);
    const isPageService = page.services.some((s) => s._id === service._id);

    if (isWorkerService && !isPageService) {
      return t('booking:notProvidedByPage');
    }

    if (!isWorkerService && !isPageService) {
      return t('booking:noLongerAvailable');
    }

    if (!isWorkerService && isPageService) {
      return t('booking:notProvidedBySelectedPro');
    }

    return undefined;
  }, [worker, page, workerServices]);

  const serviceTotalPrice = React.useMemo(
    () => computeServicePrice(service.quantity, 0, service.customPrice),
    [service.customPrice, service.quantity],
  );

  return (
    <TouchableOpacity
      onPress={() => onSelect(service)}
      style={{ padding: 8, borderWidth: 1, borderColor: colors.GEYSER, borderRadius: 6 }}
    >
      <Column
        style={[
          { borderRadius: 6 },
          isHovered && { backgroundColor: colors.ALABASTER },
          isSelected && { backgroundColor: colors.SKY_BLUE },
        ]}
      >
        <Column style={{ paddingLeft: 8, paddingRight: 4, paddingVertical: 8 }}>
          <Row>
            <Title style={{ flex: 1 }}>{service.name}</Title>
            {isHovered && !disabled ? (
              <>
                <HoverButtons onEditPress={() => onSelect(service)} onDeletePress={() => onDelete(service)} />
                <HSpacer left={4} />
              </>
            ) : (
              <>
                <Column>
                  <Body
                    style={[
                      { paddingRight: 8 },
                      serviceTotalPrice.type === 'Hidden' && { fontSize: 12, textTransform: 'uppercase' },
                    ]}
                  >
                    {getPriceText({
                      type: 'discounted',
                      price: computedPrice ?? serviceTotalPrice,
                      t,
                    })}{' '}
                    {serviceTotalPrice.type !== 'Hidden' && t(getUnit(service.customPrice))}
                  </Body>
                </Column>
                <Icon type="next" color={colors.DARK_BLUE} />
              </>
            )}
          </Row>
          <Row>
            <SmallBody style={{ color: colors.COMET, flex: 1 }}>
              {service.quantity} x {duration}
            </SmallBody>
            {computedPrice && isDiscountedPrice(computedPrice) && !(isHovered && !disabled) ? (
              <SmallBody
                style={{
                  color: colors.COMET,
                  textDecorationLine: 'line-through',
                  textAlign: 'right',
                  paddingRight: 32,
                }}
              >
                {getPriceText({
                  type: 'total',
                  price: computedPrice,
                  t,
                })}{' '}
                {computedPrice.type !== 'Hidden' && t(getUnit(service.customPrice))}
              </SmallBody>
            ) : null}
          </Row>
        </Column>
        {Boolean(warningLabel) ? (
          <>
            <SmallBody
              style={[
                {
                  fontSize: 12,
                  paddingBottom: 8,
                  paddingLeft: 8,
                },
              ]}
            >
              * {warningLabel?.toLowerCase()}
            </SmallBody>
          </>
        ) : (
          <>
            <Column flexWrap="wrap" style={{ marginLeft: 8 }}>
              {memberships.map((membership) => {
                const membershipItem = membership.items.find((i) => i._id === service._id);
                return (
                  <TouchableOpacity
                    key={membership._id}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingLeft: 4,
                      paddingRight: 8,
                      backgroundColor: colors.ATHENS_GRAY,
                      borderRadius: 4,
                      marginRight: 8,
                      marginTop: 8,
                      alignSelf: 'flex-start',
                    }}
                    disabled={!clientId}
                    onPress={() => {
                      if (clientId) {
                        navigation.navigate('ClientDetails', {
                          screen: 'MembershipDetailsScreen',
                          params: {
                            pageId: page._id,
                            clientId,
                            membershipPurchaseId: membership._id,
                            readonly: true,
                          },
                        });
                      }
                    }}
                  >
                    <MembershipIcon />
                    <HSpacer left={sizes[4]} />
                    <Label style={[meroStyles.text.semibold, { color: colors.COMET }]}>
                      {membership.name}
                      {membershipItem && membershipItem.quantity !== Infinity
                        ? ` (${t('booking:membershipLeft', { count: membershipItem.quantity })})`
                        : ` (${t('booking:membershipLeftUnlimited')})`}
                    </Label>
                  </TouchableOpacity>
                );
              })}
              <Spacer size={8} />
            </Column>
          </>
        )}
      </Column>
    </TouchableOpacity>
  );
};

export default pipe(ServiceListItem, withHoverState);
