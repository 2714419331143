import { MergedService } from '@mero/api-sdk/dist/services';
import { Line, SimpleListItem, SmallBody, colors, formatPriceText, Spacer } from '@mero/components';
import { formatRangeDuration } from '@mero/shared-components';
import { DateTime, Zone } from 'luxon';
import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import HSpacer from '@mero/components/lib/components/HSpacer';

import { FlashyLabel } from '../FlashyLabel';

export type Props = {
  /**
   * Service data to display
   */
  readonly service: MergedService;
  /**
   * Service start time
   */
  readonly startTime?: Date;

  readonly timeZone: Zone;
  /**
   * Item press handler
   */
  readonly onPress?: () => void;
  /**
   * When set to true will add a horizontal line at the bottom of the element
   */
  readonly hasLine?: boolean;
  /**
   * Choose checked icon style
   */
  readonly checkIcon?: 'checked' | 'checked-outline' | 'next';
  /**
   * Align content
   */
  readonly alignContent?: 'middle' | 'top';
  /**
   * Custom styles TouchableOpacity container
   */
  readonly style?: StyleProp<ViewStyle>;
  /**
   * Custom styles for SimpleListItem
   */
  readonly itemStyle?: StyleProp<ViewStyle>;

  readonly hasMembership?: boolean;
};

const MergedServiceListItem: React.FC<Props> = ({
  service,
  startTime,
  timeZone,
  onPress,
  checkIcon = 'checked',
  alignContent = 'middle',
  hasLine,
  style,
  itemStyle,
  hasMembership = false,
}: Props) => {
  const priceStr = formatPriceText(service.price);
  const startDateTime = React.useMemo(
    () => (startTime !== undefined ? DateTime.fromJSDate(startTime, { zone: timeZone }) : undefined),
    [startTime, timeZone],
  );

  const subtitle = (
    <SmallBody>
      {`${
        startDateTime !== undefined ? `${startDateTime.toFormat('H:mm', { locale: 'ro' })} - ` : ''
      }${formatRangeDuration(service.durationInMinutes.from, service.durationInMinutes.to)}`}
      {priceStr !== undefined ? ', ' : ''}
      {service.price.type === 'fixed' && service.price?.promo ? (
        <>
          <SmallBody>
            {service.price.promo} lei{' '}
            <SmallBody style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
              ({service.price.fixed} lei)
            </SmallBody>
          </SmallBody>
        </>
      ) : (
        <SmallBody>{priceStr}</SmallBody>
      )}
    </SmallBody>
  );
  return (
    <TouchableOpacity onPress={onPress} disabled={onPress === undefined} style={style}>
      <SimpleListItem
        title={service.name}
        subtitle={subtitle}
        iconColor={checkIcon === 'next' ? colors.DARK_BLUE : undefined}
        icon={checkIcon ?? (onPress !== undefined ? 'next' : undefined)}
        iconPosition={checkIcon === 'next' ? 'right' : 'left'}
        alignContent={alignContent}
        style={itemStyle}
        centerContainerStyle={checkIcon === 'checked-outline' ? { marginLeft: 12 } : {}}
        paddingBottom={8}
      />
      {hasMembership ? (
        <>
          <HSpacer left={16}>
            <FlashyLabel type="info" text="Inclus in abonament" />
          </HSpacer>
          <Spacer size="16" />
        </>
      ) : (
        <Spacer size="8" />
      )}
      {hasLine ? <Line /> : null}
    </TouchableOpacity>
  );
};

export default MergedServiceListItem;
