import {
  RecurrenceRule,
  DefinedString,
  Firstname,
  isDefined,
  OptionalLastname,
  StrictPhoneNumber,
  BookedServicesPayload,
  ConsumedProductsPayload,
  InventoryId,
  ProductSearchHit,
  StockDescription,
  ConsumedProduct as ConsumedProductPayload,
  BookedService,
} from '@mero/api-sdk';
import { ClientPreview } from '@mero/api-sdk/dist/clients';
import { MembershipItemConsumptionLock } from '@mero/api-sdk/dist/memberships/membershipConsumptionLock';
import {
  customizeService,
  HasServiceId,
  mergeServices,
  Price,
  SavedService,
  ServiceId,
} from '@mero/api-sdk/dist/services';
import { SavedWorker, WorkerId } from '@mero/api-sdk/dist/workers';
import { createModelContext } from '@mero/components';
import { MeroUnits, Money, Option, PositiveInt, PositiveScaledNumber, ScaledNumber } from '@mero/shared-sdk';
import * as Ap from 'fp-ts/lib/Apply';
import * as A from 'fp-ts/lib/Array';
import * as Nea from 'fp-ts/lib/NonEmptyArray';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import * as React from 'react';

import { getPriceAndType } from '../../utils/number';

export interface BookedServicePreview extends HasServiceId {
  readonly name: string;
  readonly durationInMinutes: number | { from: number; to: number };
  readonly price: Price;
  readonly quantity: Option<PositiveInt>;
}

export type AppointmentService = {
  readonly name: string;
  readonly durationInMinutes: number | { from: number; to: number };
  membershipIndex?: number;
} & BookedServicesPayload[number];

export const convertServiceToBookedService = (
  service: Pick<BookedServicePreview, '_id' | 'durationInMinutes' | 'name' | 'price' | 'quantity'> &
    Pick<BookedService, 'customPrice'> & { membershipIndex?: number },
): AppointmentService => {
  const computedPrice = getPriceAndType(service.price);

  return {
    _id: service._id,
    name: service.name,
    durationInMinutes: service.durationInMinutes,
    quantity: service.quantity ?? (1 as PositiveInt),
    membershipIndex: service.membershipIndex,
    customPrice: service.customPrice ?? computedPrice,
  };
};

export type ValidBookedService = {
  readonly type: 'valid';
  readonly service: AppointmentService;
};

export type InvalidBookedService = {
  readonly type: 'invalid';
  readonly service: AppointmentService;
};

type BookedServiceValidation = ValidBookedService | InvalidBookedService;

const isValidBookedService = (s: BookedServiceValidation): s is ValidBookedService => s.type === 'valid';

export type ConsumedProduct = ConsumedProductsPayload[number] &
  Omit<ProductSearchHit, 'price' | 'stockDescription'> & { stock: StockDescription } & {
    price: Pick<ProductSearchHit['price'], 'discountedPrice'>;
  };

export type ValidConsumedProduct = {
  readonly type: 'valid';
  readonly product: ConsumedProduct;
};

export type InvalidConsumedProduct = {
  readonly type: 'invalid';
  readonly product: ConsumedProduct;
};

type ConsumedProductValidation = ValidConsumedProduct | InvalidConsumedProduct;

const isValidConsumedProduct = (p: ConsumedProductValidation): p is ValidConsumedProduct => p.type === 'valid';

export const readConsumedProductToConsumedProduct = (
  product: ConsumedProductPayload,
  stock: StockDescription,
  isStockManagementEnabled: boolean,
): ConsumedProduct => ({
  _id: product._id,
  quantity: product.quantity,
  inventoryId: product.inventoryId,
  measure: product.measure,
  price: product.price,
  customPrice: product.customPrice,
  name: product.name,
  stock,
  isStockManagementEnabled,
});

export const convertProductToConsumedProduct = (
  product: ProductSearchHit,
  inventoryId: InventoryId,
  stock: StockDescription,
): ConsumedProduct => ({
  ...product,
  quantity: ScaledNumber.fromNumber(1, 2) as PositiveScaledNumber,
  customPrice: {
    amount: {
      amount: product.price.discountedPrice.amount,
      unit: MeroUnits.RON.code,
    },
    discount: undefined,
  },
  inventoryId,
  stock,
});

export type BookingClientNone = {
  readonly type: 'none';
};

const BookingClientNone: BookingClientNone = {
  type: 'none',
};

export type BookingClientExisting = {
  readonly type: 'existing';
  readonly client: ClientPreview;
};

export type BookingClientNewEmpty = {
  readonly type: 'new';
  readonly isValid: true;
  readonly isEmpty: true;
  readonly fullname?: undefined;
  readonly phone?: undefined;
};

export type BookingClientNewValid = {
  readonly type: 'new';
  readonly isValid: true;
  readonly isEmpty: false;
  readonly fullname: string;
  readonly firstname: Firstname;
  readonly lastname: OptionalLastname;
  readonly phone: StrictPhoneNumber;
};

export type BookingClientNewInvalid = {
  readonly type: 'new';
  readonly isValid: false;
  readonly isEmpty: false;
  readonly fullname?: string;
  readonly phone?: string;
};

export type BookingClient =
  | BookingClientNone
  | BookingClientExisting
  | BookingClientNewEmpty
  | BookingClientNewValid
  | BookingClientNewInvalid;

const isValidBookingClient = (
  client: BookingClient,
): client is BookingClientNone | BookingClientExisting | BookingClientNewValid | BookingClientNewEmpty =>
  client.type === 'none' || client.type === 'existing' || (client.type === 'new' && client.isValid);

type ValidBookingForm = {
  readonly type: 'Valid';
  readonly start: Date;
  readonly end: Date;
  readonly recurrenceRule?: RecurrenceRule.Any;
  readonly performer: SavedWorker;
  readonly bookedServiceIds: Nea.NonEmptyArray<ServiceId>;
  readonly services: Nea.NonEmptyArray<BookedServiceValidation>;
  readonly memberships: MembershipItemConsumptionLock[];
  readonly products: ConsumedProductValidation[];
  readonly notes?: DefinedString;
  readonly client: BookingClientNone | BookingClientExisting | BookingClientNewValid | BookingClientNewEmpty;
};

export type InvalidBookingForm = {
  readonly type: 'Invalid';
  readonly start?: Date;
  readonly end?: Date;
  readonly recurrenceRule?: RecurrenceRule.Any;
  readonly services: BookedServiceValidation[];
  readonly products: ConsumedProductValidation[];
  readonly memberships: MembershipItemConsumptionLock[];
  readonly performer?: SavedWorker;
  readonly notes?: DefinedString;
  readonly client: BookingClient;
};

export type BookingFormContextState = InvalidBookingForm | ValidBookingForm;

/**
 * Default event duration in milliseconds
 */
const DefaultEventDuration = 1800000;

export const addDefaultEventDuration = (start: Date): Date => new Date(start.getTime() + DefaultEventDuration);
export const subDefaultEventDuration = (end: Date): Date => new Date(end.getTime() - DefaultEventDuration);

type HasBookedService = { service: AppointmentService };

/**
 * Get booking start date for given {@link end} and a list of {@link services}
 * For services with variable duration will use minimum duration
 */
export const getBookingStart = (end: Date, services: HasBookedService[]): Date =>
  services.length > 0
    ? services.reduce(
        (end, s) =>
          new Date(
            end.getTime() -
              (t.number.is(s.service.durationInMinutes)
                ? s.service.durationInMinutes
                : s.service.durationInMinutes.from) *
                60000,
          ),
        end,
      )
    : subDefaultEventDuration(end);

/**
 * Get booking end date for given {@link start} and a list of {@link services}
 * For services with variable duration will use minimum duration
 */
export const getBookingEnd = (payload: {
  newStart: Date;
  oldStart?: Date;
  oldEnd?: Date;
  services: HasBookedService[];
}): Date => {
  const { newStart, oldStart, oldEnd, services } = payload;
  if (oldStart && oldEnd) {
    const oldDuration = oldEnd.getTime() - oldStart.getTime();
    const newEnd = newStart.getTime() + oldDuration;

    return new Date(newEnd);
  }
  return services.length > 0
    ? services.reduce(
        (start, s) =>
          new Date(
            start.getTime() +
              (t.number.is(s.service.durationInMinutes)
                ? s.service.durationInMinutes
                : s.service.durationInMinutes.from) *
                60000 *
                s.service.quantity,
          ),
        newStart,
      )
    : addDefaultEventDuration(newStart);
};

const validateServices =
  (worker: SavedWorker | undefined) =>
  (services: BookedServiceValidation[]): BookedServiceValidation[] =>
    services.map((sv) => {
      // Consider service valid if there is no worker selected or selected worker can perform this service
      if (!worker || worker.services.some((s) => s._id === sv.service._id)) {
        return {
          type: 'valid',
          service: sv.service,
        };
      } else {
        return {
          type: 'invalid',
          service: sv.service,
        };
      }
    });

const validateProducts = (product: ConsumedProductValidation[]): ConsumedProductValidation[] =>
  product.map((p) => {
    if (!p.product.isStockManagementEnabled || p.product.stock.stock.value >= p.product.quantity.value) {
      return {
        type: 'valid',
        product: p.product,
      };
    } else {
      return {
        type: 'invalid',
        product: p.product,
      };
    }
  });

const validate = (state: BookingFormContextState): BookingFormContextState =>
  pipe(
    Ap.sequenceT(O.Apply)(
      O.fromNullable(state.start),
      O.fromNullable(state.end),
      pipe(state.client, O.fromNullable, O.filter(isValidBookingClient)),
      pipe(state.performer, O.fromNullable),
      pipe(state.services, O.of, O.filter(A.every(isValidBookedService)), O.filter(A.isNonEmpty)),
      pipe(state.products, O.of, O.filter(A.every(isValidConsumedProduct))),
    ),
    O.map(
      ([start, end, client, performer, services, products]): ValidBookingForm => ({
        type: 'Valid',
        performer,
        start,
        end,
        bookedServiceIds: pipe(
          services,
          Nea.map((s) => s.service._id),
        ),
        services,
        products,
        memberships: state.memberships,
        notes: state.notes,
        client: client,
        recurrenceRule: state.recurrenceRule,
      }),
    ),
    O.getOrElseW(() => state),
  );

const defaultState = (): BookingFormContextState => ({
  type: 'Invalid',
  performer: undefined,
  services: [],
  products: [],
  memberships: [],
  client: BookingClientNone,
});

export const BookingFormContext = createModelContext(
  defaultState(),
  {
    reset: (
      _,
      payload: {
        readonly start?: Date;
        readonly end?: Date;
        readonly recurrenceRule?: RecurrenceRule.Any;
        readonly notes?: DefinedString;
        readonly client?: ClientPreview;
        readonly memberships?: MembershipItemConsumptionLock[];
        readonly newClient?: Pick<BookingClientNewInvalid, 'fullname' | 'phone'>;
        readonly performer?: SavedWorker;
        readonly services?: AppointmentService[];
        readonly products?: ConsumedProduct[];
      },
    ) => {
      const services = pipe(
        payload.services ?? [],
        A.map(
          (s): BookedServiceValidation => ({
            type: 'invalid',
            service: s,
          }),
        ),
        validateServices(payload.performer),
      );
      (payload.memberships ?? []).forEach((m, index) => {
        services[m.item.bookingServiceIndex].service.membershipIndex = index;
      });

      const end =
        payload.end ??
        getBookingEnd({
          newStart: payload.start ?? new Date(),
          services: services,
        });

      return validate({
        type: 'Invalid',
        start: payload.start,
        end,
        recurrenceRule: payload.recurrenceRule,
        notes: payload.notes,
        memberships: payload.memberships ?? [],
        client:
          payload.newClient && !payload.client
            ? {
                type: 'new',
                isValid: false,
                isEmpty: false,
                fullname: payload.newClient.fullname,
                phone: payload.newClient.phone,
              }
            : payload.client === undefined
            ? BookingClientNone
            : { type: 'existing', client: payload.client },
        performer: payload.performer,
        services,
        products: pipe(
          payload.products ?? [],
          A.map((p): ConsumedProductValidation => ({ type: 'invalid', product: p })),
          validateProducts,
        ),
      });
    },
    setStart: (state, start: Date) =>
      validate({
        ...state,
        start,
        // always reset end when start changed
        end: getBookingEnd({
          newStart: start,
          oldStart: state.start,
          oldEnd: state.end,
          services: state.services,
        }),
      }),
    setEnd: (state, end: Date) =>
      validate({
        ...state,
        type: 'Invalid',
        // reset start date only if after end
        start:
          !state.start || state.start.getTime() > end.getTime() ? getBookingStart(end, state.services) : state.start,
        end,
      }),
    setPerformer: (state, performer: SavedWorker) =>
      validate({
        ...state,
        type: 'Invalid',
        performer,
        services: validateServices(performer)(state.services),
      }),
    setRecurrenceRule: (state, recurrenceRule: RecurrenceRule.Any | undefined) => ({
      ...state,
      recurrenceRule,
    }),
    setNotes: (state, notes: DefinedString | undefined) => ({
      ...state,
      notes,
    }),
    setClient: (state, client: BookingClient) =>
      validate({
        ...state,
        type: 'Invalid',
        client,
      }),
    addService: (state, srv: AppointmentService) => {
      const services = validateServices(state.performer)(
        state.services.concat([
          {
            type: 'invalid',
            service: srv,
          },
        ]),
      );

      return validate({
        ...state,
        type: 'Invalid',
        services: services,
        end: state.start ? getBookingEnd({ newStart: state.start, services }) : undefined,
      });
    },
    updateServicesPreview: (state, payload: { pageServices: SavedService[]; workers: SavedWorker[] }) => {
      type ServiceById = { [K in ServiceId]?: SavedService };
      const buildServicesMap = (services: SavedService[]): ServiceById =>
        services.reduce((acc: ServiceById, s) => ({ ...acc, [s._id]: s }), {});

      type WorkerServicesById = { [K in WorkerId]?: ServiceById };
      const pageServicesMap = buildServicesMap(payload.pageServices);
      const workersServicesMap: WorkerServicesById = payload.workers.reduce(
        (acc: WorkerServicesById, w) => ({ ...acc, [w._id]: buildServicesMap(w.services) }),
        {},
      );

      const performer = state.performer;

      if (performer) {
        // when performer is selected - use his (customised) version of services
        const services = validateServices(performer)(
          state.services
            .map((s): BookedServiceValidation | undefined => {
              const pageService: SavedService | undefined = pageServicesMap[s.service._id];
              const workerServices = workersServicesMap[performer._id];
              const workerService: SavedService | undefined = workerServices
                ? workerServices[s.service._id]
                : undefined;

              if (pageService) {
                if (workerService) {
                  return {
                    type: 'invalid',
                    service: convertServiceToBookedService({
                      ...customizeService(pageService, workerService),
                      customPrice: undefined,
                      quantity: undefined,
                    }),
                  };
                } else {
                  return s;
                }
              } else {
                return undefined;
              }
            })
            .filter(isDefined),
        );

        return validate({
          ...state,
          type: 'Invalid',
          services,
          end: state.start ? getBookingEnd({ newStart: state.start, services }) : undefined,
        });
      } else {
        // When performer is not selected - use a merged version of services
        type ServicesById = { [K in ServiceId]?: SavedService[] };
        const workersServicesMap = payload.workers.reduce((acc: ServicesById, worker) => {
          worker.services.forEach((ws) => {
            const services = acc[ws._id] ?? [];
            services.push(ws);
            acc[ws._id] = services;
          });

          return acc;
        }, {});

        const services = validateServices(performer)(
          state.services
            .map((s): BookedServiceValidation | undefined => {
              const pageService = pageServicesMap[s.service._id];

              if (pageService) {
                const mergedService = mergeServices(pageService, workersServicesMap[pageService._id] ?? []);
                if (mergedService) {
                  return {
                    type: 'invalid',
                    service: convertServiceToBookedService({
                      ...mergedService,
                      customPrice: undefined,
                      quantity: undefined,
                    }),
                  };
                } else {
                  return undefined;
                }
              } else {
                return undefined;
              }
            })
            .filter(isDefined),
        );

        return validate({
          ...state,
          type: 'Invalid',
          services,
          end: state.start ? getBookingEnd({ newStart: state.start, services }) : undefined,
        });
      }
    },
    updateServiceAt: (state, payload: { index: number; service: AppointmentService }) => {
      const { index, service } = payload;
      const services = pipe(
        state.services,
        A.updateAt(index, {
          type: 'valid',
          service,
        } as BookedServiceValidation),
        O.getOrElseW(() => state.services),
      );

      const end = state.start ? getBookingEnd({ newStart: state.start, services }) : undefined;

      return validate({
        ...state,
        type: 'Invalid',
        services: services,
        end: end,
      });
    },
    deleteServiceAt: (state, index: number) => {
      const service = state.services[index];
      const services = pipe(
        state.services,
        A.deleteAt(index),
        O.getOrElseW(() => state.services),
      );

      const end = state.start ? getBookingEnd({ newStart: state.start, services }) : undefined;

      if (service.service.membershipIndex !== undefined) {
        const memberships = state.memberships.filter((m) => m.item.bookingServiceIndex !== index);
        return validate({
          ...state,
          type: 'Invalid',
          services,
          memberships,
          end,
        });
      }

      return validate({
        ...state,
        type: 'Invalid',
        services: services,
        end: end,
      });
    },
    addProduct: (state, product: ConsumedProduct) => {
      const products = validateProducts(
        state.products.concat([
          {
            type: 'invalid',
            product: product,
          },
        ]),
      );

      return validate({
        ...state,
        type: 'Invalid',
        products: products,
      });
    },
    updateProductAt: (state, payload: { index: number; product: ConsumedProduct }) => {
      const { index, product } = payload;
      const products = pipe(
        state.products,
        A.updateAt(index, {
          type: 'valid',
          product,
        } as ConsumedProductValidation),
        O.getOrElseW(() => state.products),
      );

      return validate({
        ...state,
        type: 'Invalid',
        products,
      });
    },
    deleteProductAt: (state, index: number) => {
      const products = pipe(
        state.products,
        A.deleteAt(index),
        O.getOrElseW(() => state.products),
      );

      return validate({
        ...state,
        type: 'Invalid',
        products,
      });
    },
    confirmOverride: (state) => ({
      ...state,
      override: true,
    }),
    addMembership: (state, membership: MembershipItemConsumptionLock) => {
      return {
        ...state,
        memberships: [...state.memberships, membership],
      };
    },
    updateMembershipAt: (state, payload: { index: number; membership: MembershipItemConsumptionLock }) => {
      const { index, membership } = payload;
      return {
        ...state,
        memberships: state.memberships.map((m, i) => (i === index ? membership : m)),
      };
    },
    removeMembershipAt: (state, index: number) => {
      return {
        ...state,
        memberships: state.memberships.filter((_, i) => i !== index),
      };
    },
  },
  (dispatch) => {
    return {
      reset: dispatch.reset,
      setStart: dispatch.setStart,
      setEnd: dispatch.setEnd,
      setPerformer: dispatch.setPerformer,
      setRecurrenceRule: dispatch.setRecurrenceRule,
      setNotes: dispatch.setNotes,
      setClient: dispatch.setClient,
      addService: dispatch.addService,
      updateServicesPreview: dispatch.updateServicesPreview,
      updateServiceAt: dispatch.updateServiceAt,
      deleteServiceAt: dispatch.deleteServiceAt,
      addProduct: dispatch.addProduct,
      updateProductAt: dispatch.updateProductAt,
      deleteProductAt: dispatch.deleteProductAt,
      confirmOverride: dispatch.confirmOverride,
      addMembership: dispatch.addMembership,
      updateMembershipAt: dispatch.updateMembershipAt,
      removeMembershipAt: dispatch.removeMembershipAt,
    };
  },
);

export const withBookingFormContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithBookingFormContextProvider(props: P) {
    return (
      <BookingFormContext.Provider>
        <Content {...props} />
      </BookingFormContext.Provider>
    );
  };
};
