import { ClientPreview } from '@mero/api-sdk/dist/clients';
import { colors, sizes, Icon, IconProps, Row, Column, Avatar } from '@mero/components';
import { formatPhoneNumber } from '@mero/shared-components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import ClientBlocked from '@mero/components/lib/components/Icon/ClientBlocked';
import ClientWarned from '@mero/components/lib/components/Icon/ClientWarned';
import Line from '@mero/components/lib/components/Line';
import ClientBlockedLabel from '@mero/components/lib/components/Mero/ClientBlockedLabel';
import ClientBoostLabel from '@mero/components/lib/components/Mero/ClientBoostedLabel';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';
import Spacer from '@mero/components/lib/components/Spacer';
import SmallBody from '@mero/components/lib/components/Text/SmallBody';
import Title from '@mero/components/lib/components/Text/Title';

import HoverButtons from './HoverButtons';
import { withHoverState } from './HoverState';

export type ClientListItemProps = {
  /**
   * Service data to display
   */
  readonly client: Pick<
    ClientPreview,
    | 'firstname'
    | 'lastname'
    | 'isBlocked'
    | 'isWarned'
    | 'isFavourite'
    | 'photo'
    | 'phone'
    | 'isBoost'
    | 'hideBoostDetails'
  >;
  /**
   * Item press handler
   */
  readonly onPress?: () => void;

  /**
   * Custom styles TouchableOpacity container
   */
  readonly style?: StyleProp<ViewStyle>;

  readonly disabled?: boolean;

  readonly isHovered: boolean;

  readonly isSelected: boolean;

  readonly onDelete: () => void;
};

const ClientListItem: React.FC<React.PropsWithChildren<ClientListItemProps>> = ({
  client,
  onPress,
  style,
  isHovered,
  onDelete,
  disabled = false,
  isSelected,
}) => (
  <TouchableOpacity
    onPress={onPress}
    disabled={onPress === undefined}
    style={[{ padding: 8, borderWidth: 1, borderColor: colors.GEYSER, borderRadius: 6 }, style]}
  >
    <Column
      style={[
        { paddingLeft: 8, paddingRight: 4, paddingVertical: 8, borderRadius: 6 },
        isHovered && { backgroundColor: colors.ALABASTER },
        isSelected && { backgroundColor: colors.SKY_BLUE },
      ]}
    >
      <Row>
        <Avatar
          firstname={client.firstname ?? ''}
          lastname={client.lastname ?? ''}
          source={client.photo?.thumbnail}
          size={32}
        />
        <Column style={{ flex: 1, paddingLeft: 8 }}>
          <Title numberOfLines={1} ellipsizeMode="tail">
            {`${client.firstname ?? ''} ${client.lastname ?? ''}`.trim() || 'Fără nume'}{' '}
            {client.isBlocked ? <ClientBlocked /> : client.isWarned ? <ClientWarned /> : null}
          </Title>

          {client.phone && !client.hideBoostDetails ? (
            <SmallBody style={{ color: colors.COMET }} numberOfLines={1} ellipsizeMode="tail">
              {formatPhoneNumber(client.phone)}
            </SmallBody>
          ) : null}

          {client.isBlocked ? (
            <>
              <Spacer size="4" />
              <ClientBlockedLabel />
            </>
          ) : null}

          {client.hideBoostDetails ? (
            <>
              <Spacer size="4" />
              <ClientBoostLabel />
            </>
          ) : null}
        </Column>
        {isHovered && !disabled ? (
          <HoverButtons showEditIcon={false} onDeletePress={onDelete} />
        ) : disabled ? null : (
          <Icon type="next" color={colors.DARK_BLUE} />
        )}
      </Row>
    </Column>
  </TouchableOpacity>
);

export default pipe(ClientListItem, withHoverState);
