import { AppointmentId, CalendarEntryDetails } from '@mero/api-sdk';
import { AppointmentHistoryRecord } from '@mero/api-sdk/dist/calendar/appointment-history-record';
import { CheckoutTransactionPreview } from '@mero/api-sdk/dist/checkout/checkoutTransactionPreview';
import { colors, Column, H3s, Row, SmallBody, Spacer, styles as meroStyles, FormCard, Body } from '@mero/components';
import { formatDurationInMinutes } from '@mero/shared-components';
import { MeroUnits } from '@mero/shared-sdk';
import { flow } from 'fp-ts/function';
import { pipe } from 'fp-ts/lib/function';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import Button from '@mero/components/lib/components/Button';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import { ComputedInputState } from '../../../hooks/useSafeInput';

import {
  AppointmentService,
  BookingClient,
  BookingFormContext,
  ConsumedProduct,
} from '../../../contexts/BookingFormContext';
import { ClientDetailsContextProvider } from '../../../contexts/ClientDetailsContext';
import { CurrentPageInfo } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList } from '../../../types';
import { scaledToString } from '../../../utils/scaled';
import { getPriceText, PricesTotal, ServicePrice } from '../../../utils/servicePrice';
import AppointmentDetails from './AppointmentDetails';
import AppointmentSkeleton from './AppointmentSkeleton';
import Calendar from './Calendar';
import Client from './Client';
import {
  CheckoutIcon,
  CheckoutIconButton,
  ConfirmPendingIcon,
  DeleteIconButton,
  NewAppointmentIcon,
  NewAppointmentIconWhite,
  NoShowIcon,
} from './Icons';
import Product from './Product';
import Service from './Service';
import { BookedServiceWithWorkerItem, MembershipServices, OptionalNotes, ProductPrice } from './common';

export enum RightPane {
  Calendar = 'calendar',
  Client = 'client',
  Services = 'services',
  Products = 'products',
}

type Props = {
  page: CurrentPageInfo;
  appointmentId?: AppointmentId;
  existingAppointment?: CalendarEntryDetails.Appointment;
  hasPassed: boolean;
  startDateTime: DateTime;
  endDateTime: DateTime;
  timeZone: IANAZone;
  canEdit: CalendarEntryDetails.AppointmentEditableStatus.Editable['fields'];
  nonEmptyServices: BookedServiceWithWorkerItem[];
  usableMemberships: MembershipServices[];
  canAddMoreServices: boolean;
  showErrors: boolean;
  showProducts: boolean;
  hasNotesOrRecurrence: boolean;
  notesInputVisible: boolean;
  hasFinishedCheckout: boolean;
  finishedCheckout?: CheckoutTransactionPreview.Finished<MeroUnits.Any>;
  notesValue: ComputedInputState<OptionalNotes>;
  history: AppointmentHistoryRecord[];
  servicesPrice: ServicePrice[];
  productsPrice: ProductPrice[];
  onSelectService: (payload: { service: AppointmentService; index: number }) => void;
  onRemoveService: (payload: { service: unknown; index: number }) => void;
  onSelectProduct: (payload: { product: ConsumedProduct; index: number }) => void;
  onUpdateProduct: (payload: { product: ConsumedProduct; index: number }) => void;
  onRemoveProduct: (payload: { product: unknown; index: number }) => void;
  onClientRemove: () => void;
  onClientChange: (client: BookingClient) => void;
  isDirty: boolean;
  setDirty: () => void;
  isLoading: boolean;
  selectedService?: {
    service: AppointmentService;
    index: number;
  };
  setSelectedService: (value: { service: AppointmentService; index: number } | undefined) => void;
  selectedProduct?: {
    product: ConsumedProduct;
    index: number;
  };
  setSelectedProduct: (value: { product: ConsumedProduct; index: number } | undefined) => void;
  setNotesValue: (notes: string) => void;
  editRecurrenceRule: () => void;
  onClose: () => void;
  setShowNotesClicked: (value: boolean) => void;
  bookAgain: () => void;
  goToCheckoutDetails: () => void;
  total: PricesTotal;
  isPendingAppointment: boolean;
  durationInMinutes: number;
  acceptPendingAppointment: () => void;
  onDeleteBooking: () => void;
  canDeleteAppointment: boolean;
  canMarkAsNoShow: boolean;
  showCheckoutButton: boolean;
  onMarkAsNoShow: () => void;
  latestDraft: CheckoutTransactionPreview.AnyDraft;
  resetFormValues: (appointment: CalendarEntryDetails.Appointment) => void;
  onUpdateBooking: () => void;
  onSaveBooking: (payload?: { override?: boolean; onlyOnce?: boolean }) => void;
};
const AppointmentScreenDesktop: React.FC<Props> = ({
  isLoading,
  page,
  appointmentId,
  existingAppointment,
  hasPassed,
  startDateTime,
  hasFinishedCheckout,
  finishedCheckout,
  endDateTime,
  timeZone,
  canEdit,
  nonEmptyServices,
  usableMemberships,
  canAddMoreServices,
  showErrors,
  showProducts,
  hasNotesOrRecurrence,
  notesInputVisible,
  notesValue,
  history,
  servicesPrice,
  productsPrice,
  isDirty,
  setDirty,
  onSelectService,
  onClientRemove,
  onClientChange,
  onRemoveService,
  selectedService,
  setSelectedService,
  onSelectProduct,
  onUpdateProduct,
  onRemoveProduct,
  selectedProduct,
  setSelectedProduct,
  setNotesValue,
  editRecurrenceRule,
  onClose,
  setShowNotesClicked,
  bookAgain,
  goToCheckoutDetails,
  total,
  isPendingAppointment,
  durationInMinutes,
  acceptPendingAppointment,
  onDeleteBooking,
  canDeleteAppointment,
  canMarkAsNoShow,
  showCheckoutButton,
  onMarkAsNoShow,
  latestDraft,
  resetFormValues,
  onUpdateBooking,
  onSaveBooking,
}) => {
  const i18n = useTranslation('booking');
  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList>>();

  const [formState, { setStart, setEnd, updateServiceAt }] = BookingFormContext.useContext();
  const { client, performer } = formState;

  const [rightPane, setRightPane] = React.useState<RightPane>(RightPane.Calendar);

  const checkClientSelectedCallback = React.useCallback(() => rightPane === RightPane.Client, [rightPane]);

  const checkServiceSelectedCallback = React.useCallback(
    (idx: number) => selectedService?.index === idx && rightPane === RightPane.Services,
    [rightPane, selectedService],
  );

  const checkProductSelectedCallback = React.useCallback(
    (idx: number) => selectedProduct?.index === idx && rightPane === RightPane.Products,
    [rightPane, selectedProduct],
  );

  /**
   * On escape press
   */
  const refRightPane = React.useRef(rightPane);
  refRightPane.current = rightPane;

  useEscPressWeb(
    {
      onPress: () => {
        if (refRightPane.current !== RightPane.Calendar) {
          setRightPane(RightPane.Calendar);
        } else {
          onClose();
        }
      },
    },
    [isDirty, rightPane],
  );

  const rightPaneContent = React.useMemo(() => {
    switch (rightPane) {
      case RightPane.Client:
        return client.type === 'existing' ? (
          <Column style={{ flex: 1, marginBottom: 83 }}>
            <ClientDetailsContextProvider clientId={client.client._id} pageId={page.details._id}>
              <Client
                clientId={client.client._id}
                pageId={page.details._id}
                onClientRemove={() => setRightPane(RightPane.Calendar)}
              />
            </ClientDetailsContextProvider>
          </Column>
        ) : null;
      case RightPane.Services:
        return selectedService ? (
          <Service
            disabled={!canEdit.services}
            memberships={usableMemberships.filter((m) => m.items.some((i) => i._id === selectedService.service._id))}
            serviceIndex={selectedService.index}
            serviceId={selectedService.service._id}
            onSave={flow((service) => updateServiceAt({ service, index: selectedService.index }), setDirty)}
            onClose={flow(
              () => setSelectedService(undefined),
              () => setRightPane(RightPane.Calendar),
            )}
            onRemove={flow(
              () => {
                onRemoveService({ service: selectedService.service, index: selectedService.index });
              },
              () => setSelectedService(undefined),
              () => setRightPane(RightPane.Calendar),
              setDirty,
            )}
          />
        ) : null;
      case RightPane.Products:
        return selectedProduct ? (
          <Product
            //@TODO check if canEdit.products
            disabled={!canEdit.services}
            productIndex={selectedProduct.index}
            productId={selectedProduct.product._id}
            onSave={flow((product) => onUpdateProduct({ product: product, index: selectedProduct.index }), setDirty)}
            onClose={flow(
              () => setSelectedProduct(undefined),
              () => setRightPane(RightPane.Calendar),
            )}
            onRemove={flow(
              () => {
                onRemoveProduct({ product: selectedProduct.product, index: selectedProduct.index });
              },
              () => setSelectedService(undefined),
              () => setRightPane(RightPane.Calendar),
              setDirty,
            )}
          />
        ) : null;
      case RightPane.Calendar:
      default:
        return (
          <Calendar
            appointmentId={appointmentId}
            date={{ start: startDateTime, end: endDateTime, timeZone }}
            editable={{
              start: canEdit.start,
              end: canEdit.end,
            }}
            setStartDate={flow(setStart, setDirty)}
            setEndDate={flow(setEnd, setDirty)}
            onEditRecurrenceRule={editRecurrenceRule}
          />
        );
    }
  }, [
    rightPane,
    startDateTime,
    endDateTime,
    timeZone,
    canEdit,
    performer,
    editRecurrenceRule,
    selectedService,
    selectedProduct,
    usableMemberships,
  ]);

  return (
    <>
      <AppointmentSkeleton isLoading={isLoading}>
        <Row style={{ flex: 1 }}>
          {/** Left Pane */}
          <ScrollView style={{ width: 424, borderRightWidth: 1, borderRightColor: colors.GEYSER }}>
            <Spacer size={16} />
            <AppointmentDetails
              page={page}
              appointmentId={appointmentId}
              existingAppointment={existingAppointment}
              hasPassed={hasPassed}
              startDateTime={startDateTime}
              canEdit={canEdit}
              nonEmptyServices={nonEmptyServices}
              usableMemberships={usableMemberships}
              canAddMoreServices={canAddMoreServices}
              showErrors={showErrors}
              showProducts={showProducts}
              servicesPrice={servicesPrice}
              productsPrice={productsPrice}
              hasNotesOrRecurrence={hasNotesOrRecurrence}
              notesInputVisible={notesInputVisible}
              notesValue={notesValue}
              history={history}
              checkClientSelected={checkClientSelectedCallback}
              checkServiceSelected={checkServiceSelectedCallback}
              checkProductSelected={checkProductSelectedCallback}
              onClientPress={() => setRightPane(RightPane.Client)}
              onClientRemove={flow(onClientRemove, setDirty)}
              onClientChange={flow(onClientChange, setDirty)}
              onSelectService={flow(onSelectService, () => setRightPane(RightPane.Services))}
              onRemoveService={flow(onRemoveService, () => setRightPane(RightPane.Calendar))}
              onSelectProduct={flow(onSelectProduct, () => setRightPane(RightPane.Products))}
              onRemoveProduct={flow(onRemoveProduct, () => setRightPane(RightPane.Calendar))}
              setNotesValue={flow(setNotesValue, setDirty)}
              editRecurrenceRule={editRecurrenceRule}
              setShowNotesClicked={setShowNotesClicked}
            />
            <Spacer size={history.length === 0 ? 326 : 196} />
          </ScrollView>
          {/** Right Pane */}
          <Column style={{ width: 336 }}>{rightPaneContent}</Column>
        </Row>
        <FormCard
          dropShaddow
          paddings="button"
          style={[
            { position: 'absolute', bottom: 0, right: 0, left: 0 },
            hasFinishedCheckout && { backgroundColor: colors.SHAMROCK },
          ]}
        >
          {hasFinishedCheckout && finishedCheckout ? (
            <Row justifyContent="space-between" alignItems="center">
              <Column style={{ flex: 1 }}>
                <H3s style={{ color: colors.WHITE }}>
                  {finishedCheckout.paymentTypes.length === 0
                    ? i18n.t('checkout:addProtocol')
                    : i18n.t('checkout:totalPayed')}
                  :{' '}
                  <Body style={{ fontSize: 17, color: colors.WHITE }}>
                    {scaledToString(finishedCheckout.total.total.amount)} {i18n.t(finishedCheckout.total.total.unit)}
                  </Body>
                </H3s>
                <Spacer size={6} />
                <SmallBody style={{ color: colors.WHITE, fontSize: 12 }}>
                  {DateTime.fromJSDate(finishedCheckout.createdAt).toFormat('dd MMM. yyyy, HH:mm').toLowerCase()}
                </SmallBody>
              </Column>
              <TouchableOpacity style={{ alignItems: 'center', marginRight: 24 }} onPress={bookAgain}>
                <NewAppointmentIconWhite />
                <SmallBody style={[{ color: colors.WHITE, fontSize: 12 }, meroStyles.text.semibold]}>
                  {i18n.t('newAppointmentButton')}
                </SmallBody>
              </TouchableOpacity>
              <TouchableOpacity style={{ alignItems: 'center', marginRight: 24 }} onPress={goToCheckoutDetails}>
                <CheckoutIcon />
                <SmallBody style={[{ color: colors.WHITE, fontSize: 12 }, meroStyles.text.semibold]}>
                  {i18n.t('checkoutButton')}
                </SmallBody>
              </TouchableOpacity>
            </Row>
          ) : (
            <Row justifyContent="space-between" alignItems="center">
              <Column style={{ flex: 1 }}>
                <Body>
                  <Trans
                    ns="booking"
                    i18nKey="totalPayment"
                    values={{
                      value: getPriceText({
                        price: total,
                        t: i18n.t,
                      }),
                      currency: total.type === 'Hidden' ? '' : i18n.t(MeroUnits.RON.code),
                    }}
                  >
                    <H3s style={{ fontSize: 17 }}>0</H3s>1
                  </Trans>
                </Body>
                <SmallBody style={{ color: colors.COMET, fontSize: 12 }}>
                  {i18n.t('durationInMinutes', { value: formatDurationInMinutes(durationInMinutes, true) })}
                </SmallBody>
              </Column>
              <Row>
                {appointmentId ? (
                  <>
                    {!isDirty && isPendingAppointment ? (
                      <TouchableOpacity
                        style={{ alignItems: 'center', marginLeft: 24 }}
                        onPress={acceptPendingAppointment}
                      >
                        <ConfirmPendingIcon />
                        <SmallBody style={[{ color: colors.SHAMROCK, fontSize: 12 }, meroStyles.text.semibold]}>
                          {i18n.t('confirmButton')}
                        </SmallBody>
                      </TouchableOpacity>
                    ) : null}
                    {!isDirty && isPendingAppointment ? (
                      <TouchableOpacity style={{ alignItems: 'center', marginLeft: 24 }} onPress={onDeleteBooking}>
                        <DeleteIconButton />
                        <SmallBody style={[{ color: colors.RADICAL_RED, fontSize: 12 }, meroStyles.text.semibold]}>
                          {i18n.t('rejectButton')}
                        </SmallBody>
                      </TouchableOpacity>
                    ) : null}
                    {!isDirty && canDeleteAppointment ? (
                      <TouchableOpacity style={{ alignItems: 'center', marginLeft: 24 }} onPress={onDeleteBooking}>
                        <DeleteIconButton />
                        <SmallBody style={[{ color: colors.RADICAL_RED, fontSize: 12 }, meroStyles.text.semibold]}>
                          {i18n.t('deleteButton')}
                        </SmallBody>
                      </TouchableOpacity>
                    ) : null}
                    {!isDirty && canMarkAsNoShow ? (
                      <TouchableOpacity style={{ alignItems: 'center', marginLeft: 24 }} onPress={onMarkAsNoShow}>
                        <NoShowIcon />
                        <SmallBody
                          style={[{ color: colors.OUTRAGEOUS_ORANGE, fontSize: 12 }, meroStyles.text.semibold]}
                        >
                          {i18n.t('noShow')}
                        </SmallBody>
                      </TouchableOpacity>
                    ) : null}
                    {/*{!isDirty && hasPassed ? (*/}
                    {/*  <TouchableOpacity style={{ alignItems: 'center', marginRight: 24 }}>*/}
                    {/*    <PaymentIcon />*/}
                    {/*    <SmallBody style={[{ color: colors.DARK_BLUE, fontSize: 12 }, meroStyles.text.semibold]}>*/}
                    {/*      {i18n.t('paymentLink')}*/}
                    {/*    </SmallBody>*/}
                    {/*  </TouchableOpacity>*/}
                    {/*) : null}*/}
                    {!isDirty && (
                      <TouchableOpacity style={{ alignItems: 'center', marginLeft: 24 }} onPress={bookAgain}>
                        <NewAppointmentIcon />
                        <SmallBody style={[{ color: colors.DARK_BLUE, fontSize: 12 }, meroStyles.text.semibold]}>
                          {i18n.t('newAppointmentButton')}
                        </SmallBody>
                      </TouchableOpacity>
                    )}

                    {/*<TouchableOpacity style={{ alignItems: 'center', marginRight: 24 }}>*/}
                    {/*  <MoreIcon />*/}
                    {/*  <SmallBody style={[{ color: colors.DARK_BLUE, fontSize: 12 }, meroStyles.text.semibold]}>*/}
                    {/*    {i18n.t('more')}*/}
                    {/*  </SmallBody>*/}
                    {/*</TouchableOpacity>*/}
                    {!isDirty && showCheckoutButton ? (
                      <Column style={{ marginLeft: 24 }}>
                        <Button
                          padding={24}
                          LeftComponent={CheckoutIconButton}
                          text={i18n.t('checkout')}
                          onPress={() =>
                            latestDraft
                              ? navigation.navigate('CombineCheckout', {
                                  screen: 'CheckoutStack',
                                  params: {
                                    screen: 'AddProceedScreen',
                                    params: {
                                      type: 'Booking',
                                      transactionId: latestDraft._id,
                                    },
                                  },
                                })
                              : navigation.navigate('CombineCheckout', {
                                  screen: 'CheckoutStack',
                                  params: {
                                    screen: 'AddProceedScreen',
                                    params: {
                                      type: 'Booking',
                                      ...(existingAppointment
                                        ? {
                                            appointmentId: existingAppointment._id,
                                            calendarId: existingAppointment.calendarId,
                                            occurrenceIndex: existingAppointment.occurrenceIndex,
                                          }
                                        : {}),
                                    },
                                  },
                                })
                          }
                        />
                      </Column>
                    ) : null}
                  </>
                ) : null}
                {isDirty && existingAppointment ? (
                  <Column style={{ marginLeft: 24 }}>
                    <Button
                      color={colors.RADICAL_RED}
                      backgroundColor={colors.WHITE}
                      text={i18n.t('revertChanges')}
                      onPress={flow(
                        () => resetFormValues(existingAppointment),
                        () => setRightPane(RightPane.Calendar),
                      )}
                    />
                  </Column>
                ) : null}
                {isDirty && (
                  <Column style={{ marginLeft: 24 }}>
                    <Button
                      text={appointmentId ? i18n.t('saveChanges') : i18n.t('saveAppointment')}
                      padding={24}
                      onPress={appointmentId ? onUpdateBooking : onSaveBooking}
                    />
                  </Column>
                )}
              </Row>
            </Row>
          )}
        </FormCard>
      </AppointmentSkeleton>
    </>
  );
};

export default pipe(AppointmentScreenDesktop);
