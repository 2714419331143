import { UserAppointment } from '@mero/api-sdk/dist/calendar';
import { FormCard, sizes } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import UserAppointmentListItem from './UserAppointmentList/UserAppointmentListItem';

type Props = {
  readonly appointment: UserAppointment;
  readonly now: DateTime;
  readonly onPress: (appointment: UserAppointment) => void;
  readonly isBoost?: boolean;
  readonly size?: 'small' | 'normal';
};

const ClientAppointmentListItemView: React.FC<Props> = ({ appointment, now, size, onPress, isBoost = false }) => {
  // const start = React.useMemo(() => DateTime.fromJSDate(appointment.start), [appointment.start]);
  // const end = React.useMemo(() => DateTime.fromJSDate(appointment.end), [appointment.end]);
  // const startStr = React.useMemo(() => capitalize(start.toFormat('ccc dd MMM', { locale: 'ro' })), [start]);
  // const durationImMinutes = React.useMemo(() => Math.floor((end.toMillis() - start.toMillis()) / 60000), [start, end]);
  // const timeStr = React.useMemo(
  //   () => `${start.toFormat('H:mm')} - ${end.toFormat('H:mm')} (${formatDurationInMinutes(durationImMinutes)})`,
  //   [start, end, durationImMinutes],
  // );
  // const isPast = React.useMemo(() => start < now, [start, now]);
  const onPressCallback = React.useCallback(() => {
    onPress(appointment);
  }, [appointment, onPress]);

  return (
    <FormCard
      dropShaddow
      style={{
        marginLeft: sizes[16],
        marginRight: sizes[16],
        marginBottom: sizes[16],
      }}
      rounded
      paddings="none"
    >
      <TouchableOpacity
        onPress={onPressCallback}
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <UserAppointmentListItem
          size={size}
          appointment={appointment}
          hideArrow={appointment.status === 'cancelled'}
          isBoost={isBoost}
        />
      </TouchableOpacity>
    </FormCard>
  );
};

export default ClientAppointmentListItemView;
